<!-- Card template -->
<div
  class="listing-card"
  [ngClass]="
    componentType === 'PPSS' ? 'listing-card-ppss' : 'listing-card-navs-idcw'
  "
>
  <!-- Card header -->
  <div class="header">
    <a
      class="fund-name"
      [routerLink]="fundListingCard?.fundLink?.overview"
      (click)="navigateToFundDetail($event)"
      >{{ fundListingCard?.fundName }}</a
    >
    <span
      class="icon-fti_close"
      *ngIf="showClosebutton"
      (click)="onCloseButtonClicked(fundListingCard?.fundId)"
    ></span>
  </div>

  <!-- Card body -->
  <div
    class="body"
    [ngClass]="componentType === 'PPSS' ? 'body-ppss' : 'body-navs-idcw'"
  >
    <!-- Fund listing PPSS card layout -->
    <div *ngIf="componentType === 'PPSS'">
      <!-- Fund description -->
      <p class="fund-description">
        {{ fundListingCard?.fundDescription | ftDashIfEmpty }}
      </p>

      <!-- Performance section -->
      <div class="fund-performance">
        <div *ngIf="fundListingCard?.shareClassData?.performanceData">
          <div class="perf-name">
            <span
              ><a [routerLink]="fundListingCard?.fundLink?.performance">{{
                "products.ppss-card-annualized-returns" | translate
              }}</a></span
            >
            <span
              >{{ "products.as-of" | translate }}
              {{
                fundListingCard.shareClassData.performanceData.asOfDate
              }}</span
            >
          </div>

          <div class="perf-value">
            <span
              >{{
                ppssPerfValue
                  ? ppssPerfValue
                  : fundListingCard.shareClassData.performanceData
                      .averageAnnualReturnSinceInception
              }}%</span
            >
            <ft-select
              (dropDownChange)="onPerfPeriodChange($event)"
              [dropdownList]="fundListingCard?.ppssPeriodDropdownItems"
              [customClasses]="'select-filter'"
              [(ngModel)]="selectedPpssPeriodDropdownValue"
            ></ft-select>
          </div>
        </div>

        <div *ngIf="!fundListingCard?.shareClassData?.performanceData">
          <div class="perf-name">
            <span class="no-perf">{{
              "products.ppss-card-annualized-returns" | translate
            }}</span>
          </div>

          <div class="perf-value">
            <span class="no-perf">{{
              "products.ppss-card-na" | translate
            }}</span>
          </div>
        </div>
      </div>

      <!-- Additional info -->
      <div class="additional-info">
        <ng-template #tipContent>
          <div id="{{ fundListingCard?.fundId }}">
            <div>
              {{ fundListingCard?.suitableFor }}
            </div>
          </div>
        </ng-template>
        <div
          [ngbTooltip]="tipContent"
          triggers="click"
          tooltipClass="custom-tooltip-class"
          placement="bottom"
        >
          <span *ngIf="fundListingCard?.suitableFor" class="icon">i</span>
          &nbsp;
          <span *ngIf="fundListingCard?.suitableFor">
            {{ "products.ppss-suitable-for" | translate }}
          </span>
        </div>
      </div>

      <hr class="dotted" />
    </div>

    <!-- Fund listing NAVs card layout -->
    <div *ngIf="componentType === 'LatestNavs'">
      <!-- Nav as of date -->
      <div class="nav-details">
        <span>NAV</span>

        <div>
          <span>
            {{ "products.as-of" | translate }}
            {{
              fundListingCard?.shareClassesChunksData[0][0]?.navData?.navDate
            }}
          </span>
        </div>
      </div>

      <!-- Nav section -->
      <div class="fund-nav">
        <div
          *ngFor="
            let shareClassData of fundListingCard?.shareClassesChunksData[
              defaultSetForChunksDisplay
            ]
          "
        >
          <span>{{ shareClassData?.shareClassName }}</span>
          <span>{{
            shareClassData?.navData?.navValue | inrSymbol: "pre"
          }}</span>
        </div>
      </div>

      <!-- Toggle Navs section -->
      <div class="toggle-navs">
        <span
          (click)="toggleNavs('previous')"
          [ngClass]="
            disablePreviousToggleNav
              ? 'toggle-nav-button-disabled'
              : 'toggle-nav-button-enabled'
          "
        >
          <<
        </span>
        <span
          (click)="toggleNavs('next')"
          [ngClass]="
            disableNextToggleNav
              ? 'toggle-nav-button-disabled'
              : 'toggle-nav-button-enabled'
          "
          >>></span
        >
      </div>
    </div>

    <!-- Fund listing IDCW card layout -->
    <div *ngIf="componentType === 'IDCW'" class="idcw-card">
      <div *ngIf="fundListingCard?.shareClassesData.length">
        <!-- Share class dropdown -->
        <div class="idcw-select-share-class">
          <select (change)="onShareClassNameChange($event)">
            <option
              *ngFor="let option of fundListingCard?.shareClassesData"
              [attr.value]="option.shareClassName"
            >
              {{ option.shareClassName }}
            </option>
          </select>
        </div>

        <!-- Share class idcw details -->
        <div class="idcw-details">
          <div
            *ngFor="
              let rowElements of distributionDataAsLists[
                defaultOptionForShareClassName
              ]
            "
          >
            <span>{{
              "products.idcw-card-row-" + rowElements[0] | translate
            }}</span>
            <span>{{ rowElements[1] | ftDashIfEmpty }}</span>
          </div>
        </div>
      </div>

      <div
        *ngIf="!fundListingCard?.shareClassesData.length"
        class="idcw-no-details"
      >
        <span>{{
          "products.idcw-card-no-distribution-details" | translate
        }}</span>
      </div>
    </div>

    <!-- Listing action buttons -->
    <div
      class="action-buttons"
      [ngClass]="
        componentType === 'PPSS'
          ? 'action-buttons-ppss'
          : 'action-buttons-navs-idcw'
      "
    >
      <!-- Actions buttons first row -->
      <div>
        <!-- Fund detail link -->
        <button>
          <a
            [routerLink]="fundListingCard?.fundLink?.overview"
            (click)="navigateToFundDetail($event)"
            >{{ "products.fund-listing-button-fund-details" | translate }}</a
          >
        </button>

        <!-- Historical navs link -->
        <button *ngIf="componentType !== 'PPSS'">
          <a
            [routerLink]="fundListingCard?.fundLink?.historicalNavs"
            (click)="navigateToFundDetail($event)"
            >{{ "products.fund-listing-button-historical-navs" | translate }}</a
          >
        </button>

        <!-- Fund comparison selection -->
        <div *ngIf="componentType === 'PPSS'">
          <input
            class="chkbox"
            type="checkbox"
            [(ngModel)]="isFundChecked"
            [attr.id]="'compare-' + fundListingCard?.fundId"
            [name]="'compare-name-' + fundListingCard?.fundId"
            (click)="onComparisonCheckboxClicked()"
            (change)="
              onFundSelectionForComparison(
                fundListingCard?.fundId,
                fundListingCard?.fundName
              )
            "
          />
          <label [attr.for]="'compare-' + fundListingCard?.fundId">{{
            "products.fund-listing-button-compare" | translate
          }}</label>
        </div>
      </div>

      <!-- Actions buttons second row -->
      <div *ngIf="componentType !== 'PPSS'">
        <!-- Fund comparison selection -->
        <div class="box-spacing">
          <input
            type="checkbox"
            [(ngModel)]="isFundChecked"
            [attr.id]="'compare-' + fundListingCard?.fundId"
            (click)="onComparisonCheckboxClicked()"
            (change)="
              onFundSelectionForComparison(
                fundListingCard?.fundId,
                fundListingCard?.fundName
              )
            "
          />
          <label [attr.for]="'compare-' + fundListingCard?.fundId">{{
            "products.fund-listing-button-compare" | translate
          }}</label>
        </div>
      </div>
    </div>
  </div>

  <!-- Card footer -->
  <div
    class="footer"
    [ngClass]="{ 'footer-disabled': !fundListingCard?.enableInvestNow }"
    (click)="investNow()"
  >
    <span>{{ "products.fund-listing-button-invest-now" | translate }}</span>
  </div>
</div>
