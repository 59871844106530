import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service';
import { FundListingBRConfiguration, FundListingDataFormat } from '@types';
import { Logger } from '@utils/logger';
import { Observable, Subject } from 'rxjs';

import {
  StorageOperation,
  WatchListActionType,
  WatchListState,
  WatchListToastAction,
} from '../watch-list.type';
import { FundListingService } from '@components/products/fund-listing/services/fund-listing.service';
import { TranslateService } from '@components/shared/translate/translate.service';

const logger = Logger.getLogger('WatchListService');

@Injectable({
  providedIn: 'root',
})
export class WatchListService {
  // Toast action
  private toastAction$: Subject<WatchListToastAction>;

  // Compare state related declarations.
  private state: WatchListState;
  private watchListState$: Subject<WatchListState>;
  private isfundListingDataLoaded = false;
  private watchListLimit = 9;

  constructor(
    private storageService: StorageService,
    private fundListingService: FundListingService,
    private translateService: TranslateService
  ) {
    this.state = {
      watchlistData: [],
      addFundList: [],
      isLoaded: false,
      selectedFundIds: [],
    };

    this.watchListState$ = new Subject();
    this.toastAction$ = new Subject();
  }

  isWatchlist$(fund: string): Observable<boolean> {
    return new Observable((observer) => {
      this.storageService.retrieveFundFavorites().then((watchlist) => {
        observer.next(watchlist && watchlist.indexOf(fund) !== -1);
        observer.complete();
      });
    });
  }

  setWatchListLimit(limit: number): void {
    this.watchListLimit = limit;
  }

  toggleFavorite(
    fund: string,
    operation: StorageOperation,
    isFundPage?: boolean
  ): void {
    // Handled add/Remove/already added/Maximum limit reached

    this.loadWatchlist$().subscribe((watchlist) => {
      const newList = watchlist || [];
      const index = watchlist?.indexOf(fund);
      if (operation === StorageOperation.REMOVE) {
        if (index !== -1) {
          newList.splice(index, 1);
          this.state.selectedFundIds = newList;
          this.storageService.storeFundFavorites(newList);
          if (isFundPage) {
            this.publishToastAction({
              type: WatchListActionType.REMOVED,
              message: this.translateService.instant(
                'products.watch-list-fund-removed'
              ),
            });
          }
          if (this.state?.watchlistData.length) {
            this.removeWatchListDataByFundId(fund);
          }
          this.watchListState$.next(this.state);
        } else {
          this.publishToastAction({
            type: WatchListActionType.REMOVED,
            message: this.translateService.instant(
              'products.watch-list-fund-removed'
            ),
          });
        }
      }

      if (operation === StorageOperation.SET) {
        if (!index || index === -1) {
          if (this.watchListLimit > newList?.length) {
            newList.push(fund); // push only if condition is true
            this.addWatchListDataByFundId(fund);
            this.publishToastAction({
              type: WatchListActionType.ADDED,
              message: this.translateService.instant(
                'products.watch-list-fund-added'
              ),
            });
            this.state.selectedFundIds = newList;
            this.storageService.storeFundFavorites(newList);
            this.watchListState$.next(this.state);
          } else {
            this.publishToastAction({
              type: WatchListActionType.LIMIT_REACHED,
              message: this.translateService.instant(
                'products.watch-list-limit-reached'
              ),
            });
          }
        } else {
          this.publishToastAction({
            type: WatchListActionType.ALREADY_EXIST,
            message: this.translateService.instant(
              'products.watch-list-fund-already-added'
            ),
          });
        }
      }
    });
  }

  loadWatchlist$(): Observable<string[]> {
    return new Observable((observer) => {
      this.storageService.retrieveFundFavorites().then((watchlist) => {
        observer.next(watchlist);
        observer.complete();
      });
    });
  }

  // update list of funds in watchlist
  updateWatchListToStorage(funds: string[]): void {
    if (this.watchListLimit >= funds?.length) {
      this.storageService.storeFundFavorites(funds);
      this.state.selectedFundIds = funds;
      this.addWatchListData(funds);
      this.watchListState$.next(this.state);
    }
  }

  /**
   * Return the get watch list toast update as observable.
   */
  getWatchListToastUpdate$(): Observable<WatchListToastAction> {
    return this.toastAction$.asObservable();
  }

  /**
   * Publish WatchListToastAction event trigger to the subscribers.
   * @param toastAction boolean flag, if limit reached or not
   */
  publishToastAction(toastAction: WatchListToastAction): void {
    this.toastAction$.next(toastAction);
  }
  /**
   * Return watch list state as observable.
   */
  getWatchListState$(): Observable<WatchListState> {
    return this.watchListState$.asObservable();
  }

  /**
   * Fetch the full watchlist info for the funds available in local storage and initialize state.
   */
  populate(brConfiguration: FundListingBRConfiguration): void {
    this.fundListingService.populate(brConfiguration, false);
    this.fundListingService.getFundInformationState$().subscribe(
      async (state) => {
        logger.debug('Mapped fund listing state: ', state);
        this.isfundListingDataLoaded = state.isLoaded;

        if (this.isfundListingDataLoaded) {
          // Assign received state for specific component type.
          this.state.assetClassFilterDropdown = state?.filterDropdowns.find(
            (dp) => dp.filterName === 'assetClass'
          );
          this.state.ppssState = state?.ppssState;
          this.state.addFundList = state?.ppssState?.ppssData.map((fd) => {
            return {
              fundId: fd.fundId,
              fundName: fd.fundName,
              assetClass: fd.assetClass,
            };
          });

          // Add push fund data in watchlist data for which the fundId is available in local storage
          this.storageService.retrieveFundFavorites().then((watchlist) => {
            this.addWatchListData(watchlist || []);
            this.state.selectedFundIds = watchlist;
            this.state.isLoaded = true;
            this.watchListState$.next(this.state);
          });
        }
      },
      (error) => {
        logger.error('Error in invoking watch list service: ', error);
        this.watchListState$.error(error);
      }
    );
  }

  addWatchListData(funds: string[]): void {
    this.state.watchlistData = [];
    this.state.ppssState?.ppssData.forEach((data: FundListingDataFormat) => {
      if (funds.includes(data.fundId)) {
        this.state.watchlistData.push(data);
      }
    });
  }

  addWatchListDataByFundId(fundId: string): void {
    const index = this.state.watchlistData.findIndex(
      (fd) => fd?.fundId === fundId
    );
    if (index === -1) {
      const watclistData = this.state?.ppssState?.ppssData.find(
        (fd) => fd.fundId === fundId
      );
      if (watclistData) {
        this.state.watchlistData.push(
          this.state?.ppssState?.ppssData.find((fd) => fd.fundId === fundId)
        );
      }
    }
  }

  removeWatchListDataByFundId(fundId: string): void {
    const index = this.state.watchlistData?.findIndex(
      (fd) => fd?.fundId === fundId
    );

    if (index !== -1) {
      this.state.watchlistData?.splice(index, 1);
    }
  }
}
