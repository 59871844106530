import { Component, Input } from '@angular/core';
import { VerticalTabsContactItem } from '../vertical-tabs.types';

@Component({
  selector: 'ft-vertical-tabs-contacts',
  templateUrl: './vertical-tabs-contacts.component.html',
  styleUrls: ['./vertical-tabs-contacts.component.scss'],
})
export class VerticalTabsContactsComponent {
  @Input() contactInfo: VerticalTabsContactItem[];
  @Input() accordionContent = false;
  @Input() paddingNone = false;
}
