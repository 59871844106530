export type State = {
  id: string;
  data_id: string;
  name: string;
  slug?: string;
};

// FIXME why do we need the class?
export class States {
  public static states: State[] = Array(
    { id: 'all-states', data_id: '', name: 'All States' },
    { id: 'AndhraPradesh', data_id: '0', name: 'Andhra Pradesh' },
    { id: 'Assam', data_id: '1', name: 'Assam' },
    { id: 'Bihar', data_id: '2', name: 'Bihar' },
    { id: 'Chandigarh', data_id: '3', name: 'Chandigarh' },
    { id: 'Chattisgarh', data_id: '4', name: 'Chattisgarh' },
    { id: 'Goa', data_id: '5', name: 'Goa' },
    { id: 'Gujarat', data_id: '6', name: 'Gujarat' },
    { id: 'Haryana', data_id: '7', name: 'Haryana' },
    { id: 'HimachalPradesh', data_id: '8', name: 'Himachal Pradesh' },
    { id: 'JammuKashmir', data_id: '9', name: 'Jammu & Kashmir' },
    { id: 'Jharkhand', data_id: '10', name: 'Jharkhand' },
    { id: 'Karnataka', data_id: '11', name: 'Karnataka' },
    { id: 'Kerala', data_id: '12', name: 'Kerala' },
    { id: 'MadhyaPradesh', data_id: '13', name: 'Madhya Pradesh' },
    { id: 'Maharashtra', data_id: '14', name: 'Maharashtra' },
    { id: 'Meghalaya', data_id: '15', name: 'Meghalaya' },
    { id: 'Nagaland', data_id: '16', name: 'Nagaland' },
    { id: 'NewDelhi', data_id: '17', name: 'New Delhi' },
    { id: 'Odisha', data_id: '18', name: 'Odisha' },
    { id: 'Punjab', data_id: '19', name: 'Punjab' },
    { id: 'Rajastan', data_id: '20', name: 'Rajastan' },
    { id: 'Sikkim', data_id: '21', name: 'Sikkim' },
    { id: 'TamilNadu', data_id: '22', name: 'TamilNadu' },
    { id: 'Telangana', data_id: '23', name: 'Telangana' },
    { id: 'Tripura', data_id: '27', name: 'Tripura' },
    { id: 'Uttarpradesh', data_id: '24', name: 'Uttar Pradesh' },
    { id: 'Uttarakhand', data_id: '25', name: 'Uttarakhand' },
    { id: 'WestBengal', data_id: '26', name: 'West Bengal' }
  );
}
