import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  FootnoteService,
  FootnoteServiceToken,
} from '../../../types/footnote-service.interface';
import {
  DebugService,
  DebugServiceToken,
} from '../../../types/debug-service.interface';

/**
 * Footnote Component
 *
 * Marks the position where a footnote reference(s) **could** appear
 *
 * **TODO**: swap whitespace between this and previous sibling with `&nbsp;` to prevent newline. See COREWEB-1647
 *
 * Selector: `ft-footnote`
 *
 * Exported as: `FootnoteComponent`
 *
 */
@Component({
  selector: 'ft-footnote',
  templateUrl: './footnote.component.html',
  styleUrls: ['./footnote.component.scss'],
})
export class FootnoteComponent {
  /**
   * This acts as an ID for the footnote's position on the parent component
   */
  @Input()
  set placement(value: string) {
    this._placement = value;
    if (value) {
      // this will trigger a new observable from footnoteService.getRefs$()
      // it allows for the Placement being changed, or set after init
      this.placement$.next(value);
    }
  }
  get placement(): string {
    return this._placement;
  }

  private _placement: string;
  private placement$: ReplaySubject<string> = new ReplaySubject(1);

  /**
   * Comma delimited list of footnote references
   * e.g. `'1, 2, 3'`
   */
  refs$: Observable<string>;

  /**
   * Indicates if the component should be in debug mode
   */
  debug$: Observable<boolean>;

  /**
   * This subscribes refs$ and (optional) debug$ observables
   * @param footnoteService A service for the component to register itself against and get matching reference numbers
   * @param debugService Optional service that can put component into debug mode
   */
  constructor(
    @Inject(FootnoteServiceToken) private footnoteService: FootnoteService,
    @Inject(DebugServiceToken) private debugService?: DebugService
  ) {
    this.refs$ = this.placement$.pipe(
      switchMap(
        (p: string): Observable<string[]> => {
          return this.footnoteService.getRefs$(p);
        }
      ),
      map(this.mapRefs)
    );

    if (this.debugService) {
      this.debug$ = this.debugService.getFootnoteDebug$();
    }
  }
  private mapRefs = (refs: string[]): string => refs?.join(',&nbsp;');
}
