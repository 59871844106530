import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  Inject,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { InUserType, SimplyLinkItem } from '@types';
import {
  NavItem,
  GlobalItem,
  SocialMediaItem,
  MobileSegment,
} from './footer.interfaces';
import get from 'lodash/get';
import { AnalyticsService } from '@services/analytics.service';
import { WINDOW } from '@ng-web-apis/common';
import { StorageService } from '@services/storage.service';
import { AppStateService } from '@services/app-state.service';
import { TranslateService } from '../translate/translate.service';
import { SessionService } from '@services/session.service';
import { Cities } from '../../../ft-constants/cities';
import { CommonService } from '@services/common.service';
import Citylist from '../../../../assets/data/citylist.json';
import { Constant } from 'src/app/ft-constants/constant';
import { LoginService } from '@services/login.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { getModelFromRef, getMenus } from '@utils/pagemodel-utils';

export interface DataWindow extends Window {
  // TODO: define type for dataLayer
  dataLayer: any[];
}

@Component({
  selector: 'ft-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  private unsubscribe$: Subject<void> = new Subject<void>();
  showSubmenu = false;
  menu: any;
  footerSubCat1 = false;
  footerSubCat2 = false;
  footerSubCat3 = false;
  fullYear = new Date().getFullYear();

  name = '';
  email = '';
  phone = '';
  city = '';
  url: string;
  otp: string;
  utmParams = [];
  distributerFormData = {
    refNo: '',
    mobileNo: '',
    emailId: '',
    guId: '',
  };
  citiesValues: any = [];
  ftPlayStoreLink = Constant.ftPlayStoreLink;
  ftAppStoreLink = Constant.ftAppStoreLink;
  ftiWhatsApp = Constant.ftiWhatsApp;
  ftiFblink = Constant.ftiFblink;
  ftiTwitterLink = Constant.ftiTwitterLink;
  ftiYoutubeLink = Constant.ftiYoutubeLink;
  ftiLinkedInLink = Constant.ftiLinkedInLink;
  isLoggedIn: boolean;
  isAdvisor: boolean;

  constructor(
    private analyticsService: AnalyticsService,
    private storageService: StorageService,
    @Inject(WINDOW) private windowRef: DataWindow,
    public appStateService: AppStateService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private loginService: LoginService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.appStateService = appStateService;
  }
  /**
   * Get Country selector for footer
   */
  getCountryItems(): SimplyLinkItem[] {
    return;
  }

  /**
   * Get language selector for footer
   */
  getLangItems(): SimplyLinkItem[] {
    return;
  }

  /**
   * Get Navigational menu for footer
   */
  getSiteNavItems(): NavItem[] {
    return;
  }

  /**
   * Get global Links for footer
   */
  getGlobalItems(): GlobalItem[] {
    return;
  }

  /**
   * Get Social Media links for footer
   */
  getSocialMediaItems(): SocialMediaItem[] {
    return;
  }

  /**
   * Generate Segment selector for Mobile
   */
  getRoleNavData(): MobileSegment[] {
    return;
  }

  /**
   * Converting roles to required mobile segments
   */
  private getSegmentsForMobile(): MobileSegment[] {
    return;
  }

  /**
   * Segment change for mobile view
   * @param event - click event
   */
  // To do: is this listener needed ?
  // @HostListener('click', ['$event'])
  // onClick(event) {
  //   return;
  // }

  /**
   * Converting BR menu item to EDS GlobalItem interface
   * @param globalItems - BR array to convert
   */
  convertGlobal(globalItems: Array<any>): GlobalItem[] {
    if (!globalItems) {
      return [];
    }
    const globalItemsEds = [];
    globalItems.forEach((item) => {
      const globalItem = {
        linkText: item.name,
        href: get(item, 'links.site.href', ''),
      };
      globalItemsEds.push(globalItem);
    });
    return globalItemsEds;
  }

  ngOnInit(): void {
    this.initMenu();
    // Get cities
    this.citiesValues = Citylist;
    this.checkUserType();
  }

  checkUserType() {
    this.loginService
      .isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn: boolean) => {
        this.isLoggedIn = loggedIn;
        this.cdr.detectChanges();
        this.loginService
          .loggedInUserType$()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((userType: InUserType) => {
            this.isAdvisor = userType === InUserType.ADVISOR;
            this.cdr.detectChanges();
          });
      });
  }

  // for overlay button in XM
  get menuModel() {
    return getModelFromRef(this.page, this.component, 'menu');
  }

  private initMenu() {
    this.menu = getMenus(this.page, this.component);
  }
  get footerLables() {
    const footerValue = this.component.getModels()?.Footer;

    return footerValue;
  }
  careesFirstMenu(start: any, end: any) {
    const menuval = getMenus(this.page, this.component).siteMenuItems[1] // this.component.getModels<MenuModels>()?.menu
      .childMenuItems;
    const menuarrayvalue = [];
    for (let i = start; i <= end; i++) {
      menuarrayvalue.push(menuval[i]);
    }
    return menuarrayvalue;
  }
  get isPreview() {
    return this.page.isPreview();
  }
  getIconandLink(linkvalue: any) {}
  displaySubMenu(event) {
    if (event.target.firstElementChild.classList.contains('open')) {
      event.target.firstElementChild.classList.remove('open');
      event.target.classList.add('collapsed');
    } else {
      event.target.classList.remove('collapsed');
      event.target.firstElementChild.classList.add('open');
    }
  }

  /**
   * Sets Analytics data on click of footer events
   */
  setFooterAnalytics(
    noSecondLevel?: boolean,
    topCategory?: any,
    category?: any,
    subCategory?: any
  ): boolean {
    const analyticsObj = {
      event: 'Event All pages',
      category: 'Footer',
      action: noSecondLevel
        ? topCategory?.name
        : category
        ? category?.name
        : 'click',
      label: subCategory?.name,
    };
    this.analyticsService.trackEvent(analyticsObj);
    return true;
  }

  setCareersAnalytics(category, subCategory) {
    const analyticsObj = {
      event: 'Event All pages',
      category: 'Footer',
      action: category?.name,
      label: subCategory?.name,
    };
    this.analyticsService.trackEvent(analyticsObj);
  }

  getUrl(url) {
    if (url) {
      let newUrl = url;
      if (url.startsWith('/ngGuest') || url.startsWith('/guest')) {
        newUrl = this.appStateService.getftiAccountsUrl() + url;
      }
      return url;
    }
  }

  setSocialMediaAnalytics(label) {
    this.analyticsService.trackEvent({
      event: 'Event All pages',
      category: 'Footer',
      action: 'Social Link clicks',
      label,
    });
    return true;
  }

  ngAfterViewInit() {
    this.getUrlParams();
    this.addContinueFormsListeners();
    this.continueDistributerForm();
    this.submitButton();
  }

  getUrlParams() {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        for (let [key, value] of Object.entries(params)) {
          if (key && key.includes('amp;')) {
            const newKey = key.split('amp;');
            key = newKey ? newKey[1] : newKey[0];
            this.utmParams[key] = value;
          } else {
            this.utmParams[key] = value;
          }
        }
      }
    });
  }

  addContinueFormsListeners() {
    const nameElement = document.querySelector('#communication_Name');
    const phoneElement = document.querySelector('#communicationPhoneNumber');
    const EmailElement = document.querySelector('#communication_Email');
    const cityElement = document.querySelector('#city');
    nameElement?.addEventListener('change', () => {
      this.name = (document.getElementById(
        'communication_Name'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    phoneElement?.addEventListener('change', () => {
      this.phone = (document.getElementById(
        'communicationPhoneNumber'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    EmailElement?.addEventListener('change', () => {
      this.email = (document.getElementById(
        'communication_Email'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    cityElement?.addEventListener('change', () => {
      this.city = (document.getElementById('city') as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
  }

  isEnableFormSubmission() {
    if (this.name && this.phone && this.email && this.city) {
      (document.getElementById(
        'continue-btn'
      ) as HTMLInputElement).disabled = false;
      (document.getElementById(
        'continue-btn'
      ) as HTMLInputElement).style.backgroundColor = 'rgb(20, 60, 111)';
      (document.getElementById(
        'submit-btn'
      ) as HTMLInputElement).style.backgroundColor = 'rgb(20, 60, 111)';
    } else {
      (document.getElementById(
        'continue-btn'
      ) as HTMLInputElement).disabled = true;
    }
  }

  continueDistributerForm() {
    const continueBtn = document.querySelector('#continue-btn');
    continueBtn?.addEventListener('click', () => {
      const communicationName = (document.getElementById(
        'communication_Name'
      ) as HTMLInputElement).value;
      const communicationPhoneNumber = (document.getElementById(
        'communicationPhoneNumber'
      ) as HTMLInputElement).value;
      const communicationEmail = (document.getElementById(
        'communication_Email'
      ) as HTMLInputElement).value;
      const city = (document.getElementById('city') as HTMLInputElement).value;
      const isValidMobile = this.commonService.isValidMobile(
        communicationPhoneNumber
      );
      const isValidEmail = this.commonService.isValidEmail(communicationEmail);
      const phoneErr = document.querySelector<HTMLElement>(
        '.communicationPhoneNumber-error'
      );
      const emailErr = document.querySelector<HTMLElement>(
        '.communicationEmail-error'
      );
      const phoneErrorElement = document.getElementsByClassName(
        'communicationPhoneNumber-error'
      );

      if (!isValidMobile) {
        phoneErr.style.display = 'block';
        phoneErrorElement[0].classList.add('phone-error');
        document.getElementsByClassName(
          'communicationPhoneNumber-error'
        )[0].innerHTML = this.translateService.instant(
          'ftiCommon.phoneInvalidmsg'
        );
      } else {
        phoneErr.style.display = 'none';
        phoneErrorElement[0].classList.add('phone-error');
        document.getElementsByClassName(
          'communicationPhoneNumber-error'
        )[0].innerHTML = '';
      }
      if (!isValidEmail) {
        emailErr.style.display = 'inline-block';
        document.getElementsByClassName(
          'communicationEmail-error'
        )[0].innerHTML = this.translateService.instant(
          'ftiCommon.emailInvalidMessage'
        );
        // add dynamic height
        if (window.screen.width <= 480 && window.screen.width > 1024) {
          [].forEach.call(
            document.querySelectorAll('.communicationEmail-error'),
            (el) => {
              el.style.marginTop = '35px';
            }
          );
        }
      } else {
        emailErr.style.display = 'none';
        document.getElementsByClassName(
          'communicationEmail-error'
        )[0].innerHTML = '';
      }
      if (isValidMobile && isValidEmail) {
        this.distributerFormData = {
          refNo: '',
          mobileNo: communicationPhoneNumber,
          emailId: communicationEmail,
          guId: '',
        };
        this.sendOtp();
      }
    });
  }

  submitButton() {
    const submitBtn = document.querySelector('#submit-btn');
    const otpElement = document.querySelector('#communication_OTP');
    otpElement?.addEventListener('change', () => {
      this.otp = (document.getElementById(
        'communication_OTP'
      ) as HTMLInputElement).value;
    });
    const otpErr = document.querySelector<HTMLElement>(
      '.communication_OTP-error'
    );
    submitBtn?.addEventListener('click', () => {
      if (this.otp) {
        otpErr.style.display = 'none';
        document.getElementsByClassName(
          'communication_OTP-error'
        )[0].innerHTML = '';
        const data = {
          OTP: this.otp,
          guId: '',
        };
        this.sessionService.validateFeederOTP$(data).subscribe(
          (response) => {
            if (response) {
              this.postDistributerFormData();
            }
          },
          (error) => {
            if (error && error.error[0]) {
              otpErr.style.display = 'block';
              document.getElementsByClassName(
                'communication_OTP-error'
              )[0].innerHTML = error.error[0].errorDescription;
            }
          }
        );
      } else {
        otpErr.style.display = 'block';
        document.getElementsByClassName(
          'communication_OTP-error'
        )[0].innerHTML = this.translateService.instant(
          'ftiCommon.otpRequiredMsg'
        );
      }
    });
  }

  handleOtpTimer() {
    let timeleft = 1;
    const self = this;
    const resendOtpElement = document.getElementsByClassName('adv-resend-otp');
    function resendClickHandler() {
      const otpErr = document.querySelector<HTMLElement>(
        '.communication_OTP-error'
      );
      otpErr.style.display = 'none';
      document.getElementsByClassName('communication_OTP-error')[0].innerHTML =
        '';
      self.sendOtp();
    }
    const otpTimer = setInterval(() => {
      if (timeleft === 30) {
        clearInterval(otpTimer);
        document.getElementsByClassName('adv-otp-timer')[0].innerHTML = '';
        resendOtpElement[0].classList.add('adv-clk-enabled');
        resendOtpElement[0]?.addEventListener('click', resendClickHandler);
        (document.getElementById(
          'adv-otp-timer'
        ) as HTMLInputElement).style.display = 'none';
      } else {
        resendOtpElement[0].removeEventListener('click', resendClickHandler);
        (document.getElementById(
          'adv-otp-timer'
        ) as HTMLInputElement).style.display = 'block';
        resendOtpElement[0].classList.remove('adv-clk-enabled');
        document.getElementsByClassName(
          'adv-otp-timer'
        )[0].innerHTML = `(Active in 00:${30 - timeleft})`;
      }
      timeleft += 1;
    }, 1000);
  }

  sendOtp() {
    if (this.distributerFormData.mobileNo) {
      this.sessionService.generateOtp$(this.distributerFormData).subscribe(
        (response) => {
          const successOtp = 'SUCCESSOTP';
          if (response && response.body.data.status === successOtp) {
            document.getElementsByClassName(
              'communicationEmail-error'
            )[0].innerHTML = '';
            document.getElementById('communication_OTP').focus();
            const hideOTPSuccessElement = Array.from(
              document.getElementsByClassName('hideOTPSuccess')
            );
            hideOTPSuccessElement.forEach((element: any) => {
              element.style.display = 'none';
            });
            // add dynamic height
            [].forEach.call(
              document.querySelectorAll('.carousel-inner'),
              (el) => {
                el.style.marginBottom = '22px';
              }
            );
            const hideElement = Array.from(
              document.getElementsByClassName('hide')
            );
            hideElement.forEach((element: any) => {
              element.style.display = 'block';
            });
            const advSuccessblockElement = Array.from(
              document.getElementsByClassName('adv-success-blk')
            );
            advSuccessblockElement.forEach((element: any) => {
              element.style.display = 'none';
            });
            document.getElementsByClassName(
              'fleadgen-otp-message'
            )[0].innerHTML = response.body.data.otpMessage;
            this.handleOtpTimer();
          }
        },
        (error) => {
          const errorResponse = error?.error[0] || error;
          if (errorResponse) {
            let errorMessage = '';
            if (error.status === 500) {
              errorMessage = error.error[0].errorDescription;
            } else {
              errorMessage = this.translateService.instant(
                'ftiCommon.serviceUnavailableMsg'
              );
            }
            document.getElementsByClassName(
              'fleadgen-otp-message'
            )[0].innerHTML = errorMessage;
            const advisorOtpMessageElement = Array.from(
              document.getElementsByClassName('fleadgen-otp-message')
            );
            advisorOtpMessageElement.forEach((element: any) => {
              element.style.color = 'red';
              element.style.textAlign = 'center';
            });
          }
        }
      );
    }
  }

  getTxnSource() {
    const location = window.location.href;
    let txnSource;
    if (location.includes('sip-calculator')) {
      txnSource = 'PM_SIPCalc';
    } else if (location.includes('lumpsum-calculator')) {
      txnSource = 'PM_LSCalc';
    } else if (location.includes('mutual-fund-calculators')) {
      txnSource = 'PM_MFCalc';
    } else if (location.includes('fund-overview')) {
      txnSource = 'PM_FundPage';
    } else {
      txnSource = 'PM_HomePage';
    }
    return txnSource;
  }

  getFundOption() {
    let fundOption = '';
    if (this.getTxnSource() === 'PM_FundPage') {
      const location = window.location.href;
      fundOption = location.substring(
        location.lastIndexOf('/') + 1,
        location.length
      );
    }
    return fundOption.replace(/-/g, ' ');
  }

  postDistributerFormData() {
    const utmCampaign = 'utm_campaign';
    const utmSource = 'utm_source';
    const utmMmedium = 'utm_medium';
    const payload = {
      name: this.name,
      mobile: this.phone,
      emailId: this.email,
      city: this.city,
      refNo: '',
      guid: '',
      txnSource: this.getTxnSource(),
      fundOption: this.getFundOption(),
      channel: 'WEB',
      campaignType: this.utmParams[utmCampaign] || '',
      source: this.utmParams[utmSource] || 'marketing-leads-form',
      medium: this.utmParams[utmMmedium] || '',
    };
    this.sessionService.postDistributeForm$(payload).subscribe(
      (response) => {
        if (response) {
          document.getElementsByClassName('fleadgen-otp-message')[0].innerHTML =
            '';
          const advSuccessblockElement = document.getElementsByClassName(
            'adv-success-blk'
          );
          advSuccessblockElement[0].classList.remove('hide');
          const advSuccessBlk = document.querySelector<HTMLElement>(
            '.adv-success-blk'
          );
          advSuccessBlk.style.display = 'block';
          const hideElement = Array.from(
            document.getElementsByClassName('hide')
          );
          hideElement.forEach((element: any) => {
            element.style.display = 'none';
          });
          const successMsg = this.translateService.instant(
            'ftiCommon.fundCampaignSuccessMsg'
          );
          document.getElementsByClassName(
            'success-msg'
          )[0].innerHTML = successMsg;
        }
      },
      (error) => {
        if (error && error.error[0]) {
          const otpErr = document.querySelector<HTMLElement>(
            '.communication_OTP-error'
          );
          otpErr.style.display = 'block';
          document.getElementsByClassName(
            'communication_OTP-error'
          )[0].innerHTML = error.error[0].errorDescription;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
