<div class="share-potion" [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <div
    [style.marginTop.px]="params?.marginTop | spacing"
    [style.marginBottom.px]="params?.marginBottom | spacing"
  >
    <ng-container
      [brManageContentButton]="document"
      class="has-edit-button float-right"
    ></ng-container>
    <ul class="share-options share-options-position">
      <li *ngIf="data?.showLearning == 'true'">
        <a href="javascript:(void)"
          ><span class="icon icon-fti_testYourLearnings"></span> Test your
          Learning</a
        >
      </li>
      <li *ngIf="data?.showEmail == 'true'">
        <a
          data-target="#mailbackModal"
          data-toggle="modal"
          class="email-icon money-simply-email"
          (click)="open(content)"
        >
          <span class="icon icon-fti_email"></span
          >{{ commonLabels?.emailLabel }}</a
        >

        <ng-template #content let-modal>
          <div class="fti-modal" *ngIf="!successmsg">
            <form [formGroup]="mailBackForm" (ngSubmit)="onSubmit()">
              <div class="modal-body">
                <a
                  type="button"
                  class="fill-circle-close"
                  (click)="modal.dismiss('Cross click')"
                >
                  <span class="icon-fti_close" aria-hidden="true"></span>
                </a>
                <h1 class="model-title">{{ commonLabels?.emailHeading }}</h1>
                <div>{{ commonLabels?.articleEmailtext }}</div>
                <div class="col-xs-12">
                  <div class="field-wrapper mb">
                    <div
                      class="form-group floatLabels row"
                      [ngClass]="{ focused: focusValue }"
                    >
                      <label class="control-label" for="inputNormal"
                        >Enter Email Address<sup>*</sup>
                      </label>
                      <input
                        name="email"
                        id="inputNormal"
                        type="text"
                        tabindex="-1"
                        class="form-control money-simply-email-value form-field"
                        formControlName="email"
                        data-field-id="money-simply-email"
                        onpaste="return false"
                        (focus)="labelMoveIn($event)"
                        (focusout)="labelMoveOut($event)"
                      />
                      <div
                        *ngIf="submitted && f.email.errors"
                        class="email-invalid"
                      >
                        <div
                          *ngIf="
                            submitted &&
                            f.email.invalid &&
                            f.email.errors.required
                          "
                        >
                          {{ commonLabels?.emailRequiredMsg }}
                        </div>
                        <div
                          *ngIf="
                            submitted && f.email.invalid && f.email.errors.email
                          "
                        >
                          {{ commonLabels?.emailInvalidMsg }}
                        </div>
                      </div>
                    </div>
                    <div class="money-simply-email-error field-error row"></div>
                  </div>
                </div>
              </div>
              <div class="modal-footer1">
                <input
                  aria-label="submit"
                  tabindex="-1"
                  type="submit"
                  class="btn btn-default panel-orange-btn pull-left money-simply-email-submit"
                  value="{{ commonLabels?.submitLabel }}"
                />
              </div>
            </form>
          </div>
          <div class="fti-modal" *ngIf="successmsg">
            <div class="modal-body success-block">
              <a
                type="button"
                class="fill-circle-close"
                (click)="modal.dismiss('Cross click')"
              >
                <span class="icon-fti_close" aria-hidden="true"></span>
              </a>
              <h1 class="model-title">{{ commonLabels?.emailHeading }}</h1>
              <div>Email has been sent successfully.</div>
            </div>
          </div>
        </ng-template>
      </li>
      <li *ngIf="data?.showSocial == 'true'" class="fti-shareBtn">
        <div class="clipboard-wrp">
          <a class="copy-clipboard active">
            <span class="icon icon-fti_share"></span
            >{{ commonLabels?.shareLabel }}
          </a>
          <div #htmlContainer class="copy-to-clipboard fade in popover-ui">
            <div class="arrow"></div>
            <div class="popover-content">
              <div class="addthis_inline_share_toolbox"></div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
