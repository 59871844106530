import { AgmMap, MapsAPILoader } from '@agm/core';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from '@services/analytics.service';
import { CommonService } from '@services/common.service';
import { Logger } from '@utils/logger';
import { POLYLINE } from 'src/app/ft-constants/polyline.constant';
import { Cities } from '../../../ft-constants/cities';
import { States } from '../../../ft-constants/state';
interface Coordinates {
  latitude: number;
  longitude: number;
}

const logger = Logger.getLogger('AddressBookComponent');

@Component({
  selector: 'ft-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddressBookComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  showSubmenu = false;
  statevalues = States.states;
  cityvalues = Cities.city;
  stateslug: any;
  coordinates: Coordinates;
  cityslug: any;
  lat = 51.678418;
  lng = 7.809007;
  latitude: number;
  longitude: number;
  pickAddress: any;
  zoom: number;
  address: string;
  focusValue = false;
  focusValueName = false;
  showSubmenuState = false;
  showSubmenuCities = false;
  accordionValue = false;
  private geoCoder;
  filterStates: any;
  filterCities: any;
  @ViewChild(AgmMap) agmMap: AgmMap;
  public origin: any;
  public destination: any;
  public maxSpeed: number;
  public polyline: Array<any>;
  public polylines: Array<any>;
  allBranches: any;
  citiesValues: any = [
    {
      id: 'all-cities',
      state_id: 'all-cities',
      name: 'All Cities',
      slug: 'all-cities',
    },
  ];
  @ViewChild('search') searchElementRef: ElementRef;
  modalReferance: any;
  @ViewChild('mapcontent') modal: ElementRef;
  @ViewChild('container') container;
  @ViewChild('subMenuContainer') subMenuContainer;
  @ViewChild('cityContainer') cityContainer;
  @ViewChild('subMenuCityContainer') subMenuCityContainer;
  @ViewChild('dropdown') dropdown;
  constructor(
    private modalService: NgbModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private analyticsService: AnalyticsService,
    private commonService: CommonService
  ) {
    this.coordinates = {} as Coordinates;
    document.addEventListener('click', this.offClickHandler.bind(this));
  }

  ngOnInit(): void {
    this.maxSpeed = 40;
    this.polyline = POLYLINE;
    this.polylines = this.rebuildPolylines();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
    this.allValues();
  }
  resizeMap() {
    this.agmMap.triggerResize();
  }

  labelMoveOutName(e: any) {
    if (e.target.value !== '') {
      this.focusValue = true;
    } else {
      this.focusValue = false;
    }
    logger.debug(e.target.value);
  }
  labelMoveInName(e: any) {
    this.focusValue = true;
  }
  offClickHandler(event: any) {
    if (!this.container.nativeElement.contains(event.target)) {
      // check click origin
      this.closeDropdown();
      const element = document.getElementById('stateDiv');
      element.classList.remove('open');
    } else if (this.subMenuContainer.nativeElement.contains(event.target)) {
      this.closeDropdown();
      const element = document.getElementById('stateDiv');
      element.classList.remove('open');
    } else {
      // alert("one");
      [].forEach.call(document.querySelectorAll('#stateDiv'), (el) => {
        if (el.classList.contains('open')) {
          el.classList.remove('open');
        } else {
          el.classList.add('open');
        }
      });
      [].forEach.call(document.querySelectorAll('#cityDiv'), (el) => {
        if (el.classList.contains('open')) {
          el.classList.remove('open');
        } else {
          el.classList.add('open');
        }
      });
    }

    if (!this.cityContainer.nativeElement.contains(event.target)) {
      // check click origin
      this.closeDropdownCities();
      const element = document.getElementById('cityDiv');
      element.classList.remove('open');
    } else if (this.subMenuCityContainer.nativeElement.contains(event.target)) {
      this.closeDropdownCities();
      const element = document.getElementById('cityDiv');
      element.classList.remove('open');
    } else {
      [].forEach.call(document.querySelectorAll('#cityDiv'), (el) => {
        if (el.classList.contains('open')) {
          el.classList.remove('open');
        } else {
          el.classList.add('open');
        }
      });
    }
  }
  findAdress() {
    let autocomplete: any;

    const options = {
      componentRestrictions: { country: 'IN' },
      mapTypeId: 'satellite',
      mapTypeControl: true,
    };
    const inputAddress = document.getElementById('address') as HTMLInputElement;

    autocomplete = new google.maps.places.Autocomplete(inputAddress, options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.ngZone.run(() => {
        // some details
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.address = place.formatted_address;
        this.origin = { lat: this.latitude, lng: this.longitude };

        // set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.destination = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.zoom = 12;
      });
    });
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = 51.678418;
        this.longitude = 7.809007;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  showModal(content, lattitide: any, longitude: any) {
    this.modalReferance = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    lattitide = lattitide !== undefined ? lattitide : this.latitude;
    longitude = longitude !== undefined ? longitude : this.longitude;
    this.getAddress(lattitide, longitude);
    document.getElementById('hiddenVal').focus();
    // content.nativeElement.on('shown.bs.modal',()=>this.agmMap.triggerResize());

    this.findAdress();
  }
  getAddress(latitude, longitude) {
    this.latitude = parseFloat(latitude);
    this.longitude = parseFloat(longitude);
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }
  private rebuildPolylines() {
    const polylines = [];
    let i = 0;
    let newPolyline = { path: [], color: 'blue' };
    for (const point of this.polyline) {
      newPolyline.path.push(point);
      const speedChanged =
        (this.polyline[i + 1] &&
          point.speed < this.maxSpeed &&
          this.polyline[i + 1].speed < this.maxSpeed) ||
        (point.speed > this.maxSpeed &&
          this.polyline[i + 1].speed > this.maxSpeed);
      if (point.speed > this.maxSpeed) {
        newPolyline.color = 'red';
      }
      if (speedChanged) {
        newPolyline.path.push(this.polyline[i + 1]);
        polylines.push(newPolyline);
        newPolyline = { path: [], color: 'blue' };
      }
      i++;
    }
    logger.debug(polylines);
    return polylines;
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  resetModal() {
    this.findAdress();
    this.modalReferance.close();
    this.destination = [];
    this.pickAddress = '';
  }
  get content() {
    const stateVal = [];
    this.document?.getData()?.stateCompoundType?.forEach((element) => {
      const slug = element?.state
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
      element.slug = slug;
      element.citiesCompoundType.forEach((el) => {
        const cityslug = el.cityname
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
        el.cityslug = cityslug;
      });
    });
    logger.debug('contentvalue >>> ', this.document?.getData());
    return this.document?.getData();
  }
  filterdetails(state: any, city: any, cat: any) {
    const stateVal = [];
    const details = this.document
      ?.getData()
      ?.stateCompoundType?.forEach((element) => {
        const slug = element?.state
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
        element.slug = slug;
        element.citiesCompoundType.forEach((el) => {
          const cityslug = el.cityname
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
          el.cityslug = cityslug;
        });
      });

    if (cat === 'alldet') {
      const detail = details;
    } else if (cat === 'citydet') {
      //  let  detail = details.filter(item => details.parentId === id);
    }
    logger.debug('detailvalue');
    logger.debug(details);
  }
  displaySub() {
    this.accordionValue = !this.accordionValue;
  }
  open(content, lattitude: any, longitude: any) {
    this.lat = lattitude;
    this.lng = longitude;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }
  ngAfterViewInit() {
    this.getCities();
  }
  showDetails() {
    if (
      typeof this.stateslug === 'undefined' &&
      typeof this.cityslug === 'undefined'
    ) {
      [].forEach.call(document.querySelectorAll('.cities-grid'), (el) => {
        el.style.display = 'inline-block';
      });
    } else if (
      this.stateslug === 'all-states' &&
      this.cityslug === 'all-cities'
    ) {
      [].forEach.call(document.querySelectorAll('.cities-grid'), (el) => {
        // alert(el);
        el.style.display = 'inline-block';
      });
    } else if (
      typeof this.stateslug !== 'undefined' &&
      this.cityslug === 'all-cities'
    ) {
      [].forEach.call(document.querySelectorAll('.' + this.stateslug), (el) => {
        el.style.display = 'inline-block';
      });
      [].forEach.call(
        document.querySelectorAll('.cities-grid:not(.' + this.stateslug + ')'),
        (el) => {
          logger.debug('elementValues');
          el.style.display = 'none';
        }
      );
    } else if (
      typeof this.stateslug !== 'undefined' &&
      typeof this.cityslug === 'undefined'
    ) {
      [].forEach.call(document.querySelectorAll('.' + this.stateslug), (el) => {
        el.style.display = 'inline-block';
      });
      [].forEach.call(
        document.querySelectorAll('.cities-grid:not(.' + this.stateslug + ')'),
        (el) => {
          logger.debug('elementValues');
          el.style.display = 'none';
        }
      );
    } else if (
      typeof this.stateslug === 'undefined' &&
      typeof this.cityslug !== 'undefined' &&
      this.cityslug !== 'all-cities'
    ) {
      [].forEach.call(document.querySelectorAll('.' + this.cityslug), (el) => {
        el.style.display = 'inline-block';
      });
      [].forEach.call(
        document.querySelectorAll('.cities-grid:not(.' + this.cityslug + ')'),
        (el) => {
          logger.debug('elementValues');
          el.style.display = 'none';
        }
      );
    } else if (
      typeof this.stateslug !== 'undefined' &&
      typeof this.cityslug !== 'undefined'
    ) {
      [].forEach.call(document.querySelectorAll('.' + this.stateslug), (el) => {
        el.style.display = 'inline-block';
      });
      [].forEach.call(document.querySelectorAll('.' + this.cityslug), (el) => {
        el.style.display = 'inline-block';
      });
      [].forEach.call(
        document.querySelectorAll('.cities-grid:not(.' + this.cityslug + ')'),
        (el) => {
          logger.debug('elementValues');
          el.style.display = 'none';
        }
      );
    } else {
      [].forEach.call(document.querySelectorAll('.cities-grid'), (el) => {
        el.style.display = 'inline-block';
      });
    }
    this.setAnalytics();
  }

  setAnalytics() {
    this.analyticsService.trackEvent({
      event: 'Event Distributor Zone',
      category: 'Distributor Zone',
      action: 'Search by Location',
      label:
        document.getElementById('all-states').innerText +
        '|' +
        document.getElementById('all-cities').innerText,
    });
  }
  resetDetails() {
    [].forEach.call(document.querySelectorAll('.cities-grid'), (el) => {
      el.style.display = 'inline-block';
    });

    [].forEach.call(
      document.querySelectorAll('.cities-list .citiesList'),
      (el) => {
        el.style.display = 'inline-block';
      }
    );

    document.getElementById('all-cities').innerText = 'All Cities';
    document.getElementById('all-states').innerText = 'All States';
  }
  get isPreview() {
    return this.page.isPreview();
  }
  get statesContent() {
    this.statevalues?.forEach((element) => {
      const slug = element.name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
      element.slug = slug;
    });
    return this.statevalues;
  }
  get citiesContent() {
    this.cityvalues?.forEach((element) => {
      const slug = element.name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
      element.slug = slug;
    });
    return this.cityvalues;
  }
  get getStates() {
    let statevalue = this.document?.getData()?.stateCompoundType;
    statevalue?.forEach((element) => {
      const slug = element?.state
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
      element.slug = slug;
      const StateId = element?.state?.replace(/ /g, '');
      element.stateId = StateId;
    });

    const array1 = [
      { stateId: 'all-states', slug: 'all-states', state: 'All States' },
    ];
    const array2 = statevalue
      ?.filter((e) => e?.stateId !== 'all-states')
      .sort((a, b) => a?.stateId.localeCompare(b?.stateId));

    statevalue = [].concat(array1, array2);
    return statevalue;
  }
  getCities() {
    const cityvalue = this.document?.getData()?.stateCompoundType;
    const citiesValue = [];
    cityvalue?.forEach((element, stateid) => {
      const cities = element?.citiesCompoundType?.forEach((el, index) => {
        const citiid = index + 1;
        const slug = el?.cityname
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '');
        const StateId = element?.state?.replace(/ /g, '');
        const cityId = el?.cityname?.replace(/ /g, '');
        const name = el?.cityname;
        const citypusvalue = {
          id: cityId,
          state_id: StateId,
          name: el?.cityname,
          slug,
        };
        this.citiesValues?.push(citypusvalue);
      });
    });
    const array1 = this.citiesValues?.filter((e) => e.id === 'all-cities');
    const array2 = this.citiesValues
      ?.filter((e) => e?.id !== 'all-cities')
      .sort((a, b) => a?.id.localeCompare(b?.id));

    this.citiesValues = [].concat(array1, array2);
    this.commonService.setCities(this.citiesValues);
    return citiesValue;
  }
  allValues() {
    const allBranchValues = [];
    let listBranches = {};
    const moreBranches = {};
    this.content?.stateCompoundType.forEach((element, stateid) => {
      element.citiesCompoundType.forEach((el) => {
        el.allBranches = [];
        el.branchCompoundType.forEach((bel) => {
          const branchValues = {
            branchname: bel?.branchname,
            latitude: bel?.latitude,
            longitude: bel?.longitude,
            telephoneNumber: bel?.telephoneNumber,
            sales: bel?.sales,
            services: bel?.services,
            address: bel?.address,
          };
          el.allBranches.push(branchValues);
        });
        listBranches = {
          allBranches: el.allBranches,
          cityname: el?.cityname,
          state: element?.state,
          slug: element?.slug,
          cityslug: el?.cityslug,
        };

        allBranchValues.push(listBranches);
      });
    });
    allBranchValues.sort((a, b) => a.cityname.localeCompare(b.cityname));

    this.allBranches = allBranchValues;
    return allBranchValues;
  }
  displaySubMenu(
    param: any,
    sub: any,
    event: any,
    id: any,
    slug: any,
    stateId: any
  ) {
    this.showSubmenu = !this.showSubmenu;
    if (param === 'states') {
      // this.showSubmenuState = !this.showSubmenuState;
    }
    if (param === 'cities') {
      // this.showSubmenuCities = !this.showSubmenuCities;
    }
    [].forEach.call(document.querySelectorAll('.' + param), (el) => {});
    if (sub === 'sub' && param === 'states') {
      this.stateslug = slug;
      document.getElementById('all-cities').innerText = 'All Cities';
      document.getElementById('all-states').innerText = event;
      this.cityslug = 'all-cities';
      if (id !== 'all-states') {
        [].forEach.call(
          document.querySelectorAll('.cities-list .' + id),
          (el) => {
            el.style.display = 'inline-block';
          }
        );

        [].forEach.call(
          document.querySelectorAll(
            '.cities-list .citiesList:not(.' + id + ')'
          ),
          (el) => {
            el.style.display = 'none';

            logger.debug(el);
          }
        );
        [].forEach.call(document.querySelectorAll('.all-cities'), (el) => {
          el.style.display = 'inline-block';
        });
        [].forEach.call(document.querySelectorAll('.all-states'), (el) => {
          el.style.display = 'inline-block';
        });
      } else {
        [].forEach.call(document.querySelectorAll('.all-cities'), (el) => {
          el.style.display = 'inline-block';
        });
        [].forEach.call(
          document.querySelectorAll('.cities-list .citiesList'),
          (el) => {
            logger.debug('elementValues');
            el.style.display = 'inline-block';
          }
        );
      }
    }
    if (sub === 'sub' && param === 'cities') {
      this.cityslug = slug;
      const nodevalue = document.getElementById('all-states').innerText;

      document.getElementById('all-cities').innerText = event;
      const text = document.getElementById(stateId).innerText;

      if (id !== 'all-cities') {
        document.getElementById('all-states').innerText = text;
      } else {
        if (nodevalue === 'All States') {
          document.getElementById('all-states').innerText = 'All States';
        }
      }
    }
    if (id === '') {
    }
  }
  closeDropdown() {
    this.showSubmenuState = false;
  }
  closeDropdownCities() {
    this.showSubmenuCities = false;
  }
  showhidePopUp($event) {
    $event.stopPropagation();
  }
  getcitiesFilters(stateid: any) {
    const citiesval = '';
    return citiesval;
  }
}
