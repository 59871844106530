import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ft-social-media-icons',
  templateUrl: './social-media-icons.component.html',
  styleUrls: ['./social-media-icons.component.scss'],
})
export class SocialMediaIconsComponent implements OnInit {
  constructor() {}
  @Input() currentPageUrl: any;
  @Input() textTitle: any;
  ngOnInit(): void {}
}
