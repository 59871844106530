import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('ContactusDisplayGridComponent');
const dismissReason = {
  byPressingESC: 'by pressing ESC',
  byClickingOnABackdrop: 'by clicking on a backdrop',
};

@Component({
  selector: 'ft-contactus-display-grid',
  templateUrl: './contactus-display-grid.component.html',
  styleUrls: ['./contactus-display-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactusDisplayGridComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  constructor(private modalService: NgbModal) {}
  data = [
    { 1: 'America' },
    { 1: 'United States' },
    { 2: 'United Kingdom' },
    { 3: 'India' },
  ];
  closeModal: string;
  ngOnInit(): void {}
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  ngAfterViewInit() {}

  triggerModal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (res) => {
          this.closeModal = `Closed with: ${res}`;
        },
        (res) => {
          this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return dismissReason.byPressingESC;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return dismissReason.byClickingOnABackdrop;
    } else {
      return `with: ${reason}`;
    }
  }
  get content() {
    return this.document?.getData();
  }

  get contentBlock() {
    return this.content?.contentBlock || [];
  }

  get isPreview() {
    return this.page.isPreview();
  }
  get layout() {
    logger.debug('this.layout');
    logger.debug(this.content);
    return this.content?.displaylayout;
  }
  get lists() {
    const dataval = this.content?.contactUsCompoundType;
    logger.debug('contactuscompoundtype >> ', dataval);
    const layoutType = 'Locate Us';
    dataval.forEach((element) => {
      if (this.layout === layoutType) {
        if (element.string3) {
          const explodeval1 = element.string3.split('(');

          if (explodeval1.length !== 0) {
            element.weekvalue = explodeval1[0];
            element.spanValue = explodeval1[1];
          } else {
            element.weekvalue = element.string3;
            element.spanValue = '';
          }
        }
        if (element.string1) {
          const explodeval = element.string1.split('(');
          if (explodeval.length !== 0) {
            element.weekvalue1 = explodeval[0];
            element.spanValue1 = explodeval[1];
          } else {
            element.weekvalue1 = element.string1;
            element.spanValue1 = '';
          }
        }
      }
    });
    logger.debug('dataValues >> ', dataval);
    return this.content?.contactUsCompoundType;
  }
}
