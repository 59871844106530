import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component as BrComponent } from '@bloomreach/spa-sdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppStateService } from '@services/app-state.service';
import { CommonService } from '@services/common.service';
import { EnvConfig } from '@services/env-config.service';
import { SessionService } from '@services/session.service';
import { Logger } from '@utils/logger';
import { StorageService } from '@services/storage.service';
import { Subscription } from 'rxjs';
import { FormService } from '@services/form.service';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { LoginService } from '@services/login.service';
import { TranslateService } from '@shared/translate/translate.service';
import { AnalyticsService } from '@services/analytics.service';

const logger = Logger.getLogger('NewInvestorLoginComponent');

@Component({
  selector: 'ft-new-investor-login',
  templateUrl: './new-investor-login.component.html',
  styleUrls: ['./new-investor-login.component.scss'],
})
export class NewInvestorLoginComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page;
  @Input() loginRegisterData;
  @ViewChild('videoContent', { static: true }) videoContent;
  urlSafe: SafeResourceUrl;

  // Login variables
  loginFormNewInvestor: FormGroup;
  investorOTPForm: FormGroup;
  watchVideoLabelUrl: string;
  successStatus = '';
  errors: any = [];
  loader: any;
  env: EnvConfig;
  KYConboarding = '';
  subscriptions: Subscription[] = [];
  KYCRegistered: any;
  isNRI = false;
  mobileNolabel: string;
  residentType = 'RESIND';
  kycNotRegistered: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private modal: NgbModal,
    public sanitizer: DomSanitizer,
    private commonService: CommonService,
    private sessionService: SessionService,
    private appStateServ: AppStateService,
    private changeDetector: ChangeDetectorRef,
    private storageService: StorageService,
    private formService: FormService,
    private loginService: LoginService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) readonly documentRef: Document,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    this.createLoginInvestor();
    this.createInvestorOTPForm();
    this.env = this.appStateServ.getEnvConfig();
  }

  ngOnInit(): void {
    // Get values from resource bundle
    this.loader = this.documentRef.getElementById('loader');
    this.watchVideoLabelUrl = this.translateService.instant(
      'ftiNewInvestor.watchVideoLabel.url'
    );
    // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   this.content?.watchVideoLabel?.url
    // );
    // logger.debug('urlSafe:::', this.urlSafe);
    this.mobileNolabel = this.translateService.instant(
      'ftiNewInvestor.mobileInputFieldLabel'
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe subscriptions
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public keyPress(event: any): void {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    // logger.debug('keyCode', event.keyCode);
    // logger.debug('inputChar', inputChar);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSelectedRes(value: string) {
    this.residentType = value;
  }

  clickResidentType($value) {
    if ($value === 'RESIND') {
      this.isNRI = false;
      this.mobileNolabel = this.translateService.instant(
        'ftiNewInvestor.mobileInputFieldLabel'
      );
      this.residentType = 'RESIND';
      this.loginFormNewInvestor.controls.mobile.setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]{10}$'),
      ]);
      this.loginFormNewInvestor.controls.mobile.updateValueAndValidity();
      this.changeDetector.detectChanges();
    }
    if ($value === 'NRI') {
      this.isNRI = true;
      this.residentType = 'NRIR';
      this.mobileNolabel = 'Enter 10-digit Indian Mobile Number (optional) ';
      this.loginFormNewInvestor.controls.mobile.clearValidators();
      this.loginFormNewInvestor.controls.mobile.updateValueAndValidity();
      this.changeDetector.detectChanges();
    }
  }

  public registerUser(): void {
    this.kycNotRegistered = false;
    this.loader.style.display = 'block';
    const submitObject = {
      signInForm: this.loginFormNewInvestor.value,
    };
    const loginForm = {
      panNo: submitObject?.signInForm?.userid.toUpperCase(),
      emailId: submitObject?.signInForm?.email,
      mobileNo: submitObject?.signInForm?.mobile,
    };
    const loginDetails = JSON.stringify(loginForm);

    const kycSubscription: Subscription = this.sessionService
      .allowOnBoarding$()
      .subscribe(
        (response: any) => {
          this.KYConboarding = response.body.codeValueList[0].value;
        },
        (error: any) => {
          this.errors = this.loginService.errorStatus(error);
          this.changeDetector.detectChanges();
        }
      );
    this.subscriptions.push(kycSubscription);

    const subscription: Subscription = this.sessionService
      .generatePaperlessOTP$(loginDetails)
      .subscribe(
        (response: any) => {
          this.errors = null;
          this.loader.style.display = 'none';
          logger.debug(' paperlessOTP response:' + response);
          if (response.body) {
            if (
              (response.body.kycStatus === '01' ||
                response.body.kycStatus === '06') &&
              this.KYConboarding === 'Y'
            ) {
              this.kycNotRegistered = true;
            } else if (
              (response.body.kycStatus === '07' &&
                this.KYConboarding === 'Y') ||
              (response.body.kycStatus === '02' && this.isNRI) ||
              (response.body.data && response.body.data.status === 'SUCCESSOTP')
            ) {
              this.successStatus = response.body.data.otpMessage;
              this.changeDetector.detectChanges();
              this.documentRef.getElementById('newInvestorForm').style.display =
                'none';
              this.documentRef.getElementById(
                'newInvestorOTPFrm'
              ).style.display = 'block';
            } else {
              this.setRegisterCookie(response);
              this.setResTypeCookie(this.residentType);
              this.windowRef.location.href =
                this.appStateServ.getftiAccountsUrl() +
                '/ngGuest/#/transact/buy/paperless/kycregistration';
            }
          }
        },
        (error: any) => {
          this.loader.style.display = 'none';
          if (error.status === 0) {
            this.errors.errorDescription = error.statusText;
            this.windowRef.location.href =
              this.appStateServ.getftiAccountsUrl() +
              this.appStateServ.getftiftitransactPaperLessKyc();
          } else {
            this.errors = error.error[0];
            this.changeDetector.detectChanges();
          }
        }
      );
    this.subscriptions.push(subscription);
  }

  public validateOTP(): void {
    this.loader.style.display = 'block';
    const submitObject = {
      signInForm: this.investorOTPForm.value,
    };
    const loginForm = {
      OTP: parseInt(submitObject?.signInForm?.otp, 10),
      otptxn: '',
      guid: '',
    };
    const loginDetails = JSON.stringify(loginForm);
    const kycRegsubscription: Subscription = this.sessionService
      .validateKYC$()
      .subscribe(
        (response: any) => {
          this.KYCRegistered = response.body.codeValueList[0].code;
        },
        (error: any) => {
          this.errors = this.loginService.errorStatus(error);
          this.changeDetector.detectChanges();
        }
      );
    this.subscriptions.push(kycRegsubscription);

    const subscription: Subscription = this.sessionService
      .validatePaperlessOTP$(loginDetails)
      .subscribe(
        (response: any) => {
          this.analyticsService.trackEvent({
            event: 'All Pages',
            category: 'Ask For Distributor',
            action: 'Lead',
            label: 'GenerateOTP - Success ',
          });
          this.errors = null;
          this.loader.style.display = 'none';
          this.storageService.clearCookies(this.env.baseCookieVal);
          if (
            response.body.kycStatus === '01' ||
            response.body.kycStatus === '06'
          ) {
            this.kycNotRegistered = true;
          } else if (
            response.body.kycStatus === '07' ||
            (response.body.kycStatus === '02' && this.isNRI)
          ) {
            this.setRegisterCookie(response);
            this.setResTypeCookie(this.residentType);
            this.windowRef.location.href =
              this.appStateServ.getftiAccountsUrl() +
              this.appStateServ.getftitransactPaperLessinvest();
          } else {
            this.setRegisterCookie(response);
            this.setResTypeCookie(this.residentType);
            this.windowRef.location.href =
              this.appStateServ.getftiAccountsUrl() +
              this.appStateServ.getftiftitransactPaperLessKyc();
          }
        },
        (error: any) => {
          this.loader.style.display = 'none';
          this.errors = this.loginService.errorStatus(error);
          this.changeDetector.detectChanges();
        }
      );
    this.subscriptions.push(subscription);
  }

  get document() {
    const { document } = this.component?.getModels() || {};
    const content = document && this.page?.getContent(document);
    // logger.debug(content);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get isPreview() {
    return this.page.isPreview();
  }

  public openModal(url: string): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    logger.debug('urlSafe:::', url);
    if (this.urlSafe) {
      this.modal.open(this.videoContent, { centered: true, size: 'lg' });
    }
  }

  public backToLoign(): void {
    this.documentRef.getElementById('newInvestorOTPFrm').style.display = 'none';
    this.documentRef.getElementById('newInvestorForm').style.display = 'block';
  }

  /**
   * Disabling copy and paste for server functionality
   * @param event - ClipboardEvent
   */
  public onPaste(event: ClipboardEvent) {
    this.formService.onPasteForms(event);
  }

  //////////////////
  // Private methods
  //////////////////

  /**
   * Sets 'kycRegisteredUserWOL' cookie
   * @param response - JSON response
   */
  private setRegisterCookie(response: any): void {
    const cookieName = 'kycRegisteredUserWOL';
    this.storageService.store(
      cookieName,
      JSON.stringify(response.body),
      false,
      cookieName,
      this.env.baseCookieVal
    );
  }

  /**
   * Sets 'residentType' cookie
   * @param resType - Resident Type RESIND, NRIR or NRINR
   */
  private setResTypeCookie($resType): void {
    const cookieName = 'residentType';
    this.storageService.store(
      cookieName,
      $resType,
      false,
      cookieName,
      this.env.baseCookieVal
    );
  }

  private createLoginInvestor(): void {
    this.loginFormNewInvestor = this.formBuilder.group({
      userid: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.pattern('^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}?$'),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]{10}$'),
        ],
      ],
    });
  }

  // Login Investor - OTP
  private createInvestorOTPForm(): void {
    this.investorOTPForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  public redirectRegisterFlow() {
    this.windowRef.location.href =
      this.appStateServ.getftingGuestUrl() + '/register-investor';
  }
}
