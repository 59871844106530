import { Component, Input } from '@angular/core';
import { VerticalTabsBannerBlock } from '../vertical-tabs.types';

@Component({
  selector: 'ft-vertical-tabs-banner',
  templateUrl: './vertical-tabs-banner.component.html',
  styleUrls: ['./vertical-tabs-banner.component.scss'],
})
export class VerticalTabsBannerComponent {
  @Input() bannerData: VerticalTabsBannerBlock[];
}
