import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component as BrComponent } from '@bloomreach/spa-sdk';
import { LOCATION, WINDOW } from '@ng-web-apis/common';
import { AppStateService } from '@services/app-state.service';
import { EnvConfig } from '@services/env-config.service';
import { LoginService, OtpOption } from '@services/login.service';
import { SessionService } from '@services/session.service';
import { LOCATION_LABL } from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { Constant } from '../../../ft-constants/constant';
import { DOCUMENT } from '@angular/common';
import { StorageService } from '@services/storage.service';
import { FormService } from '@services/form.service';
import { Observable, Subject } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { InUserType } from '@types';
import { replaceTokens } from '@utils/text/string-utils';

const logger = Logger.getLogger('sessionService');

type TokenizedLabels = {
  labelInvestorRegisterUrl: string;
  labelInvestorForgotUserUrl: string;
  labelInvestorForgotPasswordUrl: string;
};

@Component({
  selector: 'ft-existing-investor-login',
  templateUrl: './existing-investor-login.component.html',
  styleUrls: ['./existing-investor-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExistingInvestorLoginComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page;
  @Input() loginRegisterData;

  // Login variables
  public loginFormInvestorUserID: FormGroup;
  public loginFormInvestorUserPAN: FormGroup;
  public investorOTPForm: FormGroup;
  public investorBankAccFrm: FormGroup;
  public investorTab = true;
  public useidTab = true;
  public usepanTab = false;
  public successStatus = '';
  public errors: any = [];
  public env: EnvConfig;
  public validated: boolean;
  public maintenance$: Observable<SafeHtml>;
  public tokenizedLabels: TokenizedLabels;
  public otpError = false;
  public loginWrapper = true;
  public passwordFieldType = 'password';
  private loader: any;
  private panOtpRemember: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public registerPan: boolean;
  @ViewChild('loginRadioButton') loginRadioButton;
  @ViewChild('panRadioButton') panRadioButton;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 needs mor than 7 parameters in constructor
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private sessionService: SessionService,
    private changeDetector: ChangeDetectorRef,
    private appStateService: AppStateService,
    private storageService: StorageService,
    private formService: FormService,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(LOCATION) readonly locationRef: Location,
    @Inject(DOCUMENT) readonly documentRef: Document
  ) {
    this.createLoginInvestorUserID();
    this.createLoginInvestorUserPAN();
    this.createLoginInvestorOTPForm();
    this.createBankAccountForm();
    this.loginService.getValidated$()?.pipe(takeUntil(this.unsubscribe$)).subscribe(validated => {
      this.validated = validated;
      this.changeDetector.detectChanges();
    });
    this.maintenance$ = this.loginService.checkMaintenance();
  }

  ngOnInit(): void {
    this.env = this.appStateService.getEnvConfig();
    this.loader = this.documentRef.getElementById('loader');
    this.tokenizedLabels = {
      labelInvestorRegisterUrl: replaceTokens(
        this.content?.labelinvestorregister?.url,
        this.env
      ),
      labelInvestorForgotUserUrl: replaceTokens(
        this.content?.labelinvestorforgotuser?.url,
        this.env
      ),
      labelInvestorForgotPasswordUrl: replaceTokens(
        this.content?.labelinvestorforgotpassword?.url,
        this.env
      ),
    };
  }

  ngAfterViewInit() {
    this.defaultRadioChecked();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onlyNumeric(ev) {
    const charCode = ev.which ? ev.which : ev.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      ev.preventDefault();
      return false;
    }
    return true;
  }

  clickRadio($value) {
    this.loginFormsReset();
    if ($value === 'useid') {
      this.useidTab = true;
      this.usepanTab = false;
      this.changeDetector.detectChanges();
    }
    if ($value === 'usepan') {
      this.usepanTab = true;
      this.useidTab = false;
      this.changeDetector.detectChanges();
    }
  }

  get document() {
    const { document } = this.component?.getModels() || {};
    return document && this.page?.getContent(document);
  }

  get content() {
    return this.document?.getData();
  }

  get isPreview() {
    return this.page.isPreview();
  }

  public loginUserID(): void {
    this.loader.style.display = 'block';
    const submitObject = {
      signInForm: this.loginFormInvestorUserID.value,
    };
    const loginForm = {
      userId: submitObject?.signInForm?.userid,
      password: submitObject?.signInForm?.password,
      userType: InUserType.INVESTOR,
    };
    const loginDetails = JSON.stringify(loginForm);
    this.sessionService
      .authenticateUser$(loginDetails)
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.errors = null;
          this.loader.style.display = 'none';
          this.loginService.trackIdLogIn(InUserType.INVESTOR, loginForm.userId);
          if (
            response &&
            response.status === 201 &&
            response.headers.get(LOCATION_LABL)
          ) {
            // NOSONAR - leaving for future tracking
            // this.trackEvent('login_fail');
            logger.debug(
              'Redirecting Profile App Flows with 201 Status',
              response
            );
            this.windowRef.location.href = response.headers.get(LOCATION_LABL);
          } else if (response.body.hasOwnProperty('userTokenResponse')) {
            this.loginService.trackIdLoginSuccess(InUserType.INVESTOR);
            this.loginService.logIn(response.body);
          } else {
            this.loginService.setResponseCookies(response.body);

            if (response.body.userRedirectURI) {
              this.windowRef.location.href = response.body.userRedirectURI;
            }
            this.successStatus = response.body.status;
            this.documentRef.getElementById('investorOTPFrm').style.display =
              'block';
            this.documentRef.getElementById('otpRememberMe').style.display =
              'block';
            this.documentRef.getElementById('loginFrm').style.display = 'none';
            this.loginWrapper = false;
            this.changeDetector.detectChanges();
            this.loginService.trackOtpSent();
            logger.debug('Profile Summary Response:', response.body);
          }
        },
        (error: any) => {
          this.loginService.trackLoginIdFailure(InUserType.INVESTOR);
          this.errorHandling(error);
        }
      );
  }

  public checkPAN(): void {
    this.registerPan = false;
    this.documentRef.getElementById('btnRegisterOtp').style.display = 'none';
    const val = this.loginFormInvestorUserPAN.value.userpan.toUpperCase();
    const loginForm = {
      panNo: val,
      userType: InUserType.INVESTOR,
      panDistId: '',
      sourceFlag: '',
    };
    const loginDetails = JSON.stringify(loginForm);
    if (val.length === 10 && Constant.panRegex.test(val)) {
      this.loader.style.display = 'block';
      this.sessionService
        .validatePAN$(loginDetails)
        ?.pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            this.errors = null;
            this.loader.style.display = 'none';
            if (
              response &&
              response.status === 201 &&
              response.headers.get(LOCATION_LABL)
            ) {
              logger.debug('PAN Validation with 201 Status', response);
              this.windowRef.location.href = response.headers.get(
                LOCATION_LABL
              );
            } else {
              if (!response.body.onlineUser) {
                this.registerPan = true;
                this.documentRef.getElementById(
                  'btnRegisterOtp'
                ).style.display = 'block';
              } else {
                logger.debug(response);
                this.loginService.trackPan(loginForm.panNo);
                this.storageService.clearCookies(this.env.baseCookieVal);
                this.loginService.setResponseCookies(response.body);
                this.panOtpRemember = response.body?.rememberMe;
                this.successStatus =
                  response.body.successFlag === 'Y'
                    ? response.body.status
                    : null;
                this.documentRef.getElementById(
                  'investorOTPFrm'
                ).style.display = 'block';
                this.documentRef.getElementById('otpRememberMe').style.display =
                  'none';
                this.documentRef.getElementById('editPanFrm').style.display =
                  'block';
                this.loginFormInvestorUserPAN.get('userpan').disable();
                this.loginService.trackOtpSent();
              }
              this.changeDetector.detectChanges();
            }
          },
          (error: any) => {
            this.documentRef.getElementById('editPanFrm').style.display =
              'block';
            this.loginFormInvestorUserPAN.get('userpan').disable();
            this.loginService.trackPanLoginFailure();
            this.errorHandling(error);
          }
        );
    }
  }

  public validateOTP(): void {
    this.loader.style.display = 'block';
    this.appStateService.getEnvConfig();
    const submitObject = {
      signInForm: this.investorOTPForm.value,
    };

    const rememberMeVal = this.setOtpRememberMe();

    const loginForm = {
      OTP: submitObject?.signInForm?.otp,
      rememberMe: rememberMeVal,
    };
    const loginDetails = JSON.stringify(loginForm);
    this.sessionService
      .validateOTP$(loginDetails)
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.errors = null;
          this.loader.style.display = 'none';
          this.storageService.clearCookies(this.env.baseCookieVal);
          const otpOption = this.usepanTab ? OtpOption.PANOTP : undefined;
          this.loginService.trackOtpSubmit(otpOption);
          if (response.body.hasOwnProperty('userTokenResponse')) {
            if (this.usepanTab && response.body?.isRememberMe === false) {
              this.switchToBankAccountForm(response.body.status);
            } else {
              const validateResponse = this.loginService.validateSessionResponse(
                response.body.userTokenResponse
              );
              if (!validateResponse.isValid) {
                this.errors = validateResponse.errorMsg;
                this.changeDetector.detectChanges();
                return;
              }
              this.loginService.trackOtpLogin(otpOption);
              this.loginService.logIn(response.body);
            }
          } else {
            this.switchToBankAccountForm(response.body.status);
          }
        },
        (error: any) => {
          this.otpError = true;
          this.errorHandling(error);
        }
      );
  }

  public validateBankAcc(): void {
    this.loader.style.display = 'block';
    const submitObject = {
      signInForm: this.investorBankAccFrm.value,
    };

    const rememberMeVal = (this.documentRef.getElementById(
      'existUserLoginRememberMeBankAcc'
    ) as HTMLInputElement).checked
      ? 'Y'
      : 'N';
    const loginForm = {
      bankAccountNumber: submitObject?.signInForm?.accountNo,
      rememberMe: rememberMeVal,
    };
    const loginDetails = JSON.stringify(loginForm);
    this.sessionService
      .validateBankAcc$(loginDetails)
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.errors = null;
          this.loader.style.display = 'none';
          this.storageService.clearCookies(this.env.baseCookieVal);
          this.loginService.trackBankAccSubmit(loginForm.bankAccountNumber);
          if (response.body.hasOwnProperty('userTokenResponse')) {
            this.loginService.trackPanSuccess();
            this.loginService.logIn(response.body);
          }
        },
        (error: any) => {
          this.loginService.trackPanLoginFailure();
          this.errorHandling(error);
        }
      );
  }

  public redirectToRegisterNow(): void {
    this.windowRef.location.href =
      this.appStateService.getftingGuestUrl() + '/register-investor';
  }

  public generateOTP(data?): void {
    this.loader.style.display = 'block';
    let payLoad = '';
    if (data) {
      payLoad = 'REGISTER';
    }
    this.sessionService
      .generateOfflineOTP$(payLoad)
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.errors = null;
          this.loader.style.display = 'none';
          this.loginService.trackOtpResend();
          this.storageService.clearCookies(this.env.baseCookieVal);
          if (response.body.successFlag === 'Y') {
            this.storageService.setCookieByName(
              response.body.guId,
              'guId',
              this.env.baseCookieVal
            );
            if (
              response.body.status &&
              (data === 'newHPINUser' || data === 'tNewHPINUser')
            ) {
              this.storageService.setCookieByName(
                response.body.status,
                'otpMsg',
                this.env.baseCookieVal
              );
              this.storageService.store(
                'registerJourney',
                true,
                false,
                'registerJourney',
                this.env.baseCookieVal
              );
              this.windowRef.location.href =
                this.appStateService.getftingGuestUrl() +
                '/register-InvestorOtp';
            }
          } else if (response.successFlag === 'N') {
            this.errors = this.loginService.errorStatus(
              null,
              response.body.status
            );
            this.changeDetector.detectChanges();
          }
        },
        (error: any) => {
          this.errorHandling(error);
        }
      );
  }

  public backToLogin(): void {
    this.errors = null;
    this.loginWrapper = true;
    this.loginFormsReset();
    if (this.documentRef.getElementById('loginPANFrm')) {
      this.documentRef.getElementById('loginPANFrm').style.display = 'block';
      this.documentRef.getElementById('editPanFrm').style.display = 'none';
    } else {
      this.documentRef.getElementById('loginFrm').style.display = 'block';
      this.useidTab = true;
      this.loginRadioButton.nativeElement.checked = true;
    }
    this.changeDetector.detectChanges();
  }

  public backToPan(): void {
    this.errors = null;
    this.loginFormsReset();
    this.documentRef.getElementById('investorBankAccFrm').style.display =
      'none';
    this.documentRef.getElementById('investorOTPFrm').style.display = 'none';
    this.documentRef.getElementById('loginPANFrm').style.display = 'block';
    this.documentRef.getElementById('editPanFrm').style.display = 'none';
    this.loginWrapper = true;
    this.usepanTab = true;
    this.changeDetector.detectChanges();
    this.panRadioButton.nativeElement.checked = true;
  }

  public backToOtp(): void {
    this.errors = null;
    this.loginFormsReset();
    this.documentRef.getElementById('investorBankAccFrm').style.display =
      'none';
    this.documentRef.getElementById('investorOTPFrm').style.display = 'block';
    this.documentRef.getElementById('loginPANFrm').style.display = 'none';
    this.loginWrapper = true;
    this.usepanTab = true;
    this.changeDetector.detectChanges();
    this.panRadioButton.nativeElement.checked = true;
  }

  /**
   * Disabling copy paste for server functionality
   * @param event - ClipboardEvent
   */
  public onPaste(event: ClipboardEvent) {
    this.formService.onPasteForms(event);
  }

  public checkboxAction(checkboxID: string): void {
    this.loginService.checkboxAction(this.documentRef, checkboxID);
  }

  public changePassType(): void {
    if (this.passwordFieldType === 'text') {
      this.passwordFieldType = 'password';
      return;
    }
    this.passwordFieldType = 'text';
  }

  public registerNow(): void {
    this.loginService.trackRegisterNow(InUserType.INVESTOR);
  }

  public forgotUid(): void {
    this.loginService.trackForgotUser(InUserType.INVESTOR);
  }

  public forgotPass(): void {
    this.loginService.trackForgotPass(InUserType.INVESTOR);
  }

  ///////////////////
  // Private methods
  ///////////////////

  private setOtpRememberMe(): string {
    if (this.usepanTab) {
      return this.panOtpRemember ? 'Y' : 'N';
    }
    return (this.documentRef.getElementById(
      'existUserLoginRememberMe'
    ) as HTMLInputElement).checked
      ? 'Y'
      : 'N';
  }

  // Login Investor - User ID
  private createLoginInvestorUserID(): void {
    this.loginFormInvestorUserID = this.formBuilder.group({
      userid: ['', [Validators.required, Validators.minLength(1)]],
      password: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  // Login Investor - User PAN
  private createLoginInvestorUserPAN(): void {
    this.loginFormInvestorUserPAN = this.formBuilder.group({
      userpan: ['', [Validators.required, Validators.minLength(10)]],
    });
  }

  // Login Investor - OTP
  private createLoginInvestorOTPForm(): void {
    this.investorOTPForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.investorBankAccFrm = this.formBuilder.group({
      accountNo: [
        '',
        [Validators.required, Validators.pattern('^[0-9 ]{1,13}$')],
      ],
    });
  }

  /**
   * Bank Account
   */
  private createBankAccountForm(): void {
    this.investorBankAccFrm = this.formBuilder.group({
      accountNo: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  // Set default radio selection
  private defaultRadioChecked(): void {
    // We are not showing the form until accounts validation
    this.loginService
      .getValidated$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((valid: boolean) => {
        if (valid) {
          this.loginRadioButton.nativeElement.checked = true;
        }
      });
  }

  // Handling error for service calls
  private errorHandling(error): void {
    this.loader.style.display = 'none';
    this.errors = this.loginService.errorStatus(error);
    this.changeDetector.detectChanges();
  }

  private switchToBankAccountForm(responseBodyStatus: string): void {
    this.documentRef.getElementById('loginPANFrm').style.display = 'none';
    this.documentRef.getElementById('investorOTPFrm').style.display = 'none';
    this.documentRef.getElementById('investorBankAccFrm').style.display =
      'block';
    this.loginWrapper = false;
    this.successStatus = responseBodyStatus;
    this.changeDetector.detectChanges();
    this.loginService.trackBankAccEnter();
  }
  /**
   * Resetting login forms
   */
  private loginFormsReset(): void {
    this.errors = null;
    this.loginFormInvestorUserID.reset();
    this.loginFormInvestorUserPAN.reset();
    this.investorOTPForm.reset();
    this.loginFormInvestorUserPAN.get('userpan').enable();
    this.documentRef.getElementById('investorOTPFrm').style.display = 'none';
  }
}
