import { Injectable, OnDestroy } from '@angular/core';
import {
  GlobalConfig,
  LanguageData,
  Segment,
  SegmentConfig,
  SegmentId,
  SiteConfiguration,
} from '@types';
import { Logger } from '@utils/logger';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { GlobalConfigService } from './global-config-service';
import { StorageService } from './storage.service';
import { Page } from '@bloomreach/spa-sdk';

// TODO: this is a temp solution due to unresolved circular dependency when importing AppStateService
declare var appInitChannelConfig; // appInitChannelConfig is defined in index.html

const logger = Logger.getLogger('SegmentService');

@Injectable({
  providedIn: 'root',
})
export class SegmentService implements OnDestroy {
  // map of camel case names in gateway model to segments
  private readonly SegmentMap = {
    Investor: SegmentId.INVESTOR,
    FinancialProfessionals: SegmentId.FINANCIAL_PROFESSIONALS,
    Distributor: SegmentId.DISTRIBUTOR,
    Gatekeeper: SegmentId.GATEKEEPER,
    Institutional: SegmentId.INSTITUTIONAL,
    Shariah: SegmentId.SHARIAH,
    InstitutionalReports: SegmentId.INSTITUTIONAL_REPORTS,
    Internal: SegmentId.INTERNAL,
    Ra: SegmentId.RA,
    Ria: SegmentId.RIA,
  };

  private unsubscribe$: Subject<void> = new Subject<void>();

  private currentSegmentId: SegmentId;
  private segments: Segment[] = [];
  private defaultSegmentId: SegmentId;
  private segmentCharacteristicsString: string;
  private multilingual: LanguageData[];

  private segmentIdSubject$: ReplaySubject<SegmentId> = new ReplaySubject<SegmentId>(
    1
  );
  private segmentsSubject$: ReplaySubject<Segment[]> = new ReplaySubject<
    Segment[]
  >(1);
  private isSegmentSetSubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>(
    1
  );

  constructor(
    globalConfigService: GlobalConfigService,
    private storageService: StorageService
  ) {
    // set default segment from channel json
    this.defaultSegmentId = appInitChannelConfig
      ? appInitChannelConfig.defaultSegment
      : null;
    logger.debug('default site segment', this.defaultSegmentId);

    // see if segment has been set in Storage
    this.checkSegmentSet();

    // Try to load segment from Local Storage not asynchronously.
    // For constructor, async load is not required and can cause issues.
    const segmentId = this.storageService.retrieveSegmentStatic();
    this.setSegmentOnInit(segmentId);

    // load segments from site config
    globalConfigService
      .getSubject$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((config: GlobalConfig) => {
        this._loadSegments(config.siteConfiguration);
        if (config.siteConfiguration?.languages) {
          this.multilingual = config.siteConfiguration.languages;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ////////////////////////
  // getters
  ////////////////////////

  /**
   * This won't emit the site segments until they are first loaded.
   * After that, any new subscribers will recieve the latest Segments array
   */
  public getSegments$(): Observable<Segment[]> {
    return this.segmentsSubject$.asObservable();
  }

  /**
   * returns an array of the site segments
   * NB: this will be undefined initially. If you need to wait until it's defined, use getSegments$() Observable instead
   */
  public getSegments(): Array<Segment> {
    return this.segments;
  }

  /**
   * returns only segments visible in role selector
   */
  public getVisibleSegments(): Array<Segment> {
    return this.segments.filter(
      (segment: Segment) => segment.isHiddenInBanner !== true
    );
  }
  /**
   * This won't emit the current segmentId until it is first set.
   * After that, any new subscribers will recieve the latest SegmentId
   */
  public getCurrentSegmentId$(): Observable<SegmentId> {
    return this.segmentIdSubject$.asObservable();
  }

  /**
   * This returns the current segment id
   * NB: it could be undefined if not set, or segments not loaded yet
   * In general, you're safer subscribing to getCurrentSegmentId$() than using this
   * @param useDefault If true, will return default segment id if no current segment. NB: this could be undefined initially too
   */
  public getCurrentSegmentId(useDefault = false): SegmentId {
    if (this.currentSegmentId) {
      return this.currentSegmentId;
    }
    if (useDefault) {
      return this.defaultSegmentId;
    }
  }

  /**
   * This won't emit the current segmentId until it is first set.
   * After that, any new subscribers will recieve the latest Segment
   * it works by combining the current segmentId with loaded segments
   */
  public getCurrentSegment$(): Observable<Segment> {
    return combineLatest([
      this.getSegments$(),
      this.getCurrentSegmentId$(),
    ]).pipe(
      map(
        ([segments, segmentId]: [Segment[], SegmentId]): Segment =>
          segments.find((segment: Segment): boolean => segment.id === segmentId)
      )
    );
  }

  /**
   * This returns the current segment object
   * NB: it could be undefined if not set, or segments not loaded yet
   * In general, you're safer subscribing to getCurrentSegment$() than using this
   * @param useDefault If true, will return default segment if no current segment. NB: this could be null initially too
   */
  public getCurrentSegment(useDefault = false): Segment {
    const segment: Segment = this.getSegment(this.getCurrentSegmentId());
    if (segment) {
      return segment;
    }
    if (useDefault) {
      return this.getDefaultSegment();
    }
  }

  /**
   * returns the default site segment id
   * NB: this will be undefined until segments loaded
   */
  public getDefaultSegmentId(): SegmentId {
    return this.defaultSegmentId;
  }

  /**
   * returns the default site segment object
   * NB: this will be undefined until segments loaded
   */
  public getDefaultSegment(): Segment {
    return this.getSegment(this.getDefaultSegmentId());
  }

  /**
   * returns a segment object based on segmentId
   * NB: this could be undefined if segments not loaded
   */
  public getSegment(segmentId: SegmentId): Segment {
    return this.segments.find((segment) => segment.id === segmentId);
  }

  /**
   * returns a segment object based on segment label
   * NB: this could be undefined if segments not loaded
   */
  public getSegmentByLabel(segmentLabel: string): Segment {
    return this.segments.find((segment: Segment) => {
      return segment.label.trim() === segmentLabel;
    });
  }

  /**
   * This will emit after the segment LS/cookie has first been checked for on start up
   * After that, setting (or removing) the segment in Storage will cause it to emit again
   */
  public isSegmentSet$(): Observable<boolean> {
    return this.isSegmentSetSubject$.asObservable();
  }

  /**
   * This will emit true when the current segment requires T&Cs accepted, but the terms cookie has not been set
   * Otherwise emits false
   * Use this to toggle display of the T&Cs modal
   */
  public termsNeedAccepted$(): Observable<boolean> {
    return combineLatest([
      this.getCurrentSegment$(),
      this.isSegmentSet$(),
    ]).pipe(
      map(
        ([segment, isSet]: [Segment, boolean]): boolean =>
          // IF we have only 1 segment we don't have gateway and need to check T&C
          (isSet || this.segments.length === 1) &&
          segment.termsRequired &&
          !segment.termsAccepted
      )
    );
  }

  ////////////////////////
  // setters
  ////////////////////////

  /**
   * used to update the current segment
   * @param segmentId sets
   * @param storeData if true, save new segmentId to LS/Cookie
   * @param forceTerms if true, and segment requires terms, set accepted and save terms LS/Cookie
   * returns false if not changed e.g. segment not found
   */
  public setSegment(
    segmentId: SegmentId,
    storeData = false,
    forceTerms = false
    // selector?: Selector  #WDE-292
  ): boolean {
    logger.debug('setSegment()', segmentId, storeData, forceTerms);

    if (!segmentId) {
      logger.debug('segmentId is undefined', segmentId, typeof segmentId);
      return false;
    }

    // 1. set current segment and update subject
    this.currentSegmentId = segmentId;
    // if (selector !== Selector.MODAL) { #WDE-292 - to verify why this condition was here
    // When segment is selected in modal we shouldn't refresh segmentIdSubject$ to avoid loading wrong content from resource-api
    this.segmentIdSubject$.next(segmentId);
    // } #WDE-292

    // 2. if storeData, write to LS/Cookie
    if (storeData) {
      this.storageService.storeSegment(segmentId, this.multilingual);
    }

    // 3. update isSegmentSet$
    // We need to check if if segment is set in local storage before getting segments from configuration.
    // It can be set in storage by bypass.
    this.checkSegmentSet();

    // 4 find segment from id
    const segment: Segment = this.getSegment(segmentId);
    if (!segment) {
      // this could happen if segments have not been loaded
      return false;
    }

    // 5. check if we need to force terms
    if (forceTerms && segment.termsRequired && !segment.termsAccepted) {
      this.acceptTerms();
    }

    return true;
  }

  /**
   * accepts terms for the current segment, and stores LS/Cookie to record this
   */
  public acceptTerms(): void {
    const segment: Segment = this.getCurrentSegment();
    logger.info('accepting terms for: ' + segment.id);
    if (segment) {
      segment.termsAccepted = true;
      this.storageService.storeTermsAgreed(segment.id, true);
      // although it's still the same segment, this calls next() because segment data has changed
      this.segmentsSubject$.next(this.getSegments());
    }
  }

  /**
   * declines terms for the current segment, and deletes segment LS/Cookie to record this
   * NB: this will be handled elsewhere, but deleteing the segment LS/Cookie will cause the role selector banner to appear
   */
  public declineTerms(): void {
    const segment: Segment = this.getCurrentSegment();
    if (segment) {
      logger.info('declining terms for: ' + segment.id);
      //  update segment
      segment.termsAccepted = false;
      // remove segment LS/Cookie
      this.storageService.removeSegment(this.multilingual);
      // shouldn't be necessary, but remove terms LS/Cookie too
      this.storageService.removeTermsAgreed(segment.id);
      // although it's still the same segment, this calls next() because segment data has changed
      this.segmentsSubject$.next(this.getSegments());
    }
    // set back to default segment
    this.setSegment(this.getDefaultSegmentId());
    // update isSegmentSet$
    this.checkSegmentSet();
  }

  /**
   * Forcing T&C in the segment
   */
  public forceTerms(): void {
    const segment: Segment = this.getCurrentSegment();
    if (segment) {
      logger.info('force terms for: ' + segment.id);
      //  update segment
      segment.termsAccepted = false;
      segment.termsRequired = true;
      this.segmentsSubject$.next(this.getSegments());
    }
  }

  ////////////////////////
  // private methods
  ////////////////////////

  /**
   * Set segment on initialization
   * @param segmentId - SegmentId
   */
  private setSegmentOnInit(segmentId: SegmentId): void {
    if (segmentId) {
      // segment found in storage, so use that value
      this.setSegment(segmentId);
      return;
    }
    if (this.defaultSegmentId) {
      // segment not found, so use site default
      // NB: this only sets the currentSegmentId property - it doesn't set any values in storage
      this.setSegment(this.defaultSegmentId);
    }
  }

  /**
   * this checks if a segment has been set in Storage, and updates the subject
   */
  private checkSegmentSet(): void {
    this.storageService.isSegmentSet().then((isSet: boolean): void => {
      logger.debug('checkSegmentSet():::::::', isSet);
      this.isSegmentSetSubject$.next(isSet);
    });
  }

  /**
   * populate segments from pageModel siteConfiguration
   */
  private _loadSegments(siteConfig: SiteConfiguration) {
    if (this.segments.length === 0 && siteConfig !== undefined) {
      const segments: Segment[] = siteConfig.segments.map(
        (segment: SegmentConfig, i: number): Segment => {
          return {
            // if no id passed (i.e. external segment), use label as id instead
            id: segment.id || (segment.label as SegmentId),
            label: segment.label,
            subLabel: segment.subLabel,
            termsRequired: segment.terms,
            termsMustRead: segment.termsMustRead,
            termsWithSignIn: segment.termsWithSignIn,
            termsAccepted: !segment.terms,
            externalLink:
              segment.externalLink !== '' ? segment.externalLink : undefined,
            insightAudiences: segment.insightAudiences_csv,
            declineUrl: segment.declineUrl,
            isHiddenInBanner: segment.isHiddenInBanner,
            isHiddenInSwitcher: segment.isHiddenInSwitcher,
          };
        }
      );
      logger.debug('parsed segments', segments);
      this.segments = segments;

      // for any segments that need terms accepted retrieve acceptance values from storage
      const needAcceptance: Segment[] = segments.filter(
        (segment: Segment): boolean => !segment.termsAccepted
      );
      if (needAcceptance.length > 0) {
        Promise.all(
          needAcceptance.map(
            (segment: Segment): Promise<void> => {
              return this.storageService
                .retrieveTermsAgreed(segment.id)
                .then((agreed: boolean) => {
                  segment.termsAccepted = agreed;
                });
            }
          )
        ).then((): void => {
          // update segments once all acceptances have been gathered
          logger.debug('segments after terms cookies checked', this.segments);
          this.segmentsSubject$.next(segments);
        });
      } else {
        this.segmentsSubject$.next(segments);
      }
    }
  }

  public getSegmentCharacteristicsString(): string {
    return this.segmentCharacteristicsString;
  }

  public setSegmentCharacteristicsString(segmentCharacteristics: string) {
    this.segmentCharacteristicsString = segmentCharacteristics;
  }

  /**
   * Checks if page can be displayed for current segment.
   * @param page - BR Page
   */
  public checkSegmentRestrict(page: Page, isLoggedIn?: boolean): boolean {
    const gatewayModel = page
      ?.getComponent('page-config', 'gateway')
      ?.getModels()?.gateway;
    if (gatewayModel) {
      const roleFilter = isLoggedIn ? 'loggedIn' : 'public';
      const accessSegments = Object.getOwnPropertyNames(gatewayModel)
        .filter((prop) => prop.startsWith(roleFilter) && gatewayModel[prop])
        .map((prop) => this.SegmentMap[prop.replace(roleFilter, '')]);

      if (isLoggedIn) {
        return (
          accessSegments.length > 0 &&
          accessSegments.includes(this.currentSegmentId)
        );
      }
      return accessSegments.length > 0
        ? accessSegments.includes(this.currentSegmentId)
        : true;
    }
    if (!gatewayModel && !isLoggedIn) {
      return true;
    }
  }
}
