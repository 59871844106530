<div class="row">
  <div class="col-6">
    <h6>{{ "ftiCommon.search-investor-filter" | translate }}</h6>
  </div>
  <div class="col-6 text-right" *ngIf="!isViewAll && totalCount > 0">
    <a class="view-all" (click)="clickEvent($event)"
      >View All {{ totalCount }} results</a
    >
  </div>
  <div class="col-12">
    <ng-container *ngIf="results?.length !== 0; else elseBlock">
      <div class="search-item" *ngFor="let result of results; let i = index">
        <ng-container *ngIf="i < valueToShow">
          <h6 class="search-title">
            <a
              class="article"
              [href]="result?.titleLink"
              [innerHTML]="result?.title"
            >
            </a>
          </h6>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #elseBlock>
      <p class="search-item">
        {{ "ftiCommon.search-no-results-desc" | translate }}
        "{{ this.queryParams.query }}"
      </p>
    </ng-template>
  </div>
</div>
<div class="row">
  <div
    class="col-xs-12 text-center"
    *ngIf="isViewAll && totalCount > valueToShow"
  >
    <input
      aria-label="loadmorebutton"
      type="button"
      value="Load More"
      class="btn btn-default panel-orange-btn load-more-btn"
      (click)="loadMore($event)"
    />
  </div>
</div>
