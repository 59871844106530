<ng-template [ngIf]="mapping && page && configuration">
  <div id="loader" class="ftic-ajax-loader text-center" style="display: none">
    <div class="ftic-ajax-loader-content text-center ftic-overlay">
      <img
        src="https://franklintempletonprod.widen.net/content/kelqgnkl5z/web/loader.gif"
        alt="Please wait"
      />
      <div>Please wait...</div>
    </div>
  </div>
  <br-page [configuration]="configuration" [mapping]="mapping" [page]="page">
    <ng-template let-page="page">
      <header *ngIf="!isBetterLifeVisible && !emi && !isMarathonPage">
        <ng-container brComponent="header-content"></ng-container>
      </header>
      <ng-container
        *ngIf="isLayoutVisible && layout !== 'home-page' && !isPageNotFound"
      >
        <ft-breadcrumb
          [page]="page"
          *ngIf="!isBetterLifeVisible && !emi && !isMarathonPage"
        ></ft-breadcrumb>
      </ng-container>

      <!-- Fund compare toast notification -->
      <ft-toast-notification
        *ngIf="showCompareToastMessage"
        [toastMessage]="compareToastMessage"
      ></ft-toast-notification>

      <!-- Watch list toast notification -->
      <ft-toast-notification
        *ngIf="showWatchlistToast"
        [toastMessage]="watchListToastMessage"
      ></ft-toast-notification>

      <section
        class="main-section"
        *ngIf="layout !== 'home-page' && !isFundPages"
      >
        <ng-container brComponent="main-content">
          <ft-flash-messages
            *ngIf="isFlashMessageExist"
            [flashPage]="flashPage"
          ></ft-flash-messages>
        </ng-container>
      </section>
      <ng-container *ngIf="!isBetterLifeVisible && !emi && !isMarathonPage">
        <section [ngSwitch]="layout">
          <section *ngSwitchCase="'home-page'">
            <section>
              <ng-container brComponent="carousel-content"></ng-container>
            </section>
            <ft-horizontal-column-tab
              [numberOfTabs]="tabCount"
              [page]="page"
              [layout]="layout"
            ></ft-horizontal-column-tab>
            <section>
              <ng-container brComponent="quick-links"></ng-container>
            </section>
            <section>
              <div>
                <div
                  class="col-md-12 col-sm-12"
                  style="float: left; background-color: #f7f9f9"
                >
                  <ng-container brComponent="updates-blogs">
                    <!-- TODO - this can be created as a base list component and data could be intake from BR config -->
                    <ft-update-list [limit]="3"></ft-update-list>
                  </ng-container>
                </div>
                <div class="col-md-6 col-sm-12" style="float: left">
                  <ng-container brComponent="marketinsight-content">
                  </ng-container>
                </div>
              </div>
            </section>
          </section>
          <section *ngSwitchCase="'small-tabs'">
            <section>
              <ng-container brComponent="profile-title"></ng-container>
            </section>
            <div class="fti-container tabs-layout">
              <div class="col-lg-12 content-container">
                <div class="row">
                  <div class="col-12 col-sm-6 pl-0">
                    <ng-container brComponent="title-content"></ng-container>
                  </div>
                  <div class="col-12 col-sm-6 pl-0">
                    <ng-container brComponent="lead-content"></ng-container>
                  </div>
                </div>
              </div>
            </div>
            <ft-horizontal-column-tab
              [numberOfTabs]="tabCount"
              [page]="page"
              [layout]="layout"
            ></ft-horizontal-column-tab>
          </section>

          <section *ngSwitchCase="'one-tab'">
            <section>
              <ng-container brComponent="profile-title"></ng-container>
            </section>
            <div class="fti-container tabs-layout">
              <div class="col-lg-12 content-container">
                <div class="row">
                  <div class="col-12 col-md-8 col-sm-6 pl-0">
                    <ng-container brComponent="title-content"></ng-container>
                  </div>
                  <div class="col-12 col-md-4 col-sm-6 pl-0">
                    <ng-container brComponent="lead-content"></ng-container>
                  </div>
                </div>
              </div>
            </div>
            <ft-horizontal-column-tab
              [numberOfTabs]="tabCount"
              [page]="page"
              [layout]="layout"
            ></ft-horizontal-column-tab>
          </section>

          <section *ngSwitchCase="'large-tabs'">
            <section>
              <ng-container brComponent="profile-title"></ng-container>
            </section>
            <ft-horizontal-column-tab
              [numberOfTabs]="tabCount"
              [page]="page"
              [layout]="layout"
            ></ft-horizontal-column-tab>
          </section>

          <section *ngSwitchCase="'smallTabsLayout'">
            <section>
              <ng-container brComponent="profile-title"></ng-container>
            </section>
            <ft-horizontal-column-tab
              [numberOfTabs]="tabCount"
              [page]="page"
              [layout]="layout"
            ></ft-horizontal-column-tab>
          </section>

          <ng-container *ngSwitchCase="'twocol'">
            <ft-twoequalcol></ft-twoequalcol>
          </ng-container>
          <ng-container *ngSwitchCase="'onecol'">
            <ft-onecol></ft-onecol>
          </ng-container>
          <ng-container *ngSwitchCase="'twocol-leftwide'">
            <ft-two-col-left-wide></ft-two-col-left-wide>
          </ng-container>
          <ng-container *ngSwitchCase="'twocol-leftwide-custom'">
            <ft-two-col-left-wide-custom></ft-two-col-left-wide-custom>
          </ng-container>
          <ng-container *ngSwitchCase="'video-inner'">
            <ft-layout-video-inner [page]="page"></ft-layout-video-inner>
          </ng-container>
        </section>
      </ng-container>
      <footer
        *ngIf="!isBetterLifeVisible && !emi && !isMarathonPage"
        class="footer-content"
      >
        <ng-container brComponent="footer-content"></ng-container>
      </footer>
    </ng-template>
  </br-page>
</ng-template>
