<div
  class="displayList"
  [ngClass]="{
    'reach-for-better-work': contentType === 'reach for better work'
  }"
>
  <ng-container *ngIf="contentType === 'BlogList'">
    <ft-blog-list
      [page]="page"
      [categories]="categories"
      [originalBlogs]="originalBlogs"
    ></ft-blog-list>
  </ng-container>
  <ng-container *ngIf="contentType === 'reach for better work'">
    <ft-reachforbetter-work
      [page]="page"
      [categories]="categories"
      [originalBlogs]="originalBlogs"
    ></ft-reachforbetter-work>
  </ng-container>
</div>
