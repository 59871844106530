<div
  data-description="SIP(Systematic Investment Plan) has the potential to beat inflation and create wealth in long run. If you know your goal amount, our SIP calculator can provide the monthly investment in an SIP, needed to help your goal as Fund Portfolio is managed by Professional fund managers."
  data-title="Marathon"
  data-url="/content-international/campaigns/en-in-retail/goodemi/marathon/The-Marathon-of-Life-Booklet.pdf"
  style="margin: 0px auto; clear: both"
>
  <div
    aria-labelledby="at-7953705b-0602-49d5-abf1-b02321412e52"
    class="at-resp-share-element at-style-responsive addthis-smartlayers addthis-animated at4-show"
    id="atstbx"
    role="region"
  >
    <div class="at-share-btn-elements">
      <a
        class="at-icon-wrapper at-share-btn at-svc-facebook"
        href="https://www.facebook.com/dialog/share?app_id=140586622674265&display=popup&href={{
          currentPageUrl
        }}%23.YgUTMctDJjI.facebook&redirect_uri=http://s7.addthis.com/static/thankyou.html"
        role="button"
        style="
          background-color: rgb(59, 89, 152);
          border-radius: 2px;
          padding: 5px;
          margin: 0 2px 5px;
        "
        tabindex="0"
        target="_blank"
      >
        <span
          class="at-icon-wrapper"
          style="line-height: 0; height: 20px; width: 20px; overflow: unset"
        >
          <svg
            preserveAspectRatio="xMidYMin"
            aria-labelledby="at-svg-facebook-1"
            class="at-icon"
            role="img"
            style="fill: rgb(255, 255, 255); height: 20px; width: 20px"
            version="1.1"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title id="at-svg-facebook-1"></title>
            <g>
              <path
                d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z"
                fill-rule="evenodd"
              ></path>
            </g>
          </svg>
        </span>
      </a>
      <a
        class="at-icon-wrapper at-share-btn at-svc-twitter"
        href="https://twitter.com/intent/tweet?text={{ textTitle }}&url={{
          currentPageUrl
        }}%23.Yjg_FHRS9_A.twitter&related="
        role="button"
        style="
          background-color: rgb(29, 161, 242);
          border-radius: 2px;
          padding: 5px;
          margin: 0 2px 5px;
        "
        tabindex="0"
        target="_blank"
      >
        <span
          class="at-icon-wrapper"
          style="line-height: 0; height: 20px; width: 20px; overflow: unset"
        >
          <svg
            aria-labelledby="at-svg-twitter-2"
            class="at-icon at-icon-twitter"
            role="img"
            style="fill: rgb(255, 255, 255); height: 20px; width: 20px"
            version="1.1"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title id="at-svg-twitter-2"></title>
            <g>
              <path
                d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336"
                fill-rule="evenodd"
              ></path>
            </g>
          </svg>
        </span>
      </a>
      <a
        class="at-icon-wrapper at-share-btn at-svc-linkedin"
        href="https://www.linkedin.com/uas/login?session_redirect=https://www.linkedin.com/shareArticle?mini=true&url={{
          currentPageUrl
        }}%2523.YgUS9SezcoY.linkedin&title={{ textTitle }}&source="
        role="button"
        style="
          background-color: rgb(0, 119, 181);
          border-radius: 2px;
          padding: 5px;
          margin: 0 2px 5px;
        "
        tabindex="0"
        target="_blank"
      >
        <span
          class="at-icon-wrapper"
          style="line-height: 0; height: 20px; width: 20px; overflow: unset"
        >
          <svg
            aria-labelledby="at-svg-linkedin-3"
            class="at-icon at-icon-linkedin"
            role="img"
            style="fill: rgb(255, 255, 255); height: 20px; width: 20px"
            version="1.1"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title id="at-svg-linkedin-3"></title>
            <g>
              <path
                d="M26 25.963h-4.185v-6.55c0-1.56-.027-3.57-2.175-3.57-2.18 0-2.51 1.7-2.51 3.46v6.66h-4.182V12.495h4.012v1.84h.058c.558-1.058 1.924-2.174 3.96-2.174 4.24 0 5.022 2.79 5.022 6.417v7.386zM8.23 10.655a2.426 2.426 0 0 1 0-4.855 2.427 2.427 0 0 1 0 4.855zm-2.098 1.84h4.19v13.468h-4.19V12.495z"
                fill-rule="evenodd"
              ></path>
            </g>
          </svg>
        </span>
      </a>
      <a
        class="at-icon-wrapper at-share-btn at-svc-whatsapp"
        href="https://api.whatsapp.com/send?text={{ textTitle }} {{
          currentPageUrl
        }}%23.YgUR_nh_sa4.whatsapp"
        role="button"
        style="
          background-color: rgb(77, 194, 71);
          border-radius: 2px;
          padding: 5px;
          margin: 0 2px 5px;
        "
        tabindex="0"
        target="_blank"
      >
        <span
          class="at-icon-wrapper"
          style="line-height: 0; height: 20px; width: 20px; overflow: unset"
        >
          <svg
            aria-labelledby="at-svg-whatsapp-4"
            class="at-icon at-icon-whatsapp"
            role="img"
            style="fill: rgb(255, 255, 255); height: 20px; width: 20px"
            version="1.1"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title id="at-svg-whatsapp-4"></title>
            <g>
              <path
                d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                fill-rule="evenodd"
              ></path>
            </g>
          </svg>
        </span>
      </a>
    </div>
  </div>
</div>
