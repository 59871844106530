import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  Component as BrComponent,
  Page,
  Document,
  Reference,
} from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { getPageUrl } from '@utils/pagemodel-utils';
const logger = Logger.getLogger('IndCarouselComponent');

@Component({
  selector: 'ft-ind-carousel',
  templateUrl: './ind-carousel.component.html',
  styleUrls: ['./ind-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndCarouselComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: any;
  navigationArrow = true;
  navigationIndicators = true;
  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    const carouselLength = this.CarouselImages;
    this.navigationArrow = carouselLength?.length > 1 ? true : false;
    this.navigationIndicators = carouselLength?.length > 1 ? true : false;
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    const contentValue = this.document?.getData();
    return contentValue;
  }

  get CarouselImages() {
    const imagesUrl = '';
    const data = this.content?.carouselCompoundType;

    // NOSONAR - leave for future development
    // if (data) {
    //   data.forEach((element) => {
    //     element.images = this.getImages(element.imageCompound);
    //   });
    // }
    // this.navigationArrow = data.length < 0 ? true : false;
    return data;
  }

  get contentBlocks() {
    const data = this.content?.contentBlocks;
    return data;
  }

  public getPageUrl(ref: Reference | string) {
    return getPageUrl(this.page, ref);
  }

  public getImageURL(data): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return '';
  }

  // TODO: Check if this is required
  public getImages(imageUrl: any): string {
    let images;
    if (imageUrl?.imagelink) {
      const url = imageUrl?.imagelink?.$ref?.split('/content/')[1] || '';
      const modelKey = 'model';
      const contentKey = 'content';
      images = this.page?.[modelKey][contentKey][url].links.site.href || '';
    } else if (
      imageUrl?.contentType === 'ftcore:ImageCompound' &&
      imageUrl?.widenAsset
    ) {
      images = JSON.parse(imageUrl?.widenAsset).url
        ? JSON.parse(imageUrl?.widenAsset).url
        : '';
    }
    return images;
  }

  get isPreview() {
    return this?.page?.isPreview() || {};
  }
}
