import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noSpaceApersand',
})
export class NoSpaceApersandPipe implements PipeTransform {
  transform(value, anchor: string): string {
    if (value && value !== '') {
      value = value.toLowerCase();
      value = value.replaceAll('&', '');
      value = value.replaceAll(' ', '-');
      return anchor === 'anchor' ? '#fti-nav_' + value : 'fti-nav_' + value;
    }
    return '';
  }
}
