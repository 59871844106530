export enum PaginationActionEnum {
  load = 'load',
  previous = 'previous',
  next = 'next',
  activePage = 'activePage',
}

export type PaginationActionType = keyof typeof PaginationActionEnum;

export interface EDSPaginationPageChangedEvent {
  page: number; // current page number
  action: PaginationActionType; // 'load' , 'previous', 'next' and 'activePage'
}

export interface OptionWithCount {
  value: string;
  count?: number;
  label?: string;
}
