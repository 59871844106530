<div class="interestedForm">
  <div class="fti-container position-relative">
    <div class="row mt-4">
      <div class="col-sm-12 col-md-9">
        <p>{{ content }}</p>
      </div>
      <div class="col-sm-12 col-md-3">
        <button
          type="button"
          class="btn show-interested"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalLabel"
          (click)="openInterestedModal()"
        >
          {{ labelInterestedButton }}
        </button>
      </div>
    </div>

    <div
      class="interestedFormModal modal fade"
      id="exampleModalLabel"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <a
              type="button"
              class="fill-circle-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ><span class="icon-fti_close"></span
            ></a>
          </div>
          <div
            class="modal-body custom-modal"
            [ngClass]="{
              'custom-padding': isThankYou === true
            }"
          >
            <div *ngIf="isThankYou" class="col-sm-12 col-xs-12">
              <div class="thankYou">
                <h5>
                  <span class="success-icon icon icon-fti_tick"></span>
                  {{ flashMessage }}
                </h5>
              </div>
            </div>
            <div *ngIf="!isThankYou">
              <div class="col-sm-12">
                <h1>I'M INTERESTED</h1>
                <p>Please enter your details</p>
              </div>
              <div class="col-sm-12">
                <form
                  [formGroup]="interestedForm"
                  (ngSubmit)="interestedSubmit()"
                  autocomplete="off"
                  novalidate="true"
                  method="post"
                >
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="field-wrapper">
                        <div
                          class="form-group"
                          [ngClass]="{
                            focused: this.interestedForm.value.name
                          }"
                        >
                          <label
                            class="control-label"
                            for="interestedformnamefield"
                            >{{ labelName }}
                            <sup>*</sup>
                          </label>
                          <input
                            id="interestedformnamefield"
                            formControlName="name"
                            maxlength="80"
                            type="text"
                            class="form-field form-control"
                            (keyup)="onKeyUp($event, 'name')"
                            onpaste="return false"
                          />
                        </div>
                        <div *ngIf="errMsg" class="clsRed">
                          <span *ngFor="let err of errMsg">
                            {{ err.name }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="field-wrapper">
                        <div
                          class="form-group"
                          [ngClass]="{
                            focused: this.interestedForm.value.emailId
                          }"
                        >
                          <label
                            class="control-label"
                            for="interestedformemailfield"
                            >{{ labelEmail }}
                            <sup>*</sup>
                          </label>
                          <input
                            id="interestedformemailfield"
                            formControlName="emailId"
                            maxlength="100"
                            type="text"
                            class="form-field form-control"
                            (keyup)="onKeyUp($event, 'emailId')"
                            onpaste="return false"
                          />
                        </div>
                        <div *ngIf="errMsg" class="clsRed">
                          <span *ngFor="let err of errMsg">
                            {{ err.emailId }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="field-wrapper">
                        <div
                          class="form-group"
                          [ngClass]="{
                            focused: this.interestedForm.value.mobile
                          }"
                        >
                          <label class="control-label" for="mobilelabel"
                            >{{ labelMobile }}
                          </label>
                          <input
                            id="mobilelabel"
                            formControlName="mobile"
                            maxlength="10"
                            type="number"
                            class="form-field form-control"
                            (keypress)="restrictCharacter($event)"
                            onpaste="return false"
                          />
                        </div>
                        <div *ngIf="errMsg" class="clsRed">
                          <span *ngFor="let err of errMsg">
                            {{ err.mobile }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="field-wrapper">
                        <div class="form-group">
                          <div
                            ngbDropdown
                            class="city-dropdown d-inline-block w-100"
                          >
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              id="dropdownBasic1"
                              ngbDropdownToggle
                            >
                              {{ selectedCity }}
                            </button>
                            <div
                              ngbDropdownMenu
                              aria-labelledby="dropdownBasic1"
                              class="dropdownSection"
                            >
                              <button
                                type="button"
                                ngbDropdownItem
                                *ngFor="
                                  let city of citiesValues | slice: 1;
                                  index as i
                                "
                                [ngClass]="{
                                  even: i % 2 != 0,
                                  odd: i % 2 == 0
                                }"
                                (click)="onSelect(city.name)"
                              >
                                {{ city.name }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="field-wrapper">
                        <div
                          class="form-group"
                          [ngClass]="{
                            focused: this.interestedForm.value.distId
                          }"
                        >
                          <label class="control-label" for="arnlabel"
                            >{{ labelARN }}
                            <sup>*</sup>
                          </label>
                          <input
                            id="arnlabel"
                            formControlName="distId"
                            maxlength="20"
                            type="text"
                            class="form-field form-control"
                            (keyup)="onKeyUp($event, 'distId')"
                            onpaste="return false"
                          />
                        </div>
                        <div *ngIf="errMsg" class="clsRed">
                          <span *ngFor="let err of errMsg">
                            {{ err.distId }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-2 pb-2">
                    <div class="col-sm-12 col-md-6">
                      <div class="field-wrapper">
                        <div class="form-group">
                          <input
                            aria-label="arncheckbox"
                            type="checkbox"
                            [checked]="accepted"
                            (click)="arnNotRequired($event)"
                          />
                          <p class="arnCheckbox">
                            {{ labelARNCheckbox }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="field-wrapper">
                        <div
                          class="form-group"
                          [ngClass]="{
                            focused: this.interestedForm.value.subscriptions
                          }"
                        >
                          <label class="control-label" for="comment"
                            >{{ labelComment }}
                            <sup>*</sup>
                          </label>
                          <textarea
                            id="comment"
                            formControlName="subscriptions"
                            maxlength="500"
                            aria-label="subscriptions"
                            class="form-field form-control"
                            (keyup)="onKeyUp($event, 'subscriptions')"
                            onpaste="return false"
                          ></textarea>
                        </div>
                        <div *ngIf="errMsg" class="clsRed">
                          <span *ngFor="let err of errMsg">
                            {{ err.feedback }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row interested-btn">
                    <div class="col-sm-12 p-0">
                      <button
                        [disabled]="!interestedForm.valid || isFormSubmitted"
                        type="submit"
                        class="btn btn-default submit-btn"
                      >
                        {{ labelSubmit }}
                        <span
                          *ngIf="isFormSubmitted"
                          class="icon glyphicon glyphicon-repeat fast-right-spinner"
                        ></span>
                      </button>

                      <button
                        type="button"
                        class="btn btn-default reset-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        (click)="reset()"
                      >
                        {{ labelCancel }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
