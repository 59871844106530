import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import forEach from 'lodash/forEach';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import { DownloadsService } from '@services/downloads.service';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { FundReportPageCategory } from 'src/app/ft-constants/fund-reports';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ft-fund-reports',
  templateUrl: './fund-reports.component.html',
  styleUrls: ['./fund-reports.component.scss'],
})
export class FundReportsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  selectedPrimaryDropdown: any;
  selectedDropdown: any;
  containers: any;
  fundDocuments: any;
  errorInService: boolean;
  isLoaded = false;
  documentName: { allDocs: []; docNames: [] };
  showDocumentPanel = true;
  reportCategories: any;
  serviceStatus = 'Loading';
  hasMultipleFilters = false;
  pageCategory: string;

  // Content from BR
  categoryDocContent: any;

  // Determine from BR page type/url
  downloadPageCategory: string;
  selectedSecondaryFilterItem: string;

  // Check if deep linked filters are present
  hasDeepLinkedFilter: boolean;

  constructor(
    private downloadsService: DownloadsService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.pageCategory = this.component?.getModels().config?.documentCategory;
    switch (this.pageCategory) {
      case FundReportPageCategory.formsInstructions:
        this.downloadPageCategory =
          FundReportPageCategory.formsInstructionsValue;
        break;
      case FundReportPageCategory.fundDocuments:
        this.downloadPageCategory = FundReportPageCategory.fundDocumentsValue;
        break;
      case FundReportPageCategory.fundLiterature:
        this.downloadPageCategory = FundReportPageCategory.fundLiteratureValue;
        break;
      case FundReportPageCategory.disclosureDocument:
        this.downloadPageCategory =
          FundReportPageCategory.disclosureDocumentValue;
        break;
      case FundReportPageCategory.updates:
        this.downloadPageCategory = FundReportPageCategory.updatesValue;
        break;
      case FundReportPageCategory.report:
        this.downloadPageCategory = FundReportPageCategory.reportsValue;
        break;
      default:
        this.downloadPageCategory = this.getDeepLinkingFilterParams();
    }

    const pullDataForCategory =
      this.pageCategory === 'Reports'
        ? FundReportPageCategory.report
        : FundReportPageCategory.download;

    this.downloadsService.getData$(pullDataForCategory)?.subscribe(
      (response) => {
        this.isLoaded = true;
        this.reportCategories = response;

        if (this.reportCategories?.hasOwnProperty('PageType')) {
          // For downloads
          this.hasMultipleFilters = true;

          // determine if content is present
          forEach(this.reportCategories?.PageType, (category) => {
            forEach(category.FirstDropDown, (documentType, key) => {
              if (documentType.isCategoryDocContent) {
                // Get document data from BR
                const documents = this.component?.getModels()?.document;
                this.categoryDocContent = this.page
                  ?.getContent(documents)
                  ?.getData().contentBlocks;

                // Remove placeholder BR Content
                category.FirstDropDown.splice(key);

                // pull the required date
                const sanitizedDocContent = flatten(
                  this.categoryDocContent?.map((content) => ({
                    isContent: true,
                    id: content?.title,
                    markup: content?.content?.content?.value,
                  }))
                );

                // push sanitized data to document list
                forEach(sanitizedDocContent, (eachContentBlock) => {
                  category.FirstDropDown.push(eachContentBlock);
                });
              }
            });
          });

          // END

          this.fundDocuments = this.getDocumentsByCategory(
            this.reportCategories?.PageType,
            this.downloadPageCategory
          );
        } else {
          // For reports
          this.fundDocuments = this.reportCategories.FirstDropDown;
        }

        if (!this.fundDocuments) {
          this.serviceStatus = 'No documents found. Please try after sometime.';
          this.isLoaded = false;
        }

        this.documentName = this.documentNames(this.fundDocuments, {
          allDocs: [],
          docNames: [],
        });

        // check if 'all' sub category is present
        if (this.hasMultipleFilters) {
          this.selectedDropdown = 'ALL';
        } else {
          this.selectedDropdown = this.fundDocuments
            ? this.fundDocuments[0]?.id
            : 'FUND-FACTSHEETS';
        }
        // if present run a loop

        this.cdr.detectChanges();
      },
      (error) => {
        this.serviceStatus =
          error.error.message + '. Please try after sometime.';
        this.cdr.detectChanges();
      }
    );
  }

  getDeepLinkingFilterParams = (): string => {
    this.hasDeepLinkedFilter = this.route.snapshot.queryParams?.hasOwnProperty(
      'firstFilter'
    );
    return this.route.snapshot.queryParams?.firstFilter;
  };

  getDocumentsByCategory = (documents, category) => {
    return documents.filter((res) => res?.name === category)[0]?.FirstDropDown;
  };

  documentNames(input, docInfo) {
    forEach(input, (docs) => {
      if (docs.dataRecords?.linkdata.length > 0) {
        forEach(docs.dataRecords?.linkdata, (doc) => {
          docInfo.allDocs.push(doc);
          if (doc.dctermsTitle) {
            docInfo.docNames.push(doc.dctermsTitle);
          }
        });
      } else {
        this.documentNames(docs.secondDropDown, docInfo);
      }
    });
    return docInfo;
  }

  onDropdownChanged($event) {
    if (!this.hasMultipleFilters) {
      this.selectedDropdown = $event?.dropdown?.value;
    } else {
      // primary filter changes
      if (
        $event.elm.ftSelect.nativeElement.className.indexOf(
          'secondaryFilters'
        ) > -1
      ) {
        this.selectedPrimaryDropdown = $event.dropdown?.value;
        this.selectedDropdown = $event?.dropdown.FirstDropDown[0].value;
      } else {
        this.selectedPrimaryDropdown = $event.elm.selected
          ? $event.elm.selected
          : find($event.elm.dropdownList, (input: any) => {
              return input.label === $event.elm.labelText;
            }).value;
        this.selectedDropdown = $event?.dropdown.value;
      }

      this.fundDocuments = this.getDocumentsByCategory(
        this.reportCategories?.PageType,
        this.selectedPrimaryDropdown
      );

      // Re-initiate document names as per selected category, once user change selected category
      this.documentName = this.documentNames(this.fundDocuments, {
        allDocs: [],
        docNames: [],
      });
    }
  }

  onResultsFound(event) {
    this.showDocumentPanel = event;
  }

  getSelectedDeepLinkedFilter($event: any) {
    this.getDeepLinkingFilterParams();

    if (this.hasDeepLinkedFilter) {
      if ($event.hasOwnProperty('primaryFilter')) {
        this.selectedDropdown = $event.secondaryFilter;
        this.fundDocuments = this.getDocumentsByCategory(
          this.reportCategories?.PageType,
          $event.primaryFilter
        );
      } else {
        this.selectedDropdown = $event;
      }
    }
  }
}
