import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { ResponsiveService } from '@services/responsive.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ft-market-insights-blogs',
  templateUrl: './market-insights-blogs.component.html',
  styleUrls: ['./market-insights-blogs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarketInsightsBlogsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  contentType: any;
  isHandheld$: Observable<boolean>;

  constructor(private responsiveService: ResponsiveService) {}

  ngOnInit() {
    this.contentType = this.component?.getParameters().contentType;
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    const data = this.document?.getData();
    return data;
  }
  get BlogLists() {
    const blogDatas = this.content?.mibloglist;
    return blogDatas;
  }
  getTarget(event) {
    if (event === 'New Window') {
      return '_blank';
    } else {
      return 'same';
    }
  }
  get isPreview() {
    return this?.page?.isPreview() || {};
  }
}
