import { Injectable } from '@angular/core';
import { ProductTypeParameter } from '@components/products/services/graphql-fund-data.service';
import { PPSSFundDataService } from '@components/products/services/ppss-fund-data.service';
import {
  DropdownItem,
  FundListingBRConfiguration,
  FundListingComponentType,
  FundListingDataFormat,
  FundListingFilterDropdown,
  FundListingState,
  ProductData,
  FundListingFilterEvent,
  FundListingFundLinkDetails,
  FundId,
  TabName,
  FundListingBRComponentConfiguration,
  FundListingShareClassDataFormat,
} from '@types';
import { Logger } from '@utils/logger';
import { DocumentNode } from 'graphql';
import { Observable, Subject } from 'rxjs';
import FundListingPPSSQuery from '@graphql/fund-listing/fund-listing-ppss.graphql';
import FundListingNAVsQuery from '@graphql/fund-listing/fund-listing-navs.graphql';
import FundListingIDCWQuery from '@graphql/fund-listing/fund-listing-idcw.graphql';
import {
  PerformanceDetail,
  Product,
  ShareClass,
} from '@components/products/models';
import { TranslateService } from '@components/shared/translate/translate.service';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import { SiteConfigService } from '@services/site-config.service';
import { InrSymbolPipe } from '@components/shared/pipes/inr-symbol.pipe';
import merge from 'lodash/merge';
import { Distribution } from '@components/products/models/distribution';
import { hasValue } from '@components/products/utils/mappers/mapper.utils';

const logger = Logger.getLogger('FundListingService');

// ********** Fund listing filter configuration **********
// Create list of filter types for every fund listing component type.
// Filter types has to be a property of Product model.
const FILTER_TYPE_LIST: Record<
  FundListingComponentType,
  (keyof ProductData)[]
> = {
  PPSS: ['assetClass', 'alternativeFor', 'investmentFocus', 'investmentTenure'],
  LatestNavs: ['assetClass', 'fundName'],
  IDCW: ['assetClass', 'fundName'],
  FundCards: [],
};

@Injectable({
  providedIn: 'root',
})
export class FundListingService {
  private state: FundListingState;
  private fundListingState$: Subject<FundListingState>;
  private brConfiguration: FundListingBRConfiguration;
  private products: Product[] = [];
  private productsData: ProductData[] = [];
  private isNAVsDataPresent: boolean;
  private isIDCWDataPresent: boolean;

  constructor(
    private ppssFundDataService: PPSSFundDataService,
    private siteConfigService: SiteConfigService,
    private translateService: TranslateService,
    private inrSymbolPipe: InrSymbolPipe
  ) {
    this.state = {
      fundNames: [],
      filterDropdowns: [],
    };
    this.fundListingState$ = new Subject();
  }

  /**
   * Return fund listing state as observable.
   */
  getFundInformationState$(): Observable<FundListingState> {
    return this.fundListingState$.asObservable();
  }

  /**
   * Fetch the fund listing from PDS and initialize state.
   * @param brConfiguration bloomreach configuration
   * @param isComponentTypeChanged boolean flag if component is switched between nav and idcw.
   */
  populate(
    brConfiguration: FundListingBRConfiguration,
    isComponentTypeChanged?: boolean
  ): void {
    // Save the br configuration to be used elsewhere.
    this.brConfiguration = brConfiguration;

    if (
      isComponentTypeChanged &&
      ((this.brConfiguration.config.componentType ===
        FundListingComponentType.NAV &&
        this.isNAVsDataPresent) ||
        (this.brConfiguration.config.componentType ===
          FundListingComponentType.IDCW &&
          this.isIDCWDataPresent))
    ) {
      this.state = this.fetchFundListingState(brConfiguration, this.products);

      this.state.isLoaded = true;
      this.fundListingState$.next(this.state);
    } else {
      this.ppssFundDataService
        .fetchData(
          this.fetchGraphQLQuery(brConfiguration.config.componentType),
          {
            productType: ProductTypeParameter.MUTUAL_FUNDS,
          }
        )
        .subscribe(
          (fundListing) => {
            logger.debug('Mapped fund listing response: ', fundListing);

            if (fundListing.products.length) {
              // Assign flag value as 'true' to 'isNAVsDataPresent', if NAV data is loaded.
              if (
                this.brConfiguration.config.componentType ===
                FundListingComponentType.NAV
              ) {
                this.isNAVsDataPresent = true;
              }

              // Assign flag value as 'true' to 'isIDCWDataPresent', if IDCW data is laoded.
              if (
                this.brConfiguration.config.componentType ===
                FundListingComponentType.IDCW
              ) {
                this.isIDCWDataPresent = true;
              }
              let filteredProductData = fundListing.products;

              // Filter data if fundList is coming from BR
              if (this.brConfiguration.config?.fundList?.length) {
                filteredProductData = fundListing.products.filter(
                  (data: Product) => {
                    return this.brConfiguration.config.fundList.includes(
                      data?.fundId
                    );
                  }
                );
              }

              this.state = this.fetchFundListingState(
                brConfiguration,
                this.getProductsData(
                  this.brConfiguration.config.componentType,
                  filteredProductData
                )
              );

              this.state.isLoaded = true;
            } else {
              this.state.isLoaded = false;
            }

            this.fundListingState$.next(this.state);
          },
          (error) => {
            logger.error('Error in fetching fund listing response:', error);

            this.fundListingState$.error(error);
          }
        );
    }
  }

  /**
   * Filter the original listing based upon filter received.
   * @param filterEvent selected filter with value or searched text
   */
  filterFundListing(filterEvent: FundListingFilterEvent): void {
    this.state = this.fetchFundListingState(
      this.brConfiguration,
      cloneDeep(
        this.brConfiguration.config.componentType ===
          FundListingComponentType.PPSS // Pass flattened structure for PPSS, otherwise for NAVs/IDCW.
          ? this.productsData
          : this.products
      ), // 'cloneDeep' to avoid any reference update later.
      true,
      filterEvent
    );

    this.state.isLoaded = true;
    this.fundListingState$.next(this.state);
  }

  /**
   * Fetch appropriate state and its mapped values as per component type rendered.
   * @param brConfiguration bloomreach configuration
   * @param listingData flattened or not-flattened fund listing pds feed
   * @param isFilterApplied flag for creating the state when filter is applied
   * @param filterEvent filter details when filter is applied
   */
  private fetchFundListingState(
    brConfiguration: FundListingBRConfiguration,
    listingData: ProductData[] | Product[],
    isFilterApplied?: boolean,
    filterEvent?: FundListingFilterEvent
  ): FundListingState {
    const fundNames: string[] = isFilterApplied
      ? this.state.fundNames
      : this.getDistinctFundNames(listingData);

    const filterDropdowns: FundListingFilterDropdown[] = this.getFilterDropdowns(
      brConfiguration.config,
      listingData,
      isFilterApplied,
      filterEvent
    );

    switch (brConfiguration.config.componentType) {
      case FundListingComponentType.PPSS:
        return {
          fundNames,
          filterDropdowns,
          ppssState: {
            ppssPageTitle: this.getPPSSPageTitle(
              brConfiguration.config.assetCategory,
              filterEvent
            ),
            ppssData: isFilterApplied
              ? this.formatPPSSListingData(
                  this.getFilteredListing(
                    brConfiguration.config.assetCategory,
                    listingData,
                    filterEvent
                  ) as ProductData[],
                  brConfiguration.funds
                )
              : this.formatPPSSListingData(
                  this.getFilteredListing(
                    brConfiguration.config.assetCategory,
                    listingData
                  ) as ProductData[],
                  brConfiguration.funds
                ),
          },
        };

      case FundListingComponentType.NAV:
        return {
          fundNames,
          filterDropdowns,
          navState: {
            navData: isFilterApplied
              ? this.formatNAVListingData(
                  this.getFilteredListing(
                    brConfiguration.config.assetCategory,
                    listingData,
                    filterEvent
                  ) as Product[],
                  brConfiguration.funds
                )
              : this.formatNAVListingData(
                  this.getFilteredListing(
                    brConfiguration.config.assetCategory,
                    listingData
                  ) as Product[],
                  brConfiguration.funds
                ),
          },
        };

      case FundListingComponentType.IDCW:
        return {
          fundNames,
          filterDropdowns,
          idcwState: {
            idcwData: isFilterApplied
              ? this.formatIDCWListingData(
                  this.getFilteredListing(
                    brConfiguration.config.assetCategory,
                    listingData,
                    filterEvent
                  ) as Product[],
                  brConfiguration.funds
                )
              : this.formatIDCWListingData(
                  this.getFilteredListing(
                    brConfiguration.config.assetCategory,
                    listingData
                  ) as Product[],
                  brConfiguration.funds
                ),
          },
        };
    }
  }

  /**
   * Get dynamic filter dropdown list as per component type rendered.
   * @param brComponentConfiguration component configuration from BR
   * @param listingData flattened or not-flattened fund listing pds feed
   * @param isFilterApplied flag for creating the state when filter is applied
   * @param filterEvent filter details when filter is applied
   */
  private getFilterDropdowns(
    brComponentConfiguration: FundListingBRComponentConfiguration,
    listingData: ProductData[] | Product[],
    isFilterApplied?: boolean,
    filterEvent?: FundListingFilterEvent
  ): FundListingFilterDropdown[] {
    const ppssFilterDropdown: FundListingFilterDropdown[] = [];

    // Create filter item 'All' to be used in all filter types.
    const filterItemAll: DropdownItem = {
      label: this.translateService.instant('products.ppss-filter-type-all'),
      value: 'all',
      selected: true, // By default, make it as selected. Later, decide for any filter selection applied.
    };

    FILTER_TYPE_LIST[brComponentConfiguration.componentType].forEach(
      (filterType) => {
        ppssFilterDropdown.push({
          filterLabel: this.translateService.instant(
            `products.ppss-filter-type-${filterType}`
          ),
          filterName: filterType,
          filterItems: [
            this.checkAndApplySelectedToFilterOption(
              brComponentConfiguration.assetCategory,
              filterType,
              filterItemAll,
              isFilterApplied,
              filterEvent
            ),
            ...this.sortFilterItems(
              uniqBy(
                // Intentionally kept as 'any' to avoid conflict, but is typed in param anyways.
                (listingData as any[]).map((product) => product[filterType]),
                (iteratee: string) => {
                  return iteratee.toLowerCase(); // Return unique filter items keeping case insensitive.
                }
              ) as string[],
              filterType
            ).map((filterValue) =>
              this.checkAndApplySelectedToFilterOption(
                brComponentConfiguration.assetCategory,
                filterType,
                {
                  label: filterValue,
                  value: filterValue,
                },
                isFilterApplied,
                filterEvent
              )
            ),
          ],
        });
      }
    );

    return ppssFilterDropdown;
  }

  /**
   * Called on default page load as well as when filter is applied, either by dropdown or search.
   * If dropdown, for a matched filter type and its option - make 'selected' as true otherwise false.
   * Else, make 'selected' as true for only 'All' option.
   * @param brAssetCategory assetCategory received from BR
   * @param filterType must be one of the product type property
   * @param dropdownItem dropdown item iterated for current filterType
   * @param isFilterApplied flag when filter is applied
   * @param filterEvent filter details when filter is applied
   */
  private checkAndApplySelectedToFilterOption(
    brAssetCategory: string,
    filterType: keyof Product | keyof ProductData,
    dropdownItem: DropdownItem,
    isFilterApplied?: boolean,
    filterEvent?: FundListingFilterEvent
  ): DropdownItem {
    // First, check if its not a initial load and filter is applied.
    if (isFilterApplied) {
      // Secondly, check if filter is a dropdown selection
      if (filterEvent.selectedDropdown) {
        // If the selected filter matches the iterable filter type,
        // Further check if selected filter option also matches the iterable dropdownItem,
        // Mark its selected value as true else false.
        if (filterEvent.selectedDropdown.filterName === filterType) {
          if (filterEvent.selectedDropdown.filterValue === dropdownItem.value) {
            dropdownItem.selected = true;
          } else {
            dropdownItem.selected = false;
          }
        }
        // Otherwise, mark selected value as true if dropdownItem matches as 'All'.
        else {
          if (dropdownItem.value === 'all') {
            dropdownItem.selected = true;
          } else {
            dropdownItem.selected = false;
          }
        }
      }
      // Else, it is a search text.
      else {
        // Make the 'all' option as selected for all filter types.
        if (dropdownItem.value === 'all') {
          dropdownItem.selected = true;
        } else {
          dropdownItem.selected = false;
        }
      }
    }
    // Otherwise, its a initial page load.
    else {
      // For 'assetCategory', selected option will be same as that of 'brAssetCategory' received.
      if (filterType === 'assetClass') {
        if (
          dropdownItem.value.toLowerCase() === brAssetCategory.toLowerCase()
        ) {
          dropdownItem.selected = true;
        } else {
          dropdownItem.selected = false;
        }
      }
      // For other filter types, by default 'all' option will be selected.
      else {
        if (dropdownItem.value === 'all') {
          dropdownItem.selected = true;
        } else {
          dropdownItem.selected = false;
        }
      }
    }

    return cloneDeep(dropdownItem); // Used 'cloneDeep' here to avoid updating the reference for 'all' as selected option.
  }

  /**
   * Sort the list as per the criteria specified for the filter type.
   * @param list list to be sorted
   * @param filterType must be one of the product type property
   */
  private sortFilterItems(
    list: string[],
    filterType: keyof ProductData
  ): string[] {
    switch (filterType) {
      case 'investmentTenure': {
        return list.sort((current, next) => {
          // Match the filter item, firstly by checking its text excluding number (tenure).
          // For example, if case,  3 years and above & 2 years and above => years and above & years and above.
          // Need to sort it year wise, note that text is same except number.
          const matchResult: number = current
            .trim()
            .replace(new RegExp('^[0-9]'), '')
            .trim()
            .toLowerCase()
            .localeCompare(
              next.trim().replace(new RegExp('^[0-9]'), '').trim().toLowerCase()
            );

          // If text remains the same then compare full text including number (tenure).
          if (matchResult === 0) {
            return current.toLowerCase().localeCompare(next.toLowerCase());
          }

          return matchResult;
        });
      }
      default:
        return list.sort((current, next) =>
          current.toLocaleLowerCase().localeCompare(next.toLocaleLowerCase())
        );
    }
  }

  /**
   * Construct distinct fund name list.
   * This will be used for fund name search suggestion.
   * @param listingData flattened or not-flattened fund listing pds feed
   */
  private getDistinctFundNames(
    listingData: ProductData[] | Product[]
  ): string[] {
    return uniq(
      // Intentionally kept as 'any' to avoid conflict, but is typed in param anyways.
      (listingData as any[]).map((productData) => productData.fundName)
    ) as string[];
  }

  /**
   * Get PPSS page title as per default load and when filter happens.
   * @param assetCategory default asset category from BR
   * @param filterEvent filter details when filter is applied
   */
  private getPPSSPageTitle(
    assetCategory: string,
    filterEvent: FundListingFilterEvent
  ): string {
    const assetClassOptionAllLabel: string = this.translateService.instant(
      'products.ppss-page-title-all' // Since, in prod we have title as "All Funds".
    );

    // When filter is applied, check if it is a dropdown change.
    // For any dropdown change apart from 'assetCategory' with option as NOT 'All', page title would be 'selected option'.
    // Otherwise, page title will always be 'assetClassOptionAllLabel'. This includes search input as well.
    if (filterEvent) {
      if (
        filterEvent.selectedDropdown &&
        filterEvent.selectedDropdown?.filterName === 'assetClass' &&
        filterEvent.selectedDropdown.filterValue !== 'all'
      ) {
        return filterEvent.selectedDropdown.filterValue;
      }

      return assetClassOptionAllLabel;
    } else {
      // Default page load as per asset category from BR.
      return assetCategory.toLowerCase() === 'all' // Intentionally, asserted with lower case to avoid any casing clash from BR.
        ? assetClassOptionAllLabel
        : assetCategory;
    }
  }

  /**
   * Filter the originally 'saved' fund listing based upon filter applied.
   * This can be either dropdown or search.
   * @param brAssetCategory assetCategory received from BR
   * @param listingData original flattened or not-flattened pds fund listing
   * @param filterEvent filter details when filter is applied
   */
  private getFilteredListing(
    brAssetCategory: string,
    listingData: ProductData[] | Product[],
    filterEvent?: FundListingFilterEvent
  ): ProductData[] {
    // If filter has been applied and its not a initial load.
    if (filterEvent) {
      if (filterEvent.selectedDropdown) {
        // When dropdown filter is applied, check if, option selected isn't 'All'.
        // If yes, return full listing.
        // Else, filter based upon the option selected.
        return filterEvent.selectedDropdown.filterValue === 'all'
          ? listingData
          : (listingData as any).filter(
              // Intentionally kept as 'any' to avoid conflict, but is typed in param anyways.
              (productData) =>
                (productData[
                  filterEvent.selectedDropdown.filterName
                ] as string).toLowerCase() ===
                filterEvent.selectedDropdown.filterValue.toLowerCase()
            );
      } else {
        // When search input filter is applied, check if search text is not an empty string.
        // If yes, then return full listing.
        // Else, for now, just filter the listing upon "fund name" matching the "search text" as a 'substring'.
        return filterEvent.searchedText === ''
          ? listingData
          : (listingData as any).filter((productData) =>
              // Intentionally kept as 'any' to avoid conflict, but is typed in param anyways.
              productData.fundName
                .toLowerCase()
                .includes(filterEvent.searchedText.toLowerCase())
            );
      }
    } else {
      // Otherwise, its the initial load, filter based upon 'assetCategory' configuration received from BR.
      return brAssetCategory.toLowerCase() === 'all' // Intentionally, asserted with lower case to avoid any casing clash from BR.
        ? listingData
        : (listingData as any).filter(
            // Intentionally kept as 'any' to avoid conflict, but is typed in param anyways.
            (productData) => productData.assetClass === brAssetCategory
          );
    }
  }

  /**
   * Format the flattened product data into ppss listing format.
   * @param productsData flattened product response
   * @param brFundData all fund info from bloomreach
   */
  private formatPPSSListingData(
    productsData: ProductData[],
    brFundData: object
  ): FundListingDataFormat[] {
    const listingPPSSData: FundListingDataFormat[] = [];

    productsData
      .filter((productData) => productData.shareClass.primaryShareClass) // As only primary share class is having performance data.
      .forEach((productData) => {
        // For India site, we display monthly performance data with only that record which has calc name as 'NET'.
        const perfData: PerformanceDetail = productData.shareClass?.performance?.monthEnd?.find(
          (perfRecord) => perfRecord.calcTypeStd.toUpperCase() === 'NET'
        );

        // Merge PDS and BR fund data into one data format and push it to the array of the same.
        listingPPSSData.push({
          fundId: productData.fundId,
          fundName: productData.fundName,
          fundLink: this.getFundLinkDetails(
            productData.fundId,
            productData.fundName,
            perfData ? true : false
          ),
          assetClass: productData.assetClass,
          shareClassData: {
            isPrimaryShareClass: productData.shareClass.primaryShareClass,
            performanceData: perfData
              ? {
                  asOfDate: perfData.performanceAsOfDate,
                  averageAnnualReturnFor1Year: perfData.avgAnnual1YearReturn,
                  averageAnnualReturnFor3Year: perfData.avgAnnual3YearReturn,
                  averageAnnualReturnFor5Year: perfData.avgAnnual5YearReturn,
                  averageAnnualReturnFor10Year: perfData.avgAnnual10YearReturn,
                  averageAnnualReturnSinceInception:
                    perfData.avgAnnualSinceInceptionReturn,
                }
              : null,
          },
          enableInvestNow: brFundData
            ? brFundData[productData.fundId]?.enableInvestNow
            : false,
          fundDescription: brFundData
            ? brFundData[productData.fundId]?.fundDescription
            : '',
          suitableFor: brFundData
            ? brFundData[productData.fundId]?.suitableFor
            : '',
          ppssPeriodDropdownItems: this.createPPSSPeriodDropdownItems(perfData),
        });
      });
    return listingPPSSData;
  }

  /**
   * Format the products response into nav listing format.
   * @param products products response from pds feed
   * @param brFundData all fund info from bloomreach
   */
  private formatNAVListingData(
    products: Product[],
    brFundData: object
  ): FundListingDataFormat[] {
    const listingNAVData: FundListingDataFormat[] = [];

    // Merge PDS and BR fund data into one data format and push it to the array of the same.
    products.forEach((product) => {
      const shareClassesChunksData: FundListingShareClassDataFormat[][] = [];
      const shareClassesLength: number = product.shareClasses.length;
      let chunksCount = 0;

      // Calculate chunks count.
      // If shareClasses less than or equal to four, value will be one.
      // Else, if, shareClasses in multiple of four, value will be quotient.
      //       else, value will be quotient + one.
      if (shareClassesLength <= 4) {
        chunksCount = 1;
      } else {
        if (shareClassesLength % 4 === 0) {
          chunksCount = shareClassesLength / 4;
        } else {
          chunksCount = Math.floor(shareClassesLength / 4) + 1;
        }
      }

      // Iterate through chunks count and split the share classes in chunks of maximum four (Ref. Prod - Nav listing).
      new Array(chunksCount).fill(0).forEach((value, count) => {
        const shareClassesChunkData: FundListingShareClassDataFormat[] = [];
        const shareClassesChunk: ShareClass[] = product.shareClasses.slice(
          count * 4,
          (count + 1) * 4
        );

        shareClassesChunk.forEach((shareClass) => {
          shareClassesChunkData.push({
            shareClassName: shareClass.shareClassName,
            navData: {
              navDate: shareClass.nav?.navAsOfDate,
              navValue: shareClass.nav?.navValue,
            },
          });
        });

        shareClassesChunksData.push(shareClassesChunkData);
      });

      // Finally, push the share classes chunks and other data in nav listing format.
      listingNAVData.push({
        fundId: product.fundId,
        fundName: product.fundName,
        fundLink: this.getFundLinkDetails(product.fundId, product.fundName),
        shareClassesChunksData,
        enableInvestNow: brFundData
          ? brFundData[product.fundId]?.enableInvestNow
          : false,
      });
    });

    return listingNAVData;
  }

  /**
   * Format the products response into idcw listing format.
   * @param products products response from pds feed
   * @param brFundData all fund info from bloomreach
   */
  private formatIDCWListingData(
    products: Product[],
    brFundData: object
  ): FundListingDataFormat[] {
    const listingIDCWData: FundListingDataFormat[] = [];

    // Merge PDS and BR fund data into one data format and push it to the array of the same.
    products.forEach((product) => {
      listingIDCWData.push({
        fundId: product.fundId,
        fundName: product.fundName,
        fundLink: this.getFundLinkDetails(product.fundId, product.fundName),
        shareClassesData: product.shareClasses
          .filter((shareClass) => shareClass.distribution.length)
          .map((shareClass) => {
            // Fetched first record, since, only one record is to be displayed per distribution object for eligible share classes.
            const distributionData: Distribution = shareClass.distribution[0];

            return {
              shareClassName: shareClass.shareClassName,
              distributionData: {
                recordDate: distributionData?.incomeDistributionRecordDate,
                recordNav: this.inrSymbolPipe.transform(
                  distributionData?.recordDateNavVal,
                  'pre'
                ),
                distributionAmount: distributionData?.incomeDistributionAmount,
                exDividendDate:
                  distributionData?.incomeDistributionExDividendDate,
                exDividendNav: this.inrSymbolPipe.transform(
                  distributionData?.exDividendNavValue,
                  'pre'
                ),
              },
            };
          }),
        enableInvestNow: brFundData
          ? brFundData[product.fundId]?.enableInvestNow
          : false,
      });
    });

    return listingIDCWData;
  }

  /**
   * Return fund link object with dedicated links for required fund tabs.
   * @param fundId fundId from productData feed
   * @param fundName fundName from productData feed
   * @param isPerformanceDataPresent boolean flag if performance data is present for particular fundId
   */
  private getFundLinkDetails(
    fundId: FundId,
    fundName: string,
    isPerformanceDataPresent?: boolean
  ): FundListingFundLinkDetails {
    const fundLinkDetails: FundListingFundLinkDetails = {};

    fundLinkDetails.overview = this.siteConfigService.getFundLink(
      fundId,
      TabName.OVERVIEW,
      fundName
    );

    // Assign fund link for performance tab when performance data is present, for PPSS page only.
    if (
      this.brConfiguration.config.componentType ===
        FundListingComponentType.PPSS &&
      isPerformanceDataPresent
    ) {
      fundLinkDetails.performance = this.siteConfigService.getFundLink(
        fundId,
        TabName.PERFORMANCE,
        fundName
      );
    }

    // Assign fund link for historical-navs tab, for NAVs page only.
    if (
      this.brConfiguration.config.componentType === FundListingComponentType.NAV
    ) {
      fundLinkDetails.historicalNavs = this.siteConfigService.getFundLink(
        fundId,
        TabName.HISTORICAL_NAVS,
        fundName
      );
    }

    // Assign fund link for idcw history tab, for IDCW page only.
    if (
      this.brConfiguration.config.componentType ===
      FundListingComponentType.IDCW
    ) {
      fundLinkDetails.idcwHistory = this.siteConfigService.getFundLink(
        fundId,
        TabName.IDCW_HISTORY,
        fundName
      );
    }

    return fundLinkDetails;
  }

  /**
   * Flatten the listing against their sharedClasses when component type is PPSS.
   * Else, return the same listing.
   * Also, sort them up alphabetically with respect to fund name.
   * @param componentType type of bloomreach component getting rendered, currently - PPSS/NAVs/IDCW.
   * @param products ppss data from feed
   */
  private getProductsData(
    componentType: FundListingComponentType,
    products: Product[]
  ): ProductData[] | Product[] {
    if (componentType === FundListingComponentType.PPSS) {
      // Save the original "product data" listing to be used elsewhere.
      this.productsData = products
        .map((product) => {
          return product.shareClasses.map((shareclass) => {
            const { shareClasses, ...productRef } = product;
            const productData: ProductData = productRef;
            productData.shareClass = shareclass;
            return productData;
          });
        })
        .flat()
        .sort((a, b) => a.fundName.localeCompare(b.fundName)); // Sort the listing alphabetically, by default.

      return this.productsData;
    } else {
      // Merge & save the original 'products' listing alphabetically to be used elsewhere.
      // Merge will be effective when there is a component type switch and a new PDS call has happened.
      // So, now our 'products' data can have merge of both the responses to be used accordingly.

      if (componentType === FundListingComponentType.NAV) {
        this.products = products.sort((a, b) =>
          a.fundName.localeCompare(b.fundName)
        );
      } else {
        products
          .sort((a, b) => a.fundName.localeCompare(b.fundName))
          .forEach((product, index) => {
            this.products[index] = merge(this.products[index], product);
          });
      }

      return this.products;
    }
  }

  /**
   * Create dropdown item list as per performance periods.
   * Intentionally kept 'value' matched to "prop value" in 'FundListingPPSSShareClassPerformanceData'.
   */
  createPPSSPeriodDropdownItems(perfData: PerformanceDetail): DropdownItem[] {
    return [
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-1-year'
        ),
        value: 'averageAnnualReturnFor1Year',
        disabled: perfData
          ? !hasValue(perfData, 'avgAnnual1YearReturn')
          : false,
      },
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-3-year'
        ),
        value: 'averageAnnualReturnFor3Year',
        disabled: perfData
          ? !hasValue(perfData, 'avgAnnual3YearReturn')
          : false,
      },
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-5-year'
        ),
        value: 'averageAnnualReturnFor5Year',
        disabled: perfData
          ? !hasValue(perfData, 'avgAnnual5YearReturn')
          : false,
      },
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-10-year'
        ),
        value: 'averageAnnualReturnFor10Year',
        disabled: perfData
          ? !hasValue(perfData, 'avgAnnual10YearReturn')
          : false,
      },
      {
        label: this.translateService.instant(
          'products.ppss-perf-period-avg-annual-since-inception'
        ),
        value: 'averageAnnualReturnSinceInception',
        selected: true,
      },
    ];
  }

  /**
   * Fetch the appropriate query as per component type rendered.
   * @param componentType type of component against mentioned enum values
   */
  private fetchGraphQLQuery(
    componentType: FundListingComponentType
  ): DocumentNode {
    switch (componentType) {
      case FundListingComponentType.PPSS:
        return FundListingPPSSQuery;
      case FundListingComponentType.NAV:
        return FundListingNAVsQuery;
      case FundListingComponentType.IDCW:
        return FundListingIDCWQuery;
    }
  }
}
