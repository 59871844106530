<div class="fti-container mt-3">
  <div class="col-12 content-container">
    <div class="row">
      <div class="col-lg-12">
        <ng-container brComponent="title-content"></ng-container>
      </div>
      <div [ngClass]="{ 'col-lg-5': hasLeft }">
        <ng-container brComponent="left-content"></ng-container>
      </div>
      <div [ngClass]="{ 'col-lg-7': hasLeft, 'col-lg-12': !hasLeft }">
        <ng-container brComponent="right-content"></ng-container>
      </div>
      <div class="col-12">
        <ng-container brComponent="lower-content"></ng-container>
      </div>
    </div>
  </div>
</div>
