import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import kebabCase from 'lodash/kebabCase';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { IdentifierKey } from '@models';
import {
  AssetClass,
  CommonConfig,
  FundId,
  FundIdentifier,
  FundSectionLink,
  FundShareClassId,
  GlobalConfig,
  LiteratureConfig,
  PerformanceType,
  PortfolioComponentId,
  ProductConfig,
  ProductTabSet,
  ProductType,
  SegmentId,
  ShareClassCode,
  StatisticId,
  StatisticSuppressionSet,
  SuppressionSet,
  TabName,
  TabSet,
  WebProductTaxonomy,
} from '@types';
import { ApiUrls, AppStateService } from './app-state.service';
import { Logger } from '@utils/logger';
import { SegmentService } from './segment.service';
import { GlobalConfigService } from './global-config-service';
import {
  convertCsvFields,
  mergeConfig,
  splitField,
} from '@utils/data/object-utils';
import {
  getCached1YearAgoStd,
  getCached5YearAgoStd,
  getCached6MonthsAgoStd,
} from '@utils/il8n/moment-il8n';
const logger = Logger.getLogger('SiteConfigService');
const BASE_FUND_PAGE_URL = '/fund-details/';

const FT_PAGE_TYPE = {
  fundOverview: 'fund-overview',
  fundPortfolio: 'fund-portfolio',
  fundPerformance: 'fund-performance',
  fundHistoricalnavs: 'fund-historicalnavs',
  recentDivHistory: 'recent-div-history',
  fundResources: 'fund-resources',
};

const FT_DECODE = {
  MQ: 'MQ',
  QM: 'QM',
  Q: 'Q',
  M: 'M',
};

export enum BenchmarksToShow {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  ALL = Number.MAX_VALUE,
}

@Injectable({
  providedIn: 'root',
})
export class SiteConfigService {
  isPopulated$ = new BehaviorSubject<boolean>(false);
  /**
   * For now, we're just exposing config as public properties
   * TODO: replace these with methods for specific bits of state
   */

  apiUrls?: ApiUrls;
  segmentId: SegmentId;
  common: CommonConfig;
  product: ProductConfig = {};
  literature: LiteratureConfig = {};
  constructor(
    private segmentService: SegmentService,
    private globalConfigService: GlobalConfigService,
    protected appStateService: AppStateService // private translateService: TranslateService
  ) {
    combineLatest([
      this.globalConfigService.getSubject$(),
      this.segmentService.getCurrentSegmentId$(),
    ]).subscribe(this.mapConfigData);
  }

  private mapConfigData = ([globalConfig, segmentId]: [
    GlobalConfig,
    SegmentId
  ]): void => {
    logger.debug('args', globalConfig, segmentId);
    if (!segmentId) {
      segmentId = this.segmentService.getDefaultSegmentId();
    }
    const siteConfig = globalConfig.siteConfiguration;
    if (!siteConfig) {
      return; // isPopulated will remain false unless previously populated successfully
    }

    this.segmentId = segmentId;
    this.apiUrls = this.appStateService.getApiUrls();
    this.common = siteConfig.site;

    if (siteConfig?.funds) {
      const genericProductConfig = cloneDeep(siteConfig.funds.all) || {};
      const segmentProductConfig = cloneDeep(siteConfig.funds[segmentId]) || {};
      this.product = convertCsvFields(
        mergeConfig(genericProductConfig, segmentProductConfig)
      );
    }

    if (siteConfig?.literature) {
      const genericLiteratureConfig =
        cloneDeep(siteConfig.literature?.all) || {};
      const segmentLiteratureConfig =
        cloneDeep(siteConfig.literature[segmentId]) || {};
      const literatureUrls = {
        litDetailUrl: siteConfig.literature.litdetail || '',
        litOrderCheckoutUrl: siteConfig.literature.litordercheckout || '',
        litOrderHistoryDetailUrl:
          siteConfig.literature.litorderhistorydetail || '',
        orderConfirm: siteConfig.literature.orderconfirm || '',
        orderHistoryList: siteConfig.literature.orderhistorylist || '',
        orderSuccess: siteConfig.literature.ordersuccess || '',
        orderCheckoutAddress: siteConfig.literature.ordercheckoutaddress || '',
        litListing: siteConfig.literature.litlisting || '',
        myLiteratureLandingUrl: siteConfig.literature.myliteraturelanding || '',
      };
      this.literature = convertCsvFields(
        mergeConfig(genericLiteratureConfig, {
          ...segmentLiteratureConfig,
          ...literatureUrls,
        })
      );
    }
    logger.debug(
      'mapConfigData',
      this.segmentId,
      this.apiUrls,
      this.product,
      this.literature
    );
    this.isPopulated$.next(true);
  };

  /*******************************************************************
   * helper methods
   */

  public getDefaultIdentifierKey(
    webProductTaxonomy: WebProductTaxonomy
  ): IdentifierKey {
    if (!this.product.general.keyIdentifier) {
      return null;
    }
    return this.product.general.keyIdentifier[webProductTaxonomy] || null;
  }

  public isSegregatedFund(fundId: FundId): boolean {
    return this.getFundTabSet(fundId)?.name === 'segregated';
  }
  public isNfoFund(fundId: FundId): boolean {
    return this.getFundTabSet(fundId)?.name === 'nfo';
  }

  public getFundTabSet(fundId: FundId): TabSet {
    // 2. Get tab config for ProductType
    const productSet: ProductTabSet = this.product.tabs;
    if (!productSet) {
      logger.info('No tabsets configured');
      return null;
    }

    // 3. Calculate set name, by looping assignments
    let setName = 'default';
    if (productSet.assignments) {
      setName = Object.entries(productSet.assignments) // turn assignments object into an array
        .reduce((name, [key, funds]) => {
          return funds.includes(fundId) ? key : name;
        }, setName);
    }

    // 4. Get correct TabSet
    const tabSet: TabSet = productSet.sets.find(
      (set: TabSet): boolean => setName === set.name
    );
    if (!tabSet) {
      logger.info(`No tabset configured for ${this.segmentId} ${setName}`);
      return null;
    }

    return tabSet;
  }

  /**
   * returns an absolute link for a fund's page.
   * The FundId is required!
   * Other params will be added to the url if they are passed
   */
  public getFundLink(
    fundId: FundId,
    pageType = TabName.OVERVIEW,
    fundName?: string,
    identifier?: FundIdentifier
  ): string {
    // 1. Get TabSet
    const tabSet: TabSet = this.getFundTabSet(fundId);
    if (!tabSet) {
      return null;
    }
    return this.getFundLinkFromTabSet(
      tabSet,
      fundId,
      pageType,
      fundName,
      identifier
    );
  }

  public getFundLinkFromTabSet(
    tabSet: TabSet,
    fundId: FundId,
    pageType = TabName.OVERVIEW,
    fundName?: string,
    identifier?: FundIdentifier
  ): string {
    // 1. Get base link from TabSet
    let link: string = BASE_FUND_PAGE_URL;

    // 2. add page type
    switch (pageType) {
      case TabName.OVERVIEW:
        link += FT_PAGE_TYPE.fundOverview;
        break;
      case TabName.PORTFOLIO:
        link += FT_PAGE_TYPE.fundPortfolio;
        break;
      case TabName.PERFORMANCE:
        link += FT_PAGE_TYPE.fundPerformance;
        break;
      case TabName.HISTORICAL_NAVS:
        link += FT_PAGE_TYPE.fundHistoricalnavs;
        break;
      case TabName.IDCW_HISTORY:
        link += FT_PAGE_TYPE.recentDivHistory;
        break;
      case TabName.DOCUMENTS:
        link += FT_PAGE_TYPE.fundResources;
        break;
      default:
        logger.error('unknown tab: ', pageType);
    }

    // 3. add spaBaseUrl prefix
    if (this.apiUrls && this.apiUrls.spaBaseUrl) {
      link = this.apiUrls.spaBaseUrl + link;
    }

    // 4. Add FundId
    link = `${link}/${fundId}`;

    // 6. Add fundName in kebab case for SEO
    if (fundName) {
      link = `${link}/${kebabCase(fundName)}`;
    }

    // 7. Optionally add default Identifier for SEO
    // TODO: should this be kebabcase too?
    if (identifier) {
      link = `${link}/${identifier}`;
    }
    return link;
  }

  public isActiveFund(fundId: FundId): boolean {
    return this.product.general.activeList.includes(fundId);
  }

  // TODO: define productType type
  public getFundContentCategories(
    productType: string,
    location: string
  ): string[] {
    return this.product.overview[productType]?.fundContent?.find(
      (cats) => cats.location === location
    )?.categories;
  }

  public getRiskFactorContentCategory() {
    return this.product.overview.riskFactorContentCategory;
  }

  public mustHidePerformanceData(performanceDateStd: string): boolean {
    let hidePerformanceData = false;
    const sixMonthsAgoStd = getCached6MonthsAgoStd();
    const oneYearAgoStd = getCached1YearAgoStd();

    if (this.product?.general.showMDashForUnder1Year) {
      hidePerformanceData = performanceDateStd > oneYearAgoStd;
    } else if (this.product?.general.showMDashForUnder6Months) {
      hidePerformanceData = performanceDateStd > sixMonthsAgoStd;
    }
    return hidePerformanceData;
  }

  public hidePerformanceCurrency(
    webProductTaxonomy: WebProductTaxonomy
  ): boolean {
    return this.product.performance.hideCurrency
      ? this.product.performance.hideCurrency.includes(webProductTaxonomy)
      : false;
  }

  public getMaxNumberOfBenchmarksToShow(fundId: FundId): number {
    if (this.product.general.noBenchmarkList.includes(fundId)) {
      return BenchmarksToShow.ZERO;
    }
    if (this.product.general.twoBenchmarkList.includes(fundId)) {
      return BenchmarksToShow.TWO;
    }
    if (this.product.general.allBenchmarkList.includes(fundId)) {
      return BenchmarksToShow.ALL;
    }
    return BenchmarksToShow.ONE;
  }

  public getBenchmarkOrder(webProductTaxonomy: WebProductTaxonomy): string[] {
    if (
      this.product.performance.benchmarkOrder &&
      webProductTaxonomy in this.product.performance.benchmarkOrder &&
      this.product.performance.benchmarkOrder[webProductTaxonomy].length
    ) {
      return this.product.performance.benchmarkOrder[webProductTaxonomy];
    }
    return ['INST1', 'INST2', 'INST3', 'INST4', 'INST5'];
  }

  public getSuppressedFundInfoElements(
    webProductTaxonomy: WebProductTaxonomy
  ): string[] {
    if (
      this.product.overview.fundInfoSuppressionList &&
      webProductTaxonomy in this.product.overview.fundInfoSuppressionList
    ) {
      return this.product.overview.fundInfoSuppressionList[webProductTaxonomy];
    }
    return [];
  }

  public getSuppressedBenchmarks(
    webProductTaxonomy: WebProductTaxonomy
  ): string[] {
    if (
      this.product.performance.suppressBenchmarkData &&
      webProductTaxonomy in this.product.performance.suppressBenchmarkData
    ) {
      return this.product.performance.suppressBenchmarkData[webProductTaxonomy];
    }
    return [];
  }

  public hideAdditionalShareClassLinks(
    webProductTaxonomy: WebProductTaxonomy
  ): boolean {
    return this.product.performance.hideAddShLinkList
      ? this.product.performance.hideAddShLinkList.includes(webProductTaxonomy)
      : false;
  }

  public showRatings(productType: ProductType): boolean {
    if (
      this.product.ppss.showRatings &&
      productType in this.product.ppss.showRatings
    ) {
      return this.product.ppss.showRatings[productType];
    }
    return false;
  }

  public showOverviewRatings(
    webProductTaxonomy: WebProductTaxonomy,
    type: string
  ): boolean {
    if (
      this.product.overview.ratings &&
      webProductTaxonomy in this.product.overview.ratings
    ) {
      return this.product.overview.ratings[webProductTaxonomy].includes(type);
    }
    return false;
  }

  public showYields(productType: ProductType): boolean {
    if (
      this.product.ppss.showYields &&
      productType in this.product.ppss.showYields
    ) {
      return this.product.ppss.showYields[productType];
    }
    return false;
  }

  public hideNavChange(productType: ProductType): boolean {
    if (
      this.product.ppss.hideNavChange &&
      productType in this.product.ppss.hideNavChange
    ) {
      return this.product.ppss.hideNavChange[productType];
    }
    return false;
  }

  public hideFactsheet(productType: ProductType): boolean {
    if (
      this.product.ppss.hideFactsheet &&
      productType in this.product.ppss.hideFactsheet
    ) {
      return this.product.ppss.hideFactsheet[productType];
    }
    return false;
  }

  public showIdentifiers(productType: ProductType): boolean {
    if (
      this.product.ppss.showIdentifiers &&
      productType in this.product.ppss.showIdentifiers
    ) {
      return this.product.ppss.showIdentifiers[productType];
    }
    return false;
  }

  public getFundIdentifiers(productType: ProductType): string[] {
    if (
      this.product.ppss.fundIdentifiers &&
      productType in this.product.ppss.fundIdentifiers
    ) {
      return this.product.ppss.fundIdentifiers[productType];
    }
    return [];
  }

  public getPeriodEnds(
    productType: ProductType,
    performanceType: PerformanceType
  ): string[] {
    if (
      this.product.performance.dataRefreshRate &&
      productType in this.product.performance.dataRefreshRate
    ) {
      const dataRefreshRate: Record<PerformanceType, string> = this.product
        .performance.dataRefreshRate[productType];
      if (performanceType in dataRefreshRate) {
        return this.decodePeriodEnds(dataRefreshRate[performanceType]);
      } else {
        return [];
      }
    }
    return [];
  }

  /**
   * Return the performance period code as their respective name array.
   * @param peCode period code
   */
  public decodePeriodEnds(peCode: string): string[] {
    switch (peCode) {
      case FT_DECODE.MQ:
        return ['MONTHLY', 'QUARTERLY'];
      case FT_DECODE.QM:
        return ['QUARTERLY', 'MONTHLY'];
      case FT_DECODE.Q:
        return ['QUARTERLY'];
      case FT_DECODE.M:
        return ['MONTHLY'];
      default:
        return [];
    }
  }

  public getIsPopulated$(): Observable<boolean> {
    return this.isPopulated$.asObservable();
  }

  public getPdsCountry(): string {
    return this.product.site?.country;
  }

  public getPdsLanguage(): string {
    return this.product.site?.language;
  }

  /*******************************************************************
   * Portfolio Config helpers
   */

  public isPortfolioBenchmarkSuppressed(fundId: FundId): boolean {
    const isSuppressed: boolean = this.product.portfolio?.benchmarkSuppression.includes(
      fundId
    );
    if (isSuppressed) {
      logger.debug(`Benchmark suppressed for fundId '${fundId}'`);
    }
    return isSuppressed;
  }

  /**
   * If the component is suppressed, the apprioriate supression set will be returned
   * if not suppressed, this will return null
   */
  public getPortfolioComponentSuppressionSet(
    componentId: PortfolioComponentId,
    fundId: FundId,
    assetClass: AssetClass
  ): SuppressionSet {
    // return first set that matches componentId AND (fundId OR assetClass)
    const suppressionSet: SuppressionSet = this.product.portfolio?.suppressionSets.find(
      (set: SuppressionSet): boolean =>
        set.portfolioComponents.includes(componentId) &&
        (set.funds.includes(fundId) || set.assetClasses.includes(assetClass))
    );
    if (suppressionSet) {
      const reason = suppressionSet.funds.includes(fundId)
        ? `fundId '${fundId}'`
        : `asset class '${assetClass}'`;
      logger.debug(
        `Portfolio Component '${componentId}' is suppressed by set '${suppressionSet.id}' due to ${reason}`
      );
      return suppressionSet;
    }

    // Component not suppressed, so return null
    return null;
  }

  public isPortfolioStatisticSuppressedBySite(
    statisticId: StatisticId
  ): boolean {
    if (this.product.portfolio?.suppressStatistics.includes(statisticId)) {
      // check if this statistics is suppressed for whole site
      logger.debug(`Statistic '${statisticId}' suppressed for entire site`);
      return true;
    }
    return false;
  }

  public getPortfolioStatisticSuppressionSet(
    statisticId: StatisticId,
    fundId: FundId
  ): StatisticSuppressionSet {
    // return first set that matches statisticId AND fundId
    const suppressionSet: StatisticSuppressionSet = this.product.portfolio?.statisticSuppressionSets.find(
      (set: StatisticSuppressionSet): boolean =>
        set.statistics.includes(statisticId) && set.funds.includes(fundId)
    );
    if (suppressionSet) {
      logger.debug(
        `Portfolio Statistic '${statisticId}' is suppressed by set '${suppressionSet.id}' due to fundId '${fundId}`
      );
      return suppressionSet;
    }
    return null;
  }

  public isSiteInternational(): boolean {
    return this.product?.site?.country !== 'US';
  }

  public isSiteUcits(): boolean {
    return (
      this.product?.site?.country !== 'US' &&
      this.product?.site?.country !== 'CA'
    );
  }

  // returns true if site should have dynamic mifid labels i.e. Austria or Germany
  public isSiteDynamicLabels(): boolean {
    return this.product?.performance?.dynamicLabels;
  }

  // temit comes under channel section instead of geographical location
  public isTemit(): boolean {
    return this.appStateService.getChannel() === 'temit' ? true : false;
  }

  public showPop() {
    return !this.isSiteInternational() || this.getPdsCountry() === 'AU';
  }

  public showApplicationPrice() {
    return this.getPdsCountry() === 'AU';
  }

  public showRedemptionPrice() {
    return this.getPdsCountry() === 'AU';
  }

  public isSgHk() {
    return (
      this.product?.site?.country === 'SG' ||
      this.product?.site?.country === 'HK'
    );
  }

  public getDefaultCalcType(productType: ProductType): string {
    if (
      this.product.general.defaultCalcType &&
      productType in this.product.general.defaultCalcType
    ) {
      const calcType = this.product.general.defaultCalcType[productType].trim();
      return calcType !== '' ? calcType : null;
    }
    return null;
  }

  public getAdditionalCalcType(productType: ProductType): string {
    if (
      this.product.general.additionalCalcType &&
      productType in this.product.general.additionalCalcType
    ) {
      const calcType = this.product.general.additionalCalcType[
        productType
      ].trim();
      return calcType !== '' ? calcType : null;
    }
    return null;
  }

  /**
   * Returns GlobalConfig Site configuration
   */
  public getGlobalConfig(): GlobalConfig {
    return this.globalConfigService?.config;
  }

  public isTraded(fundId: FundId): boolean {
    return fundId && !this.product.general.nonTradedFunds?.includes(fundId);
  }

  /**
   * NGC-12770: Fund managers bios visibility condition.
   * Return boolean as flag present in 'showLinkToBios' in BR config against given taxonomy.
   * @param webProductTaxonomy valid value from pds feed
   */
  isShowLinkToBiosStatusValidForFundManagers(
    webProductTaxonomy: WebProductTaxonomy
  ): boolean {
    if (this.product.overview.showLinkToBios?.[webProductTaxonomy]) {
      return true;
    }

    return false;
  }
}
