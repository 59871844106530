import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnChanges {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  get document() {
    const { document } = this.component.getModels<DocumentModels>();
    const content = document && this.page.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get contentBlocks() {
    return this.content?.contentBlocks || [];
  }

  get title() {
    return this.page.getTitle();
  }
}
