<div class="modal-in" id="fti-modal">
  <div class="modal-header">
    <h4 *ngIf="modalHeaderText" class="modal-title" id="modal-basic-title">
      {{ modalHeaderText }}
    </h4>
    <a
      *ngIf="hasCloseButton"
      type="button"
      class="fill-circle-close close-video"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span class="icon-fti_close" (click)="closeModal()"></span>
    </a>
  </div>
  <div class="modal-body">
    <div class="mb+">
      <div class="row" *ngIf="hasAlertIcon">
        <div class="col-md-1 col-sm-1 col-1 pr-0 text-center">
          <div class="alert-icon"></div>
        </div>
        <div class="col-md-11 col-sm-11 col-11">
          {{ modalBodyText }}
        </div>
      </div>
      <div class="row" *ngIf="!hasAlertIcon">
        <div class="col-md-12 col-sm-12 col-12">
          {{ modalBodyText }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="primaryButtonText"
      class="btn btn-outline-primary"
      type="button"
      (click)="buttonPrimaryClick($event)"
    >
      {{ primaryButtonText }}
    </button>
    <button
      *ngIf="secondaryButtonText"
      class="btn btn-outline-secondary"
      type="button"
      (click)="buttonSecondaryClick($event)"
    >
      {{ secondaryButtonText }}
    </button>
  </div>
</div>
