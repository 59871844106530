<!-- distributor form start-->
<div class="footerdistributorForm">
  <footer class="site-footer" id="ft-footer-main">
    <div class="fti-container">
      <div class="fleadgen-main">
        <section class="sec-fled">
          <div class="fleadform-head">
            <h3>{{ "ftiCommon.NeedDistributorHeading" | translate }}</h3>
            <p>{{ "ftiCommon.fillYourDetailsText" | translate }}</p>
          </div>
          <form
            action="/en-in-retail/investor/blogs/blogs-detail.page?submit=true&amp;componentID=1573545605259"
            autocomplete="off"
            class="clearfix footer-contact-us-leadgen"
            id="footer-contact-us-leadgen"
            method="post"
            name="footer-contact-us-leadgen"
          >
            <div class="clearfix">
              <input
                aria-label="cidfield"
                id="cid"
                name="cid"
                type="hidden"
                value="zkBLQzYQn98JCnyKPX9uI8SAAFxuxdYKbk4sjZlFUS8."
              />
              <input
                aria-label="cpidfield"
                id="cpid"
                name="cpid"
                type="hidden"
                value="ENQa_YVswjcbpY2Uk5IHB795tgDbyBLV5YulkgggbQ4."
              />
              <input
                id="utm_medium"
                name="utm_medium"
                type="hidden"
                value=""
                aria-label="utmfield"
              />
              <input
                aria-label="utmcampaign"
                id="utm_campaign"
                name="utm_campaign"
                type="hidden"
                value=""
              />
              <input
                id="utm_source"
                name="utm_source"
                type="hidden"
                value=""
                aria-label="utmsource"
              />
              <input
                aria-label="utmcontent"
                id="utm_content"
                name="utm_content"
                type="hidden"
                value=""
              />
              <div class="col-xs-12">
                <div class="row fleadgen-main-form-fields">
                  <div
                    class="col-xs-12 col-md-6 col-lg-6 hideOTPSuccess mb-row-ve"
                  >
                    <div class="field-wrapper">
                      <div class="form-group mobile-form-group">
                        <input
                          class="form-field form-control secScrClass"
                          data-id="communicationUserName"
                          id="communication_Name"
                          name="name"
                          (paste)="(false)"
                          placeholder="{{ 'ftiCommon.name' | translate }}"
                          required=""
                          type="text"
                        />
                        <label class="form-label" for="communication_Name"
                          >{{ "ftiCommon.name" | translate }}<sup>*</sup>
                        </label>
                      </div>
                    </div>
                    <div class="communicationUserName-error field-error">
                      &nbsp;
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-6 col-lg-6 hideOTPSuccess">
                    <div class="field-wrapper">
                      <div class="form-group">
                        <input
                          class="form-field form-control"
                          data-id="communicationPhoneNumber"
                          id="communicationPhoneNumber"
                          maxlength="12"
                          name="mobile"
                          pattern="\d*"
                          (keydown)="($event.keyCode !== 69)"
                          placeholder="{{
                            'ftiCommon.MobileNumberLabel' | translate
                          }}"
                          required=""
                          type="text"
                        />
                        <label class="form-label" for="communicationPhoneNumber"
                          >{{ "ftiCommon.MobileNumberLabel" | translate
                          }}<sup>*</sup>
                        </label>
                      </div>
                      <div class="communicationPhoneNumber-error field-error">
                        &nbsp;
                      </div>
                      <div class="flead_mobile_note">
                        {{ "ftiCommon.OtpDefaultMsg" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row fleadgen-main-form-fields mb-row">
                  <div class="col-xs-12 col-md-6 col-lg-6 hideOTPSuccess">
                    <div class="field-wrapper">
                      <div class="form-group mobile-email">
                        <input
                          class="form-field form-control secScrClass"
                          data-id="communicationEmail"
                          email-validation="true"
                          id="communication_Email"
                          name="email"
                          (paste)="(false)"
                          placeholder="{{
                            'ftiCommon.emailIdLabel' | translate
                          }}"
                          required=""
                          type="text"
                        />
                        <label class="form-label" for="communication_Email"
                          >{{ "ftiCommon.emailIdLabel" | translate
                          }}<sup>*</sup>
                        </label>
                      </div>
                      <div class="communicationEmail-error field-error">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-6 col-lg-6 hideOTPSuccess">
                    <div class="field-wrapper">
                      <div class="form-group">
                        <label class="sr-only" for="city"
                          >{{ "ftiCommon.selectCity" | translate }}<sup>*</sup>
                        </label>
                        <select
                          class="form-field form-control defaultSel secScrClass"
                          data-id="communicationCityName"
                          id="city"
                          name="city"
                          (paste)="(false)"
                          required=""
                          type="select"
                        >
                          <option class="defaultSel" value="">
                            {{ "ftiCommon.selectCity" | translate }}*
                          </option>
                          <option
                            *ngFor="let cities of citiesValues"
                            value="{{ cities?.id }}"
                          >
                            {{ cities?.name }}
                          </option>
                        </select>
                      </div>
                      <div class="communicationCityName-error field-error">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hideOTPSuccess">
                  <p class="mb0 flead-priv-links">
                    {{ "ftiCommon.byClickonContinueLabel" | translate }}
                    <a href="/security-and-privacy">{{
                      "ftiCommon.privacyLabel" | translate
                    }}</a
                    >&nbsp;{{ "ftiCommon.andLabel" | translate }}
                    <a href="/terms-and-conditions">{{
                      "ftiCommon.termsOfUselabel" | translate
                    }}</a>
                  </p>
                  <div class="clearfix flead-adv-ctn-btn">
                    <input
                      aria-label="continuebutton"
                      class="btn-default panel-orange-btn pull-left m0 flead-new-continue-btn"
                      disabled="disabled"
                      id="continue-btn"
                      type="button"
                      value="{{ 'ftiCommon.continueLabel' | translate }}"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-12">
                  <div class="clearfix fleadgen-otp-message">&nbsp;</div>
                  <div class="field-wrapper fleadgen-otp-section hide">
                    <div class="form-group">
                      <input
                        class="form-control otp-field secScrClass"
                        data-id="communication_OTP"
                        id="communication_OTP"
                        name="adv-otp"
                        (paste)="(false)"
                        placeholder="{{
                          'ftiCommon.enterOtpLabel' | translate
                        }}"
                        type="password"
                      />
                      <label class="form-label" for="communication_OTP"
                        >{{ "ftiCommon.enterOtpLabel" | translate }}<sup>*</sup>
                      </label>
                    </div>
                    <div class="communication_OTP-error field-error">
                      &nbsp;
                    </div>
                    <div class="fleadgen-resend-otp">
                      {{ "ftiCommon.didntGetOtpLabel" | translate }},
                      <span class="adv-resend-otp">{{
                        "ftiCommon.clickHereLabel" | translate
                      }}</span>
                      <span class="adv-otp-timer" id="adv-otp-timer"
                        >&nbsp;</span
                      >
                    </div>
                  </div>
                  <div class="clearfix fleadgen_final-submit-btn hide">
                    <input
                      aria-label="submitbutton"
                      class="btn-default panel-orange-btn pull-left m0 fleadgen_OTP-submit-btn new-btn"
                      id="submit-btn"
                      type="button"
                      value="{{ 'ftiCommon.submitLabel' | translate }}"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="clearfix adv-success-blk hide thankyou-message">
            <div class="row">
              <div
                class="col-xs-12 feedback-success-message thankyou-container show"
              >
                <h5 class="fleadgen_adv-success-msg" style="display: flex">
                  <span class="sr-only">Heading 5</span>
                  <span class="success-icon icon icon-fti_tick">&nbsp;</span>
                  <span class="success-msg">&nbsp;</span>
                </h5>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </footer>
</div>
<!-- distributor form end-->

<ng-template [ngIf]="menu">
  <div class="position-relative">
    <ng-container [brManageMenuButton]="menuModel"></ng-container>
    <!-- <ul
      class="footer-menu list-inline"
      role="menu"
      aria-label="menupreview"
      [ngClass]="{ 'has-edit-button': page.isPreview() }"
    > -->
    <div
      class="footer-menu list-inline"
      [ngClass]="{ 'has-edit-button': page?.isPreview() }"
    >
      <!-- <li class="list-inline-item" *ngFor="let item of menu.siteMenuItems">
      <a class="nav-link" aria-current="page" role="menuitem" href="#">
         {{
        item.name
      }}
    </a>
    </li> -->

      <footer class="site-footer" id="ft-footer-main">
        <div
          class="fti-container d-none d-sm-none d-md-none d-lg-none d-xl-block"
        >
          <p class="site-for">
            {{ "ftiCommon.footer-disclaimer" | translate }}
          </p>
          <div class="row">
            <ng-container
              *ngFor="
                let footerTopMenu of menu.siteMenuItems[0].childMenuItems;
                let i = index
              "
            >
              <span
                class="footerLinksSpan"
                *ngIf="i !== 2 && i !== 3 && i !== 4"
              >
                <ng-container
                  [ngTemplateOutlet]="footerMenu"
                  [ngTemplateOutletContext]="{ footerTopMenu: footerTopMenu }"
                >
                </ng-container>
              </span>
            </ng-container>

            <span class="clubDivs">
              <ng-container
                *ngFor="
                  let footerTopMenu of menu.siteMenuItems[0].childMenuItems;
                  let i = index
                "
              >
                <ng-container *ngIf="i === 2 || i === 3">
                  <ng-container
                    [ngTemplateOutlet]="footerMenu"
                    [ngTemplateOutletContext]="{ footerTopMenu: footerTopMenu }"
                  >
                  </ng-container>
                </ng-container>
              </ng-container>
            </span>

            <ng-container
              *ngFor="
                let footerTopMenu of menu.siteMenuItems[0].childMenuItems;
                let i = index
              "
            >
              <span class="footerLinksSpan pe-0" *ngIf="i === 4">
                <ng-container
                  [ngTemplateOutlet]="footerMenu"
                  [ngTemplateOutletContext]="{ footerTopMenu: footerTopMenu }"
                >
                </ng-container>
              </span>
            </ng-container>

            <ng-template #footerMenu let-footerTopMenu="footerTopMenu">
              <div
                class="footerNavLinks"
                [ngClass]="{
                  'd-none':
                    (!isAdvisor &&
                      footerTopMenu.parameters[
                        'Request parameter (key=value)'
                      ].includes('appAdvisorLinksSection')) ||
                    (isAdvisor &&
                      footerTopMenu.parameters[
                        'Request parameter (key=value)'
                      ].includes('appAdvisorHideLinksSection'))
                }"
              >
                <h6>{{ footerTopMenu.name }}</h6>
                <span
                  *ngFor="
                    let footerSubMenu of footerTopMenu.childMenuItems;
                    let menuindex = index
                  "
                >
                  <a
                    *ngIf="
                      !footerSubMenu?.links.href &&
                      footerSubMenu.childMenuItems?.length > 0
                    "
                    class="list-group-item accordLink collapsed"
                    (click)="
                      displaySubMenu($event);
                      setCareersAnalytics(footerTopMenu, footerSubMenu)
                    "
                    [ngClass]="{
                      'd-none':
                        (!isAdvisor &&
                          footerSubMenu.parameters[
                            'Request parameter (key=value)'
                          ].includes('appAdvisorLinksSection')) ||
                        (isAdvisor &&
                          footerSubMenu.parameters[
                            'Request parameter (key=value)'
                          ].includes('appAdvisorHideLinksSection'))
                    }"
                    >{{ footerSubMenu?.name }}

                    <div class="list-group-submenu">
                      <ng-container
                        *ngFor="let submenu of footerSubMenu?.childMenuItems"
                      >
                        <ng-container
                          [ngTemplateOutlet]="footerMenuItems"
                          [ngTemplateOutletContext]="{
                            footerTopMenu: footerTopMenu,
                            footerSubMenu: footerSubMenu,
                            submenu: submenu,
                            class: 'analytics-link list-group-item'
                          }"
                        >
                        </ng-container>
                      </ng-container>
                    </div>
                  </a>
                  <div
                    *ngIf="!(footerSubMenu.childMenuItems?.length > 0)"
                    class="secondaryFooterlinks"
                  >
                    <ul>
                      <li>
                        <ng-container
                          [ngTemplateOutlet]="footerMenuItems"
                          [ngTemplateOutletContext]="{
                            footerTopMenu: footerTopMenu,
                            submenu: footerSubMenu,
                            class: '',
                            noSecondLevel: true
                          }"
                        >
                        </ng-container>
                        <!-- <a>{{footerSubMenu?.name}}</a> -->
                      </li>
                    </ul>
                  </div>
                </span>
              </div>
            </ng-template>
            <ng-template
              #footerMenuItems
              let-submenu="submenu"
              let-class="class"
              let-footerTopMenu="footerTopMenu"
              let-footerSubMenu="footerSubMenu"
              let-noSecondLevel="noSecondLevel"
            >
              <a
                *ngIf="
                  submenu.parameters['Request parameter (key=value)'].includes(
                    'externalUrl'
                  )
                "
                [attr.href]="submenu?.links?.site?.href"
                [attr.target]="submenu | isNewPage"
                class="{{ class }}"
                [ngClass]="{
                  'd-none':
                    (!isAdvisor &&
                      submenu.parameters[
                        'Request parameter (key=value)'
                      ].includes('appAdvisorLinksSection')) ||
                    (isAdvisor &&
                      submenu.parameters[
                        'Request parameter (key=value)'
                      ].includes('appAdvisorHideLinksSection'))
                }"
                (click)="
                  $event.stopPropagation();
                  setFooterAnalytics(
                    noSecondLevel,
                    footerTopMenu,
                    footerSubMenu,
                    submenu
                  )
                "
                >{{ submenu?.name }}</a
              >

              <a
                *ngIf="
                  submenu.parameters['Request parameter (key=value)'].includes(
                    'redirectGuestUrl'
                  )
                "
                [attr.href]="
                  appStateService.getftiAccountsUrl() +
                  '/' +
                  submenu?.links?.site?.href
                "
                [attr.target]="submenu | isNewPage"
                class="{{ class }}"
                [ngClass]="{
                  'd-none':
                    (!isAdvisor &&
                      submenu.parameters[
                        'Request parameter (key=value)'
                      ].includes('appAdvisorLinksSection')) ||
                    (isAdvisor &&
                      submenu.parameters[
                        'Request parameter (key=value)'
                      ].includes('appAdvisorHideLinksSection'))
                }"
                (click)="
                  $event.stopPropagation();
                  setFooterAnalytics(
                    noSecondLevel,
                    footerTopMenu,
                    footerSubMenu,
                    submenu
                  )
                "
                >{{ submenu?.name }}</a
              >

              <a
                *ngIf="
                  !submenu.parameters['Request parameter (key=value)'].includes(
                    'externalUrl'
                  ) &&
                  !submenu.parameters['Request parameter (key=value)'].includes(
                    'redirectGuestUrl'
                  ) &&
                  !(
                    submenu.parameters['Request parameter (key=value)']
                    | IsDataTarget
                  )
                "
                [routerLink]="submenu?.links?.site?.href"
                [attr.target]="submenu | isNewPage"
                class="{{ class }}"
                [ngClass]="{
                  'd-none':
                    (!isAdvisor &&
                      submenu.parameters[
                        'Request parameter (key=value)'
                      ].includes('appAdvisorLinksSection')) ||
                    (isAdvisor &&
                      submenu.parameters[
                        'Request parameter (key=value)'
                      ].includes('appAdvisorHideLinksSection'))
                }"
                (click)="
                  $event.stopPropagation();
                  setFooterAnalytics(
                    noSecondLevel,
                    footerTopMenu,
                    footerSubMenu,
                    submenu
                  )
                "
                >{{ submenu?.name }}</a
              >

              <a
                *ngIf="
                  submenu.parameters['Request parameter (key=value)']
                    | IsDataTarget
                "
                class="{{ class }}"
                href="#"
                data-bs-toggle="modal"
                [attr.data-bs-target]="
                  submenu.parameters['Request parameter (key=value)']
                    | DataTargetValue
                "
                (click)="
                  $event.stopPropagation();
                  setFooterAnalytics(
                    noSecondLevel,
                    footerTopMenu,
                    footerSubMenu,
                    submenu
                  )
                "
                >{{ submenu?.name }}</a
              >
            </ng-template>
          </div>
        </div>

        <div class="mediaLinks">
          <div class="fti-container">
            <div class="row">
              <div class="col-6 col-lg-6 col-md-6">
                <div class="row">
                  <div class="col-12 col-lg-6 col-md-6 ps-0">
                    <ul>
                      <li *ngFor="let careermenu of careesFirstMenu(0, 2)">
                        <ng-container
                          [ngTemplateOutlet]="careerMenu"
                          [ngTemplateOutletContext]="{
                            careermenu: careermenu
                          }"
                        >
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-xl-6 col-md-6 col-sm-12 ps-0">
                    <ul>
                      <li *ngFor="let careermenu of careesFirstMenu(3, 5)">
                        <ng-container
                          [ngTemplateOutlet]="careerMenu"
                          [ngTemplateOutletContext]="{
                            careermenu: careermenu
                          }"
                        >
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-6 col-md-6">
                <div class="row">
                  <div class="col-12 col-lg-6 col-md-6 col-sm-12 ps-0">
                    <ul>
                      <li *ngFor="let careermenu of careesFirstMenu(6, 8)">
                        <ng-container
                          [ngTemplateOutlet]="careerMenu"
                          [ngTemplateOutletContext]="{
                            careermenu: careermenu
                          }"
                        >
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-xl-6 col-md-6 col-sm-12 lastRow">
                    <ul>
                      <li *ngFor="let careermenu of careesFirstMenu(9, 11)">
                        <ng-container
                          [ngTemplateOutlet]="careerMenu"
                          [ngTemplateOutletContext]="{
                            careermenu: careermenu
                          }"
                        >
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ng-template #careerMenu let-careermenu="careermenu">
                <a
                  *ngIf="
                    careermenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('externalUrl')
                  "
                  [attr.href]="careermenu?.links?.site?.href"
                  [attr.target]="
                    careermenu.parameters['Open in New Tab (True or False)'] ===
                    'True'
                  "
                  (click)="setCareersAnalytics(undefined, careermenu)"
                  >{{ careermenu?.name }}</a
                >
                <a
                  *ngIf="
                    careermenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('redirectGuestUrl')
                  "
                  [attr.href]="
                    appStateService.getftiAccountsUrl() +
                    '/' +
                    careermenu?.links?.site?.href
                  "
                  [attr.target]="
                    careermenu.parameters['Open in New Tab (True or False)'] ===
                    'True'
                  "
                  [routerLink]="page.getUrl(careermenu?.links?.site?.href)"
                  (click)="setCareersAnalytics(undefined, careermenu)"
                  >{{ careermenu?.name }}</a
                >
                <a
                  *ngIf="
                    !careermenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('externalUrl') &&
                    !careermenu.parameters[
                      'Request parameter (key=value)'
                    ].includes('redirectGuestUrl')
                  "
                  [routerLink]="careermenu?.links?.site?.href"
                  [attr.target]="
                    careermenu.parameters['Open in New Tab (True or False)'] ===
                    'True'
                  "
                  (click)="setCareersAnalytics(undefined, careermenu)"
                  >{{ careermenu?.name }}</a
                >
              </ng-template>
            </div>
          </div>
        </div>
        <div class="fti-footer-logo">
          <div class="fti-container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-xl-2 col-lg-12">
                <img
                  src="/assets/images/fti_footer_logo.webp"
                  alt="ContainerImage"
                  title=""
                  alt="images"
                />
              </div>
              <div
                class="col-sm-12 col-md-12 col-xl-5 col-lg-12 ph0 custom-inv"
              >
                <!-- <small [innerHtml]="footerLables.copyright"></small> -->
                <small
                  >{{ "ftiCommon.footer-copyright" | translate }}&nbsp;1999-{{
                    fullYear
                  }}.&nbsp;<a [routerLink]="page.getUrl('/')">{{
                    "ftiCommon.footer-fti" | translate
                  }}</a
                  >.&nbsp;All Rights Reserved.</small
                >
              </div>
              <div
                class="col-sm-12 col-md-12 col-xl-5 col-lg-12 mt fti-social-links"
              >
                <div>
                  <a
                    [href]="ftiWhatsApp"
                    target="_blank"
                    (click)="setSocialMediaAnalytics('WhatsApp')"
                    rel="noopener noreferrer"
                    aria-label="Franklin Templeton On WhatsApp"
                    ><span
                      class="icon circle icon-fti_whatsapp"
                      aria-hidden="true"
                    >
                      <link
                        rel="stylesheet"
                        href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
                      />
                      <i class="fab fa-whatsapp"></i> </span
                  ></a>
                  <a
                    [href]="ftAppStoreLink"
                    target="_blank"
                    (click)="setSocialMediaAnalytics('itunes')"
                    rel="noopener noreferrer"
                    aria-label="Download on the App Store"
                    ><span
                      class="icon circle icon-fti_apple"
                      aria-hidden="true"
                    >
                    </span
                  ></a>
                  <a
                    [href]="ftPlayStoreLink"
                    target="_blank"
                    (click)="setSocialMediaAnalytics('Play Store')"
                    rel="noopener noreferrer"
                    aria-label="Get it on Google Play"
                    class="footer-social-links"
                    ><span
                      class="icon circle icon-fti_android"
                      aria-hidden="true"
                    >
                    </span
                  ></a>
                </div>
                <br />
                <a
                  class="analytics-link"
                  data-event-name="Event All pages"
                  data-category="Footer"
                  data-action="Social Link clicks"
                  data-label="Facebook"
                  [href]="ftiFblink"
                  target="_blank"
                  (click)="setSocialMediaAnalytics('Facebook')"
                  rel="noopener noreferrer"
                  aria-label="Franklin Templeton on Facebook"
                >
                  <span
                    class="icon circle icon-fti_facebook"
                    aria-hidden="true"
                  >
                  </span>
                </a>
                <a
                  class="analytics-link"
                  data-event-name="Event All pages"
                  data-category="Footer"
                  data-action="Social Link clicks"
                  data-label="Twitter"
                  [href]="ftiTwitterLink"
                  target="_blank"
                  (click)="setSocialMediaAnalytics('Twitter')"
                  rel="noopener noreferrer"
                  aria-label="Franklin Templeton on Twitter"
                >
                  <span class="icon circle icon-fti_twitter" aria-hidden="true">
                  </span>
                </a>
                <a
                  class="analytics-link"
                  data-event-name="Event All pages"
                  data-category="Footer"
                  data-action="Social Link clicks"
                  data-label="Youtube"
                  rel="noopener noreferrer"
                  aria-label="Franklin Templeton on YouTube"
                  [href]="ftiYoutubeLink"
                  target="_blank"
                  (click)="setSocialMediaAnalytics('Youtube')"
                >
                  <span
                    class="icon circle icon-fti_youtubeLogo"
                    aria-hidden="true"
                  >
                  </span>
                </a>
                <a
                  class="analytics-link"
                  data-event-name="Event All pages"
                  data-category="Footer"
                  data-action="Social Link clicks"
                  data-label="LinkedIn"
                  rel="noopener noreferrer"
                  aria-label="Franklin Templeton on LinkedIn"
                  [href]="ftiLinkedInLink"
                  target="_blank"
                  (click)="setSocialMediaAnalytics('LinkedIn')"
                >
                  <span
                    class="icon circle icon-fti_linkedin"
                    aria-hidden="true"
                  >
                  </span>
                </a>
              </div>

              <div
                class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ftic-footerAdd"
              >
                <small>
                  <p>
                    {{ "ftiCommon.footer-fti-asset-management" | translate }}
                  </p>
                  <p>{{ "ftiCommon.footer-fti-asset-desc" | translate }}</p>
                  <p>{{ "ftiCommon.footer-cin" | translate }}</p>
                  <p>{{ "ftiCommon.footer-telephone" | translate }}</p>
                  <p>
                    {{ "ftiCommon.footer-griviences-email" | translate }}
                  </p>
                </small>
              </div>
              <p class="mf-disc">
                {{ "ftiCommon.footer-mf-disclaimer" | translate }}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</ng-template>
