import { Component, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { ResponsiveService } from '@services/responsive.service';
import { Observable } from 'rxjs';

class CsrInitiativesData {
  title?: string;
  location?: string;
  partner?: string;
  implementationSchedule?: string;
  content?: Array<string>;
}

class CsrInitiativesLabels {
  locationLabel?: string;
  partnerLabel?: string;
  implementationScheduleLabel?: string;
  moreInitiatives?: string;
}

@Component({
  selector: 'ft-csr-initiatives',
  templateUrl: './csr-initiatives.component.html',
  styleUrls: ['./csr-initiatives.component.scss'],
})
export class CsrInitiativesComponent implements OnInit {
  @Input() page: Page;
  @Input() component: BrComponent;

  // component essentials
  initiativesData: CsrInitiativesData[] = [];
  displayedData: CsrInitiativesData[] = [];
  displayedDataSize = 2;
  labels: CsrInitiativesLabels;
  showMoreInitiatives = true;
  isHandheld$: Observable<boolean>;
  csrPolicyUrl =
    'https://franklintempletonprod.widen.net/s/dkvtmcnvk9/csr-policy-j05gbzd1-en-in';

  constructor(private responsiveService: ResponsiveService) {}

  ngOnInit(): void {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    if (document?.$ref) {
      const content = this.page?.getContent(document)?.getData();
      this.mapState(content);
    }
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }

  /**
   * maps raw incoming data into usable dataStructure
   * @param content Content recieved from bloomreach
   */
  // intentionally keeping type as any because data received from bloomreach is in
  // ContentModel structure and it cannot be mapped to a certain structure.
  // Instead created other datatypes to keep structureing in order.
  private mapState(content: any): void {
    if (content) {
      // iterating content to extract individual blocks data
      content?.contentBlock?.forEach((block) => {
        // iterating block items to store them into usable dataStructure
        block?.items?.forEach((csrData, index) => {
          // Using ParagraphBlock as a key to take in json data and parsing it
          if (csrData?.type === 'ParagraphBlock') {
            // removing <p> and </p> tags
            const rawJsonString = csrData?.content.slice(
              3,
              csrData?.content.length - 4
            );
            const rawData = JSON.parse(rawJsonString);

            // map data according to type recieved
            if (rawData?.type === 'initiative') {
              const csrInitiative: CsrInitiativesData = {
                title: rawData?.title,
                location: rawData?.location,
                partner: rawData?.partner,
                implementationSchedule: rawData?.implementationSchedule,
                content: rawData?.content,
              };

              if (index < 2) {
                this.displayedData.push(csrInitiative);
              }
              this.initiativesData.push(csrInitiative);
            } else if (rawData?.type === 'labels') {
              const labels: CsrInitiativesLabels = {
                locationLabel: rawData?.location,
                partnerLabel: rawData?.partner,
                implementationScheduleLabel: rawData?.implementationSchedule,
                moreInitiatives: rawData?.moreInitiatives,
              };
              this.labels = labels;
            }
          }
        });
      });
    }
  }

  /**
   * add 2 or 1 initiatives when clicked on load more button
   */
  public loadInitiative(): void {
    if (this.displayedDataSize + 1 <= this.initiativesData.length) {
      this.displayedData.push(this.initiativesData[this.displayedDataSize]);
      if (this.displayedData.length !== this.initiativesData.length) {
        this.displayedData.push(
          this.initiativesData[this.displayedDataSize + 1]
        );
      }
      this.displayedDataSize = this.displayedData.length;
    }
    if (this.displayedDataSize === this.initiativesData.length) {
      this.showMoreInitiatives = false;
    }
  }
}
