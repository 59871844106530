import { Mapper, MapperParams } from './type.mapper';
import { Identifiers } from '@angular/compiler';
import { FundId, IdentifiersDTO, ShareClassCode, Ticker } from '@types';
import { MapperFactory } from './mapper-factory';

export class IdentifiersMapper extends Mapper<Identifiers> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(identifiersDto: IdentifiersDTO): Identifiers {
    return {
      fundId: identifiersDto.fundid as FundId,
      shareClassCode: identifiersDto.shclcode as ShareClassCode,
      ticker: identifiersDto.ticker as Ticker,
      taid: identifiersDto.taid,
      isin: identifiersDto.isin,
      sedol: identifiersDto.sedol,
      bloomberg: identifiersDto.bloomberg,
      cusip: identifiersDto.cusip,
      reuterSystem: identifiersDto.reuterssym,
      countryCode: identifiersDto.cntrycode,
      languageCode: identifiersDto.langcode,
      mexId: identifiersDto.mexid,
      apir: identifiersDto.apir,
      arsn: identifiersDto.arsn,
      anbimaCode: identifiersDto.anbimacode,
      vln: identifiersDto.vln,
      intradayNav: identifiersDto.intradaynav,
      rfi: identifiersDto.rfi,
      adm: identifiersDto.adm,
      dsc: identifiersDto.dsc,
      iress: identifiersDto.iress,
      iressinavCode: identifiersDto.iressinavcode,
      fundbmTicker: identifiersDto.fundbmticker,
      wkn: identifiersDto.wkn,
      nasdaqxnms: identifiersDto.nasdaqxnms,
      iopv: identifiersDto.iopv,
    };
  }
}
