// these are the brand themes that EDS supports.
// add another option when EDS adds abother brand
// the site's brand is specific in the it's channel json file
export enum Brand {
  CLEARBRIDGE = 'clearbridge',
  CPREIF = 'cpreif',
  FRK = 'frk',
  EP = 'ep',
  NJBEST = 'njbest',
}
