<div class="fti-container">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <div>
        <div class="row">
          <h1 class="sipCalcHeading">{{ lumpsumTitile }}</h1>
        </div>
        <div class="row sip-sec">
          <div class="col-md-12 col-xl-12 col-lg-12 calcSection">
            <div class="col-12 col-sm-12 loanCalc">
              <div class="col-12 col-lg-8 col-sm-12 sipClcForm">
                <div class="inpt-slider">
                  <div class="radioBtn">
                    <input
                      name="radioGroup"
                      id="sipInvest"
                      type="radio"
                      checked="checked"
                      (change)="hideShowInvest('invest')"
                    />
                    <label class="flRight" for="sipInvest">{{
                      labelRadioValue1
                    }}</label>
                    <input
                      name="radioGroup"
                      id="sipGoal"
                      type="radio"
                      (change)="hideShowInvest('goal')"
                    />
                    <label for="sipGoal">{{ labelRadioValue2 }}</label>
                  </div>
                </div>
                <div class="ionSlider newCalc">
                  <div
                    class="inpt-slider canInvest"
                    [ngClass]="{ active: canIntValue }"
                  >
                    <div class="inpt-statement loan_amount">
                      <label class="" for="investedInputAmount">{{
                        lumpsumLabelSider1
                      }}</label>
                      <input
                        name="mnthsavings"
                        class="inpt inputBox numbersOnly loanAmt"
                        id="investedInputAmount"
                        type="text"
                        maxlength="8"
                        data-minval="10000"
                        data-maxval="10000000"
                        onpaste="return false"
                        (keypress)="
                          numberwithDotOnly('investedInputAmount', $event)
                        "
                        [(ngModel)]="investAmount"
                        (blur)="investBlurChange($event)"
                      />
                    </div>
                    <div>
                      <mv-slider
                        [min]="10000"
                        id="wish_to"
                        [max]="10000000"
                        [value]="valueinvest"
                        [tooltip]="true"
                        [(ngModel)]="investAmount"
                        [step]="1"
                        (change)="investSliderChange($event)"
                        style="width: 100%; top: 10px"
                      ></mv-slider>
                    </div>
                    <div class="dragText">
                      <span>10K</span> <span class="pull-right">1Cr</span>
                    </div>
                  </div>
                  <div
                    class="inpt-slider canGoal"
                    [ngClass]="{ active: canGoalValue }"
                  >
                    <div class="inpt-statement loan_amount">
                      <label class="" for="goalInputAmount">{{
                        labelSlider2
                      }}</label>
                      <input
                        name="mnthsavings"
                        class="inpt inputBox numbersOnly loanAmt"
                        id="goalInputAmount"
                        type="text"
                        maxlength="10"
                        data-minval="50000"
                        data-maxval="50000000"
                        onpaste="return false"
                        [(ngModel)]="goalAmount"
                        (keypress)="
                          numberwithDotOnly('goalInputAmount', $event)
                        "
                        (blur)="investGoalChange($event)"
                      />
                    </div>
                    <div>
                      <mv-slider
                        [min]="50000"
                        id="wish_to"
                        [max]="50000000"
                        [value]="valuegoal"
                        [tooltip]="true"
                        [step]="1"
                        (change)="investGoalSliderChange($event)"
                        style="width: 100%; top: 10px"
                      ></mv-slider>
                    </div>
                    <div class="dragText">
                      <span>50K</span> <span class="pull-right">5Cr</span>
                    </div>
                  </div>

                  <div class="inpt-slider noOfYears">
                    <div class="inpt-statement loan_amount">
                      <label class="" for="loan_amountInv">{{
                        labelyears
                      }}</label>
                      <input
                        name="mnthsavings"
                        class="inpt inputBox numbersOnly loanAmt"
                        id="loan_amountInv"
                        type="text"
                        maxlength="3"
                        data-minval="20"
                        data-maxval="30"
                        onpaste="return false"
                        [(ngModel)]="yearsValue"
                        (keypress)="numberOnly($event)"
                        (blur)="investYearChange($event)"
                      />
                    </div>
                    <div>
                      <mv-slider
                        [min]="1"
                        id="wish_to"
                        [max]="30"
                        [value]="value"
                        [tooltip]="true"
                        [step]="1"
                        style="width: 100%; top: 10px"
                        (change)="investSliderYearChange($event)"
                      ></mv-slider>
                    </div>
                    <div class="dragText">
                      <span>1Year</span> <span class="pull-right">30Years</span>
                    </div>
                  </div>

                  <div class="inpt-slider rateReturn">
                    <div class="inpt-statement loan_amount">
                      <label class="" for="loan_amountInterest"
                        >{{ labelReturn }}
                      </label>
                      <input
                        name="mnthsavings"
                        class="inputBox inpt"
                        id="loan_amountInterest"
                        type="text"
                        maxlength="4"
                        data-minval="2"
                        data-maxval="13"
                        onpaste="return false"
                        [(ngModel)]="rateOfReturn"
                        (blur)="investInterestChange($event)"
                        (keypress)="
                          numberOnlyPercent('loan_amountInterest', $event)
                        "
                      />
                    </div>
                    <div>
                      <mv-slider
                        [min]="2"
                        id="wish_to"
                        [max]="13"
                        [value]="valueinvestrate"
                        [tooltip]="true"
                        [step]="1"
                        style="width: 100%; top: 10px"
                        (change)="investSliderInterestChange($event)"
                      ></mv-slider>
                    </div>
                    <div class="dragText">
                      <span>2%</span> <span class="pull-right">13%</span>
                    </div>
                  </div>
                  <div
                    class="popover-pos"
                    [ngClass]="{ popOverActive: popOver }"
                  >
                    <div class="popover-cont">
                      <div class="row pop-row">
                        <small class="close-emi" (click)="closeDisclaimer()">
                          <img
                            class="pull-right"
                            src="https://franklintempletonprod.widen.net/content/l8ytgbc3rt/jpeg/close-pop.png"
                            alt="popup"
                          />
                        </small>
                      </div>
                      <div class="row text-left">
                        <p
                          class="popover-content"
                          [innerHtml]="calculatorDisclaimer"
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div class="mb vd-mb">
                    <div class="vd" (click)="showDisclaimer()">
                      {{ disclaimerLabel }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5 col-sm-12 totalBox">
                <div class="finalResult finalInvest">
                  <div
                    class="resRow monAmt"
                    [ngClass]="{ monAmtActive: monAmtValue }"
                  >
                    <span>{{ lumpsumInvestment }}</span
                    ><strong class="rupeeIconSip">₹</strong>
                    <span id="lblEMIAmt">{{ totalValue }}</span>
                  </div>
                  <div
                    class="resRow sipInv"
                    [ngClass]="{ sipActive: sipIntValue }"
                  >
                    <span>{{ labelInvested }}</span>
                    <strong class="rupeeIconSip">₹</strong
                    ><span class="txtFace" id="InvestedAmt">{{
                      investedAmount
                    }}</span>
                  </div>
                  <div
                    class="resRow sipInv"
                    [ngClass]="{ sipActive: sipIntValue }"
                  >
                    <span>{{ labelResultant }}</span>

                    <strong class="rupeeIconSip">₹</strong
                    ><span class="txtFace" id="sipReturnAmnt">{{
                      totalValue
                    }}</span>
                  </div>
                  <a
                    class="btn btn-default panel-orange-btn sipStartASip"
                    [href]="url | async"
                    target="_blank"
                    rel="nofollow"
                    >{{ investNow }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Fund list for redirection to performance page -->
        <div class="row sip-sec calcSection fund-returns">
          <div class="col-md-6 col-xs-12 mt-3 heading">
            <span>{{
              "products.sip-calc-check-fund-returns" | translate
            }}</span>
          </div>

          <div class="col-md-6 col-xs-12 mt-3 mb">
            <ft-select
              [dropdownList]="fundList"
              [Placeholder]="'products.sip-calc-select-fund' | translate"
              customClasses="sip-calc-select"
              (dropDownChange)="handleFundRedirect($event)"
            ></ft-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
