import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@components/shared/translate/translate.service';
import { AnalyticsService } from '@services/analytics.service';
import { CommonService } from '@services/common.service';
import { Subscription } from 'rxjs';
import { Logger } from '@utils/logger';
import { EnvConfigService } from '@services/env-config.service';
import { SessionService } from '@services/session.service';

const logger = Logger.getLogger('InterestedComponent');

@Component({
  selector: 'ft-interested',
  templateUrl: './interested.component.html',
  styleUrls: ['./interested.component.scss'],
})
export class InterestedComponent implements OnInit, OnDestroy {
  interestedForm: FormGroup;
  isThankYou = false;
  isFormSubmitted = false;
  errMsg: any = [];
  url = '';
  urlPrefix = '';
  ocpApimTrace = '';
  ocpApimSubscriptionKey = '';
  selectedCity = '';
  accepted = false;
  subscription: Subscription;
  citiesValues: any = [];

  // Resource Bundle
  content: any;
  labelInterestedButton: any;
  flashMessage: any;
  labelName: any;
  labelEmail: any;
  labelMobile: any;
  labelARN: any;
  labelARNCheckbox: any;
  labelComment: any;
  labelSubmit: any;
  labelCancel: any;
  environment: any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private commonService: CommonService,
    private envConfig: EnvConfigService,
    private sessionService: SessionService
  ) {
    this.createInterestedForm();
    this.environment = this.envConfig?.getEnvConfig()?.environment;
  }

  // Interested form
  createInterestedForm() {
    this.interestedForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(1)]],
      emailId: ['', [Validators.required, Validators.minLength(1)]],
      mobile: ['', [Validators.minLength(3)]],
      distId: ['', [Validators.required, Validators.minLength(1)]],
      subscriptions: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  ngOnInit(): void {
    this.ocpApimSubscriptionKey = this.envConfig?.getEnvConfig()?.ocpApimSubscriptionKey;
    this.ocpApimTrace = this.envConfig?.getEnvConfig()?.ocpApimTrace;
    this.urlPrefix = this.envConfig?.getEnvConfig()?.ftiApiDomain;

    // Get cities
    this.subscription = this.commonService?.cityData?.subscribe(
      (citiesValues) => (this.citiesValues = citiesValues)
    );

    // Get values from resource bundle
    this.url =
      this.urlPrefix + this.translateService?.instant('ftiInterested.formURL');
    this.content = this.translateService?.instant('ftiInterested.content');
    this.labelInterestedButton = this.translateService?.instant(
      'ftiInterested.labelInterestedButton'
    );
    this.flashMessage = this.translateService?.instant(
      'ftiInterested.flashMessage'
    );
    this.labelName = this.translateService?.instant('ftiInterested.labelName');
    this.labelEmail = this.translateService?.instant(
      'ftiInterested.labelEmail'
    );
    this.labelMobile = this.translateService?.instant(
      'ftiInterested.labelMobile'
    );
    this.selectedCity = this.translateService?.instant(
      'ftiInterested.labelCity'
    );
    this.labelARN = this.translateService?.instant('ftiInterested.labelARN');
    this.labelARNCheckbox = this.translateService?.instant(
      'ftiInterested.labelARNCheckbox'
    );
    this.labelComment = this.translateService?.instant(
      'ftiInterested.labelComment'
    );
    this.labelSubmit = this.translateService?.instant(
      'ftiInterested.labelSubmit'
    );
    this.labelCancel = this.translateService?.instant(
      'ftiInterested.labelCancel'
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  reset() {
    this.interestedForm?.reset();
  }

  openInterestedModal() {
    this.errMsg = [];
    this.reset();
    this.accepted = false;
    this.interestedForm?.controls?.distId?.setValidators([
      Validators.required,
      Validators.minLength(1),
    ]);
    this.interestedForm?.controls?.distId?.enable();
    this.isThankYou = false;
    this.isFormSubmitted = false;
    this.selectedCity = 'City';
  }

  arnNotRequired($event) {
    if ($event?.target?.checked) {
      this.accepted = true;
      this.interestedForm?.get('distId').clearValidators();
      this.interestedForm?.get('distId').reset();
      this.interestedForm?.controls?.distId?.disable();
    } else {
      this.accepted = false;
      this.interestedForm?.controls?.distId?.setValidators([
        Validators.required,
        Validators.minLength(1),
      ]);
      this.interestedForm?.controls?.distId?.enable();
    }
  }

  restrictCharacter($event) {
    const keyCode = 'which' in $event ? $event.which : $event.keyCode;
    if (keyCode < 48 || keyCode > 57) {
      $event.preventDefault();
      return false;
    } else {
      if ($event.target.value.length >= 10) {
        $event.preventDefault();
        return false;
      } else {
        return true;
      }
    }
  }

  onKeyUp($event, fieldName) {
    let enteredCharacter = $event?.target?.value;
    enteredCharacter = enteredCharacter?.replace(/[\/\\$'"<>]/g, '');
    this.interestedForm?.controls[fieldName]?.setValue(enteredCharacter);
  }

  interestedSubmit() {
    this.errMsg = [];
    this.isFormSubmitted = true;

    const name = this.interestedForm?.value?.name;
    const emailId = this.interestedForm?.value?.emailId;
    const mobile = this.interestedForm?.value?.mobile;
    const city = this.selectedCity;
    const distId = this.interestedForm?.value?.distId;
    const subscriptions = this.interestedForm?.value?.subscriptions;

    if (name) {
      if (!/^(.|\s){1,80}$/.test(name)) {
        this.errMsg.push({
          name: this.translateService?.instant(
            'ftiInterested.nameValidationMsg'
          ),
        });
      }
    }
    if (emailId) {
      if (
        !/^(.|\s){1,100}$/.test(emailId) ||
        !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
          emailId
        )
      ) {
        this.errMsg.push({
          emailId: this.translateService?.instant(
            'ftiInterested.emailValidationMsg'
          ),
        });
      }
    }
    if (mobile) {
      if (!/^\d{10}$/.test(mobile)) {
        this.errMsg.push({
          mobile: this.translateService?.instant(
            'ftiInterested.mobileValidationMsg'
          ),
        });
      }
    }
    if (distId) {
      if (!/^[0-9A-Za-z- ]{3,20}$/.test(distId)) {
        this.errMsg.push({
          distId: this.translateService?.instant(
            'ftiInterested.distIdValidationMsg'
          ),
        });
      }
    }
    if (subscriptions) {
      if (!/^(.|\s){1,500}$/.test(subscriptions)) {
        this.errMsg.push({
          subscriptions: this.translateService?.instant(
            'ftiInterested.subscriptionErrorMsg'
          ),
        });
      }
    }
    if (this.errMsg.length) {
      this.isFormSubmitted = false;
      return false;
    }

    const data = {
      name,
      emailId,
      mobile: mobile ? mobile : '',
      city: city !== 'City' ? city : '',
      distId,
      subscriptions,
    };

    const options =
      this.environment === 'pre' || this.environment === 'prod'
        ? this.sessionService?.apiHttpOptionsWithCredentials
        : this.sessionService?.apiHttpOptions;

    this.http.post(this.url, data, options).subscribe(
      (response) => {
        this.isThankYou = true;
        this.isFormSubmitted = false;
        this.setAnalytics();
        this.changeDetector.markForCheck();
      },
      (error) => {
        logger.error('error >>>> ', error);
      }
    );
  }

  setAnalytics() {
    this.analyticsService.trackEvent({
      event: 'Event Distributor Zone',
      category: 'Distributor Zone',
      action: 'Submit',
      label: 'Im Interested Form',
    });
  }

  onSelect(selectedCity) {
    this.selectedCity = selectedCity;
  }
}
