import { Component, Input } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  get data() {
    return this.component?.getParameters();
  }
  get params() {
    return this.data;
  }
}
