import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { WINDOW } from '@ng-web-apis/common';
import { AnalyticsService } from '@services/analytics.service';
import { WindowService } from '@services/window.service';
import { constants } from 'buffer';
import { fromEvent, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  pluck,
  switchMap,
  map,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'ft-document-picker',
  templateUrl: './document-picker.component.html',
  styleUrls: ['./document-picker.component.scss'],
})
export class DocumentPickerComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  active = false;
  previousIndex: number;
  clickedIndex: any;
  @ViewChildren('li_div') divElements;
  @ViewChild('searchBarInput') searchBarInput: ElementRef;
  @ViewChild('stickyHeader') stickyHeader: ElementRef;
  term: string;
  data: any;
  finalResults: any;
  noResultsFound = false;
  isAlphabetClick = false;
  isSearchItemClicked = false;
  isSearch = false;
  filteredSearchResults: any;
  searchTerm = '';
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    @Inject(WINDOW) private windowRef: Window,
    private analyticsService: AnalyticsService,
    private windowService: WindowService
  ) {}

  ngOnInit(): void {
    this.clickedIndex = 0;
    this.getData();
  }
  ngAfterViewInit(): void {
    fromEvent<string>(this.searchBarInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event;
        })
      )
      .subscribe((event) => {
        this.term = event.target.value;
        if (event.keyCode === 13) {
          this.isSearch = true;
          this.setAnalytics(this.term);
        } else {
          this.isSearch = false;
        }
        if (String(this.term).length >= 3 || this.term === '') {
          this.isSearchItemClicked = false;
          this.noResultsFound = false;
          this.finalResults = [];
          if (this.term) {
            this.updateData(this.term);
          } else {
            this.getData();
            this.cdRef.detectChanges();
          }
        }
      });

    // Track Window Scroll (Throttled)
    this.windowService
      .getScrollObs$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (
          this.divElements?._results &&
          !this.isSearchItemClicked &&
          !this.isAlphabetClick
        ) {
          const { height: headerHeight } = this.getDimensions(
            this.stickyHeader
          );
          const scrollPosition = this.windowRef.scrollY + 20 + headerHeight;
          for (let i = 0; i < this.divElements?._results?.length; i++) {
            if (this.divElements?._results[i]) {
              const { offsetBottom, offsetTop } = this.getDimensions(
                this.divElements?._results[i]
              );
              if (this.windowRef.scrollY === 0) {
                this.clickedIndex = 0;
                break;
              } else if (
                scrollPosition > offsetTop &&
                scrollPosition < offsetBottom
              ) {
                this.clickedIndex = i;
                break;
              }
            }
          }
        }
      });
  }
  updateData(searchTerm) {
    this.finalResults = [];
    const data = JSON.parse(JSON.stringify(this.getData()));
    data.forEach((element) => {
      const filteredResults = element.glossaryContent.filter((innerEle) => {
        return innerEle?.title
          ?.toLowerCase()
          .includes(searchTerm.toString().toLowerCase());
      });
      // element.glossaryContent = this.filteredResults;
      if (filteredResults && filteredResults.length > 0) {
        filteredResults.forEach((ele) => {
          this.finalResults.push(ele);
        });
      }
    });
    if (!this.finalResults.length) {
      this.noResultsFound = true;
    }
    this.cdRef.detectChanges();
  }
  setAnalytics(title) {
    this.analyticsService.trackEvent({
      event: 'Event InvestorEducation',
      category: 'InvestorEducation',
      action: 'Search',
      label: title,
    });
  }
  onSearchItemClick(result) {
    this.searchTerm = result?.title;
    this.isSearchItemClicked = true;
    this.filteredSearchResults = [];
    this.filteredSearchResults = [result];
    this.setAnalytics(result?.title);
    this.cdRef.detectChanges();
  }
  noItemSearch(term) {
    this.searchTerm = term;
    this.isSearchItemClicked = true;
    this.filteredSearchResults = [];
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  getData() {
    const documentData: any = this.document;
    this.data = documentData?.getData()?.glossaryCompound;
    return this.data;
  }
  onSearchClick() {
    this.setAnalytics(this.searchTerm);
    this.isSearch = true;
    this.isSearchItemClicked = false;
  }
  onAlphabetClick(e) {
    this.isAlphabetClick = true;
    this.isSearch = false;
    this.isSearchItemClicked = false;
    this.searchTerm = '';
    setTimeout(() => {
      const selectedDiv = this.divElements?._results.find((element) => {
        return element.nativeElement.id?.split('-')[0] === e.target.textContent;
      });
      const stickyHeaderHeight = this.stickyHeader.nativeElement.offsetHeight;
      this.windowRef.scroll(
        0,
        selectedDiv.nativeElement.offsetTop - stickyHeaderHeight - 10
      );
      this.isAlphabetClick = false;
    });
  }
  getDimensions(ele) {
    const { height } = ele.nativeElement.getBoundingClientRect();
    const offsetTop = ele.nativeElement.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  }

  /**
   * On component destroyed
   * Removes any remaining subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
