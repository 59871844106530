<div *ngIf="contactInfo" class="row">
  <div *ngFor="let item of contactInfo">
    <div
      class="col-10 contact-data"
      [ngClass]="{
        'border-dotted': accordionContent === false,
        'padding-top-none': paddingNone === true
      }"
    >
      <h6 class="contact-data-title">{{ item?.name }}</h6>

      <div *ngFor="let item of item?.address">{{ item }}</div>
      <a
        class="phone-number"
        [href]="'tel:' + item?.contactNumber"
        *ngFor="let item of item?.contactNumber"
        ><span class="fti-printer icon icon-fti_phone"></span> : {{ item }}</a
      >
      <div class="phone-number" *ngIf="item?.fax">
        <span class="fti-printer icon icon-fti_printer"></span> :
        {{ item?.fax }}
      </div>
    </div>
  </div>
</div>
