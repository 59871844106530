import {
  Component,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@shared/translate/translate.service';
import { Logger } from '@utils/logger';
import cloneDeep from 'lodash/cloneDeep';
import { HighchartsThemeService } from '../services/highchart/highcharts-theme/highcharts-theme.service';
import {
  ChartData,
  ChartDataPoint,
  ChartTableDataPoint,
} from '../bar-chart/bar-chart.component';

const logger = Logger.getLogger('PiChartComponent');

@Component({
  selector: 'ft-pi-chart',
  templateUrl: './pi-chart.component.html',
  styleUrls: ['./pi-chart.component.scss'],
})
export class PiChartComponent implements OnInit {
  highcharts;
  private chartInstance: Highcharts.Chart;

  @Input() data: ChartData;

  public isPopulated = false;
  public chartOptions: Highcharts.Options;
  public tablePoints: ChartTableDataPoint[];

  updateChart = false;
  private chartHeight: number;
  labelsFontSize = '13'; // Default font size for chart labels
  /**
   * controlling the table toggle for expand collapse state
   */
  toggle = false;
  tooltips: { [name: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private highchartsTheme: HighchartsThemeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.labelsFontSize = '13';
    this.updateChart = true;

    this.tooltips[
      'products.sector-duration-column-header'
    ] = this.translateService.tooltip('products.sector-duration-column-header');
    if (this.data) {
      import(/* webpackChunkName: "highcharts-async" */ 'highcharts').then(
        (highchartsModule) => {
          highchartsModule.setOptions(this.highchartsTheme.themeOptions);
          this.highcharts = highchartsModule;

          this.processData(highchartsModule);
        }
      );
    }
  }

  /**
   * called by Highcharts component after it inits, to pass a reference to the chart instance
   */
  public chartCallback = (chart: Highcharts.Chart): void => {
    this.chartInstance = chart;
  };

  /**
   * this causes the chart to redraw when called.
   * Used to fix dynamic chart size issues
   */
  public resizeChart(): void {
    setTimeout(() => {
      if (this.chartInstance?.options) {
        this.chartInstance?.reflow();
      }
    });
  }

  /**
   * When data is passed into this component, this function processes it into suitable chart and table data
   * Called direct from Entry component, as ngOnInit and ngOnchanges not called when dynamic component
   */
  public processData(highchartsModule): void {
    this.chartHeight = 300;

    // get data for table
    this.tablePoints = this.getTableData();

    this.chartOptions = this.getDefaultChartOptions(highchartsModule);
    logger.debug('chart options', this.chartOptions);

    // display component
    this.isPopulated = true;

    this.cdr.detectChanges();
  }

  /**
   * This returns (nested) data in a format suitable for generating a table to accompany the chart
   */
  private getTableData(): ChartTableDataPoint[] {
    // map point colors
    return this.data.dataPoints.map(
      (point: ChartDataPoint, index: number): ChartTableDataPoint => ({
        ...point,
        // NGC-16431: In Portfolio, fixed income is coming from PDS
        // value as 0.00% whereas in prod it was displayed as 0%
        breakdown:
          point?.breakdownStd === 0
            ? `${point?.breakdownStd}%`
            : point?.breakdown,
        pointColor: this.highchartsTheme.getPointColor(
          index,
          this.data.dataPoints.length
        ),
      })
    );
  }

  /**
   * This creates a Highcharts Options object with options used by all types of chart
   */
  private getBaseChartOptions(highchartsModule): Highcharts.Options {
    const valueSuffix: string = this.data.isPercent ? '%' : '';
    return cloneDeep({
      chart: {
        height: this.chartHeight,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        events: {
          render(this) {
            const that: Highcharts.Chart = this;
            setTimeout(() => {
              if (that?.options) {
                that.reflow();
              }
            }, 0);
          },
        },
        reflow: true,
      },
      accessibility: {
        point: {
          valueSuffix,
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
        },
      },
      title: {
        text: null,
      },
    });
  }

  /**
   * This creates a Highcharts Options object suitable for displaying most Portfolio Charts
   */
  private getDefaultChartOptions(highchartsModule): Highcharts.Options {
    const opts: Highcharts.Options = this.getBaseChartOptions(highchartsModule);
    const dataPoints = this.data.dataPoints;
    opts.series = [
      {
        type: 'pie',
        colorByPoint: true,
        // return actual allocation if data, otherwise default to breakdown
        // This logic may need strengthened in future
        data: this.data.dataPoints.map((point: ChartDataPoint) => ({
          name: point.label,
          y: point.actualAllocationStd || point.breakdownStd,
        })),
      },
    ];

    opts.tooltip = {
      formatter() {
        const thisPoint = dataPoints[this.point.index];
        return `${thisPoint.label}<br/>${
          thisPoint.actualAllocation || thisPoint.breakdown
        }`;
      },
    };
    return opts;
  }
}
