import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { DomSanitizer, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ft-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageNotFoundComponent {
  @Input() component!: BrComponent;
  @Input() page: Page;

  constructor(private sanitizeHtml: DomSanitizer, meta: Meta) {
    meta.addTag({
      name: 'prerender-status-code',
      content: '404',
    });
  }

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  /**
   * At the moment only one content block is configured on BR.
   */
  get contentBlock() {
    return (
      this.content?.contentBlocks &&
      this.content?.contentBlocks?.length &&
      this.content?.contentBlocks[0]
    );
  }

  htmlCode(data) {
    return this.sanitizeHtml.bypassSecurityTrustHtml(data);
  }

  getImageURL(data) {
    const imageURL = JSON.parse(data)?.url;
    return `url('${imageURL}')`;
  }
}
