import { FundId } from '.';
import { Product, ShareClass } from '../models';

export interface FundListingBRConfiguration {
  config: FundListingBRComponentConfiguration;
  funds: object;
}

export interface FundListingBRComponentConfiguration {
  componentType: FundListingComponentType;
  assetCategory: string;
  fundList?: FundId[];
}

export enum FundListingComponentType {
  'PPSS' = 'PPSS',
  'NAV' = 'LatestNavs',
  'IDCW' = 'IDCW',
  'FUNDCARDS' = 'FundCards',
}

export interface FundListingState {
  fundNames: string[];
  filterDropdowns: FundListingFilterDropdown[];
  ppssState?: FundListingPPSSState;
  navState?: FundListingNAVState;
  idcwState?: FundListingIDCWState;
  isLoaded?: boolean;
}

export interface FundListingFilterDropdown {
  filterLabel: string;
  filterName: string;
  filterItems: DropdownItem[];
}

export interface DropdownItem {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
  id?: string;
  FirstDropDown?: DropdownItem[];
}

export interface FundListingPPSSState {
  ppssPageTitle: string;
  ppssData: FundListingDataFormat[];
}

export interface FundListingNAVState {
  navData: FundListingDataFormat[];
}

export interface FundListingIDCWState {
  idcwData: FundListingDataFormat[];
}

export type FundListingDataFormat = {
  fundId: FundId;
  fundName: string;
  fundLink: FundListingFundLinkDetails;
  assetClass?: string; // Only required for watchlist
  shareClassData?: FundListingShareClassDataFormat; // For PPSS.
  shareClassesData?: FundListingShareClassDataFormat[]; // For IDCW.
  shareClassesChunksData?: FundListingShareClassDataFormat[][]; // For NAVs.
  ppssPeriodDropdownItems?: DropdownItem[]; //  Period Dropdown Item at card level for only PPSS
} & FundListingBRData;

export interface FundListingBRData {
  enableInvestNow: boolean;
  fundDescription?: string;
  suitableFor?: string;
}

export interface FundListingFundLinkDetails {
  overview?: string;
  performance?: string;
  historicalNavs?: string;
  idcwHistory?: string;
}

export interface FundListingShareClassDataFormat {
  isPrimaryShareClass?: boolean;
  shareClassName?: string;
  performanceData?: FundListingShareClassPerformanceDataFormat;
  navData?: FundListingShareClassNavDataFormat;
  distributionData?: FundListingShareClassDistributionDataFormat;
}

export interface FundListingShareClassPerformanceDataFormat {
  asOfDate: string;
  averageAnnualReturnFor1Year: string;
  averageAnnualReturnFor3Year: string;
  averageAnnualReturnFor5Year: string;
  averageAnnualReturnFor10Year: string;
  averageAnnualReturnSinceInception: string;
}

export interface FundListingShareClassNavDataFormat {
  navDate: string;
  navValue: string;
}

export interface FundListingShareClassDistributionDataFormat {
  recordDate: string;
  recordNav: string;
  distributionAmount: string;
  exDividendDate: string;
  exDividendNav: string;
}

export interface FundListingFilterEvent {
  selectedDropdown?: FundListingFilterSelectedDropdown;
  searchedText?: string;
}

export interface FundListingFilterSelectedDropdown {
  filterName: string;
  filterValue: string;
}

export interface ProductData extends Omit<Product, 'shareClasses'> {
  shareClass?: ShareClass;
}
