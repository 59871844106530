import { IdentifierKey } from '@models';
import {
  ConfigurationCountry,
  ConfigurationLocale,
  ProductType,
} from '@products/types';
import {
  AssetClass,
  FundId,
  FundShareClassId,
  FundUmbrella,
  PortfolioComponentId,
  StatisticId,
  WebProductTaxonomy,
} from '../types';
import { SegmentId } from './segment.type';

export enum PerformanceType {
  AVG_ANNUAL = 'AVG_ANNUAL',
  CALENDAR_YEAR = 'CALENDAR_YEAR',
  CUMULATIVE = 'CUMULATIVE',
  DISCRETE = 'DISCRETE',
}
export type FundSectionLink = {
  link: string;
  labelKey: string;
};

export type GeneralConfig = {
  activeList?: FundId[];
  noBenchmarkList?: FundId[];
  twoBenchmarkList?: FundId[];
  allBenchmarkList?: FundId[];
  hideYtdTotalReturnAtNav?: FundUmbrella[];
  showMDashForUnder1Year?: boolean;
  showMDashForUnder6Months?: boolean;
  suppress1And2StarMSRatings?: boolean;
  keyIdentifier?: Record<WebProductTaxonomy, IdentifierKey>;
  search?: any;
  labelOverrideList?: LabelOverride[];
  showAacr?: string[];
  defaultCalcType?: Record<ProductType, string>;
  additionalCalcType?: Record<ProductType, string>;
  nonTradedFunds?: FundId[];
  // for activeList optimization
  activeListCommon?: number[]; // per segment
};

export type LabelOverride = {
  funds: FundId[];
  key: string; // label id. TODO: consider defining LabelId type
  value: string; // new label value
};

export type OverviewConfig = {
  riskFactorContentCategory?: string;
  ratings?: Record<WebProductTaxonomy, string[]>;
  showLinkToBios?: Record<WebProductTaxonomy, boolean>;
  fundInfoSuppressionList?: Record<WebProductTaxonomy, string[]>;
};
export type PerformanceConfig = {
  hideYTDForCumulativeAndCalendarYear?: boolean;
  dataRefreshRate?: Record<ProductType, Record<PerformanceType, string>>;
  ppssPerformanceViews?: Record<ProductType, PerformanceType[]>;
  hideCurrency?: string[];
  benchmarkOrder?: Record<WebProductTaxonomy, string[]>;
  suppressBenchmarkData?: Record<WebProductTaxonomy, string[]>;
  hideAddShLinkList?: WebProductTaxonomy[];
  showYtd1M3M6M?: boolean;
  cumulativeHypoChart?: string;
  cumulativeHistorical?: boolean;
  acHistorical?: boolean;
  showCharts1YearLess?: boolean;
  dynamicLabels?: boolean;
};

// TODO: not sure if this is still needed?
// export type RefreshOverrideSet = {
//   id: string;
//   refreshRate: RefreshRate;
//   funds: FundId[];
//   portfolioComponents: PortfolioComponentJsonId[];
//   portfolioStatistics: StatisticId[]
// };

export type PortfolioConfig = {
  benchmarkSuppression?: FundId[];
  showHoldingsDownload: boolean;
  statisticSuppressionSets?: StatisticSuppressionSet[];
  suppressStatistics?: StatisticId[];
  suppressionSets?: SuppressionSet[];
  holdingsIdentifierOverride?: FundId[]; // overrides ISIN with CUSIP
};

export type StatisticSuppressionSet = {
  id: string;
  funds: FundId[];
  statistics: StatisticId[];
};

export type SuppressionSet = {
  assetClasses: AssetClass[]; // NB: refers to FundCategory in this case
  funds: FundId[];
  id: string;
  portfolioComponents: PortfolioComponentId[];
};

export type PpssConfig = {
  allConfigItem?: SegmentId;
  component?: any[];
  segmentItem?: SegmentId;
  dataRefreshRate?: any;
  primaryFilters?: any; // TODO: check BaseFilter type
  additionalFilters?: any; // TODO: check BaseFilter type
  filtersOptions?: any;
  investmentVehicleList?: string[];
  suppressSearch?: boolean;
  suppressPerformance?: boolean;
  showPerformanceCurrencies?: boolean;
  showDailyAum?: boolean;
  suppressNavChange?: boolean;
  taxonomyList?: any; // need to change to do
  showRatings?: Record<ProductType, boolean>;
  showYields?: Record<ProductType, boolean>;
  showIdentifiers?: Record<ProductType, boolean>;
  fundIdentifiers?: Record<ProductType, string[]>;
  redirectToPpssUrl?: string;
  hideNavChange?: Record<ProductType, boolean>;
  hideFactsheet?: Record<ProductType, boolean>;
};

export type PriceDistributionConfig = {};
export type SearchConfig = {};

export type SiteConfig = {
  country?: ConfigurationCountry;
  language?: ConfigurationLocale;
};

export enum TabName {
  OVERVIEW = 'overview',
  PORTFOLIO = 'portfolio',
  PERFORMANCE = 'performance',
  HISTORICAL_NAVS = 'historicalNavs',
  IDCW_HISTORY = 'idcwHistory',
  DOCUMENTS = 'documents',
}

export type TabSet = {
  name: string;
  tabs: string[];
};

export type ProductTabSet = {
  assignments?: {
    [setName: string]: FundId[];
  };
  sets?: TabSet[];
};

export type ProductConfig = {
  general?: GeneralConfig;
  overview?: OverviewConfig;
  performance?: PerformanceConfig;
  portfolio?: PortfolioConfig;
  ppss?: PpssConfig;
  priceDistribution?: PriceDistributionConfig;
  site?: SiteConfig;
  tabs?: Record<ProductType, ProductTabSet>;
};

export type LiteratureConfig = {
  // TODO: define string value types
  pageType?: [
    {
      primaryFilters?: string[];
      additionalFilters?: string[];
      documentTypeDisplay?: string[];
      literatureListGridColumn?: string[];
      literatureDocument?: string[];
      segmentOverrideList?: string[];
      pageTypeName?: string;
    }
  ];
  criteriaType?: [
    {
      documentTypeDisplay?: string[];
      criteriaTypeName?: string;
    }
  ];
  fundDocumentSegmentOverrideList?: string[];
  fundDocument?: string[];
  fundDocumentTab?: string[];
  fundAdditionalResources?: string[];
  fundDocumentGridColumn?: string[];
  litDetailUrl?: string;
  litOrderCheckoutUrl?: string;
  litOrderHistoryDetailUrl?: string;
  orderConfirm?: string;
  orderHistoryList?: string;
  orderSuccess?: string;
  orderCheckoutAddress?: string;
  litListing?: string;
  myLiteratureLandingUrl?: string;
};

export type CommonConfig = {
  locale?: string;
  dateFormat?: string;
  taxonomyCountry?: string;
  taxonomyLanguage?: string;
  authentication?: string;
  searchHeader?: boolean;
  authenticationLegacy?: boolean;
  maintenanceMode?: boolean;
  maintenancePageUrl?: string;
  enableFavoriteFunds?: boolean;
};

export type SiteParams = {
  countryHubLink?: string;
  footerLogo?: string;
  footerMicrositeTextLogo?: string;
  footerDisclaimerOverride?: string;
  headerLogo?: string;
  logoAltText?: string;
  companyInfo?: string;
  isMicrosite?: boolean;
  headerMicrositeTextLogo?: string;
  headerMicrositeLogoHref?: string;
  personalizeBannerTitleOverride?: string;
  personalizeBannerPreTitleOverride?: string;
  summary?: string;
  insightCardInfo?: InsightCardInfo;
  isSocialMediaSharingButtonsDisabled?: boolean;
  personalizeBannerImage: string;
  personalizeBannerTransparencyRatio: '40' | '60' | '50';
  personalizeBannerTransparency: boolean;
};

export interface InsightCardInfo {
  viewMoreUrl: string;
}
