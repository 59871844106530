<div class="filter-layout">
  <!-- Filter search -->
  <div class="filter-search">
    <label for="resource-search" class="sr-only">Search Terms</label>
    <input
      type="text"
      id="resource-search"
      [attr.placeholder]="
        'literature.literature-listing-search-input-placeholder' | translate
      "
      [(ngModel)]="searchedInput"
      (input)="onSearchInput($event)"
    />
    <button
      type="button"
      id="resourceCloseBtn"
      class="close"
      (click)="onClearSearch($event)"
      [ngClass]="{ hide: !searchedInput.length }"
      aria-label="Close"
    >
      <span aria-hidden="true">×</span>
    </button>
    <button
      class="btn btn-default pull-right page-searchBtn"
      (click)="onSearchSuggestionSelected(searchSuggestions)"
      type="submit"
      id="resource-search-icon"
    >
      <i class="icon-fti_search searchIcon"></i>
      <span class="sr-only">Enter to Search</span>
    </button>
    <!-- Suggestion Container -->
    <div class="filter-search-suggestions">
      <ul *ngIf="searchSuggestions.length && !clearTextBox">
        <li
          *ngFor="let suggestion of searchSuggestions"
          (click)="onSearchSuggestionSelected(suggestion)"
        >
          {{ suggestion }}
        </li>
      </ul>
      <ul
        *ngIf="
          !searchSuggestions.length &&
          showNoSearchSuggestionMessage &&
          !clearTextBox
        "
      >
        <li class="no-search-result-option">
          {{ "literature.literature-listing-no-search-suggestion" | translate }}
          "{{ searchedInputAgainstNoSearchSuggestionMessage }}"
        </li>
      </ul>
    </div>
  </div>

  <!-- Filter dropdowns -->
  <div class="filter-dropdowns" [class.hide]="!showDocumentPanel">
    <ng-container *ngIf="!hasMultipleFilters; else multipleFilterTemplate">
      <div *ngFor="let filterDropdown of filterDropdowns; let i = index">
        <ft-select
          [(ngModel)]="selectedFilterItem"
          [dropdownList]="filterDropdown"
          [customClasses]="'select-filter select-filter_thick'"
          (dropDownChange)="onDropdownChange($event, null)"
          #primaryFilter
        ></ft-select>
      </div>
    </ng-container>

    <!-- Multiple filters -->
    <ng-template #multipleFilterTemplate>
      <div *ngFor="let filterDropdown of filterDropdowns; let i = index">
        <ft-select
          [(ngModel)]="selectedFilterItem"
          [dropdownList]="filterDropdown"
          [customClasses]="'select-filter select-filter_thick primaryFilters'"
          (dropDownChange)="onPrimaryDropdownChange($event, secondaryFilter)"
          #primaryFilter
        ></ft-select>
        <ft-select
          [(ngModel)]="selectedSecondaryFilterItem"
          [dropdownList]="secondaryFilters?.FirstDropDown"
          [customClasses]="'select-filter select-filter_thick secondaryFilters'"
          (dropDownChange)="onDropdownChange($event, primaryFilter)"
          #secondaryFilter
        ></ft-select>
      </div>
    </ng-template>
  </div>

  <!-- Search Results -->
  <div
    id="filteredData"
    class="pb mb+ clearfix"
    *ngIf="!showDocumentPanel && searchedInput"
  >
    <div class="col-xs-12">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-6">
          <h3 class="resource-search-heading">
            {{ "literature.search-result-heading" | translate }}
          </h3>
        </div>

        <div
          class="col-md-6 col-sm-6 col-xs-6 text-right"
          *ngIf="count > resultLimit"
        >
          <a
            href="javascript:void(0);"
            (click)="showAllResults()"
            [hidden]="resultLimit > 10"
            >View All {{ count }} Results</a
          >
        </div>
      </div>
    </div>
    <div class="ph col-xs-12 search-site-group">
      <span class="mb+ resources-view-all resource-all">
        Search Results : All -&nbsp;
        <a href="javascript:void(0);" class="resources-count all-count"></a>
        <span class="resources-count">{{ count }}</span>
      </span>
      <span class="search-site mb+">
        Search the Site -&nbsp;"<a
          [href]="'/search?query=' + searchedInput + '&filter=all'"
          class="resource-site-search"
          >{{ searchedInput }}</a
        >"</span
      >
    </div>
    <div id="resourcesFilteredData" class="col-xs-12">
      <ng-container
        *ngFor="let selectedDocument of selectedDocuments; let i = index"
      >
        <div class="col-md-12 search-item pv" *ngIf="i < resultLimit">
          <a
            [href]="'download' + selectedDocument.literatureHref"
            target="_blank"
          >
            <span class="acc-content">{{ selectedDocument.dctermsTitle }}</span>
            <span class="list-grid-img pull-right">
              <img
                *ngIf="selectedDocument.literatureHref; else notFound"
                [src]="
                  '/assets/images/' + fileExtension(selectedDocument) + '.png'
                "
                alt="Click to download"
              />
            </span>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #notFound> N/A </ng-template>
