import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import reverse from 'lodash/reverse';
import flatten from 'lodash/flatten';
import { BaseEntryProductComponent } from '@products/base-entry-product-component';
import {
  FundDocumentCategoryDTO,
  FundDocumentsService,
} from './services/fund-documents.service';
import { Subscription } from 'rxjs';
import { Logger } from '@utils/logger';
import { LiteratureDTO } from '@types';
import { FundDocument } from '../types/fund-document.type';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';

const logger = Logger.getLogger('Fund Documents');

@Component({
  selector: 'ft-fund-documents',
  templateUrl: './fund-documents.component.html',
  styleUrls: ['./fund-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundDocumentsComponent
  extends BaseEntryProductComponent
  implements OnInit, AfterViewInit, OnDestroy {
  public fundDocumentCategory: FundDocumentCategoryDTO;
  public fundDocuments: any;
  public errorInService = false;
  public docsLoaded = false;
  subscription: Subscription;
  fundVideo: SafeResourceUrl;
  fundVideoImage: SafeResourceUrl;
  popvisible = false;
  hashNavigation: any;

  constructor(
    private fundService: FundDocumentsService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super();
    this.hashNavigation = this.route.snapshot?.fragment;
  }

  ngOnInit(): void {
    super.ngOnInit();

    /**
     * Create custom object in 'Fund Category' for 'Fund Documents'
     */
    const customFundCategory = {
      name: 'india literature fund documents tab',
      fundDocTypeToDisplay_csv: '',
      fundDocCategory: 'FUND-DOCUMENTS',
      documents: [],
    };

    // Get Fund Types (other than categories)
    const fundType = this.fundService.fundLiterature?.fundType[0]?.documentTypeDisplay_csv?.split(
      ','
    );
    // Set Literature Categories
    this.fundDocumentCategory = cloneDeep(
      this.fundService.fundLiterature?.fundCategory
    );

    // Call FundService to pull the data
    this.subscription = this.fundService
      .getFundLiteratureDocument$(
        '?channel=en-in&cache=true&frkOneTISNumber=' + this.brConfig?.fundId
      )
      ?.subscribe(
        (data: LiteratureDTO): void => {
          const fundTypeDocs = [];
          this.docsLoaded = true;

          // Get data for Fund Category
          forEach(this.fundDocumentCategory, (input) => {
            const fundDoc = filter(data.document, {
              contentGrouping: input.fundDocCategory,
            });
            if (fundDoc.length > 0) {
              // Check if file extension is present, otherwise add one from file url
              fundDoc.map((doc) => {
                doc.fileType = doc.fileExtension
                  ? doc.fileExtension
                  : this.fileExtension(doc);
              });

              input.documents = fundDoc;
              logger.debug(input);
            }
          });
          this.fundDocuments = this.fundDocumentCategory;
          // Get data for Fund Type
          forEach(fundType, (sortedCategories) => {
            const fundDoc = filter(data.document, {
              contentGrouping: sortedCategories,
            });
            if (fundDoc.length > 0) {
              // Check if file extension is present, otherwise add one from file url
              fundDoc.map((doc) => {
                doc.fileType = doc.fileExtension
                  ? doc.fileExtension
                  : this.fileExtension(doc);
              });
              fundTypeDocs.push(fundDoc);
            }
          });

          // Match the document sequence per production
          customFundCategory.documents = flatten(fundTypeDocs);

          /**
           * Push custom object in 'Fund Category' with 'Fund Documents'
           */
          this.fundDocuments.push(reverse(customFundCategory));
          this.changeDetectorRef.detectChanges();
        },
        (err) => {
          logger.debug('Literature HTTP Error', err);
          this.errorInService = true;
          this.changeDetectorRef.detectChanges();
        }
      );
    const documents = this.component?.getModels()?.documents;

    const fundContentDocument: FundDocument = this.page
      ?.getContent(documents)
      ?.getData<FundDocument>();
    logger.debug(fundContentDocument);
    if (fundContentDocument?.fundvideo) {
      this.fundVideoImage = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://img.youtube.com/vi/${fundContentDocument.fundvideo}/0.jpg`
      );
      this.fundVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/embed/${fundContentDocument.fundvideo}?rel=0`
      );
    }
  }

  ngAfterViewInit() {
    if (this.hashNavigation !== undefined) {
      setTimeout(() => {
        this.smoothScroll(this.hashNavigation);
      }, 2000);
    }
  }

  smoothScroll(target) {
    let stra2 = document.querySelector('#' + target);
    stra2 = stra2 === undefined ? document.querySelector(`#${target}`) : stra2;
    const doScrolling = (elementY, duration) => {
      const startingY = window.pageYOffset;
      const diff = elementY - startingY;
      let start;

      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) {
          start = timestamp;
        }
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        const percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent + 30);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    };

    if (stra2 != null) {
      doScrolling(stra2.getBoundingClientRect().top + window.scrollY - 40, 10);
    }
  }

  openVideoModal(videoModal) {
    this.modalService.open(videoModal, {
      ariaLabelledBy: 'Fund Video',
      size: 'lg',
      backdrop: 'static',
    });
    this.popvisible = true;
    const videoOpen = document.querySelector('#fti-modal');
    const bodyHTML = document.querySelector('html');

    if (videoOpen.classList.contains('modal-in')) {
      window.scrollTo(0, 0);
      bodyHTML.style.overflow = 'hidden';
    }
  }

  closePopup() {
    this.popvisible = false;
    const videoOpen = document.querySelector('#fti-modal');
    const bodyHTML = document.querySelector('html');

    if (videoOpen.classList.contains('modal-in')) {
      bodyHTML.style.overflow = 'auto';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // Capture file extension from document url (if filetype is not present)
  public fileExtension(input) {
    const extensionIndex = input?.literatureHref.lastIndexOf('.');
    return input?.literatureHref?.slice(extensionIndex + 1).toLowerCase();
  }
}
