export class VerticalTabsBlocks {
  sectionId?: string;
  block?: VerticalTabsContent[];
  blockId?: number;
  selected?: boolean;
}

export class VerticalTabsContent {
  blockType?: VerticalTabContentType;
  content?: string;
  tableContent?: VerticalTabListBlock;
  accordionContent?: VerticalTabsContactItem[];
  bannerContent?: VerticalTabsBannerBlock[];
  imageUrl?: string;
}

export enum VerticalTabContentType {
  Paragraph = 'paragraph',
  Image = 'image',
  Accordion = 'accordion',
  Table = 'table',
  Intro = 'intro',
  Banner = 'banner',
  IndiaOffices = 'indiaOffices',
  TabImage = 'tabImage',
}

export class VerticalTabListBlock {
  title?: string;
  borderColor?: string;
  tableData?: Array<TabList>;
}

export class TabList {
  listTitle?: string;
  list?: Array<string>;
}

export class VerticalTabsBannerBlock {
  value?: number;
  label?: string;
  superscript?: string;
}

export class VerticalTabsContactItem {
  country?: string;
  name?: string;
  address?: Array<string>;
  contactNumber?: Array<string>;
  fax?: string;
}
