import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { getMenus } from '@utils/pagemodel-utils';

const logger = Logger.getLogger('BreadcrumbService');

export interface BreadcrumbItem {
  name?: string;
  parent?: string;
  link?: string;
}
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbByNameMap: Map<string, BreadcrumbItem>;
  breadcrumbByUrlMap: Map<string, BreadcrumbItem>;

  constructor(private router: Router) {}

  getBreadcrumbs(page: Page, leaf?: string): BreadcrumbItem[] {
    if (!this.breadcrumbByNameMap) {
      this.createBreadcrumbMapFromMenus(page);
      logger.debug(this.breadcrumbByNameMap);
    }

    return this.getBreadcrumbsByUrl(this.router.url, leaf);
  }

  private createBreadcrumbMapFromMenus(page: Page) {
    this.breadcrumbByNameMap = new Map();
    this.breadcrumbByUrlMap = new Map();

    const footerMenus = getMenus(
      page,
      page.getComponent('footer-content', 'footer')
    );

    footerMenus?.siteMenuItems?.forEach((menu) => {
      menu.childMenuItems.forEach((childItem) => {
        this.loopThroughSiteMenuItems(null, childItem);
      });
    });
    // do the header menus second so they overwrite dodgy footer menu items
    const headerMenus = getMenus(
      page,
      page.getComponent('header-content', 'header')
    );

    headerMenus?.siteMenuItems?.forEach((menu) => {
      menu.childMenuItems.forEach((childItem) => {
        this.loopThroughSiteMenuItems(null, childItem);
      });
    });
  }

  private loopThroughSiteMenuItems(parent: BreadcrumbItem, menuItem: any) {
    const link = menuItem.links?.site?.href;
    const breadcrumb: BreadcrumbItem = {
      parent: parent?.name,
      name: menuItem.name,
      link,
    };
    this.breadcrumbByNameMap.set(menuItem.name, breadcrumb);
    if (link) {
      this.breadcrumbByUrlMap.set(link, breadcrumb);
    }
    menuItem.childMenuItems.forEach((childItem) => {
      this.loopThroughSiteMenuItems(breadcrumb, childItem);
    });
  }

  private getBreadcrumbsByUrl(url: string, leaf?: string): BreadcrumbItem[] {
    if (url?.includes('?')) {
      url = url?.split('?')[0];
    }
    const ret: BreadcrumbItem[] = [];
    let item: BreadcrumbItem;
    // try special cases first
    if (url?.startsWith('/fund-details')) {
      // fund detail page
      item = {
        parent: 'All Funds',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/new-to-mutual-funds/video') ||
      url?.includes('/new-to-mutual-funds/article')
    ) {
      item = {
        parent: 'New to Mutual Funds',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/more-about-mutual-funds/video') ||
      url?.includes('/more-about-mutual-funds/article')
    ) {
      item = {
        parent: 'More about Mutual Funds',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/women-and-investing/video') ||
      url?.includes('/women-and-investing/article')
    ) {
      item = {
        parent: 'Women and Investing',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/planning-for-retirement/video') ||
      url?.includes('/planning-for-retirement/article')
    ) {
      item = {
        parent: 'Planning for Retirement',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/your-childs-future/video') ||
      url?.includes('/your-childs-future/article')
    ) {
      item = {
        parent: "Your Child's Future",
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/smart-tax-planning/video') ||
      url?.includes('/smart-tax-planning/article')
    ) {
      item = {
        parent: 'Guide to Income Tax Planning',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/managing-volatility-and-pitfalls-of-investing/video') ||
      url?.includes('/managing-volatility-and-pitfalls-of-investing/article')
    ) {
      item = {
        parent: 'Managing Volatility and Pitfalls of Investing',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/webinars/video') ||
      url?.includes('/webinars/article')
    ) {
      item = {
        parent: 'webinars',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/new-to-investing/video') ||
      url?.includes('/new-to-investing/article')
    ) {
      // new to investing video page
      item = {
        parent: 'New to Investing',
        name: this.getLeaf(url, leaf),
      };
    } else if (
      url?.includes('/market-insights/video/frankly-speaking-webinars')
    ) {
      // market-outlook Frankly Speaking Webinars video page
      item = {
        parent: 'Frankly Speaking Webinars',
        name: this.getLeaf(url, leaf),
      };
    } else if (url?.includes('/market-insights/video/')) {
      // market-outlook video page
      item = {
        parent: 'Latest Commentaries',
        name: this.getLeaf(url, leaf),
      };
    }
    if (!item) {
      item = this.breadcrumbByUrlMap.get(url);
    }

    // build up breadcrumbs by adding ancestors
    if (item) {
      ret.unshift(item);
      while (item.parent) {
        item = this.breadcrumbByNameMap.get(item.parent);
        ret.unshift(item);
      }
    } else {
      if (url?.includes('?')) {
        url = url?.split('?')[0];
      }
      ret.unshift({
        parent: null,
        name: this.getLeaf(url, leaf),
      });
    }

    if (url?.includes('/nps')) {
      ret[0].name = 'Feedback';
    }
    return ret;
  }

  private getLeaf(url: string, leaf?: string): string {
    return leaf ? leaf : this.extractNameFromUrl(url);
  }

  // TODO there must be a better way than this
  private extractNameFromUrl(url): string {
    const lastPathFragment = url?.split('/').reverse()[0];
    let flag = true;
    let name = '';
    const arrName = lastPathFragment?.split('-');
    arrName?.forEach((value) => {
      if (flag) {
        name = value.charAt(0).toUpperCase() + value.substring(1);
        flag = false;
      } else {
        name = name + ' ' + value.charAt(0).toUpperCase() + value.substring(1);
      }
    });
    return name;
  }
}
