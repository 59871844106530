import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { EnvConfigService } from '@services/env-config.service';
import { AppStateService } from '@services/app-state.service';
import { APP_BASE_HREF } from '@angular/common';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { ContentComponent } from '@components/content/content.component';
import { PageContainerComponent } from '@pages/page-container/page-container.component';
import { RichTextComponent } from '@shared/rich-text/rich-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FundCaveatComponent } from './ft-components/marketing/fund-caveat/fund-caveat.component';
import { QuickLinkListComponent } from '@components/marketing/quick-link-list/quick-link-list.component';
import {
  NgbModule,
  NgbTooltipModule,
  NgbRatingModule,
} from '@ng-bootstrap/ng-bootstrap';
import { IndCarouselComponent } from './ft-components/marketing/ind-carousel/ind-carousel.component';
import { BreadcrumbComponent } from './ft-components/shared/breadcrumb/breadcrumb.component';
import { AccordionComponent } from './ft-components/shared/accordion/accordion.component';
import { SipCalculatorComponent } from './ft-components/marketing/sip-calculator/sip-calculator.component';
import { HorizontalColumnTabComponent } from './ft-components/marketing/horizontal-column-tab/horizontal-column-tab.component';
import { LumpsumCalculatorComponent } from './ft-components/marketing/lumpsum-calculator/lumpsum-calculator.component';
import { ContactusDisplayGridComponent } from './ft-components/marketing/contactus-display-grid/contactus-display-grid.component';
import { AddressBookComponent } from './ft-components/marketing/address-book/address-book.component';
import { ArticleDetailComponent } from './ft-components/marketing/article-detail/article-detail.component';
import { VideoArticlesComponent } from '@components/marketing/video-articles/video-articles.component';
import { SharedModule } from '@components/shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResourceApiHeadersInterceptor } from '@components/shared/utils/resource-api-headers-interceptor';
import { ArticleListComponent } from './ft-components/marketing/article-list/article-list.component';
import { BeginersGuideComponent } from '@components/marketing/beginers-guide/beginers-guide.component';
import { LayoutVideoInnerComponent } from '@pages/layout-video-inner/layout-video-inner.component';
import { CommonService } from '@services/common.service';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { ShareOptionsComponent } from '@components/marketing/share-options/share-options.component';
import { NewInvestorLoginComponent } from '@components/marketing/new-investor-login/new-investor-login.component';
import { ExistingInvestorLoginComponent } from '@components/marketing/existing-investor-login/existing-investor-login.component';
import { TwoequalcolComponent } from '@pages/twoequalcol/twoequalcol.component';
import { DocumentPickerComponent } from '@shared/document-picker/document-picker.component';
import { SectionTitleComponent } from '@shared/section-title/section-title.component';
import { FeedbackComponent } from './ft-components/marketing/feedback/feedback.component';
import { InterestedComponent } from './ft-components/marketing/interested/interested.component';
import { DisplayListComponent } from './ft-components/marketing/display-list/display-list.component';
import { OnecolComponent } from './ft-pages/onecol/onecol.component';
import { TwoColLeftWideComponent } from './ft-pages/two-col-left-wide/two-col-left-wide.component';
import { TwoColLeftWideCustomComponent } from './ft-pages/two-col-left-wide-custom/two-col-left-wide-custom.component';
import { CommonComponent } from './ft-components/marketing/common/common.component';
import { FlashMessagesComponent } from './ft-components/marketing/flash-messages/flash-messages.component';
import { LatestCommentariesComponent } from './ft-components/marketing/latest-commentaries/latest-commentaries.component';
import { HighchartsThemeService } from '@components/shared/services/highchart/highcharts-theme/highcharts-theme.service';
import { IndHighchartsThemeService } from '@components/shared/services/highchart/ind.highcharts-theme.service';
import { FootnoteServiceToken } from './types/footnote-service.interface';
import { CaveatService } from '@components/products/caveats/services/caveat.service';
import { ProximalServiceToken } from './types/proximal-service.interface';
import { TextContentComponent } from './ft-components/marketing/text-content/text-content.component';
import { BlogListComponent } from './ft-components/marketing/blog-list/blog-list.component';
import { DisplayContentComponent } from '@components/marketing/display-content/display-content.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { UpdateListComponent } from './ft-components/literature/update-list/update-list.component';
import { FundReportsComponent } from './ft-components/literature/fund-reports/fund-reports.component';
import { LiteratureSearchComponent } from './ft-components/literature/literature-search/literature-search.component';
import { IndSimpleDisplayComponent } from './ft-components/marketing/ind-simple-display/ind-simple-display.component';
import { VerticalTabsComponent } from './ft-components/marketing/vertical-tabs/vertical-tabs.component';
import { VerticalTabsTableListComponent } from './ft-components/marketing/vertical-tabs/vertical-tabs-table-list/vertical-tabs-table-list.component';
import { VerticalTabsBannerComponent } from './ft-components/marketing/vertical-tabs/vertical-tabs-banner/vertical-tabs-banner.component';
import { VerticalTabsContactsComponent } from './ft-components/marketing/vertical-tabs/vertical-tabs-contacts/vertical-tabs-contacts.component';
import { MediaArticlesComponent } from './ft-components/marketing/media-articles/media-articles.component';
import { CsrInitiativesComponent } from './ft-components/marketing/csr-initiatives/csr-initiatives.component';
import { SearchComponent } from '@shared/search/search.component';
import { SearchResultsGeneralComponent } from '@shared/search/search-results-general/search-results-general.component';
import { SearchResultsFundsComponent } from '@shared/search/search-results-funds/search-results-funds.component';
import { SearchResultsInvestorComponent } from '@shared/search/search-results-investor/search-results-investor.component';
import { SearchResultsAdvisorComponent } from '@shared/search/search-results-advisor/search-results-advisor.component';
import { SearchResultsLiteratureComponent } from '@shared/search/search-results-literature/search-results-literature.component';
import { MarketInsightsBlogsComponent } from './ft-components/marketing/market-insights-blogs/market-insights-blogs.component';
import { LatestUpdatesComponent } from './ft-components/marketing/latest-updates/latest-updates.component';
import { ReachforbetterWorkComponent } from './ft-components/marketing/reachforbetter-work/reachforbetter-work.component';
import { ReachforbetterarticlePageComponent } from './ft-components/marketing/reachforbetterarticle-page/reachforbetterarticle-page.component';
import { SafePipe } from '@shared/pipes/safe-content.pipe';
import { NpsComponent } from './ft-components/marketing/nps/nps.component';

const initConfig = (
  envConfig: EnvConfigService,
  appStateService: AppStateService
) => {
  return async () => {
    await envConfig.loadEnvConfig();
    appStateService.initializeLogging();
  };
};

// creates a brand specific version of highcharts service, suitable for the current channel
// Will need to update this function if EDS creates more brands in future
const highchartsThemeFactory = (): HighchartsThemeService => {
  return new IndHighchartsThemeService();
};

@NgModule({
  declarations: [
    AppComponent,
    PageContainerComponent,
    ContentComponent,
    RichTextComponent,
    FundCaveatComponent,
    QuickLinkListComponent,
    IndCarouselComponent,
    BreadcrumbComponent,
    AccordionComponent,
    SipCalculatorComponent,
    HorizontalColumnTabComponent,
    LumpsumCalculatorComponent,
    ContactusDisplayGridComponent,
    AddressBookComponent,
    ArticleDetailComponent,
    VideoArticlesComponent,
    ArticleListComponent,
    BeginersGuideComponent,
    LayoutVideoInnerComponent,
    ShareOptionsComponent,
    NewInvestorLoginComponent,
    ExistingInvestorLoginComponent,
    TwoequalcolComponent,
    DocumentPickerComponent,
    SectionTitleComponent,
    FeedbackComponent,
    InterestedComponent,
    DisplayListComponent,
    OnecolComponent,
    TwoColLeftWideComponent,
    TwoColLeftWideCustomComponent,
    CommonComponent,
    FlashMessagesComponent,
    LatestCommentariesComponent,
    TextContentComponent,
    BlogListComponent,
    DisplayContentComponent,
    UpdateListComponent,
    FundReportsComponent,
    LiteratureSearchComponent,
    IndSimpleDisplayComponent,
    MediaArticlesComponent,
    VerticalTabsComponent,
    VerticalTabsTableListComponent,
    VerticalTabsBannerComponent,
    VerticalTabsContactsComponent,
    SearchComponent,
    CsrInitiativesComponent,
    SearchResultsGeneralComponent,
    SearchResultsFundsComponent,
    SearchResultsInvestorComponent,
    SearchResultsAdvisorComponent,
    SearchResultsLiteratureComponent,
    MarketInsightsBlogsComponent,
    LatestUpdatesComponent,
    ReachforbetterWorkComponent,
    ReachforbetterarticlePageComponent,
    SafePipe,
    NpsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrSdkModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgbModule,
    SharedModule,
    NgxBootstrapSliderModule,
    NgbTooltipModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB-jR6_sFZD9SKcJEE0eVg0gtTJTD4K48M',
      libraries: ['places'],
    }),
    AgmDirectionModule,
    NgbRatingModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResourceApiHeadersInterceptor,
      multi: true,
    },
    EnvConfigService,
    AppStateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [EnvConfigService, AppStateService],
    },
    {
      provide: HighchartsThemeService,
      useFactory: highchartsThemeFactory,
      deps: [],
    },
    { provide: FootnoteServiceToken, useExisting: CaveatService },
    { provide: ProximalServiceToken, useExisting: CaveatService },
    CommonService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
