import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Frk defined breakpoints
 *
 * WARNING: these need to be kept in sync with the CSS defined breakpoints in src/scss/abstracts/_variables.scss
 */
export enum FrkBreakpoint {
  MobileMin = '(max-width: 640px)',
  Mobile = '(max-width: 767px)',
  MiniTablet = '(min-width: 768px) and (max-width: 991px)',
  Tablet = '(min-width: 768px) and (max-width: 1023px)',
  LargeTablet = '(min-width: 1024px) and (max-width: 1199px)',
  SmallDesktop = '(min-width: 1200px) and (max-width: 1599px)',
  Desktop = '(min-width: 1600px) and (max-width: 1899px)',
  LargeDesktop = '(min-width: 1900px)',
  Print = '(min-width: 800px)',
}

/**
 * Array containing all defined Frk Breakpoints
 */
export const FrkAllBreakpoints: FrkBreakpoint[] = [
  FrkBreakpoint.MobileMin,
  FrkBreakpoint.Mobile,
  FrkBreakpoint.MiniTablet,
  FrkBreakpoint.Tablet,
  FrkBreakpoint.LargeTablet,
  FrkBreakpoint.SmallDesktop,
  FrkBreakpoint.Desktop,
  FrkBreakpoint.LargeDesktop,
  FrkBreakpoint.Print,
];

/**
 * A service that provides details on the current breakpoint
 */
@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  /**
   * constructor gets injected with the Angular CDK BreakpointObserver
   */
  constructor(private breakpointObserver: BreakpointObserver) {}

  /**
   * Returns the current breakpoint
   */
  public getBreakpoint(): FrkBreakpoint {
    return FrkAllBreakpoints.find((bp: FrkBreakpoint): boolean =>
      this.breakpointObserver.isMatched(bp)
    );
  }

  /**
   * Returns an observable stream that fires every time the breakpoint changes
   */
  public getBreakpointObs$(): Observable<FrkBreakpoint> {
    return this.breakpointObserver
      .observe(FrkAllBreakpoints)
      .pipe(
        map(
          (val: BreakpointState): FrkBreakpoint =>
            FrkAllBreakpoints.find(
              (bp: FrkBreakpoint): boolean => val.breakpoints[bp]
            )
        )
      );
  }

  /**
   * Returns a boolean observable stream that fires when the breakpoint changes to handheld (mobile or tablet)
   */
  public isHandheld$(): Observable<boolean> {
    return this.breakpointObserver.observe(FrkAllBreakpoints).pipe(
      map(
        // return true if current breakpoint is Mobile or Tablet
        (val: BreakpointState): boolean =>
          val.breakpoints[FrkBreakpoint.Mobile] ||
          val.breakpoints[FrkBreakpoint.LargeTablet] ||
          val.breakpoints[FrkBreakpoint.Tablet]
      )
    );
  }

  /**
   * Returns a boolean observable stream that fires when the breakpoint changes to handheld (mobile or mini-tablet)
   */
  public isHandheldMini$(): Observable<boolean> {
    return this.breakpointObserver.observe(FrkAllBreakpoints).pipe(
      map(
        // return true if current breakpoint is Mobile or MiniTablet
        (val: BreakpointState): boolean =>
          val.breakpoints[FrkBreakpoint.Mobile] ||
          val.breakpoints[FrkBreakpoint.MiniTablet]
      )
    );
  }

  /**
   * Returns a boolean observable stream that fires when the breakpoint changes to handheld (mobile min resolution only)
   */
  public isMobileMin$(): Observable<boolean> {
    return this.breakpointObserver.observe(FrkAllBreakpoints).pipe(
      map(
        // return true if current breakpoint is Mobile
        (val: BreakpointState): boolean =>
          val.breakpoints[FrkBreakpoint.MobileMin]
      )
    );
  }

  /**
   * Returns a boolean observable stream that fires when the breakpoint changes to handheld (mobile only)
   */
  public isMobile$(): Observable<boolean> {
    return this.breakpointObserver.observe(FrkAllBreakpoints).pipe(
      map(
        // return true if current breakpoint is Mobile
        (val: BreakpointState): boolean => val.breakpoints[FrkBreakpoint.Mobile]
      )
    );
  }

  /**
   * Returns a boolean observable stream that fires when the breakpoint changes to handheld (tablet only)
   */
  public isTablet$(): Observable<boolean> {
    return this.breakpointObserver.observe(FrkAllBreakpoints).pipe(
      map(
        // return true if current breakpoint is Tablet
        (val: BreakpointState): boolean =>
          val.breakpoints[FrkBreakpoint.LargeTablet] ||
          val.breakpoints[FrkBreakpoint.Tablet]
      )
    );
  }

  /**
   * Print only
   */
  public isPrint$(): Observable<boolean> {
    return this.breakpointObserver.observe(FrkAllBreakpoints).pipe(
      map(
        // return true if current breakpoint is Mobile
        (val: BreakpointState): boolean => val.breakpoints[FrkBreakpoint.Print]
      )
    );
  }
}
