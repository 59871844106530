<div class="row">
  <div class="col-6">
    <h6>{{ "ftiCommon.search-funds" | translate }}</h6>
  </div>
  <div class="col-6 text-right" *ngIf="!isViewAll && totalCount > 0">
    <a class="view-all" (click)="clickEvent($event)"
      >View All {{ totalCount }} results</a
    >
  </div>
  <div class="col-12">
    <ng-container *ngIf="results?.length !== 0; else elseBlock">
      <div class="search-item" *ngFor="let result of results; let i = index">
        <ng-container *ngIf="i < valueToShow">
          <div class="row">
            <div class="col-12 col-sm-2 fund-record-code">
              <div class="search-record-thumb">
                {{ result?.ticker }}
              </div>
            </div>
            <div class="col-12 col-sm-9">
              <h6 class="search-title">
                <a
                  class="article"
                  [routerLink]="result?.fundsOverviewLink"
                  [innerHTML]="result?.fundName"
                >
                </a>
              </h6>
              <div class="search-other-info">
                <span class="asofTitle">AS of {{ result?.navdate }}</span>
                <strong
                  >NAV <span class="icon icon-fti_rupee"></span>
                  <span class="nav-val">{{ result?.navvalue }}</span>
                </strong>
              </div>

              <p class="search-description p-0">
                <a
                  *ngIf="result?.fundsOverviewLink"
                  [routerLink]="result?.fundsOverviewLink"
                  >{{ "products.tab-overview" | translate }}</a
                >&nbsp;|&nbsp;
                <a
                  *ngIf="result?.fundsPortfolioLink"
                  [routerLink]="result?.fundsPortfolioLink"
                  >{{ "products.tab-portfolio" | translate }}</a
                >&nbsp;|&nbsp;
                <a
                  *ngIf="result?.fundsPerformanceLink"
                  [routerLink]="result?.fundsPerformanceLink"
                  >{{ "products.tab-performance" | translate }}</a
                >&nbsp;|&nbsp;
                <a
                  *ngIf="result?.fundsHistoricalNavsLink"
                  [routerLink]="result?.fundsHistoricalNavsLink"
                  >{{ "products.tab-historical-navs" | translate }}</a
                >&nbsp;|&nbsp;
                <a
                  *ngIf="result?.fundsIDCWLink"
                  [routerLink]="result?.fundsIDCWLink"
                  >{{
                    "products.distributions-dividend-history" | translate
                  }}</a
                >
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #elseBlock>
      <p class="search-item">
        {{ "ftiCommon.search-no-results-desc" | translate }}
        "{{ this.queryParams.query }}"
      </p>
    </ng-template>
  </div>
</div>
<div class="row">
  <div
    class="col-xs-12 text-center"
    *ngIf="isViewAll && totalCount > valueToShow"
  >
    <input
      aria-label="loadmorebutton"
      type="button"
      value="Load More"
      class="btn btn-default panel-orange-btn load-more-btn"
      (click)="loadMore($event)"
    />
  </div>
</div>
