<div class="popover-wrp">
  <div class="icon-fti_sip fti-date-picker"></div>
  <div
    class="popover-ui fade in popover copy-to-clipboard"
    #popoverUi
    (click)="$event.stopPropagation()"
  >
    <div class="arrow"></div>
    <div class="fti-section-wrapper" *ngIf="type === 'date'">
      <div class="form-group select-div">
        <select
          class="form-control"
          [(ngModel)]="selectedDate"
          name="selectedDate"
        >
          <option value="{{ date }}" *ngFor="let date of dates">
            {{ date }}
          </option>
        </select>
      </div>
    </div>
    <div class="fti-section-wrapper" *ngIf="type !== 'year'">
      <div class="form-group select-div">
        <select
          class="form-control"
          [(ngModel)]="selectedMonth"
          name="selectedMonth"
        >
          <option value="{{ month }}" *ngFor="let month of months">
            {{ month }}
          </option>
        </select>
      </div>
    </div>
    <div class="fti-section-wrapper">
      <div class="form-group select-div">
        <select
          class="form-control"
          [(ngModel)]="selectedYear"
          name="selectedYear"
        >
          <option value="{{ year }}" *ngFor="let year of years">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
    <button
      class="btn panel-orange-btn"
      type="button"
      (click)="setSelectedDate()"
    >
      {{ "products.date-picker-set" | translate }}
    </button>
    <div class="inception-date" *ngIf="fundInception">
      {{ fundInceptionText }}&nbsp;:&nbsp;<span>{{ fundInception }}</span>
    </div>
  </div>
</div>
