import { Component, Input, OnInit } from '@angular/core';
import {
  Component as BrComponent,
  Content,
  Page,
  Reference,
} from '@bloomreach/spa-sdk';
import { AnalyticsService } from '@services/analytics.service';
import { Logger } from '@utils/logger';
import { OriginalData } from '../../../types/display-list.type';
import moment from 'moment';
import { Constant } from 'src/app/ft-constants/constant';
import { getPageContent, getPageUrl } from '@utils/pagemodel-utils';

const logger = Logger.getLogger('LatestCommentariesComponent');

@Component({
  selector: 'ft-latest-commentaries',
  templateUrl: './latest-commentaries.component.html',
  styleUrls: ['./latest-commentaries.component.scss'],
})
export class LatestCommentariesComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  categories: any = [];
  originalData: OriginalData[] = [];
  headingText = 'Latest Commentaries';
  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.nextPost();
    const location = window.location.href;
    if (location.includes('frankly-speaking-webinars')) {
      this.headingText = 'Frankly Speaking Webinars';
    }
  }
  get document(): Document {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    return document && this.page?.getContent<Document>(document);
  }

  private nextPost(): void {
    this.component = this.page
      ?.getComponent('main-content')
      ?.getComponent('IND-display-content');
    const componentModels: any = this.component?.getModels();
    /* tslint:disable:no-string-literal */
    const elementData: any = getPageContent(
      this.page,
      componentModels?.document
    );
    elementData?.randomSelector?.forEach((childElement: Reference) => {
      const latestData = getPageContent(this.page, childElement);
      const commentaryDate: string = moment
        .unix(latestData?.publishDate / 1000)
        .format(Constant.dateFormat);
      logger.debug('commentaryDate:: contentType', commentaryDate);
      this.originalData.push({
        ref: childElement,
        date: commentaryDate,
        image: latestData?.widenAssetCompound,
        data: latestData,
        video: latestData?.videoArticleInfo,
      });
    });
    /* tslint:enable:no-string-literal */
  }

  getPageUrl(ref: Reference | string) {
    return getPageUrl(this.page, ref);
  }

  public getImageURL(data: string): string {
    if (data) {
      return JSON.parse(data)?.url;
    }
    return '';
  }
  get isPreview(): boolean {
    return this.page?.isPreview();
  }

  /**
   * sets anlytics data on click of tiles of commentaries/videos
   * @param title title of the artcle/video
   */

  public setAnalytics(title: string): void {
    this.analyticsService.trackEvent({
      event: 'Event Market Insights',
      category: 'View video',
      action: 'View video',
      label: 'Latest Commentaries | ' + title,
    });
  }
}
