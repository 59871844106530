<div class="position-relative contactus-display">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-ftindia-document"
        folderTemplateQuery="new-ftindia-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="clear" style="clear: both; height: 0.5em"></div>

      <div class="fti-container" *ngIf="layout == 'Locate Us'">
        <span [innerHtml]="lists[0].address.value"></span>
        <div class="text-addon-common text-addon-phone blue-color-text-addon">
          <div class="row">
            <div class="col-lg-12 content-block new-contact-us-main">
              <div
                class="row mt15 investor-block"
                *ngIf="layout == 'Locate Us'"
              >
                <div class="col-lg-6" *ngFor="let contact of lists">
                  <p
                    class="executive-type mb0 new-contact-us-pb new-contact-us-font"
                  >
                    {{ contact?.staticdropdown }}
                  </p>
                  <span
                    class="phone-number mb0 new-contact-us-pb col-md-6"
                    *ngFor="
                      let mobile of contact.string4;
                      let indexOfelement = index
                    "
                  >
                    <span
                      [ngClass]="
                        indexOfelement === 0 ? 'icon icon-fti_phone' : ''
                      "
                    ></span>
                    <span *ngIf="indexOfelement !== 0"> / </span> {{ mobile }}
                  </span>

                  <p class="consult-time mb0 new-contact-us-pb">
                    {{ contact?.string1 }}
                  </p>
                  <div>
                    <h5 class="m0 new-contact-us-h5">
                      <span class="icon icon-fti_email"></span
                      ><a
                        class="phone-number mb0 new-contact-us-h5-a"
                        href="mailto:{{ contact.string }}"
                      >
                        {{ contact?.string }}
                      </a>
                    </h5>
                  </div>
                  <div class="franklinOnWassup pb10">
                    <img
                      class="wassupLink"
                      src="https://franklintempletonprod.widen.net/content/o2jytydl7z/jpeg/WhatsApp_Icon_Transparent_BG.jpeg"
                      alt="Franklin on Whatsapp"
                      title="Franklin on Whatsapp"
                    />
                    <a
                      href="https://api.whatsapp.com/send/?phone=91{{
                        contact?.whatsappNumber
                      }}&text=Hi&type=phone_number&app_absent=0"
                      class="phone-number mb0 new-contact-us-pb"
                    >
                      {{ contact?.whatsappNumber }}
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="row advisor-block"
                *ngIf="layout == 'Locate Us'"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="layout == 'Cities'">
        <div class="schemeDocsAccordion">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div id="locationDetails">
                <div></div>
                <div
                  *ngFor="let content of lists; let i = index"
                  class="panel panel-default firstAcc acc-details cities-grid Agartala"
                >
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a
                        class="accordion-toggle"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="javascript:void(0)"
                        (click)="triggerModal('testing')"
                      >
                        <span class="acc-content1">{{ content.city }}</span>
                      </a>
                    </h4>
                  </div>
                  <div id="Agartala" class="panel-collapse accordion-content">
                    <div class="panel-body">
                      <span [innerHtml]="content.address.value"> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" *ngIf="layout == 'Media Contacts'">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-lg-5">
            <div class="fti-panel panel-group dropCol-tablet">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title">Media Contacts</h4>
                </div>
                <div>
                  <div class="panel-body pt0 pb0">
                    <div class="fti-contactCard">
                      <div
                        class="contactDetails"
                        *ngFor="let content of lists; let i = index"
                      >
                        <h5>
                          {{ content.contactname
                          }}<span>{{ content.designation }}</span>
                        </h5>
                        <span *ngIf="i == 0" class="contactIcon landline"
                          ><a href="tel:{{ content.string4 }}">{{
                            content.string4
                          }}</a></span
                        >
                        <span *ngIf="i != 0" class="contactIcon tel"
                          ><a href="tel:{{ content.string4 }}">{{
                            content.string4
                          }}</a></span
                        >
                        <span class="contactIcon email"
                          ><a href="mailto:{{ content.string }}">{{
                            content.string
                          }}</a></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" *ngIf="layout == 'Tab'">
        <div class="row">
          <div class="fti-contactCard row mh">
            <div class="col-xs-12 col-md-12 col-lg-12">
              <div
                class="col-lg-6"
                *ngFor="let content of lists; let i = index"
              >
                <div class="contactDetails">
                  <h6>{{ content.contactname }} <br />{{ content.city }}</h6>
                  <span [innerHtml]="content.address.value"></span>
                  <a
                    class="tel"
                    *ngFor="let telephone of content.string4"
                    href="tel: {{ telephone }}"
                    >{{ telephone }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button
  class="btn btn-danger openModal"
  style="display: none"
  (click)="triggerModal(modalData)"
>
  Open Modal
</button>
<ng-template #modalData let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Angular Bootstrap Modal Popup
    </h4>
    <a
      class="fill-circle-close ResetTxtPlaces"
      aria-label="Close"
      (click)="modal.close('Save click')"
      type="button"
      data-dismiss="modal"
      >&nbsp;<span class="icon-fti_close"></span
    ></a>
  </div>
  <div class="modal-body">
    <p>
      <strong
        >Are you sure you want to delete
        <span class="text-primary">"Agatha Harkness"</span> profile?</strong
      >
    </p>

    <p>All data related to west view profile will be removed permanently.</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Save click')"
    >
      Close
    </button>
  </div>
</ng-template>
