<div class="fti-container sip-calc-block" [ngClass]="{ gooemiClass: goodemi }">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <div [ngClass]="{ gooemiClass: goodemi }">
        <div class="row">
          <h1 class="sipCalcHeading">{{ sipTitle }}</h1>
        </div>
        <div class="row sip-sec">
          <div class="col-md-12 col-xl-12 col-lg-12 calcSection">
            <div class="col-12 col-sm-12 loanCalc">
              <div class="col-12 col-md-7 col-lg-8 col-sm-12 sipClcForm">
                <div class="inpt-slider">
                  <div class="radioBtn">
                    <input
                      name="radioGroup"
                      id="sipInvest"
                      type="radio"
                      (change)="hideShowInvest('invest')"
                      checked
                    />
                    <label class="flRight" for="sipInvest">{{
                      labelRadioValue1
                    }}</label>
                    <input
                      name="radioGroup"
                      id="sipGoal"
                      type="radio"
                      (change)="hideShowInvest('goal')"
                    />
                    <label for="sipGoal">{{ labelRadioValue2 }}</label>
                  </div>
                </div>
                <div class="ionSlider newCalc">
                  <div
                    class="inpt-slider canInvest"
                    [ngClass]="{ active: canIntValue }"
                  >
                    <div class="inpt-statement loan_amount">
                      <label class="" for="investedInputAmount">{{
                        labelSlider1
                      }}</label>
                      <input
                        name="mnthsavings"
                        class="inpt inputBox numbersOnly loanAmt"
                        id="investedInputAmount"
                        type="text"
                        maxlength="6"
                        data-minval="5000"
                        data-maxval="500000"
                        (paste)="(false)"
                        [(ngModel)]="investAmount"
                        (blur)="investBlurChange($event)"
                        (keypress)="
                          numberwithDotOnly('investedInputAmount', $event)
                        "
                      />
                    </div>
                    <div>
                      <mv-slider
                        [min]="500"
                        id="wish_to"
                        [max]="500000"
                        [value]="valueinvest"
                        aria-label="investslider"
                        [tooltip]="true"
                        [step]="1"
                        (change)="investSliderChange($event)"
                        style="width: 100%; top: 10px"
                      ></mv-slider>
                    </div>
                    <div class="dragText">
                      <span aria-label="investamountmin">500</span>
                      <span class="pull-right" aria-label="investamountmax"
                        >5L</span
                      >
                    </div>
                  </div>
                  <div
                    class="inpt-slider canGoal"
                    [ngClass]="{ active: canGoalValue }"
                  >
                    <div class="inpt-statement loan_amount">
                      <label class="" for="goalInputAmount">{{
                        labelSlider2
                      }}</label>
                      <input
                        name="mnthsavings"
                        class="inpt inputBox numbersOnly loanAmt"
                        id="goalInputAmount"
                        type="text"
                        maxlength="10"
                        aria-label="goal"
                        data-minval="500000"
                        data-maxval="50000000"
                        (paste)="(false)"
                        [(ngModel)]="goalAmount"
                        (keypress)="
                          numberwithDotOnly('goalInputAmount', $event)
                        "
                        (blur)="investGoalChange($event)"
                      />
                    </div>
                    <div>
                      <mv-slider
                        [min]="500000"
                        id="wish_to"
                        [max]="50000000"
                        [value]="valuegoal"
                        aria-label="goalslider"
                        [tooltip]="true"
                        [step]="1"
                        (change)="investGoalSliderChange($event)"
                        style="width: 100%; top: 10px"
                      ></mv-slider>
                    </div>
                    <div class="dragText">
                      <span aria-label="goalamountmin">5L</span>
                      <span class="pull-right" aria-label="goalamountmax"
                        >5Cr</span
                      >
                    </div>
                  </div>

                  <div class="inpt-slider noOfYears">
                    <div class="inpt-statement loan_amount">
                      <label class="" for="loan_amountInv">
                        {{ labelyears }}
                      </label>
                      <input
                        name="mnthsavings"
                        class="inpt inputBox numbersOnly loanAmt"
                        id="loan_amountInv"
                        type="text"
                        maxlength="30"
                        data-minval="20"
                        data-maxval="30"
                        (paste)="(false)"
                        aria-label="years"
                        [(ngModel)]="yearsValue"
                        (keypress)="numberOnly($event)"
                        (blur)="investYearChange($event)"
                      />
                    </div>
                    <div>
                      <mv-slider
                        [min]="1"
                        id="wish_to"
                        [max]="30"
                        [value]="value"
                        [tooltip]="true"
                        aria-label="yearslider"
                        [step]="1"
                        style="width: 100%; top: 10px"
                        (change)="investSliderYearChange($event)"
                      ></mv-slider>
                    </div>
                    <div class="dragText">
                      <span aria-label="yearamountmin">1Year</span>
                      <span class="pull-right" aria-label="yearamountmax"
                        >30Years</span
                      >
                    </div>
                  </div>

                  <div class="inpt-slider rateReturn">
                    <div class="inpt-statement loan_amount">
                      <label class="" for="loan_amountInterest"
                        >{{ labelReturn }}
                      </label>
                      <input
                        name="mnthsavings"
                        class="inputBox inpt numbersOnly loanAmt"
                        id="loan_amountInterest"
                        type="text"
                        [(ngModel)]="rateOfReturn"
                        aria-label="interest"
                        maxlength="4"
                        data-minval="2"
                        data-maxval="13"
                        (paste)="(false)"
                        (keypress)="
                          numberOnlyPercent('loan_amountInterest', $event)
                        "
                        (blur)="investInterestChange($event)"
                      />
                    </div>
                    <div>
                      <mv-slider
                        [min]="2"
                        id="wish_to"
                        [max]="13"
                        [value]="valueinvestrate"
                        aria-label="interestslider"
                        [tooltip]="true"
                        [step]="1"
                        style="width: 100%; top: 10px"
                        (change)="investSliderInterestChange($event)"
                      ></mv-slider>
                    </div>
                    <div class="dragText">
                      <span aria-label="interestamountmin">2%</span>
                      <span class="pull-right" aria-label="interestamountmax"
                        >13%</span
                      >
                    </div>
                  </div>
                  <div
                    class="popover-pos"
                    [ngClass]="{ popOverActive: popOver, popOverEmi: goodemi }"
                  >
                    <div class="popover-cont">
                      <div class="row pop-row">
                        <small class="close-emi" (click)="closeDisclaimer()">
                          <img
                            class="pull-right"
                            alt="Cose Pop"
                            src="https://franklintempletonprod.widen.net/content/l8ytgbc3rt/jpeg/close-pop.png"
                          />
                        </small>
                      </div>
                      <div class="row text-left">
                        <p
                          class="popover-content"
                          [innerHtml]="calculatorDisclaimer"
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div class="mb vd-mb">
                    <div class="vd" (click)="showDisclaimer()">
                      {{ disclaimerLabel }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5 col-sm-12 totalBox">
                <div class="finalResult finalInvest">
                  <div
                    class="resRow monAmt"
                    [ngClass]="{ monAmtActive: monAmtValue }"
                  >
                    <span>{{ labelMontlySIP }}</span>
                    <strong class="rupeeIconSip">₹</strong>
                    <span id="lblEMIAmt">{{ totalValue }}</span>
                  </div>
                  <div
                    class="resRow sipInv"
                    [ngClass]="{ sipActive: sipIntValue }"
                  >
                    <span>{{ labelInvested }}</span>
                    <strong class="rupeeIconSip">₹</strong
                    ><span class="txtFace" id="InvestedAmt">{{
                      investedAmount
                    }}</span>
                  </div>
                  <div
                    class="resRow sipInv"
                    [ngClass]="{ sipActive: sipIntValue }"
                  >
                    <span>{{ labelResultant }}</span>

                    <strong class="rupeeIconSip">₹</strong
                    ><span class="txtFace" id="sipReturnAmnt">{{
                      totalValue
                    }}</span>
                  </div>
                  <a
                    class="btn btn-default panel-orange-btn sipStartASip"
                    (click)="startASIP()"
                    target="_blank"
                    rel="nofollow"
                    aria-label="Login"
                    >{{ labelSipButton }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Fund list for redirection to performance page -->
        <div class="row sip-sec calcSection fund-returns">
          <div class="col-md-6 col-xs-12 mt-3 heading">
            <span>{{
              "products.sip-calc-check-fund-returns" | translate
            }}</span>
          </div>

          <div class="col-md-6 col-xs-12 mt-3 mb">
            <ft-select
              [dropdownList]="fundList"
              [Placeholder]="'products.sip-calc-select-fund' | translate"
              customClasses="sip-calc-select"
              (dropDownChange)="handleFundRedirect($event)"
            ></ft-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
