<div class="page-layout fund-literature-wrapper">
  <div class="row">
    <div class="col-sm-12">
      <h1 *ngIf="pageCategory !== 'Reports'">
        {{ "literature.downloadPageHeading" | translate }}
      </h1>
      <h1 *ngIf="pageCategory === 'Reports'">
        {{ "literature.reportPageHeading" | translate }}
      </h1>
      <ng-container *ngIf="isLoaded; else showError">
        <ft-literature-search
          [primarySelectedFilter]="downloadPageCategory"
          [secondarySelectedFilter]="selectedSecondaryFilterItem"
          [dropdownItems]="reportCategories"
          [literatureDocuments]="documentName.allDocs"
          [literatureNames]="documentName.docNames"
          (dropDownChanged)="onDropdownChanged($event)"
          (resultsFound)="onResultsFound($event)"
          (deeplinkFilters)="getSelectedDeepLinkedFilter($event)"
          [hasMultipleFilters]="hasMultipleFilters"
          [hasDeepLinkedFilter]="hasDeepLinkedFilter"
        ></ft-literature-search>
        <!-- Results -->
        <div
          *ngIf="showDocumentPanel"
          [class]="'selected-category-' + selectedDropdown"
        >
          <h3 class="headResources">
            <ng-container *ngIf="selectedDropdown === 'ALL'">
              <ng-container *ngIf="selectedPrimaryDropdown">
                {{ "literature." + selectedPrimaryDropdown | translate }}
              </ng-container>
              <ng-container *ngIf="!selectedPrimaryDropdown">
                {{ "literature." + downloadPageCategory | translate }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedDropdown !== 'ALL'">
              {{ "literature." + selectedDropdown | translate }}
            </ng-container>
          </h3>
          <div class="panel-group reports">
            <ft-document-panel
              [fundDocuments]="fundDocuments"
              [errorInService]="errorInService"
              [hasFilters]="true"
              [selectedFilter]="selectedDropdown"
            ></ft-document-panel>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #showError>
  <p>{{ serviceStatus }}</p>
</ng-template>
