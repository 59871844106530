<div class="fti-container mt-4">
  <div class="col-lg-12 content-container">
    <div class="row">
      <div class="col-12">
        <ng-container brComponent="title-content"></ng-container>
      </div>
      <div class="col-12">
        <ng-container brComponent="main-content"></ng-container>
      </div>
      <div class="col-12">
        <ng-container brComponent="lower-content"></ng-container>
      </div>
    </div>
  </div>
</div>
