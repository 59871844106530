<div class="fti-container mt-3">
  <div class="col-12 content-container">
    <div class="row">
      <div class="col-lg-12">
        <ng-container brComponent="title-content"></ng-container>
      </div>
      <div class="col-lg-8 col-md-8 pt-2">
        <ng-container brComponent="left-content"></ng-container>
      </div>
      <div class="col-lg-4 col-md-4 pt-2">
        <ng-container brComponent="right-content"></ng-container>
      </div>
      <div class="col-12">
        <ng-container brComponent="lower-content"></ng-container>
      </div>
    </div>
  </div>
</div>
