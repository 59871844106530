import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import {
  PageConfig,
  PageContainerService,
} from '@pages/services/page-container.service';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Page } from '@bloomreach/spa-sdk';
import { AppStateService } from '@services/app-state.service';
import { DatePipe, Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Renderer2 } from '@angular/core';
import { EnvConfigService } from '@services/env-config.service';
import { loadScript } from '@utils/load-script';
import { TitleService } from '@services/title.service';
import { Constant } from 'src/app/ft-constants/constant';
import { WINDOW } from '@ng-web-apis/common';
import { FundHeaderService } from '@products/overview/services/fund-header.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  page: Page; // FIXME data is a Page not any - why is it called data?!!!!!!
  title = 'Franklin Templeton India';
  initialNavigationComplete = false;
  currentURL = '';

  // Resource Bundle
  locale: any;
  siteName: any;
  chatBot = '';
  chatModule = '';
  chatBotUrl = '';
  chatModuleUrl = '';
  firstHiveUrl = '';
  domainName: string;
  FtiOutagemessage: any;
  FtiOutageMessageCloseValue: any;
  FtiOutageMessageCloseOption: any;
  ftiOutageMessageValue: any;
  showMessage = true;
  channelName: string = Constant.channelName;
  constructor(
    private pageContainerService: PageContainerService,
    private router: Router,
    private titleService: TitleService,
    private fundHeaderService: FundHeaderService,
    private metaService: Meta,
    private appStateService: AppStateService,
    private envConfig: EnvConfigService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private _window: Window,
    private renderer: Renderer2,
    private location: Location,
    private translateService: TranslateService,
    private datepipe: DatePipe,
    private storageService: StorageService
  ) {
    this.domainName = this.appStateService.getftiAccountsUrl();
    this.chatBot = this.envConfig.getEnvConfig()?.ftichatBotUrl;
    this.chatModule = this.envConfig.getEnvConfig()?.ftichatModuleUrl;
    this.chatBotUrl = this.domainName + this.chatBot;
    this.chatModuleUrl = this.domainName + this.chatModule;

    const env = this.envConfig.getEnvConfig();
    // First Hive script now moved to TitleService

    // Chatbot scripts
    // loadScript(this.document.body, {
    //   id: 'jqueryRef',
    //   src: `${env.ftiAccountsUrl}/chatWidget/jquery.min.js`,
    // });
    loadScript(this.document.body, {
      src: `${env?.ftiAccountsUrl}/chatWidget/chatBotWeb.min.js`,
      'data-chat-bot-env': env?.ftiChatBotEnv,
    });
    loadScript(this.document.body, {
      id: 'chatWidgetRef',
      src: `${env?.ftiAccountsUrl}${env?.ftichatModuleUrl}`,
      'data-chat-widget-env': env?.ftiChatBotEnv,
      'data-chat-widget-xOpts': '',
    });
    const currentDate = new Date();
    const latestDate = this.datepipe.transform(currentDate, 'ddMMyyyy');
    loadScript(this.document.body, {
      src: `/assets/js/addthis/communicationInit.js` + '?' + latestDate,
    });

    loadScript(this.document.body, {
      src: `https://www.google.com/recaptcha/api.js?onload=onloadCaptchaCallback&render=explicit`,
    });

    // Router event
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.currentURL = document?.location?.origin + item.url;

        /*
         * Set hreflang
         */
        const linkRel = 'rel';
        const linkHrefLang = 'hreflang';
        const docHeadKey = 'head';
        const arr = Array.from(this.document.head.children);
        let linkElt = arr.find(
          (e) =>
            e[linkRel] === 'alternate' && e[linkHrefLang] === this.channelName
        );
        // If link not exist
        if (!linkElt) {
          linkElt = this.renderer.createElement('link');
          this.renderer.setAttribute(linkElt, 'rel', 'alternate');
          this.renderer.setAttribute(linkElt, 'hreflang', this.channelName);
          this.renderer.setAttribute(linkElt, 'href', this.currentURL);
          this.renderer.appendChild(this.document[docHeadKey], linkElt);
        } else {
          this.renderer.setAttribute(linkElt, 'href', this.currentURL);
        }
      }
    });

    // Page Container
    // FIXME Why is this done here? It should be in the page container service or component!!!!
    this.pageContainerService.page$.subscribe((pageConfig: PageConfig) => {
      this.page = pageConfig?.page;
      this.initialNavigationComplete = true;

      // Get values from resource bundle
      this.locale = this.translateService.instant('ftiMetaData.locale');
      this.siteName = this.translateService.instant('ftiMetaData.siteName');
      this.FtiOutagemessage = this.translateService.instant(
        'ftiCommon.FtiOutagemessage'
      );
      this.FtiOutageMessageCloseValue = this.translateService.instant(
        'ftiCommon.FtiOutageMessageCloseValue'
      );
      this.FtiOutageMessageCloseOption = this.translateService.instant(
        'ftiCommon.FtiOutageMessageCloseOption'
      );
      this.ftiOutageMessageValue = this.translateService.instant(
        'ftiCommon.ftiOutageMessageValue'
      );
      const cookkievalues = document.cookie.indexOf('outage_en_IN=');
      if (cookkievalues === -1) {
        this.showMessage = true;
      } else {
        this.showMessage = false;
      }
      // Page Meta
      const pageData = this.page?.getComponent().getModels()?.pageData;
      const metaTitle = pageData?.metaTitle;
      const PageTitleData = this.page?.getComponent();
      const pageTitle = this.page?.getTitle();
      const metaKeywords = pageData?.metaKeywords;
      const metaDescription = pageData?.metaDescription;

      this.titleService.setTitle(metaTitle);
      this.metaService.updateTag(
        { name: 'keywords', content: metaKeywords ? metaKeywords : '' },
        "name='keywords'"
      );
      this.metaService.updateTag(
        {
          name: 'description',
          content: metaDescription ? metaDescription : '',
        },
        "name='description'"
      );
      this.metaService.updateTag(
        { name: 'canonical', content: this.currentURL ? this.currentURL : '' },
        "name='canonical'"
      );
      this.metaService.updateTag(
        {
          property: 'og:title',
          content: metaTitle ? metaTitle?.substring(0, 48) + '...' : pageTitle,
        },
        "property='og:title'"
      );
      this.metaService.updateTag(
        {
          property: 'og:description',
          content: metaDescription ? metaDescription : '',
        },
        "property='og:description'"
      );
      this.metaService.updateTag(
        {
          property: 'og:image',
          content: '/web-resources/ui/responsive/images/fti_pos.png',
        },
        "property='og:image'"
      );
      this.metaService.updateTag(
        { property: 'og:url', content: this.currentURL ? this.currentURL : '' },
        "property='og:url'"
      );
      this.metaService.updateTag(
        { property: 'og:locale', content: this.locale ? this.locale : '' },
        "property='og:locale'"
      );
      this.metaService.updateTag(
        {
          property: 'og:site_name',
          content: this.siteName ? this.siteName : '',
        },
        "property='og:site_name'"
      );
    });
    this.messageListener(env);
  }
  ngOnInit(): void {
    this.updateAddThis();
  }
  ngAfterViewInit() {
    this.updateAddThis();
  }
  updateAddThis() {
    // tslint:disable-next-line
    const addThis = this._window['addthis'];
    if (addThis) {
      addThis.url = this._window.location.href;
      addThis.title = this._window.document.title;
      addThis.toolbox('.addthis_inline_share_toolbox');
    }

    if (addThis?.layers?.refresh) {
      addThis.layers.refresh();
    }
  }

  private messageListener(env) {
    const that = this;
    this._window.addEventListener('message', (event) => {
      if (event.data === 'ManageLeadForm') {
        that.storageService.setCookieByNameVal(
          'IsNotAProspect',
          'true',
          env.baseCookieVal
        );
        that.storageService.setCookieByNameVal(
          'membertype',
          'Investor_LogIn',
          env.baseCookieVal
        );
      }
    });
  }

  public closeMessage() {
    const cookkievalues = document.cookie.indexOf('outage_en_IN=');
    if (cookkievalues === -1) {
      this.showMessage = false;
      const date = new Date();
      date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      document.cookie = 'outage_en_IN=outage' + '; ' + expires + '; path=/';
    } else {
      this.showMessage = true;
    }
  }
}
