<div class="accordionContent">
  <div class="container position-relative">
    <div class="section-inner-txt">
      <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
        <ng-container
          *ngIf="page?.isPreview()"
          [brManageContentButton]="document"
          documentTemplateQuery="new-ftindia-document"
          folderTemplateQuery="new-ftindia-folder"
          parameter="document"
          [relative]="false"
          root=""
        >
        </ng-container>

        <div *ngIf="document" class="section-wrp">
          <ng-container *ngFor="let item of content; let i = index">
            <ng-container *ngIf="i < 30">
              <div class="panel-accordion accordion-item">
                <h2 class="accordion-header" [id]="'heading' + i">
                  <button
                    class="accordion-button"
                    [ngClass]="{
                      collapsed:
                        currentPath !== item?.title.toLowerCase() &&
                        !(openFirstAccordion && i === 0)
                    }"
                    type="button"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse' + i"
                    aria-expanded="false"
                    [attr.aria-controls]="'collapse' + i"
                  >
                    {{ getAccordionTitle(item?.title, "heading" + i) }}
                  </button>
                </h2>
                <div
                  [id]="'collapse' + i"
                  class="accordion-collapse collapse panel-body"
                  [ngClass]="{
                    show:
                      currentPath === item?.title.toLowerCase() ||
                      hashNavigation === 'heading' + i ||
                      (openFirstAccordion && i === 0)
                  }"
                  [attr.aria-labelledby]="'heading' + i"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    [innerHTML]="item?.content?.content?.value"
                  ></div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="clear" style="clear: both; height: 2em"></div>
        </div>
      </div>
    </div>
  </div>
</div>
