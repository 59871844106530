import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Interface of a Proximal Service that will get injected into the {@link ProximalComponent}
 *
 * Defining this as an interface allows the logic of the proximal service to be defined outside of the EDS project
 */
export interface ProximalService {
  /**
   * This both registers the proximal and gets an Observable of matching proximal content
   */
  getProximals$: (placement: string) => Observable<string[]>;
}

/**
 * Used when configuring a {@link ProximalService} inside a module
 *
 * We need to create an injection token, because we can't inject an interface directly. (Typescript removes them when compiling)
 *
 * see: {@link https://angular.io/guide/dependency-injection-providers#non-class-dependencies}
 *
 * example: `providers: [{provide: ProximalServiceToken, useClass: MyProximalService}]`
 */
export const ProximalServiceToken = new InjectionToken<ProximalService>(
  'proximal.service'
);
