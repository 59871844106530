<div
  class="container-xl p-0 mb-5"
  [ngClass]="{ 'has-edit-button': page?.isPreview() }"
>
  <ng-container
    *ngIf="page?.isPreview()"
    [brManageContentButton]="document"
    documentTemplateQuery="new-ftindia-document"
    folderTemplateQuery="new-ftindia-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>

  <div class="about-us">
    <div class="row">
      <!-- vertical tabs component -->
      <div class="col-3 tabs" *ngIf="(isHandheld$ | async) === false">
        <ul class="tab-list nav flex-column">
          <li *ngFor="let item of tabData" class="tab-list-item">
            <a
              class="tab-list-item-link"
              [ngClass]="{ 'selected-link': item?.selected }"
              href="javascript:void(0)"
              (click)="updateSection(item?.blockId)"
            >
              {{ item?.sectionId }}
            </a>
          </li>
        </ul>
        <img [src]="tabImageUrl" class="tab-image" alt="imageurl" />
      </div>

      <div
        class="col-12 col responsive-dropdown"
        *ngIf="(isHandheld$ | async) === true"
      >
        <div ngbDropdown class="d-inline-block dropdown-container">
          <button
            class="btn btn-outline-primary col-12 dropdown-toggle"
            id="dropdownBasic1"
            ngbDropdownToggle
          >
            <span class="span-label">{{ selectedTab }}</span>
          </button>
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
            class="dropdown-section"
          >
            <button
              ngbDropdownItem
              *ngFor="let item of tabData"
              (click)="updateSection(item?.blockId)"
            >
              {{ item?.sectionId }}
            </button>
          </div>
        </div>
      </div>

      <!-- block display content -->
      <div
        class="col-lg-9 col-md-9 col-sm-12 col-xs-12 tabs-content"
        [ngClass]="{ 'extra-padding': (isHandheld$ | async) === true }"
      >
        <div
          class="tabs-content-item"
          *ngFor="let item of displayedSection?.block"
        >
          <!-- paragraph block -->
          <div *ngIf="item?.blockType === 'intro'" class="para-block">
            <div [innerHtml]="item?.content"></div>
          </div>

          <!-- image block -->
          <div
            *ngIf="item?.blockType === 'image' && item?.imageUrl"
            class="image-block"
          >
            <img [src]="item?.imageUrl" alt="imageurl" />
          </div>

          <!-- table block -->
          <div *ngIf="item?.blockType === 'table'" class="table-block">
            <ft-vertical-tabs-table-list
              [title]="item?.tableContent?.title"
              [borderColor]="item?.tableContent?.borderColor"
              [tableData]="item?.tableContent?.tableData"
            >
            </ft-vertical-tabs-table-list>
          </div>

          <!-- banner block -->
          <div *ngIf="item?.blockType === 'banner'">
            <ft-vertical-tabs-banner [bannerData]="item?.bannerContent">
            </ft-vertical-tabs-banner>
          </div>

          <!-- accordion block -->
          <div *ngIf="item?.blockType === 'accordion'">
            <ngb-accordion
              [closeOthers]="true"
              class="global-contact-accordion custom-padding-accordion"
            >
              <ngb-panel
                *ngFor="let item of item?.accordionContent; let i = index"
                id="panel-{{ i }}"
                class="accordion-panel"
              >
                <ng-template ngbPanelTitle class="panel-title">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex accordion-header">
                      {{ item[0]?.country }}
                    </div>
                    <div class="d-flex">
                      <i class="icon-fti_rightArrow pull-right"></i>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent class="panel-content">
                  <ft-vertical-tabs-contacts
                    [contactInfo]="item"
                    [accordionContent]="true"
                  ></ft-vertical-tabs-contacts>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>

          <!-- India offices block -->
          <div *ngIf="item?.blockType === 'indiaOffices'">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <ft-vertical-tabs-contacts
                  [contactInfo]="item?.accordionContent[0]"
                  [paddingNone]="true"
                ></ft-vertical-tabs-contacts>
              </div>

              <div
                class="col-lg-6 col-md-12 col-sm-12 col-xs-12 extra-padding-top"
              >
                <ft-vertical-tabs-contacts
                  [contactInfo]="item?.accordionContent[1]"
                  [paddingNone]="true"
                ></ft-vertical-tabs-contacts>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <ft-vertical-tabs-contacts
                  [contactInfo]="item?.accordionContent[2]"
                ></ft-vertical-tabs-contacts>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <ft-vertical-tabs-contacts
                  [contactInfo]="item?.accordionContent[3]"
                ></ft-vertical-tabs-contacts>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <ft-vertical-tabs-contacts
                  [contactInfo]="item?.accordionContent[4]"
                ></ft-vertical-tabs-contacts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
