export type City = {
  id: string;
  state_id: string;
  name: string;
  slug?: string;
};

// FIXME why do we need the class?
export class Cities {
  public static city: City[] = Array(
    {
      id: 'all-cities',
      'data-id': '',
      state_id: 'all-states',
      name: 'All Cities',
    },
    { id: 'Agra', 'data-id': '0', state_id: 'Uttarpradesh', name: 'Agra' },
    { id: 'Ahmedabad', 'data-id': '0', state_id: 'Gujarat', name: 'Ahmedabad' },
    {
      id: 'Ahmednagar',
      'data-id': '0',
      state_id: 'Maharashtra',
      name: 'Ahmednagar',
    },
    { id: 'Ajmer', 'data-id': '0', state_id: 'Rajastan', name: 'Ajmer' },
    { id: 'Akola', 'data-id': '1', state_id: 'Maharashtra', name: 'Akola' },
    {
      id: 'Aligarh',
      'data-id': '1',
      state_id: 'Uttarpradesh',
      name: 'Aligarh',
    },
    {
      id: 'Allahabad',
      'data-id': '2',
      state_id: 'Uttarpradesh',
      name: 'Allahabad',
    },
    {
      id: 'Anantapur',
      'data-id': '0',
      state_id: 'AndhraPradesh',
      name: 'Anantapur',
    },
    { id: 'Guntur', 'data-id': '1', state_id: 'AndhraPradesh', name: 'Guntur' },
    {
      id: 'Hyderabad',
      'data-id': '2',
      state_id: 'AndhraPradesh',
      name: 'Hyderabad',
    },
    { id: 'Kadapa', 'data-id': '3', state_id: 'AndhraPradesh', name: 'Kadapa' },
    {
      id: 'Kakinada',
      'data-id': '4',
      state_id: 'AndhraPradesh',
      name: 'Kakinada',
    },
    {
      id: 'Karimnagar',
      'data-id': '5',
      state_id: 'AndhraPradesh',
      name: 'Karimnagar',
    },
    {
      id: 'Kurnool',
      'data-id': '6',
      state_id: 'AndhraPradesh',
      name: 'Kurnool',
    },
    {
      id: 'Nellore',
      'data-id': '7',
      state_id: 'AndhraPradesh',
      name: 'Nellore',
    },
    {
      id: 'Rajahmundry',
      'data-id': '8',
      state_id: 'AndhraPradesh',
      name: 'Rajahmundry',
    },
    {
      id: 'Secunderabad',
      'data-id': '9',
      state_id: 'AndhraPradesh',
      name: 'Secunderabad',
    },
    {
      id: 'Srikakulam',
      'data-id': '10',
      state_id: 'AndhraPradesh',
      name: 'Srikakulam',
    },
    {
      id: 'Tirupathi',
      'data-id': '11',
      state_id: 'AndhraPradesh',
      name: 'Tirupathi',
    },
    {
      id: 'Vijayawada',
      'data-id': '12',
      state_id: 'AndhraPradesh',
      name: 'Vijayawada',
    },
    {
      id: 'Vijaynagaram',
      'data-id': '13',
      state_id: 'AndhraPradesh',
      name: 'Vijaynagaram',
    },
    {
      id: 'Visakhapatnam',
      'data-id': '14',
      state_id: 'AndhraPradesh',
      name: 'Visakhapatnam',
    },
    {
      id: 'Warangal',
      'data-id': '15',
      state_id: 'AndhraPradesh',
      name: 'Warangal',
    },
    { id: 'Chennai', 'data-id': '0', state_id: 'TamilNadu', name: 'Chennai' },
    {
      id: 'Coimbatore',
      'data-id': '1',
      state_id: 'TamilNadu',
      name: 'Coimbatore',
    },
    {
      id: 'Dharmapuri',
      'data-id': '2',
      state_id: 'TamilNadu',
      name: 'Dharmapuri',
    },
    { id: 'Erode', 'data-id': '3', state_id: 'TamilNadu', name: 'Erode' },
    { id: 'Hosur', 'data-id': '4', state_id: 'TamilNadu', name: 'Hosur' },
    { id: 'Karur', 'data-id': '5', state_id: 'TamilNadu', name: 'Karur' },
    {
      id: 'Kumbakonam',
      'data-id': '6',
      state_id: 'TamilNadu',
      name: 'Kumbakonam',
    },
    { id: 'Madurai', 'data-id': '7', state_id: 'TamilNadu', name: 'Madurai' },
    { id: 'Namakkal', 'data-id': '8', state_id: 'TamilNadu', name: 'Namakkal' },
    {
      id: 'Pondicherry',
      'data-id': '9',
      state_id: 'TamilNadu',
      name: 'Pondicherry',
    },
    {
      id: 'Rajapalayam',
      'data-id': '10',
      state_id: 'TamilNadu',
      name: 'Rajapalayam',
    },
    { id: 'Salem', 'data-id': '11', state_id: 'TamilNadu', name: 'Salem' },
    {
      id: 'Tirunelveli',
      'data-id': '12',
      state_id: 'TamilNadu',
      name: 'Tirunelveli',
    },
    { id: 'Tirupur', 'data-id': '13', state_id: 'TamilNadu', name: 'Tirupur' },
    { id: 'Trichy', 'data-id': '14', state_id: 'TamilNadu', name: 'Trichy' },
    {
      id: 'Tuticorin',
      'data-id': '15',
      state_id: 'TamilNadu',
      name: 'Tuticorin',
    },
    { id: 'Vellore', 'data-id': '16', state_id: 'TamilNadu', name: 'Vellore' }
  );
}
