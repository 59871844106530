import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('NgModalComponent');

@Component({
  selector: 'ft-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent {
  // Modal parameters
  @Input() hasCloseButton = true;
  @Input() hasAlertIcon = false;
  @Input() modalHeaderText;
  @Input() modalBodyText;
  @Input() primaryButtonText;
  @Input() secondaryButtonText;
  @Input() primaryButtonAction;

  // Click events outputs
  @Output() modalDisplayed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  primaryButtonEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output()
  secondaryButtonEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor(private ngModalService: NgbModal) {
    // No empty constructor
  }

  /**
   * Modal close event
   */
  public closeModal() {
    logger.debug('Close modal');
    this.modalDisplayed.emit(false);
    this.ngModalService.dismissAll();
  }

  /**
   * Primary button click event
   */
  public buttonPrimaryClick(event: MouseEvent) {
    logger.debug('Primary button click');
    this.primaryButtonEvent.emit(event);
  }

  /**
   * Secondary button click event
   */
  public buttonSecondaryClick(event: MouseEvent) {
    logger.debug('Secondary button click');
    this.secondaryButtonEvent.emit(event);
  }
}
