import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import {
  FtSearchResults,
  FundDataItem,
  FundItem,
} from '@components/shared/search/search.interface';
import { TabName } from '@types';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ft-auto-suggestions-list',
  templateUrl: './auto-suggestions-list.component.html',
  styleUrls: ['./auto-suggestions-list.component.scss'],
})
export class AutoSuggestionsListComponent implements OnChanges {
  @Input() searchText: string;
  @Input() triggerChange: boolean;
  fundItems: FundItem[];

  constructor(
    private ftSearchservice: FtsearchService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private sanitized: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.triggerChange) {
      this.ftSearchservice
        .getAutoCompleteResults(this.searchText, 0, 3)
        .subscribe((data: any) => {
          const responseItems = data?.results.filter((item) => {
            return item.name === 'funds';
          })[0].response?.hits?.hits;
          const fundItems = responseItems.map((item) => {
            const fundDataSource: FundDataItem =
              item.inner_hits.shareClassesMatched.hits.hits[0]._source;
            const fundData = {
              fundId: fundDataSource.fundid,
              fundName: fundDataSource.title,
            };
            return {
              title: this.sanitized.bypassSecurityTrustHtml(
                this.getFundName(item, fundDataSource)
              ),
              titleLink: this.ftSearchservice.getFundsTabLink(
                fundData,
                TabName.OVERVIEW
              ),
            };
          });
          this.fundItems = fundItems;
          this.cdr.detectChanges();
        });
    }
  }

  getFundName(item, fundDataSource) {
    return item.highlight && item.highlight['title.ngrams']
      ? item.highlight['title.ngrams'][0]
      : fundDataSource.title;
  }

  viewAllFunds() {
    this.router.navigate(['/search'], {
      queryParams: { query: this.searchText, filter: 'funds' },
    });
  }

  viewAllGeneral() {
    this.router.navigate(['/search'], {
      queryParams: { query: this.searchText, filter: 'pages' },
    });
  }
}
