import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy,
  Inject,
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Location } from '@angular/common';
import { Constant } from '../../../ft-constants/constant';
import get from 'lodash/get';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from '@services/session.service';
import { elementAt, takeUntil } from 'rxjs/operators';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Logger } from '@utils/logger';
import { WindowService, WindowSize } from '@services/window.service';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

const logger = Logger.getLogger('RichTextComponent');
@Component({
  selector: 'ft-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichTextComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  isNewToInvesting: boolean;
  onlineServices: boolean;
  waysToTransactWithUs: boolean;
  aboutus: boolean;
  screenHeight: number;
  screenWidth: number;
  name = '';
  email = '';
  city = '';
  phone = '';
  url = '';
  otp: string;
  distributerFormData = {
    refNo: '',
    mobileNo: '',
    emailId: '',
    guId: '',
  };
  private unsubscribe$: Subject<void> = new Subject<void>();

  currentPathValue: any;
  constructor(
    @Inject(DOCUMENT) private docu: Document,
    private location: Location,
    private sanitizeHtml: DomSanitizer,
    private http: HttpClient,
    private sessionService: SessionService,
    private windowService: WindowService
  ) {
    this.getScreenSize();
  }
  ngOnInit(): void {
    const currentPath = this.location.path().split('/');
    const pathFragment = currentPath.reverse()[0];
    this.currentPathValue = pathFragment;
    this.isNewToInvesting =
      pathFragment === Constant.isNewToInvesting ? true : false;
    this.onlineServices = pathFragment === 'online-services' ? true : false;
    this.waysToTransactWithUs =
      pathFragment === 'ways-to-transact-with-us' ? true : false;
    this.aboutus = this.location.path().indexOf('about-us/') > 0 ? true : false;
  }

  ngOnChanges(): void {}

  get document() {
    const { document } = this.component?.getModels() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  ngAfterViewInit() {
    this.fundCalculatorListener();
    this.continueMutualFundCalculatorForm();
    this.submitButton();
    this.emailUsPopup();

    // Track Window Resize (Throttled)
    this.windowService
      .getResizeObs$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((windowSize: WindowSize) => {
        this.screenHeight = windowSize.height;
        this.screenWidth = windowSize.width;
        logger.debug(this.screenHeight, this.screenWidth);
      });
  }

  fundCalculatorListener() {
    const nameElement = document.querySelector('#fleadgen_Name');
    const phoneElement = document.querySelector('#fleadgenPhoneNumber');
    const EmailElement = document.querySelector('#fleadgen_Email');
    const cityElement = document.querySelector('#fleadgenCityName');
    nameElement?.addEventListener('change', () => {
      this.name = (document.getElementById(
        'fleadgen_Name'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    phoneElement?.addEventListener('change', () => {
      this.phone = (document.getElementById(
        'fleadgenPhoneNumber'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    EmailElement?.addEventListener('change', () => {
      this.email = this.phone = (document.getElementById(
        'fleadgen_Email'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    cityElement?.addEventListener('change', () => {
      this.city = this.phone = (document.getElementById(
        'fleadgenCityName'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
  }

  isEnableFormSubmission() {
    if (this.name && this.phone && this.email && this.city) {
      (document.getElementById(
        'fleadgen_Continue'
      ) as HTMLInputElement).disabled = false;
      // (<HTMLInputElement>(
      //   document.getElementById('fleadgen_Continue')
      // )).style.backgroundColor = 'rgb(20, 60, 111)';

      (document.getElementById(
        'fleadgen_Continue'
      ) as HTMLInputElement).style.cursor = 'pointer';

      const advSuccessblockElement = document.getElementById(
        'fleadgen_Continue'
      );
      advSuccessblockElement[0].classList.remove('flead-new-continue-btn');
      advSuccessblockElement[0].classList.add('panel-orange-btn');

      (document.getElementById(
        'fleadgen_Submit'
      ) as HTMLInputElement).style.backgroundColor = 'rgb(20, 60, 111)';
    } else {
      (document.getElementById(
        'fleadgen_Continue'
      ) as HTMLInputElement).disabled = true;
    }
  }

  continueMutualFundCalculatorForm() {
    const continueButton = document.querySelector('#fleadgen_Continue');
    continueButton?.addEventListener('click', () => {
      const fleadgenName = (document.getElementById(
        'fleadgen_Name'
      ) as HTMLInputElement).value;

      const fleadgenPhoneNumber = (document.getElementById(
        'fleadgenPhoneNumber'
      ) as HTMLInputElement).value;

      const fleadgenEmail = (document.getElementById(
        'fleadgen_Email'
      ) as HTMLInputElement).value;

      const fleadgenCityName = (document.getElementById(
        'fleadgenCityName'
      ) as HTMLInputElement).value;

      const isValidMobile = this.isValidMobile(fleadgenPhoneNumber);
      const isValidEmail = this.isValidEmail(fleadgenEmail);

      if (isValidMobile) {
        if (isValidEmail) {
          this.distributerFormData = {
            refNo: '',
            mobileNo: fleadgenPhoneNumber,
            emailId: fleadgenEmail,
            guId: '',
          };
          this.sendOtp();
          // Call Api
        } else {
          document.getElementsByClassName('fleadgen_Email-error')[0].innerHTML =
            'The Format of the Email address is invalid.';
        }
      } else {
        document.getElementsByClassName(
          'fleadgenPhoneNumber-error'
        )[0].innerHTML =
          'Phone Number should be numeric and can contain 10 or max 12 digits in length.';
      }
    });
  }

  submitButton() {
    const submitBtn = document.querySelector('#fleadgen_Submit');
    const otpElement = document.querySelector('#fleadgen_OTP');
    otpElement?.addEventListener('change', () => {
      this.otp = (document.getElementById(
        'fleadgen_OTP'
      ) as HTMLInputElement).value;

      submitBtn?.addEventListener('click', () => {
        const data = {
          OTP: this.otp,
          guId: '',
        };
        this.sessionService.validateFeederOTP$(data).subscribe(
          (response) => {
            if (response) {
              this.fundCalculatorFormData();
            }
          },
          (error) => {
            if (error && error.error[0]) {
              document.getElementsByClassName(
                'fleadgen-resend-otp'
              )[0].innerHTML = error.error[0].errorDescription;
            }
          }
        );
      });
    });
  }

  isValidMobile(mobile) {
    if (mobile.length === 10 || mobile.length === 12) {
      return true;
    } else {
      return false;
    }
  }

  isValidEmail(email: string) {
    return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
      email
    );
  }

  handleOtpTimer() {
    let timeleft = 1;
    const self = this;
    const resendOtpElement = document.getElementsByClassName(
      'flead-adv-resend-otp'
    );
    function resendClickHandler() {
      self.sendOtp();
    }
    const otpTimer = setInterval(() => {
      if (timeleft === 30) {
        clearInterval(otpTimer);
        document.getElementsByClassName('adv-otp-timer')[0].innerHTML = '';
        resendOtpElement[0].classList.add('flead-adv-clk-enabled');
        resendOtpElement[0]?.addEventListener('click', resendClickHandler);
        (document.getElementById(
          'adv-otp-timer'
        ) as HTMLInputElement).style.display = 'none';
      } else {
        (document.getElementById(
          'adv-otp-timer'
        ) as HTMLInputElement).style.display = 'inline';

        resendOtpElement[0].classList.remove('flead-adv-clk-enabled');
        resendOtpElement[0].removeEventListener('click', resendClickHandler);
        document.getElementsByClassName(
          'adv-otp-timer'
        )[0].innerHTML = `(Active in 00:${30 - timeleft})`;
      }
      timeleft += 1;
    }, 1000);
  }

  sendOtp() {
    this.sessionService.generateOtp$(this.distributerFormData).subscribe(
      (response) => {
        if (response && response.body.data.status === 'SUCCESSOTP') {
          document.getElementsByClassName('fleadgen_Email-error')[0].innerHTML =
            '';
          document.getElementById('fleadgen_OTP').focus();
          const hideOTPSuccessElement = Array.from(
            document.getElementsByClassName('fleadGenhideOTPSuccess')
          );
          hideOTPSuccessElement.forEach((element: any) => {
            element.style.display = 'none';
          });
          const hideElement = Array.from(
            document.getElementsByClassName('hide')
          );
          hideElement.forEach((element: any) => {
            element.style.display = 'block';
          });
          const advSuccessblockElement = Array.from(
            document.getElementsByClassName('fleadgen_adv-success-blk')
          );
          advSuccessblockElement.forEach((element: any) => {
            element.style.display = 'none';
          });
          document.getElementsByClassName('fleadgen-otp-message')[0].innerHTML =
            response.body.data.otpMessage;
          this.handleOtpTimer();
        }
      },
      (error) => {
        if (error && error.error[0]) {
          let errorMessage = '';
          if (error.status === 500) {
            errorMessage = error.error[0].errorDescription;
          } else {
            errorMessage = 'Service Unavaialble. Please Try Again Later...';
          }
          document.getElementsByClassName(
            'fleadgen-otp-message'
          )[0].innerHTML = errorMessage;
          const advisorOtpMessageElement = Array.from(
            document.getElementsByClassName('fleadgen-otp-message')
          );
          advisorOtpMessageElement.forEach((element: any) => {
            element.style.color = 'red';
          });
        }
      }
    );
  }

  fundCalculatorFormData() {
    const UTMParams = {};
    const utmCampaign = 'utm_campaign';
    const utmSource = 'utm_source';
    const utmMedium = 'utm_medium';
    const payload = {
      name: this.name,
      mobile: this.phone,
      emailId: this.email,
      city: this.city,
      refNo: '',
      guid: '',
      campaignType: UTMParams[utmCampaign] || '',
      source: UTMParams[utmSource] || 'marketing-leads-form',
      medium: UTMParams[utmMedium] || '',
    };

    this.sessionService.postDistributeForm$(payload).subscribe(
      (response) => {
        if (response) {
          document.getElementsByClassName('fleadgen-otp-message')[0].innerHTML =
            '';

          const hideOTPSuccessElement = Array.from(
            document.getElementsByClassName('fleadGenhideOTPSuccess')
          );

          hideOTPSuccessElement.forEach((element: any) => {
            element.style.display = 'none';
          });
          const hideElement = Array.from(
            document.getElementsByClassName('hide')
          );
          hideElement.forEach((element: any) => {
            element.style.display = 'none';
          });
          const advSuccessBlk = document.querySelector<HTMLElement>(
            '.fleadgen_adv-success-blk'
          );
          advSuccessBlk.style.display = 'block';

          document.getElementsByClassName('success-text')[0].innerHTML =
            'Thank you! Your request has been registered. Our team will reach out to you soon.';
        }
      },
      (error) => {
        if (error && error.error[0]) {
          document.getElementsByClassName('fleadgen_OTP-error')[0].innerHTML =
            error.error[0].errorDescription;
        }
      }
    );
  }
  get item() {
    logger.debug('rich text data:::', this.document?.getData()?.contentBlocks);
    return this.document?.getData()?.contentBlocks;
  }

  get isPreview() {
    return this.page.isPreview();
  }
  getWiden(item) {
    logger.debug('item::::', item);
    let content = item?.widenAssetCompound?.widenDocument;
    // let content = get(item?.widenImageBlock, 'image.widenAsset');
    logger.debug('content::::', content);
    let source = get(item?.widenImageBlock?.image, 'mobileImage.widenDocument');
    logger.debug('source::::', source);
    if (source && this.screenWidth < 768) {
      source = JSON.parse(source);
      return source?.url;
    }
    if (content) {
      content = JSON.parse(content);
      return content?.url;
    }
    return;
  }
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    logger.debug(this.screenHeight, this.screenWidth);
  }

  htmlCode(data) {
    return this.sanitizeHtml.bypassSecurityTrustHtml(data);
  }

  emailUsPopup() {
    const communicationsIcons = document.querySelector('#communications-icons');
    if (!communicationsIcons) {
      logger.debug(
        'Communication wizard error ::: Communication wizard not found. Check whether communication widget is visible on site'
      );
    } else {
      const attributes = {
        'data-target': '#emailUsPopup',
        'data-bs-target': '#emailUsPopup',
        'data-toggle': 'modal',
        'data-bs-toggle': 'modal',
      };

      const eleEmailUsPopup = document.querySelector('#emailUsPopup');
      if (eleEmailUsPopup) {
        this.setEleAttributes(eleEmailUsPopup, attributes);
        eleEmailUsPopup.removeAttribute('id');
      }
    }
  }

  setEleAttributes(element, attributes) {
    Object.keys(attributes).forEach((attr) => {
      element.setAttribute(attr, attributes[attr]);
    });
  }

  /**
   * On component destroyed
   * Removes any remaining subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
