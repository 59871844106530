<div class="col-sm-12 col-md-6 fti-listGroup-wrapper ftl">
  <section class="fti-listGroup" data-fti-component="Updates/Blog">
    <h2>{{ "literature.updatesHeading" | translate }}</h2>
    <ul class="list-group">
      <span *ngIf="!updateList">{{
        "literature.statusMessage" | translate
      }}</span>
      <li
        class="list-group-item"
        *ngFor="let update of updateList?.document; let i = index"
      >
        <small></small>
        <h5 class="list-group-item-heading">
          <a
            class="analytics-link"
            [href]="'download' + update.literatureHref"
            target="_blank"
            data-event-name="Event Homepage"
            data-category="Homepage"
            data-action="Updates"
            [attr.data-label]="update.dctermsTitle"
            >{{ update.dctermsTitle }}</a
          >
        </h5>
      </li>
    </ul>
    <a
      class="btn btn-default panel-orange-btn analytics-link btn-view-more"
      [href]="'literature.updatesViewAllLink' | translate"
      target="_self"
      data-event-name="Event Homepage"
      data-category="Homepage"
      data-action="Updates"
      [attr.data-label]="'literature.updatesCTAText' | translate"
    >
      {{ "literature.updatesCTAText" | translate }}
    </a>
  </section>
  <div style="clear: both; height: 5em"></div>
</div>
