import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  Provider,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownItem } from '@types';

const SELECT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true,
};

const noop = () => {};

@Component({
  selector: 'ft-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [SELECT_CONTROL_VALUE_ACCESSOR],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  @Input() dropdownList: DropdownItem[];
  @Input() Placeholder: string;
  @Input() customClasses = '';
  @Input() defaultValue: DropdownItem;

  @Output()
  public dropDownChange: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  selected!: string;
  labelText = '';
  disabled = false; // Todo: disable functionlity needs to cover later
  isOpen = false;
  @ViewChild('ftSelect')
  ftSelect: ElementRef;

  onChanged: (_value: string) => void = noop;
  onTouched: () => void = noop;

  constructor(private changeDetRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.defaultValue) {
      this.SelectList(this.defaultValue);
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.ftSelect.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouched();
  }

  SelectList(selectedList: DropdownItem, onSelect?: boolean): void {
    if (selectedList?.disabled) {
      return;
    }
    this.selected = selectedList.value;
    this.isOpen = false;
    this.writeValue(selectedList.value);
    if (this.onChanged) {
      this.onChanged(selectedList.value); // <-- call function to let know of a change
    }

    if (onSelect) {
      selectedList.selected = true;
      this.dropDownChange.emit(selectedList);
    }
  }

  getselectedText(_selectList: DropdownItem[], selectedValue: string): void {
    let labelText: string;
    if (selectedValue) {
      labelText = _selectList?.find((item) => item.value === selectedValue)
        ?.label;
    }

    if (!labelText && _selectList?.length > 0) {
      labelText = _selectList[0]?.label;
    }

    this.labelText = labelText;
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  writeValue(value: string): void {
    this.selected = value ?? '';
    this.getselectedText(this.dropdownList, this.selected);
    this.changeDetRef.detectChanges(); // TODO: See a better approach for default value selected
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn; // <-- save the function
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn; // <-- save the function
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
