import {
  HighchartsThemeService,
  HighchartThemeFonts,
} from './highcharts-theme/highcharts-theme.service';

export class IndHighchartsThemeService extends HighchartsThemeService {
  public mapColors: string[] = [
    '#005598',
    '#81BB00',
    '#BAD583',
    '#00A0DC',
    '#A5D7F5',
    '#767676',
    '#A7A7A7',
    '#E8E8E8',
    '#ED7700',
    '#F59B00',
    '#F7BC00',
  ];
  protected themeColors: string[] = [
    '#005598',
    '#4e9d2d',
    '#00a0dc',
    '#333',
    '#ed7700',
    '#8a1811',
    '#163758',
    'rgb(154,212,25)',
    '#5fc0eb',
    '#767676',
    '#F7BC00',
    '#F6DC6B',
  ];
  protected themeColorsShort: string[] = [
    '#005598',
    '#4E9D2D',
    '#00A0DC',
    '#333',
    '#ed7700',
    '#8a1811',
  ];

  public benchmarkColor = '#E8E8E8';

  public greyScaleColors: string[] = ['#767676', '#A7A7A7', '#E8E8E8'];

  public monochromaticColors: string[] = ['#00A0DC', '#A5D7F5', '#DBEFFB'];

  public lineGraphThemeColors: string[] = [
    '#888888',
    '#F79A00',
    '#5A9BD3',
    '#00ffff',
  ];

  // replaces FrkFont
  public themeFonts: HighchartThemeFonts = {
    colors: {
      grey: '#f79a00',
      orange: '#f59b00',
    },
    size: {
      p: '12px',
      small: '11px',
    },
    fontFamily: 'Trade Gothic LT W05 Bold No-_2, arial bold, sans-serif',
    fontFamilyNormal: 'Trade Gothic LT W05 Roman, arial, sans-serif',
    fontFamilyVerdana: 'normal 14px Verdana, sans-serif',
  };
}
