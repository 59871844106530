<div class="articleDetail">
  <div class="fti-container position-relative">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-ftindia-document"
        folderTemplateQuery="new-ftindia-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="articleDetails" *ngIf="content?.staticdropdown === 'Article'">
        <div class="row">
          <div class="col-sm-12 col-md-7">
            <p class="go-back">
              <a
                aria-label="Back"
                class="icon icon-fti_leftArrow float-start"
                [routerLink]="getBackLinkUrl(this.backLink)"
              >
              </a
              >{{ content?.backLink }}
            </p>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-5">
            <div class="social-share mt-3">
              <ft-social-media-icons
                [currentPageUrl]="currentPageUrl"
                [textTitle]="textTitle"
              ></ft-social-media-icons>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-7">
            <h1 class="page-title">{{ content?.title }}</h1>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="float-end chapter-dropdown">
              <div ngbDropdown class="d-inline-block w-100" *ngIf="showChapter">
                <button
                  class="btn btn-outline-primary"
                  id="dropdownBasic1"
                  ngbDropdownToggle
                >
                  {{ getSelectedChapter(selectedChapter, content) }}
                </button>
                <div
                  ngbDropdownMenu
                  aria-labelledby="dropdownBasic1"
                  class="dropdownSection"
                >
                  <ng-container
                    *ngFor="let data of articleChapters; index as i"
                  >
                    <ng-container *ngIf="isChapterOne">
                      <ng-container *ngIf="i > 0">
                        <button
                          ngbDropdownItem
                          (click)="onChange(getPageUrl(data.articleData))"
                        >
                          <a>{{ chapterLabel }} {{ i + 1 }}</a>
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!isChapterOne">
                      <button
                        ngbDropdownItem
                        (click)="onChange(getPageUrl(data.articleData))"
                      >
                        <a>{{ chapterLabel }} {{ i + 1 }}</a>
                      </button>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container
          *ngIf="content?.indwidenImage?.bannerImage?.widenDocument"
        >
          <div class="row">
            <div class="col-sm-12">
              <img
                src="{{
                  getImageURL(
                    content?.indwidenImage?.bannerImage?.widenDocument
                  )
                }}"
                alt="Image"
              />
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="!content?.indwidenImage?.bannerImage?.widenDocument"
        >
          <div class="row">
            <div class="col-sm-12">
              <img src="{{ defaultArticleBannerImage }}" alt="Image" />
            </div>
          </div>
        </ng-container>

        <div class="row content-section">
          <div class="col-sm-12">
            <ng-container *ngFor="let item of content?.contentBlocks">
              <h2 *ngIf="item?.title">{{ item?.title }}</h2>
              <div
                *ngIf="item?.content?.content?.value"
                [innerHtml]="htmlCode(item?.content?.content?.value)"
              ></div>
              <div
                class="{{ item?.imagePosition }}"
                *ngIf="item?.widenAssetCompound?.widenDocument"
              >
                <img
                  src="{{
                    getImageURL(item?.widenAssetCompound?.widenDocument)
                  }}"
                  [style.height]="item?.imageHeight"
                  [style.width]="item?.imageWidth"
                  alt="Widen Image"
                />
              </div>
            </ng-container>
          </div>
          <div class="row nextToCome" *ngIf="nextToComeTitle">
            <div class="col-sm-12">
              <h6>
                <span>NEXT TO COME:</span>
                <a
                  [routerLink]="nextToComeURL"
                  (click)="onChange(nextToComeURL)"
                  >{{ nextToComeTitle }}</a
                >
              </h6>
            </div>
          </div>
          <div class="row" *ngIf="askForDistributor">
            <div class="beginers-guide-buttons">
              <a
                href="javascript:void(0);"
                class="btn-dark-blue"
                data-bs-target="#advisor"
                data-bs-toggle="modal"
              >
                <span class="btn panel-orange-btn txt">
                  {{ askForDistributor }}</span
                >
              </a>
              <a
                href="javascript:void(0);"
                class="btn-dark-blue"
                data-bs-target="#speaktous"
                data-bs-toggle="modal"
              >
                <span class="btn panel-orange-btn txt">{{ speakTous }}</span>
              </a>
              <a
                class="loadtransactNowPage btn-dark-blue"
                [href]="investNowLink$ | async"
              >
                <span class="btn panel-orange-btn txt">{{ investNow }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="blogDetail" *ngIf="content?.staticdropdown === 'Blog'">
        <div class="india-cards-main">
          <div class="row">
            <div class="col-md-12">
              <div class="row commentary-india">
                <h1
                  class="header-commentary col-lg-8 col-md-12 col-sm-12 col-12"
                >
                  {{ content.title }}
                </h1>
                <div class="col-xl-8 col-md-12 col-sm-12 col-xs-12">
                  <div id="load-article-content">
                    <div class="panel panel-default panel-blogs">
                      <div class="panel-body commentary-profile">
                        <div
                          class="row"
                          *ngIf="
                            content?.indwidenImage?.bannerImage?.widenDocument
                          "
                        >
                          <div class="col-sm-12">
                            <img
                              src="{{
                                getImageURL(
                                  content?.indwidenImage?.bannerImage
                                    ?.widenDocument
                                )
                              }}"
                              alt="Banner Image"
                              class="bannerImage"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <ft-social-media-icons
                            [currentPageUrl]="currentPageUrl"
                            [textTitle]="textTitle"
                          ></ft-social-media-icons>
                        </div>
                        <div class="row content-section">
                          <div class="col-sm-12">
                            <ng-container
                              *ngFor="let item of content?.contentBlocks"
                            >
                              <h2
                                *ngIf="
                                  item?.title && item.title != 'SameRowImage'
                                "
                              >
                                {{ item?.title }}
                              </h2>
                              <p
                                *ngIf="
                                  item?.widenAssetCompound?.widenDocument &&
                                  item.title !== 'SameRowImage'
                                "
                              >
                                <img
                                  src="{{
                                    getImageURL(
                                      item?.widenAssetCompound?.widenDocument
                                    )
                                  }}"
                                  [style.width]="item?.imageWidth"
                                  [style.height]="item?.imageHeight"
                                  alt="Widen Image "
                                  height="{{ item?.imageHeight }}"
                                  width="{{ item?.imageWidth }}"
                                />
                              </p>
                              <p
                                *ngIf="
                                  item?.widenAssetCompound?.widenDocument &&
                                  item.title === 'SameRowImage'
                                "
                                class="sameRowImage"
                              >
                                <img
                                  src="{{
                                    getImageURL(
                                      item?.widenAssetCompound?.widenDocument
                                    )
                                  }}"
                                  [style.width]="item?.imageWidth"
                                  [style.height]="item?.imageHeight"
                                  alt="Widen Image"
                                  height="{{ item?.imageHeight }}"
                                  width="{{ item?.imageWidth }}"
                                />
                              </p>
                              <div
                                *ngIf="item?.content?.content?.value"
                                [innerHtml]="
                                  htmlCode(item?.content?.content?.value)
                                "
                              ></div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="author?.contentBlocks" class="blogmt">
                    <div style="padding: 0 10px">
                      <h5>
                        <strong>{{ authorTitle }}</strong>
                      </h5>
                      <img
                        class="blogmtImage blogmb"
                        alt="Sathish Prabhu"
                        [src]="authorImage"
                      />
                      <span [innerHtml]="authorContent"></span>
                    </div>
                  </div>
                  <span> </span>
                </div>
                <div class="col-xl-4 col-md-12 col-sm-12 col-xs-12 topReads">
                  <div class="fti-panel panel-group">
                    <div class="panel panel-default fti-ifp-pan">
                      <div class="panel-heading">
                        <h4 class="panel-title">Top Reads</h4>
                      </div>
                      <div class="panel-body">
                        <div class="watch-learn">
                          <div class="fti-mediagrid fti-newtoinvest">
                            <div class="row">
                              <div
                                class="col-xl-12 col-xs-12 col-sm-12 col-md-6"
                              >
                                <div class="mediaItem">
                                  <a [routerLink]="topRead1?.url">
                                    <img
                                      *ngIf="
                                        topRead1?.widenAssetCompound
                                          ?.widenDocument
                                      "
                                      [src]="
                                        getImageURL(
                                          topRead1?.widenAssetCompound
                                            ?.widenDocument
                                        )
                                      "
                                      alt="Importance Of Financial Planning"
                                    />
                                    <span class="gridCaption green-bg">{{
                                      topRead1?.title
                                    }}</span>
                                  </a>
                                </div>
                              </div>
                              <div
                                class="col-xl-12 col-xs-12 col-sm-12 col-md-6"
                              >
                                <div class="mediaItem">
                                  <a [routerLink]="topRead2?.url">
                                    <img
                                      *ngIf="
                                        topRead2?.widenAssetCompound
                                          ?.widenDocument
                                      "
                                      [src]="
                                        getImageURL(
                                          topRead2?.widenAssetCompound
                                            ?.widenDocument
                                        )
                                      "
                                      alt="Importance Of Financial Planning"
                                    />
                                    <span class="gridCaption green-bg">{{
                                      topRead2?.title
                                    }}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="note"></div>
      </div>
    </div>
  </div>
</div>
