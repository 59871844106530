<div class="fti-container position-relative addressbookContainer">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-ftindia-document"
        folderTemplateQuery="new-ftindia-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="clear" style="clear: both; height: 1em"></div>
      <section
        class="locate-us-section"
        data-template="/assets/assets-india/templates/locateUsTemplate.html"
      >
        <div class="parentTabs tab-content whyftTabs why-ft-page">
          <div
            class="tab-pane active"
            id="tab0"
            data-source="/en-in-retail/investor/customer-services/find-franklin-templeton/locate-us.page?bid=india.why-ft"
          >
            <div class="fti-document-list filters-nav">
              <h2 class="search-address d-none d-sm-none d-md-block d-lg-block">
                Find a Branch
              </h2>
              <a
                class="accordion-toggle d-block d-sm-block d-md-none d-lg-none d-xl-none"
                href="javascript:void(0)"
                aria-hidden="true"
                data-toggle="collapse"
                data-parent="#accordion"
                (click)="displaySub()"
                >Search-Address<i class="icon-fti_downArrow pull-right"></i>
              </a>
              <div
                class="select-data accordianData clearfix collapse"
                id="filter-accordian"
                [ngClass]="{ show: accordionValue }"
              >
                <ul class="filters-nav-ul">
                  <li
                    class="filters-nav-ul-li ml0 fti-whyft-list"
                    #container
                    data-bs-target="#subMenuContainer"
                    data-bs-toggle="collapse"
                  >
                    <div
                      class="btn-group states"
                      [ngClass]="{ open: showSubmenuState }"
                      id="stateDiv"
                    >
                      <button
                        class="whyft-btn btn btn-default dropdown-toggle first-list-drop"
                        aria-expanded="false"
                        aria-haspopup="true"
                        type="button"
                        data-toggle="dropdown"
                        (click)="
                          displaySubMenu('states', 'main', this, '', '', '')
                        "
                      >
                        <span
                          class="pull-left list-replace-state"
                          id="all-states"
                          >All States</span
                        >
                        <i
                          class="icon-fti_downArrow pull-right why-ft-downArrow"
                        ></i>
                      </button>
                      <ul class="dropdown-menu states-list" #subMenuContainer>
                        <li
                          class="listClass statesList"
                          *ngFor="let states of getStates"
                          id="{{ states?.stateId }}"
                          (click)="
                            displaySubMenu(
                              'states',
                              'sub',
                              states?.state,
                              states?.stateId,
                              states?.slug,
                              states?.stateId
                            );
                            getcitiesFilters(states?.stateId)
                          "
                        >
                          <a>{{ states?.state }}</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    class="filters-nav-ul-li ml0 fti-whyft-list"
                    #cityContainer
                    data-bs-target="#subMenuCityContainer"
                    data-bs-toggle="collapse"
                  >
                    <div
                      class="btn-group cities"
                      [ngClass]="{ open: showSubmenuCities }"
                      id="cityDiv"
                    >
                      <button
                        class="whyft-btn btn btn-default dropdown-toggle secnd-list-drop"
                        aria-expanded="false"
                        aria-haspopup="true"
                        type="button"
                        data-toggle="dropdown"
                        (click)="
                          displaySubMenu('cities', 'main', this, '', '', '')
                        "
                      >
                        <span
                          class="pull-left list-replace-city"
                          id="all-cities"
                          >All Cities</span
                        ><i
                          class="icon-fti_downArrow why-ft-downArrow pull-right"
                        ></i>
                      </button>
                      <ul
                        class="dropdown-menu cities-list"
                        #subMenuCityContainer
                      >
                        <li
                          *ngFor="let cities of citiesValues"
                          id="{{ cities?.id }}"
                          data-id="{{ cities?.state_id }}"
                          (click)="
                            displaySubMenu(
                              'cities',
                              'sub',
                              cities?.name,
                              cities?.id,
                              cities?.slug,
                              cities?.state_id
                            )
                          "
                          [ngClass]="[
                            'listClass',
                            'citiesList',
                            cities?.state_id
                          ]"
                        >
                          <a>{{ cities?.name }}</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <div class="fti-whyft-links pull-right">
                  <button
                    class="btn btn-default panel-orange-btn search-btn"
                    (click)="showDetails()"
                  >
                    SEARCH
                  </button>
                  <button
                    class="btn btn-default panel-gray-btn reset-comp-btn"
                    (click)="resetDetails()"
                  >
                    RESET
                  </button>
                </div>
                <div class="clear" style="clear: both; height: 0.5em"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="clear" style="clear: both; height: 1.2em"></div>
      <div class="">
        <div class="schemeDocsAccordion">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div id="locationDetails">
                <div
                  *ngFor="let lists of allBranches; let i = index"
                  class="displayAddressDetails"
                >
                  <div
                    [ngClass]="[
                      'panel',
                      'panel-default',
                      'firstAcc',
                      'acc-details',
                      'cities-grid',
                      lists?.cityname,
                      lists?.state,
                      lists?.slug,
                      lists?.cityslug
                    ]"
                  >
                    <div
                      class="panel-heading"
                      data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse' + i"
                    >
                      <h4 class="panel-title">
                        <a
                          class="accordion-toggle"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="javascript:void(0)"
                        >
                          <span class="acc-content1">{{
                            lists?.cityname
                          }}</span>
                        </a>
                      </h4>
                    </div>
                    <div
                      [id]="'collapse' + i"
                      class="accordion-collapse collapse show panel-collapse accordion-content"
                    >
                      <div class="panel-body">
                        <ul>
                          <li
                            *ngFor="let listbranch of lists?.allBranches"
                            class="acc-data"
                          >
                            <div class="acc-content">
                              <h2 *ngIf="listbranch?.branchname">
                                {{ listbranch?.branchname }}
                              </h2>
                              <h2 *ngIf="!listbranch?.branchname">
                                <span class="sr-only">Heading 2</span>
                                &nbsp;
                              </h2>
                              <a
                                class="fti-map icon icon-fti_mapNoHorizon pull-right"
                                href="javascript:void(0)"
                                (click)="
                                  showModal(
                                    mapcontent,
                                    listbranch?.latitude,
                                    listbranch?.longitude
                                  )
                                "
                                data-target="#locateAddressMapModal"
                                data-toggle="modal"
                                id="Agartala"
                              >
                                <span class="sr-only"
                                  >Show the location in Map (Opens a
                                  Pop-up)</span
                                >
                              </a>
                              <div class="clear"></div>
                              <span *ngIf="listbranch?.telephoneNumber">
                                <p
                                  *ngFor="
                                    let tel of listbranch?.telephoneNumber
                                  "
                                >
                                  <span
                                    class="fti-printer icon icon-fti_phone"
                                  ></span>
                                  : {{ tel }}
                                </p>
                              </span>
                              <p
                                class="recordAlignment"
                                *ngIf="listbranch?.sales"
                              >
                                Sales :
                                <span class="bold">{{
                                  listbranch?.sales
                                }}</span>
                                <span
                                  class="recordAlignment"
                                  *ngIf="
                                    listbranch.services && listbranch?.sales
                                  "
                                >
                                  /</span
                                >
                              </p>
                              <p
                                class="recordAlignment"
                                *ngIf="listbranch.services"
                              >
                                Services :
                                <span class="bold">{{
                                  listbranch?.services
                                }}</span>
                              </p>
                              <p
                                class="recordAlignment"
                                *ngIf="!listbranch?.sales"
                              ></p>
                              <p
                                class="recordAlignment"
                                *ngIf="!listbranch?.services"
                              ></p>
                              <p></p>

                              <p>{{ listbranch?.address }}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #mapcontent let-modal>
  <div class="modal-content">
    <div class="modalMapLoad">
      <div id="googleSuggest field-wrapper">
        <div
          class="form-group floatLabels"
          [ngClass]="{ focused: focusValue }"
          id="destinationText"
        >
          <label for="address" class="control-label">From<sup>*</sup></label>
          <input
            type="text"
            (focus)="labelMoveInName($event)"
            (focusout)="labelMoveOutName($event)"
            id="address"
            placeholder="&nbsp;"
            class="form-field form-control pac-target-input"
            [(ngModel)]="pickAddress"
            name="pickAddress"
            (ngModelChange)="findAdress()"
            onpaste="return false"
            autocomplete="off"
          />
        </div>
      </div>

      <agm-map
        #agmMap
        [latitude]="latitude"
        [longitude]="longitude"
        [zoom]="11"
      >
        <agm-direction [origin]="origin" [destination]="destination">
        </agm-direction>
        <agm-map-type-control></agm-map-type-control>

        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
      </agm-map>
    </div>
    <a type="button" class="fill-circle-close" (click)="resetModal()">
      <span class="icon-fti_close" aria-hidden="true"></span>
    </a>
  </div>
</ng-template>
