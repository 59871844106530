import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';

import { APOLLO_OPTIONS } from 'apollo-angular';
import { AbstractBaseComponent } from './abstract-base/abstract-base.component';
import { AppStateService } from '@services/app-state.service';
import { BaseModalComponent } from './base-modal/base-modal.component';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { ButtonDirective } from './directives/button/button.directive';
import { CommonModule, DatePipe } from '@angular/common';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DatePickerModule } from '@shared/date-picker/date-picker.module';
import { DebugComponent } from './debug/debug.component';
import { DebugService } from '@services/debug.service';
import { FooterComponent } from './footer/footer.component';
// import { GatewayModalComponent } from '@marketing/gateway-modal/gateway-modal.component';
import { HeaderComponent } from './header/header.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { HttpLink } from 'apollo-angular/http';
import { MarketoDirective } from './directives/marketo/marketo.directive';
import { ModalComponent } from './modal/modal.component';
import { NgModule } from '@angular/core';
import { ProductTooltipComponent } from './product-tooltip/product-tooltip.component';
import { RouterModule } from '@angular/router';
// import { SegmentSelectorComponent } from './segment-selector/segment-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisualHintComponent } from './visual-hint/visual-hint.component';
import { TranslateModule } from './translate/translate.module';
import { VisualHintModule } from './visual-hint/visual-hint.module';
import { BarChartComponent } from './bar-chart/bar-chart.component';
// import { MarketoFormComponent } from './marketo-form/marketo-form.component';
import { PipesModule } from '@shared/pipes/pipes.module';

import { CommonComponent } from '@components/marketing/common/common.component';
import { ArticleListComponent } from '@components/marketing/article-list/article-list.component';
import { ContentComponent } from '@components/content/content.component';
import { RichTextComponent } from '@components/shared/rich-text/rich-text.component';
import { FundCaveatComponent } from '@components/marketing/fund-caveat/fund-caveat.component';
import { QuickLinkListComponent } from '@components/marketing/quick-link-list/quick-link-list.component';
import { AccordionComponent } from '@components/shared/accordion/accordion.component';
import { IndCarouselComponent } from '@components/marketing/ind-carousel/ind-carousel.component';
import { SipCalculatorComponent } from '@components/marketing/sip-calculator/sip-calculator.component';
import { MarketInsightsBlogsComponent } from '@components/marketing/market-insights-blogs/market-insights-blogs.component';

import { ContactusDisplayGridComponent } from '@components/marketing/contactus-display-grid/contactus-display-grid.component';
import { LumpsumCalculatorComponent } from '@components/marketing/lumpsum-calculator/lumpsum-calculator.component';
import { LoginRegisterFormComponent } from '@components/shared/login-register-form/login-register-form.component';
import { AddressBookComponent } from '@components/marketing/address-book/address-book.component';
import { ArticleDetailComponent } from '@components/marketing/article-detail/article-detail.component';
import { VideoArticlesComponent } from '@components/marketing/video-articles/video-articles.component';
import { BeginersGuideComponent } from '@components/marketing/beginers-guide/beginers-guide.component';
import { ShareOptionsComponent } from '@components/marketing/share-options/share-options.component';
import { NewInvestorLoginComponent } from '@components/marketing/new-investor-login/new-investor-login.component';
import { ExistingInvestorLoginComponent } from '@components/marketing/existing-investor-login/existing-investor-login.component';
import { DisplayListComponent } from '@components/marketing/display-list/display-list.component';
import { DocumentPickerComponent } from '@shared/document-picker/document-picker.component';
import { SectionTitleComponent } from '@shared/section-title/section-title.component';
import { FeedbackComponent } from '@components/marketing/feedback/feedback.component';
import { NpsComponent } from '@components/marketing/nps/nps.component';
import { InterestedComponent } from '@components/marketing/interested/interested.component';
import { CodeComponent } from '@components/code/code.component';
import { NoSpaceApersandPipe } from '@shared/pipes/no-space-apersand.pipe';
import { DebugServiceToken } from 'src/app/types/debug-service.interface';
import { FootnoteModule } from './footnote';
import { FundCardComponent } from './fund-card/fund-card.component';
import { PiChartComponent } from './pi-chart/pi-chart.component';
import { DisplayContentComponent } from '@components/marketing/display-content/display-content.component';
import { DocumentPanelComponent } from './document-panel/document-panel.component';
import { IndSimpleDisplayComponent } from '@components/marketing/ind-simple-display/ind-simple-display.component';
import { CompareStickyFooterComponent } from './compare-sticky-footer/compare-sticky-footer.component';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SearchComponent } from './search/search.component';
import { ArticlePagesComponent } from '@components/marketing/article-pages/article-pages.component';
import { AddFundsComponent } from './add-funds/add-funds.component';
import { AutoSuggestionsListComponent } from './header/auto-suggestions-list/auto-suggestions-list.component';
import { SelectComponent } from './select/select.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SocialMediaIconsComponent } from '@components/marketing/social-media-icons/social-media-icons.component';
import { ReachforbetterarticlePageComponent } from '@components/marketing/reachforbetterarticle-page/reachforbetterarticle-page.component';

export function createApolloOptions(
  httpLink: HttpLink,
  appStateService: AppStateService,
  debugService: DebugService
): ApolloClientOptions<any> {
  let defaultEnv = debugService.getPdsEnv();
  if (defaultEnv === '') {
    defaultEnv = appStateService.getPdsDefaultEnv();
    debugService.setPdsEnv(defaultEnv);
  }

  return {
    link: httpLink.create({
      uri: (operation) =>
        `${appStateService.getPdsApiUrl(defaultEnv)}&op=${
          operation.operationName
        }`,
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  };
}

// Components
@NgModule({
  imports: [
    BrSdkModule,
    CommonModule,
    DatePickerModule,
    HighchartsChartModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    VisualHintModule,
    PipesModule,
    FootnoteModule,
    NgbTooltipModule,
  ],
  declarations: [
    AbstractBaseComponent,
    BarChartComponent,
    PiChartComponent,
    BaseModalComponent,
    ButtonDirective,
    DebugComponent,
    FooterComponent,
    HeaderComponent,
    LoginRegisterFormComponent,
    MarketoDirective,
    // MarketoFormComponent,
    ModalComponent,
    ProductTooltipComponent,
    // TODO: To remove. Temporarily added for segmentation workaround
    // SegmentSelectorComponent,
    // GatewayModalComponent,
    CodeComponent,
    FundCardComponent,
    NoSpaceApersandPipe,
    DocumentPanelComponent,
    CompareStickyFooterComponent,
    ToastNotificationComponent,
    PageNotFoundComponent,
    AddFundsComponent,
    AutoSuggestionsListComponent,
    SelectComponent,
    AlertModalComponent,
    ArticlePagesComponent,
    SocialMediaIconsComponent,
  ],
  exports: [
    AbstractBaseComponent,
    BarChartComponent,
    PiChartComponent,
    BaseModalComponent,
    ButtonDirective,
    DatePickerComponent,
    DebugComponent,
    FootnoteModule,
    HighchartsChartModule,
    MarketoDirective,
    // MarketoFormComponent,
    ModalComponent,
    ProductTooltipComponent,
    ReactiveFormsModule,
    TranslateModule,
    VisualHintComponent,
    // TODO: To remove. Temporarily added for segmentation workaround
    // GatewayModalComponent,
    PipesModule,
    FormsModule,
    FundCardComponent,
    DocumentPanelComponent,
    CompareStickyFooterComponent,
    ToastNotificationComponent,
    PageNotFoundComponent,
    AddFundsComponent,
    SelectComponent,
    ArticlePagesComponent,
    SocialMediaIconsComponent,
  ],
  providers: [
    // order matters below
    { provide: DebugService, useClass: DebugService },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApolloOptions,
      deps: [HttpLink, AppStateService, DebugService],
    },
    { provide: DebugServiceToken, useExisting: DebugService }, // for EDS
    DatePipe, // for literatture-ag-grid-helper service
  ],
})
export class SharedModule {}

export const SharedComponentMapping = {
  // 'Important Legal': ImportantLegalComponent  // Navigation
  Header: HeaderComponent,
  'Content Blocks': ContentComponent,
  'Footer Main Menu': FooterComponent,
  'ind-rich-text': RichTextComponent,
  'ind-fund-caveat': FundCaveatComponent,
  'ind-link-list': QuickLinkListComponent,
  'ind-sip-calculator': SipCalculatorComponent,
  'ind-market-insights-blogs': MarketInsightsBlogsComponent,
  'ind-simple-display': IndSimpleDisplayComponent,
  'ind-contact-us': ContactusDisplayGridComponent,
  'ind-lumpsum-calculator': LumpsumCalculatorComponent,
  'ind-accordion': AccordionComponent,
  'ind-carousel': IndCarouselComponent,
  'ind-loginregister-form': LoginRegisterFormComponent,
  'ind-feedback-form': FeedbackComponent,
  'ind-nps-form': NpsComponent,
  'ind-reach-for-better-work': ReachforbetterarticlePageComponent,
  'ind-interested-form': InterestedComponent,
  'ind-address-book': AddressBookComponent,
  'ind-article-details': ArticleDetailComponent,
  'ind-video-articles': VideoArticlesComponent,
  Footer: FooterComponent,
  'Segment Banner': '',
  'Utility Bar': '',
  'ind-common': CommonComponent,
  'ind-article-list': ArticleListComponent,
  'ind-display-list': DisplayListComponent,
  'ind-enquiry-button': BeginersGuideComponent,
  'ind-share-button': ShareOptionsComponent,
  // 'ind-new-investor-form': NewInvestorLoginComponent,
  'ind-existing-investor-form': ExistingInvestorLoginComponent,
  'IND Section Title': SectionTitleComponent,
  'IND Glossary Component': DocumentPickerComponent,
  'ind-code': CodeComponent,
  'ind-display-content': DisplayContentComponent,
  'IND Page Not Found': PageNotFoundComponent,
  'IND Search Component': SearchComponent,
};
