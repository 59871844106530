<div
  class="ft-select"
  [ngClass]="customClasses"
  #ftSelect
>
  <div
    class="btn-group"
    [class.open]="isOpen"
  >
    <button
      type="button"
      class="btn btn-default dropdown-toggle"
      aria-expanded="true"
      (click)="toggle()"
    >
      <span
        class="pull-left list-replace"
        data-id="all-funds"
      >{{
        labelText || Placeholder
        }}</span>
      <i class="icon-fti_downArrow pull-right"></i>
    </button>
    <ul class="dropdown-menu">
      <li
        class="listClass"
        id="all-funds"
        *ngFor="let item of dropdownList"
        (click)="SelectList(item, true)"
        [class.disabled]="item?.disabled"
      >
        <a>{{ item.label }}</a>
      </li>
    </ul>
  </div>
</div>
