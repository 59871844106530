<div class="commentary-section" *ngIf="originalData">
  <div class="fti-container">
    <div class="row">
      <div class="col-xs-12">
        <h1>{{ headingText }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 contentCategory">
        <div class="row">
          <ng-container *ngFor="let blog of originalData; index as i">
            <div class="col-xs-12 col-md-4 col-lg-3">
              <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
                <ng-container
                  *ngIf="page?.isPreview()"
                  [brManageContentButton]="page?.getContent(blog?.ref)"
                ></ng-container>
                <div class="mediaItem">
                  <a
                    *ngIf="
                      blog?.data?.contentType === 'ftindia:INDVideoArticles'
                    "
                    [href]="blog?.data?.links?.site?.href"
                    target="_self"
                    class="analytics-link"
                    (click)="setAnalytics(blog.data.title)"
                    data-event-name="Event Market Insights"
                    data-category="Market Insights"
                    data-action="View Article"
                    data-label="Latest Commentaries| Union Budget: 2022-23 - An Insight"
                  >
                    <img
                      src="{{
                        getImageURL(
                          blog?.data?.widenAssetCompound?.widenDocument
                        )
                      }}"
                      alt="{{ blog?.data?.displayName }}"
                      title="{{ blog?.data?.displayName }}"
                    />
                    <span class="dateStamp">{{ blog?.date }}</span>
                    <span
                      class="icon icon-fti_play"
                      *ngIf="blog?.data?.videoTimeStamp"
                    ></span>
                    <span
                      class="gridCaption blue-bg"
                      [ngClass]="
                        blog?.data?.videoTimeStamp !== ''
                          ? 'green-bg'
                          : 'my_class2'
                      "
                      >{{ blog?.data?.ftindiaPageMetaData?.metaTitle
                      }}<span
                        class="timeStamp"
                        *ngIf="blog?.data?.videoTimeStamp"
                        >{{ blog?.data?.videoTimeStamp }}</span
                      ></span
                    >
                  </a>
                  <a
                    *ngIf="
                      blog?.data?.contentType === 'ftindia:INDArticleDetails'
                    "
                    [href]="blog?.data?.pdfURL"
                    target="_blank"
                    class="analytics-link"
                    data-event-name="Event Market Insights"
                    data-category="Market Insights"
                    data-action="View Article"
                    data-label="Latest Commentaries| Union Budget: 2022-23 - An Insight"
                  >
                    <img
                      src="{{ getImageURL(blog?.image?.widenDocument) }}"
                      alt="{{ blog?.data?.title }}"
                      title="{{ blog?.data?.title }}"
                    />
                    <span class="dateStamp">{{ blog?.date }}</span>
                    <span class="gridCaption blue-bg">{{
                      blog?.data?.title
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
