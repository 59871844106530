import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AddFundsList, AddFundsMessage, AddFundsModal } from './add-funds.type';

export interface FormData {
  assetClassFormControl?: string;
  searchInputControl?: string;
}
@Component({
  selector: 'ft-add-funds',
  templateUrl: './add-funds.component.html',
  styleUrls: ['./add-funds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFundsComponent implements OnInit, OnDestroy {
  @Input() addFundsData: AddFundsModal;

  @Output()
  addedFunds: EventEmitter<AddFundsMessage> = new EventEmitter<AddFundsMessage>();

  formSubscription: Subscription;
  currentFundList: AddFundsList[];
  currentSelectedFundIds: string[] = [];
  addFundsForm: FormGroup;
  maxLimit = 3;
  isMaxLimitExceed = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.addFundsForm = this.formBuilder.group({
      assetClassFormControl: [''],
      searchInputControl: ['', [Validators.maxLength(64)]],
    });
    if (this.addFundsData) {
      this.maxLimit = this.addFundsData.maxLimit || 3;
    }
    this.formValueChanges();
  }

  formValueChanges() {
    this.formSubscription = this.addFundsForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val: FormData) => {
        this.filterDataOnInputChanges(val);
      });
  }
  filterDataOnInputChanges(formvalue: FormData): void {
    const ALL = 'all';
    const searchInputValue = formvalue.searchInputControl.trim().toLowerCase();
    const assetClassInputValue = formvalue.assetClassFormControl
      .trim()
      .toLowerCase();

    // Searching means making show to true in case of checkbox list
    this.currentFundList?.map((fd) => {
      fd.show = true;
      if (
        assetClassInputValue === ALL &&
        searchInputValue &&
        !fd.fundName.toLowerCase().includes(searchInputValue)
      ) {
        fd.show = false;
      } else if (
        assetClassInputValue !== ALL &&
        !searchInputValue &&
        fd.assetClass.toLowerCase() !== assetClassInputValue
      ) {
        fd.show = false;
      } else if (
        assetClassInputValue !== ALL &&
        searchInputValue &&
        !(
          fd.assetClass.toLowerCase() === assetClassInputValue &&
          fd.fundName.toLowerCase().includes(searchInputValue)
        )
      ) {
        fd.show = false;
      }
      return fd;
    });
  }

  onDropdownchange(e: Event): void {
    this.addFundsForm.get('searchInputControl').setValue('');
  }

  openAddFundsModal(modalRef): void {
    this.resetModal();
    this.modalService.open(modalRef, {
      size: 'lg',
      windowClass: 'ft-modal',
    });
  }

  checkMaxLimit(): void {
    this.isMaxLimitExceed = false;
    if (
      this.currentFundList.filter((fd) => fd.selected === true)?.length +
        this.addFundsData.selectedFundIds?.length >
      this.maxLimit
    ) {
      this.isMaxLimitExceed = true;
    }
    this.cdr.detectChanges();
  }

  disableSubmit(): boolean {
    return (
      this.currentFundList.filter((fd) => fd.selected === true).length === 0 ||
      this.isMaxLimitExceed
    );
  }
  onAddedFunds(modal: any): void {
    const currentSelectedFundIds: string[] = [];

    this.currentFundList
      .filter((fd) => fd.selected === true)
      .forEach((cfd) => {
        currentSelectedFundIds.push(cfd.fundId);
      });

    this.addedFunds.emit({
      isMaxLimitExceed: this.isMaxLimitExceed,
      funds: !this.addFundsData.selectedFundIds
        ? currentSelectedFundIds
        : this.addFundsData.selectedFundIds.concat(currentSelectedFundIds),
    });

    modal.dismiss('Cross click');
  }

  getNonSelectedFundList(
    fundListData: AddFundsList[],
    fundIds: string[]
  ): AddFundsList[] {
    return fundListData
      .filter((fd) => !fundIds?.includes(fd.fundId))
      .map((fd) => {
        fd.selected = false;
        fd.show = true;
        return fd;
      });
  }

  resetModal(): void {
    this.addFundsForm
      .get('assetClassFormControl')
      .setValue(this.addFundsData.assetClassFilter.filterItems[0]?.value);
    this.addFundsForm.get('searchInputControl').setValue('');
    this.isMaxLimitExceed = false;
    this.initializeFundList();
  }

  initializeFundList(): void {
    if (this.addFundsData.fundList?.length > 0) {
      this.currentFundList = this.getNonSelectedFundList(
        this.addFundsData.fundList,
        this.addFundsData.selectedFundIds
      );
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe search text subscriber.
    this.modalService.dismissAll();
    this.formSubscription?.unsubscribe();
  }
}
