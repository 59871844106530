import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { Constant } from '../../../ft-constants/constant';
import { CommonService } from '@services/common.service';
import { StorageService } from '@services/storage.service';
import { Logger } from '@utils/logger';
import { ActivatedRoute } from '@angular/router';

const logger = Logger.getLogger('HorizontalColumnTabComponent');

@Component({
  selector: 'ft-horizontal-column-tab',
  templateUrl: './horizontal-column-tab.component.html',
  styleUrls: ['./horizontal-column-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbNavConfig],
})
export class HorizontalColumnTabComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() numberOfTabs;
  @Input() layout;
  tabArray = [];
  isLayoutWithSelect: boolean;
  isNewToInvesting: boolean;
  isFranklinTempleton: boolean;
  isNewToMutualFunds: boolean;
  isWomenAndInvesting: boolean;
  isMoreAboutMutualFunds: boolean;
  isPlanningforRetirement: boolean;
  isYourChildsFuture: boolean;
  isSmartTaxPlanning: boolean;
  isManageVolatilityPitfalls: boolean;
  isWebinars: boolean;
  isVisible: boolean;
  tab = 0;
  tabSelected = '';
  selectedKey: number;

  constructor(
    config: NgbNavConfig,
    private location: Location,
    private commonService: CommonService,
    private storageService: StorageService,
    private route: ActivatedRoute
  ) {
    config.destroyOnHide = false;
  }

  ngOnInit(): void {
    if (this.route?.snapshot?.fragment) {
      const tabIndex = +this.route?.snapshot?.fragment.replace('tab_tab', '');
      this.tab = tabIndex > +this.numberOfTabs - 1 ? 0 : tabIndex;
    }

    this.getTabSelectedLabel(1);

    const currentPath = this.location?.path().split('/');
    const pathFragment = currentPath?.reverse()[0];
    this.isLayoutWithSelect =
      pathFragment === Constant?.isLayoutWithSelect ? true : false;
    this.isNewToInvesting =
      pathFragment === Constant?.isNewToInvesting ||
      this.location.path().includes(Constant?.isNewToInvesting + '/index')
        ? true
        : false;
    this.isNewToMutualFunds =
      pathFragment === Constant?.isNewToMutualFunds ||
      this.location.path().includes(Constant?.isNewToMutualFunds)
        ? true
        : false;

    this.isMoreAboutMutualFunds =
      pathFragment === Constant?.isMoreAboutMutualFunds ||
      this.location?.path().includes(Constant.isMoreAboutMutualFunds)
        ? true
        : false;
    this.isWomenAndInvesting =
      pathFragment === Constant?.isWomenAndInvesting ||
      this.location?.path().includes(Constant.isWomenAndInvesting)
        ? true
        : false;
    this.isPlanningforRetirement =
      pathFragment === Constant?.isPlanningforRetirement ||
      this.location?.path().includes(Constant.isPlanningforRetirement)
        ? true
        : false;
    this.isYourChildsFuture =
      pathFragment === Constant?.isYourChildsFuture ||
      this.location?.path().includes(Constant.isYourChildsFuture)
        ? true
        : false;
    this.isSmartTaxPlanning =
      pathFragment === Constant?.isSmartTaxPlanning ||
      this.location?.path().includes(Constant.isSmartTaxPlanning)
        ? true
        : false;
    this.isManageVolatilityPitfalls =
      pathFragment === Constant?.isManageVolatilityPitfalls ||
      this.location?.path().includes(Constant.isManageVolatilityPitfalls)
        ? true
        : false;
    this.isWebinars =
      pathFragment === Constant?.isWebinars ||
      this.location?.path().includes(Constant.isWebinars)
        ? true
        : false;
    this.isVisible =
      this.isNewToInvesting ||
      this.isNewToMutualFunds ||
      this.isMoreAboutMutualFunds ||
      this.isWomenAndInvesting ||
      this.isYourChildsFuture ||
      this.isSmartTaxPlanning ||
      this.isManageVolatilityPitfalls ||
      this.isWebinars;
    this.isFranklinTempleton =
      pathFragment === Constant?.isFranklinTempleton ||
      this.location?.path().includes(Constant.isFranklinTempleton)
        ? true
        : false;

    this.numberOfTabs = isNaN(this.numberOfTabs)
      ? 0
      : parseInt(this.numberOfTabs, 10);
    this.tabArray = Array(this.numberOfTabs).fill(this.numberOfTabs);
    // To check one-tab or two tab layout
    localStorage.setItem('numebrOfTbas', this.numberOfTabs);

    if (this.location?.path().indexOf('about-us/fti-india') > 0) {
      this.tab = 1;
      this.navigateTab('tab-area-2');
      this.getTabSelectedLabel(2);
    } else if (this.location?.path().indexOf('about-us/fti') > 0) {
      this.tab = 0;
      this.navigateTab('tab-area-1');
      this.getTabSelectedLabel(1);
    } else if (this.location?.path().indexOf('about-us/global-offices') > 0) {
      this.tab = 2;
      this.navigateTab('tab-area-3');
      this.getTabSelectedLabel(3);
    } else if (
      this.location?.path().indexOf('about-us/csr-initiatives-india') > 0
    ) {
      this.tab = 3;
      this.navigateTab('tab-area-4');
      this.getTabSelectedLabel(4);
    } else {
      // Check for selectedTab value in local storage
      // If exist replace the this.tab value
      this.storageService?.retrieveSelectedTab().then((storageData) => {
        if (storageData === 'tab-area-2') {
          this.tab = 1;
          this.navigateTab('tab-area-2');
        } else {
          this.navigateTab('tab-area-1');
        }
      });
    }
  }

  getItemData(ind: any) {
    let document;
    let componentData;
    const components: BrComponent[] = this.page?.getComponent()?.getChildren();
    components?.forEach((comp, index) => {
      if (comp.getName() === 'tab-area-' + ind) {
        const firstChild = comp?.getChildren()[0];
        if (firstChild) {
          if (this.isVisible || this.isFranklinTempleton) {
            if (this.isFranklinTempleton) {
              if (comp.getName() === 'tab-area-1') {
                document = firstChild.getModels()?.document;
              } else {
                document = firstChild.getModels()?.document;
              }
            } else {
              document = firstChild.getParameters();
            }
          } else {
            document = firstChild.getModels()?.document;
          }
        }
      }
    });
    if (document) {
      if (this.isVisible || this.isFranklinTempleton) {
        if (this.isVisible) {
          componentData = document;
        } else if (this.isFranklinTempleton) {
          if (document?.$ref) {
            componentData = this.page?.getContent(document)?.getData();
            if (componentData?.contentBlocks) {
              componentData = componentData?.contentBlocks[0]?.title;
            } else {
              componentData = componentData?.displayName;
            }
            logger.debug('documentData>>', componentData);
          } else {
            componentData = document;
          }
        }
      } else {
        componentData = this.page?.getContent(document)?.getData();
      }

      return componentData;
    }
    return;
  }

  public getTabTitle(ind: any) {
    const data = this.getItemData(ind);
    if (data) {
      if (this.isVisible) {
        return data?.title;
      } else if (this.isFranklinTempleton) {
        return data;
      } else {
        return data?.displayName;
      }
    } else {
      return 'Read & Learn';
    }
    return;
  }

  /**
   * Registering tab navigation.
   * @param selectedTab - selected tab string
   * @param tabName - tab name string for URL (optional)
   */
  public navigateTab(selectedTab: string, tabName?: string): void {
    this.storageService.storeSelectedTab(selectedTab);
    this.commonService.changeData(selectedTab);
    if (tabName) {
      this.location.replaceState(this.location.path() + tabName);
    }
  }

  /**
   * Returns string for tab anchor
   * @param index - number of tab anchor index
   */
  public tabNameToAnchor(index: number): string {
    return '#' + this.getTabTitle(index).replace(/ /g, '_').toLowerCase();
  }

  getTabSelectedLabel(selectedKey) {
    this.selectedKey = selectedKey;
    this.tabSelected = this.getTabTitle(selectedKey);
  }
}
