import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LiteratureService } from '@services/literature.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@shared/translate/translate.service';
import filter from 'lodash/filter';

@Component({
  selector: 'ft-latest-updates',
  templateUrl: './latest-updates.component.html',
  styleUrls: ['./latest-updates.component.scss'],
})
export class LatestUpdatesComponent implements OnInit, OnDestroy {
  public subscription: Subscription;
  public latestDocuments: any;
  errorInService = false;
  constructor(
    private translateService: TranslateService,
    private litService: LiteratureService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const params = this.translateService?.instant(
      'literature.wind-up-contentGrouping'
    );

    // Get custom links from BR labels
    const customLinksGroup = JSON.parse(
      this.translateService?.instant('literature.customLinksGroup')
    );

    // Call service for article data
    this.subscription = this.litService
      ?.getData$('?channel=en-in&contentGrouping=' + encodeURI(params))
      ?.subscribe(
        (response) => {
          // get the documents
          const rawData = response.document;

          const mergedData = rawData?.concat(customLinksGroup);

          // get 'contentGrouping' from BR label
          const contentGrouping = params?.split(',');

          // club documents together belongs to same category
          // assign new group to template for looping
          this.latestDocuments = contentGrouping?.map((group) => ({
            id: group,
            dataRecords: {
              linkdata: filter(mergedData, ['contentGrouping', group]),
            },
          }));

          this.cdr.detectChanges();
        },
        (error) => {
          this.errorInService = true;
          this.cdr.detectChanges();
        }
      );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
