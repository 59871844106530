<div class="flashMessage">
  <div class="fti-container">
    <div class="row m-0">
      <div class="col-sm-12 col-xs-12 flashSection">
        <div class="thankYou">
          <h5>
            <span class="success-icon icon icon-fti_tick"></span>
            {{ title }}
          </h5>
          <p>{{ content }}</p>
        </div>
        <img
          class="m-top20"
          alt="Image"
          src="/assets/images/SIPborder.png"
          *ngIf="flashPage === 'vrm-signup' || flashPage === 'vrm-signout'"
        />
      </div>
    </div>
  </div>
</div>
