import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FtSearchResults, TotalCount } from './search.interface';
import { FtsearchService } from './ftsearch.service';
import { InUserType } from '@types';

const funds = 'funds';
const pages = 'pages';
const literature = 'literature';
const FILTER_SECTION = {
  fundsFilterSection: 'funds_filter_section',
  generalFilterSection: 'general_filter_section',
  investorFilterSection: 'investor_filter_section',
  advisorFilterSection: 'advisor_filter_section',
  literatureFilterSection: 'literature_filter_section',
};

@Component({
  selector: 'ft-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  start: number;
  end: number;
  queryParams: Params;
  results = [];
  visualHint: Element;
  isViewAll = {
    funds: false,
    pages: false,
    advisor: false,
    investor: false,
    literature: false,
  };
  generalItems = [];
  fundsItems = [];
  literatureItems = [];
  totalCount: TotalCount;
  valueToShow: TotalCount;
  totalResultsGeneralFunds: string;
  totalResultsInvAdvisor: number;
  totalResults: number;
  advisorResults = [];
  investorResults = [];
  isAdvisor: any;
  isInvestor = false;
  showSections: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private ftSearchService: FtsearchService
  ) {}

  ngOnInit() {
    this.resetStartAndEnd();
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.valueToShow = this.ftSearchService.getValueToShow();
    this.ftSearchService.checkUserType()?.subscribe((userType) => {
      this.showSections = this.ftSearchService.getShowSections();
      this.isAdvisor = userType === InUserType.ADVISOR;
      this.isInvestor = userType === InUserType.INVESTOR;
      this.showSelectedSection();
      this.fetchResults(this.start, this.end);
    });
  }
  resetViewAll() {
    this.isViewAll.funds = false;
    this.isViewAll.pages = false;
    this.isViewAll.investor = false;
    this.isViewAll.advisor = false;
    this.isViewAll.literature = false;
  }
  fetchResults(start, end, collection?: string) {
    this.ftSearchService
      .getFTISearchResults(this.queryParams.query, start, end, collection)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        const generalFundsResults = data[0] as FtSearchResults;
        const advisorInvestorResults = data[1]?.body || data[1];
        this.populateGeneralFundsResults(generalFundsResults, collection);
        this.populateAdvisorIvestorResults(advisorInvestorResults);
        this.totalCount = this.ftSearchService.getTotalCount();
        this.totalResults =
          parseInt(this.totalResultsGeneralFunds, 10) +
          this.totalResultsInvAdvisor;
        this.cdRef.detectChanges();
      });
  }
  populateAdvisorIvestorResults(data) {
    this.advisorResults = this.ftSearchService.getAdvisorResults(data);
    this.investorResults = this.ftSearchService.getInvestorResults(data);
    this.totalResultsInvAdvisor =
      this.advisorResults?.length + this.investorResults?.length;
  }
  populateGeneralFundsResults(data, collection) {
    this.totalResultsGeneralFunds = data?.totalResults;
    this.results = data?.results;
    this.ftSearchService.setResults(this.results);
    if (collection === pages && this.start > 0) {
      this.generalItems = this.generalItems.concat(
        this.ftSearchService.getGeneralItems()
      );
      this.valueToShow.pages = this.generalItems?.length;
    } else if (collection === funds && this.start > 0) {
      this.fundsItems = this.fundsItems.concat(
        this.ftSearchService.getFundsItems()
      );
      this.valueToShow.funds = this.fundsItems?.length;
    } else if (collection === literature && this.start > 0) {
      this.literatureItems = this.literatureItems.concat(
        this.ftSearchService.getLiteratureData()
      );
      this.valueToShow.literature = this.literatureItems?.length;
    }
    if (this.start === 0) {
      this.generalItems = this.ftSearchService.getGeneralItems();
      this.fundsItems = this.ftSearchService.getFundsItems();
      this.literatureItems = this.ftSearchService.getLiteratureData();
    }
  }
  showSelectedSection() {
    const sectionFilterFromUrl = this.queryParams.filter;
    this.hideAllSections();
    if (sectionFilterFromUrl !== 'all') {
      this.showSections.all = false;
      let id = '';
      switch (sectionFilterFromUrl) {
        case funds:
          id = 'funds_filter_section';
          this.showSections.funds = true;
          break;
        case pages:
          id = 'general_filter_section';
          this.showSections.pages = true;
          break;
      }
      this.selectSection(id);
    } else {
      this.showAllSections();
    }
  }
  viewSearchResults(event) {
    const id = event.target.closest('.search-result-section').id;
    this.selectSection(id);
  }
  hideAllSections() {
    for (const key of Object.keys(this.showSections)) {
      this.showSections[key] = false;
    }
  }
  selectSection(sectionId) {
    this.showSections.all = false;
    this.hideAllSections();
    switch (sectionId) {
      case FILTER_SECTION.fundsFilterSection:
        this.isViewAll.funds = true;
        this.valueToShow.funds = 10;
        this.showSections.funds = true;
        break;
      case FILTER_SECTION.generalFilterSection:
        this.isViewAll.pages = true;
        this.valueToShow.pages = 100;
        this.showSections.pages = true;
        break;
      case FILTER_SECTION.investorFilterSection:
        this.isViewAll.investor = true;
        this.valueToShow.investor = this.investorResults?.length;
        this.showSections.investor = true;
        break;
      case FILTER_SECTION.advisorFilterSection:
        this.isViewAll.advisor = true;
        this.valueToShow.advisor = this.advisorResults?.length;
        this.showSections.advisor = true;
        break;
      case FILTER_SECTION.literatureFilterSection:
        this.isViewAll.literature = true;
        this.valueToShow.literature = 100;
        this.showSections.literature = true;
        break;
      default:
        break;
    }
    this.cdRef.detectChanges();
  }
  checkLink(event) {
    const anchorEl = event
      .composedPath()
      .find((el) => el instanceof HTMLAnchorElement);
    if (anchorEl) {
      return anchorEl;
    }
  }
  resetStartAndEnd() {
    this.start = 0;
    this.end = 100;
  }
  onClickOfSearchFilter(event) {
    const anchorEl = this.checkLink(event);
    const id = anchorEl.id;
    const count = anchorEl.getElementsByClassName('count')[0].textContent;
    this.resetStartAndEnd();
    if (count !== '0') {
      if (id !== 'all_filter') {
        this.selectSection(id + '_section');
      } else {
        this.resetStartAndEnd();
        this.fetchResults(this.start, this.end);
        this.ftSearchService.setValueToShow();
        this.resetViewAll();
        this.showAllSections();
      }
    }
  }
  showAllSections() {
    this.hideAllSections();
    this.showSections.all = true;
  }
  loadMore(event) {
    this.start = this.end;
    this.end = this.end + 100;
    switch (event.type) {
      case pages:
        this.fetchResults(this.start, this.end, pages);
        break;
      case literature:
        this.fetchResults(this.start, this.end, literature);
        break;
      case funds:
        if (this.fundsItems.length < this.totalCount.funds) {
          this.fetchResults(this.start, this.end, funds);
        } else {
          this.valueToShow.funds = this.valueToShow.funds + 10;
        }
        break;
    }
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
