<div
  [style.marginTop.px]="params?.marginTop | spacing"
  [style.marginBottom.px]="params?.marginBottom | spacing"
  [style.paddingLeft.px]="params?.paddingLeft | spacing"
  [style.paddingRight.px]="params?.paddingRight | spacing"
>
  <div
    [ngClass]="{
      'has-edit-button': page?.isPreview(),
      'fti-container': params?.container
    }"
  >
    <ng-container [ngSwitch]="data?.background">
      <ng-container *ngSwitchDefault>
        <h1 *ngIf="data?.title" class="default-title">{{ data!.title }}</h1>
      </ng-container>
    </ng-container>
  </div>
</div>
