import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Logger } from '@utils/logger';
import { TranslateService } from '@components/shared/translate/translate.service';

const logger = Logger.getLogger('ft-date-picker');
import moment from 'moment';
enum DatePickerType {
  MONTH = 'month',
  DATE = 'date',
  YEAR = 'year',
}
@Component({
  selector: 'ft-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnChanges, OnInit {
  @Input() type = DatePickerType.DATE;
  @Input() fundInception: string;
  @Input() displayDate: string;
  @Input() convertToFormat: string;
  @Input() sentDateFormat: string;
  @Input() fundInceptionText = this.translateService.instant(
    `ftiCommon.fund-inception-text`
  );
  @Output() finalDate = new EventEmitter();
  selectedDate: string;
  selectedMonth: string;
  selectedYear: string;
  dateString: string;
  currentYear: number;
  hideYear = false;
  hideMonth = false;
  hideDate = false;
  years = [];
  dates = [];
  months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  private dayFormat = 'D';
  private monthFormat = 'MMM';
  private yearFormat = 'YYYY';

  @ViewChild('popoverUi') public popoverUi: ElementRef;

  constructor(private translateService: TranslateService) {}

  /**
   * Sets calendar years and dates
   */
  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    for (let i = 1900; i <= this.currentYear; i++) {
      this.years.push(i);
    }
    for (let i = 1; i <= 31; i++) {
      this.dates.push(i);
    }
  }

  /**
   * Called on changes of inputs
   */
  ngOnChanges(changes: SimpleChanges): void {
    const displayDate = moment(this.displayDate, this.sentDateFormat);
    this.selectedDate = moment(displayDate).format(this.dayFormat);
    this.selectedMonth = moment(displayDate)
      .format(this.monthFormat)
      .toUpperCase();
    this.selectedYear = moment(displayDate).format(this.yearFormat);
  }

  /**
   * On call of set method
   */
  setSelectedDate(): void {
    this.dateString = moment(
      `${this.selectedDate}-${this.selectedMonth}-${this.selectedYear}`,
      `${this.dayFormat}-${this.monthFormat}-${this.yearFormat}`
    )
      .format(this.convertToFormat)
      ?.toUpperCase();
    this.finalDate.emit(this.dateString);
    this.popoverUi.nativeElement.classList.remove('show');
  }
}
