import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { LiteratureService } from '@services/literature.service';
import { GlobalConfigService } from '@services/global-config-service';
import { Subscription } from 'rxjs';
import { Logger } from '@utils/logger';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import {
  Component as BrComponent,
  Content,
  Page,
  Document,
} from '@bloomreach/spa-sdk';
import { Constant } from 'src/app/ft-constants/constant';

const logger = Logger.getLogger('Media Articles');

export interface MediaArticle {
  title: string;
  date: any;
  longDate: any;
  shortDate: any;
  link: string;
  year: any;
  fileType?: string;
  mediaType: string;
}

export interface Tabs {
  type: string;
  text: string;
}

@Component({
  selector: 'ft-media-articles',
  templateUrl: './media-articles.component.html',
  styleUrls: ['./media-articles.component.scss'],
})
export class MediaArticlesComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  public isLoaded = false;
  public articleBRConfig: any;
  public processedData: MediaArticle[];
  public articleData: MediaArticle[];
  public releasesData: MediaArticle[];
  public filteredData: MediaArticle[];
  public currentMediaType: MediaArticle[];
  public yearsFilterDropdown: Array<number> = [];
  public subscription: Subscription;
  public selectedYear: any;
  public activeTab: string;
  public limitResults: number;
  public threshold = 8;
  public tabs: Tabs[];
  public mediaContacts: any;
  public currentSort = 'desc';

  constructor(
    private litService: LiteratureService,
    private globalConfig: GlobalConfigService,
    private cdr: ChangeDetectorRef
  ) {}

  public mapMediaData = (input): MediaArticle[] => {
    return input.map(
      (doc): MediaArticle => ({
        title: doc.dctermsTitle,
        year: moment(doc.frkReferenceDate).year(),
        date: doc.frkReferenceDate,
        longDate: moment(doc.frkReferenceDate).format(Constant.dateFormat),
        shortDate: moment(doc.frkReferenceDate).format(
          Constant.latestDateFormat
        ),
        link: doc.literatureHref,
        fileType: doc.fileExtension ? doc.fileExtension : 'NA',
        mediaType: doc.dctermsType,
      })
    );
    this.cdr.detectChanges();
  };

  public getYears = (selector) => {
    if (this.activeTab === 'PRESS-ARTICLE') {
      return uniqBy(this.articleData, selector);
    }
    return uniqBy(this.releasesData, selector);
  };

  ngOnInit(): void {
    // Pull BR component data
    const component: BrComponent = this.page
      ?.getComponent('main-content')
      ?.getComponent('IND-media-articles');

    const componentParams: any = component?.getParameters();
    const componentModels: any = component?.getModels();

    const categoryData: Content = this.page?.getContent(
      componentModels?.document
    );

    this.articleBRConfig = categoryData?.getData();

    // Set tabs
    this.tabs = this.articleBRConfig?.mediaArticleTabs.map((tab) => ({
      type: tab.articlesDataParam,
      text: tab.tabTitle,
    }));

    // Set content
    this.mediaContacts = this.articleBRConfig?.mediaArticlesTextBlock?.map(
      (content) => ({
        heading: content.mediaTextBlockTitle,
        markup: content.mediaTextBlockContent.content.value,
      })
    )[0];

    // Call service for article data
    this.subscription = this.litService
      .getData$('?channel=en-in&type=Press Release,Press Article&cache=true')
      ?.subscribe(
        (response) => {
          const rawData = response.document;

          // Set active tab
          this.activeTab = this.tabs[0].type;

          // Set records in descending order
          this.processedData = orderBy(
            this.mapMediaData(rawData),
            ['date'],
            ['desc']
          );

          this.articleData = filter(this.processedData, [
            'mediaType',
            'Press Article',
          ]);

          this.releasesData = filter(this.processedData, [
            'mediaType',
            'Press Release',
          ]);
          // Get Years from Data
          this.getYears('year').forEach((data) => {
            this.yearsFilterDropdown.push(data.year);
          });

          // Set default year in dropdown
          this.selectedYear = this.yearsFilterDropdown[0];
          this.filterData(null, this.articleData);
          // END
          this.isLoaded = true;

          this.cdr.detectChanges();
        },
        (error) => {
          logger.debug(error);
        }
      );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  truncateText(input) {
    if (input.length > 50) {
      return input.substring(0, 50) + '...';
    }
    return input;
  }

  filterData($event?: Event, mediaType?: MediaArticle[]) {
    this.limitResults = this.threshold;
    mediaType = mediaType ? mediaType : this.articleData;
    this.filteredData = filter(mediaType, [
      'year',
      parseInt(this.selectedYear, 10),
    ]);
  }

  onTabClick($event: MouseEvent) {
    $event.preventDefault();
    // Reset Years Dropdown values
    this.yearsFilterDropdown = [];
    const currentTab = ($event.currentTarget as HTMLElement).getAttribute('id');
    this.activeTab = currentTab;

    // Get Years from Data
    this.getYears('year').forEach((data) => {
      this.yearsFilterDropdown.push(data.year);
    });
    // Set default year in dropdown
    this.selectedYear = this.yearsFilterDropdown[0];

    if (currentTab === 'PRESS-ARTICLE') {
      this.currentMediaType = this.articleData;
      this.filterData($event, this.articleData);
    } else {
      this.currentMediaType = this.releasesData;
      this.filterData($event, this.releasesData);
    }
  }

  loadMoreResults($event: MouseEvent) {
    this.limitResults = this.limitResults + this.threshold;
  }

  // Sort by Date
  sortTable($event: MouseEvent, sortSelection) {
    if (sortSelection === 'desc') {
      this.filteredData = orderBy(this.filteredData, ['date'], ['asc']);
      this.currentSort = 'asc';
    } else {
      this.filteredData = orderBy(this.filteredData, ['date'], ['desc']);
      this.currentSort = 'desc';
    }
  }

  get document() {
    const { document } = this.component?.getModels() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
}
