import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TotalCount } from '../search.interface';

@Component({
  selector: 'ft-search-results-general',
  templateUrl: './search-results-general.component.html',
  styleUrls: ['./search-results-general.component.scss'],
})
export class SearchResultsGeneralComponent implements OnInit {
  @Input() results: [];
  @Input() totalCount: TotalCount;
  @Input() valueToShow: number;
  queryParams: Params;
  @Input() isViewAll: boolean;
  @Output()
  viewAllClick: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  loadMoreGeneral: EventEmitter<object> = new EventEmitter<object>();

  clickEvent(event): void {
    this.isViewAll = true;
    this.viewAllClick.emit(event);
  }

  loadMore(event): void {
    this.loadMoreGeneral.emit({ event, type: 'pages' });
  }

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
  }
}
