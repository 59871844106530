<div
  class="horizontalColumnTab"
  [ngClass]="{
    'fti-bgcolor': layout === 'home-page' && numberOfTabs === 3
  }"
>
  <div
    class="fti-container position-relative colTab"
    [ngClass]="{ 'p-10': isVisible }"
  >
    <div class="section-inner-txt">
      <div class="grid">
        <!-- LARGE TABS -->
        <div
          class="large-tabs"
          *ngIf="
            layout === 'largeTabsLayout' ||
            layout === 'large-tabs' ||
            layout === 'home-page'
          "
        >
          <!-- Large tab 7 -->
          <ng-container *ngIf="numberOfTabs === 7">
            <div class="large-tab-section large-tab-seven webView">
              <div class="section-wrp">
                <ul
                  ngbNav
                  #nav="ngbNav"
                  class="nav-tabs grid-row"
                  [destroyOnHide]="false"
                >
                  <li
                    [ngbNavItem]="i"
                    *ngFor="let item of tabArray; index as i"
                    class="sevenCol"
                  >
                    <a ngbNavLink>
                      <span
                        class="tabs-circle"
                        [ngClass]="{
                          'orange-border': i + 1 === 1 || i + 1 === 4,
                          'green-border':
                            i + 1 === 2 || i + 1 === 5 || i + 1 === 7,
                          'red-border': i + 1 === 3 || i + 1 === 6
                        }"
                      >
                        <span class="icon account" *ngIf="i + 1 === 1">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                          <span class="path11"></span>
                          <span class="path12"></span>
                          <span class="path13"></span>
                          <span class="path14"></span>
                        </span>
                        <span class="icon buy" *ngIf="i + 1 === 2"></span>
                        <span class="icon portfolio" *ngIf="i + 1 === 3"></span>
                        <span class="icon register" *ngIf="i + 1 === 4"></span>
                        <span class="icon request" *ngIf="i + 1 === 5">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                          <span class="path6"></span>
                          <span class="path7"></span>
                          <span class="path8"></span>
                          <span class="path9"></span>
                          <span class="path10"></span>
                        </span>
                        <span class="icon dividend" *ngIf="i + 1 === 6"></span>
                        <span class="icon special" *ngIf="i + 1 === 7"></span>
                      </span>

                      <ng-container *ngIf="getTabTitle(i + 1)"
                        ><span class="tab-title">{{
                          getTabTitle(i + 1)
                        }}</span></ng-container
                      >
                    </a>
                    <ng-template ngbNavContent>
                      <ng-container
                        brComponent="tab-area-{{ i + 1 }}"
                      ></ng-container>
                    </ng-template>
                  </li>
                </ul>
              </div>
              <div class="row" style="margin: 0">
                <div class="section-wrp">
                  <div [ngbNavOutlet]="nav" class="mt-0"></div>
                </div>
              </div>
            </div>

            <div class="large-tab-section mobileView">
              <div ngbDropdown class="d-inline-block w-100">
                <button
                  class="btn btn-outline-primary"
                  id="dropdownBasic1"
                  ngbDropdownToggle
                >
                  <span class="spanLabel">{{ tabSelected }}</span>
                </button>
                <div
                  ngbDropdownMenu
                  aria-labelledby="dropdownBasic1"
                  class="dropdownSection"
                >
                  <button
                    ngbDropdownItem
                    *ngFor="let item of tabArray; index as i"
                    (click)="getTabSelectedLabel(i + 1)"
                  >
                    {{ getTabTitle(i + 1) }}
                  </button>
                </div>
              </div>
              <div>
                <ng-container
                  brComponent="tab-area-{{ selectedKey }}"
                ></ng-container>
              </div>
            </div>
          </ng-container>

          <!-- Large tab 2 and Large tab 3 -->
          <ng-container *ngIf="numberOfTabs === 2 || numberOfTabs === 3">
            <div
              [ngClass]="{
                'large-tab-section': numberOfTabs === 2,
                'medium-tab-section': numberOfTabs === 3
              }"
            >
              <div
                class="section-wrp"
                [ngClass]="{ 'pull-up-20': numberOfTabs === 3 }"
              >
                <ul
                  ngbNav
                  #nav="ngbNav"
                  class="nav-tabs grid-row"
                  [destroyOnHide]="false"
                  [activeId]="tab"
                >
                  <li
                    [ngbNavItem]="i"
                    *ngFor="let item of tabArray; index as i"
                    [ngClass]="{
                      twoCol: numberOfTabs === 2
                    }"
                  >
                    <a
                      ngbNavLink
                      class="analytics-tab-link"
                      data-action="Tab click"
                    >
                      <!-- Large tab 2 -->
                      <ng-container *ngIf="numberOfTabs === 2">
                        <span
                          class="tabs-circle"
                          [ngClass]="{
                            'orange-border': i + 1 === 1,
                            'red-border': i + 1 === 2
                          }"
                        >
                          <span
                            class="icon branches"
                            *ngIf="i + 1 === 1"
                          ></span>
                          <span class="icon academy" *ngIf="i + 1 === 2"></span>
                        </span>
                      </ng-container>

                      <!-- Large tab 3 -->
                      <ng-container *ngIf="numberOfTabs === 3">
                        <!-- <span
                          class="icon-fti_planNeeds circle icon removeborder plan"

                        ></span> -->
                        <i
                          class="icon-fti_planNeeds circle removeborder icon"
                          *ngIf="i + 1 === 1"
                        ></i>
                        <span
                          class="circle icon learn"
                          *ngIf="i + 1 === 2"
                        ></span>
                        <span
                          class="circle icon transact"
                          *ngIf="i + 1 === 3"
                        ></span>
                      </ng-container>

                      <ng-container *ngIf="getTabTitle(i + 1)"
                        ><span class="tab-title">{{
                          getTabTitle(i + 1)
                        }}</span></ng-container
                      >
                    </a>
                    <ng-template ngbNavContent>
                      <ng-container
                        brComponent="tab-area-{{ i + 1 }}"
                      ></ng-container>
                    </ng-template>
                  </li>
                </ul>
              </div>
              <div class="row" style="margin: 0">
                <div class="section-wrp">
                  <div [ngbNavOutlet]="nav" class="mt-0"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- SMALL TABS -->
        <div
          class="small-tabs"
          *ngIf="
            layout === 'smallTabsLayout' ||
            layout === 'small-tabs' ||
            layout === 'one-tab'
          "
          [ngClass]="{ 'pt-0': isVisible }"
        >
          <!-- Small tab 4 -->
          <ng-container *ngIf="numberOfTabs === 4">
            <div class="small-tab-section webView">
              <div class="section-wrp">
                <ul
                  ngbNav
                  #nav="ngbNav"
                  class="nav-tabs grid-row"
                  [destroyOnHide]="false"
                  [(activeId)]="tab"
                >
                  <li
                    [ngbNavItem]="i"
                    *ngFor="let item of tabArray; index as i"
                    [ngClass]="{ 'h-auto': isVisible }"
                  >
                    <a
                      ngbNavLink
                      data-toggle="tab"
                      [href]="tabNameToAnchor(i + 1)"
                      (click)="
                        navigateTab(
                          'tab-area-' + (i + 1),
                          tabNameToAnchor(i + 1)
                        )
                      "
                      [ngClass]="{ 'ft-13 custom-padding': isVisible }"
                    >
                      <ng-container *ngIf="getTabTitle(i + 1)">{{
                        getTabTitle(i + 1)
                      }}</ng-container>
                    </a>
                    <ng-template ngbNavContent>
                      <ng-container
                        brComponent="tab-area-{{ i + 1 }}"
                      ></ng-container>
                    </ng-template>
                  </li>
                  <li *ngIf="isLayoutWithSelect">
                    <select>
                      <option>Latest Articles & Interviews</option>
                      <option>2021</option>
                      <option>2020</option>
                      <option>2019</option>
                      <option>2018</option>
                      <option>2017</option>
                      <option>2016</option>
                    </select>
                  </li>
                </ul>
              </div>
              <div class="row bg-white" style="margin: 0">
                <div class="section-wrp">
                  <div [ngbNavOutlet]="nav" class="mt-0"></div>
                </div>
              </div>
            </div>

            <div class="small-tab-section mobileView">
              <div ngbDropdown class="d-inline-block w-100">
                <button
                  class="btn btn-outline-primary"
                  id="dropdownBasic1"
                  ngbDropdownToggle
                >
                  <span class="spanLabel">{{ tabSelected }}</span>
                </button>
                <div
                  ngbDropdownMenu
                  aria-labelledby="dropdownBasic1"
                  class="dropdownSection"
                >
                  <button
                    ngbDropdownItem
                    *ngFor="let item of tabArray; index as i"
                    (click)="getTabSelectedLabel(i + 1)"
                  >
                    {{ getTabTitle(i + 1) }}
                  </button>
                </div>
              </div>
              <div>
                <ng-container
                  brComponent="tab-area-{{ selectedKey }}"
                ></ng-container>
              </div>
            </div>
          </ng-container>

          <!-- Small tab 1, Small tab 2 and Small tab 5 -->
          <ng-container
            *ngIf="
              numberOfTabs === 1 || numberOfTabs === 2 || numberOfTabs === 5
            "
          >
            <div class="small-tab-section">
              <div class="section-wrp">
                <ul
                  ngbNav
                  #nav="ngbNav"
                  class="nav-tabs grid-row"
                  [destroyOnHide]="false"
                  [(activeId)]="tab"
                >
                  <li
                    [ngbNavItem]="i"
                    *ngFor="let item of tabArray; index as i"
                    [ngClass]="{ 'h-auto': isVisible }"
                  >
                    <a
                      ngbNavLink
                      (click)="navigateTab('tab-area-' + (i + 1))"
                      [ngClass]="{ 'ft-13 custom-padding': isVisible }"
                    >
                      <ng-container *ngIf="getTabTitle(i + 1)">{{
                        getTabTitle(i + 1)
                      }}</ng-container>
                    </a>
                    <ng-template ngbNavContent>
                      <ng-container
                        brComponent="tab-area-{{ i + 1 }}"
                      ></ng-container>
                    </ng-template>
                  </li>
                  <li *ngIf="isLayoutWithSelect">
                    <select>
                      <option>Latest Articles & Interviews</option>
                      <option>2021</option>
                      <option>2020</option>
                      <option>2019</option>
                      <option>2018</option>
                      <option>2017</option>
                      <option>2016</option>
                    </select>
                  </li>
                </ul>
              </div>
              <div class="row bg-white" style="margin: 0">
                <div class="section-wrp">
                  <div [ngbNavOutlet]="nav" class="mt-0"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
