<div class="fti-container">
  <div class="col-12 content-container">
    <div class="row">
      <div class="col-sm-6 col-12">
        <ng-container brComponent="title-content"></ng-container>
      </div>
      <div class="col-sm-6 col-12">
        <ng-container brComponent="lead-content"></ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ng-container brComponent="middle-content"></ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ng-container brComponent="disclaimer"></ng-container>
      </div>
    </div>
  </div>
</div>
