import { SharedComponentMapping } from '@shared/shared.module';
import { Observable, of, from, forkJoin } from 'rxjs';
import { Compiler, Injectable, Injector, NgModuleRef } from '@angular/core';
import {
  Component,
  ContainerItem,
  isContainerItem,
  Page,
  TYPE_CONTAINER_ITEM_UNDEFINED,
} from '@bloomreach/spa-sdk/';
import { CaveatService } from '@products/caveats/services/caveat.service';
import {
  ComponentMapping,
  Dependency,
  DynamicModule,
  ModuleLoaderConfig,
} from './module-loader.config';
import { Logger } from '@utils/logger';
import { map } from 'rxjs/operators';
import { FundDocumentsComponent } from '@products/fund-documents/fund-documents.component';
import { FundReportsComponent } from '@components/literature/fund-reports/fund-reports.component';
import { MediaArticlesComponent } from '@marketing/media-articles/media-articles.component';
import { VerticalTabsComponent } from '@marketing/vertical-tabs/vertical-tabs.component';
import { CsrInitiativesComponent } from '@marketing/csr-initiatives/csr-initiatives.component';
import { LatestUpdatesComponent } from '@marketing/latest-updates/latest-updates.component';
import { AppStateService } from './app-state.service';

const logger = Logger.getLogger('ModuleLoaderService');

@Injectable({
  providedIn: 'root',
})
export class ModuleLoaderService {
  /**
   * Bloomreach component mappings
   */
  mapping: ComponentMapping = {
    ...SharedComponentMapping,
    'Fund Document': FundDocumentsComponent,
    'IND Literature Listing': FundReportsComponent,
    'IND Latest Lit Updates': LatestUpdatesComponent,
    'ind-media-articles': MediaArticlesComponent,
    'IND Vertical Tabs': VerticalTabsComponent,
    'IND CSR Initiatives': CsrInitiativesComponent,
  };

  isDebug: boolean;
  private loadedDependencies: Dependency[] = []; // keep track so only loaded once

  constructor(
    private compiler: Compiler,
    private injector: Injector,
    appState: AppStateService
  ) {
    this.isDebug = appState.isDebugPageModel();
  }

  getMapping$(page: Page): Observable<any> {
    const components = this.getComponentsPresent(page);
    const modules: ModuleLoaderConfig[] = ModuleLoaderConfig.getRequiredModules(
      components,
      this.isDebug
    );
    if (modules.length > 0) {
      if (this.isDebug) {
        this.mapping = {};
      }
      return this.loadModules$(modules);
    } else {
      return of(this.mapping);
    }
  }

  getCurrentMapping(): ComponentMapping {
    return this.mapping;
  }

  private loadModules$(modules: ModuleLoaderConfig[]) {
    return forkJoin([
      this.loadDependencies(modules),
      from(
        new Promise<ComponentMapping>((resolve) => {
          this.loadModulesSynchronously(
            modules.map((config) => config.moduleLoader()), // initiates loading js
            resolve
          );
        }).then((mapping) => mapping)
      ),
    ]).pipe(map((results) => results[1]));
  }

  /**
   * Although loading a module is necessarily asynchronous, it is imperative that we only try and load one module at a time.
   * Otherwise the DependencyInjector gets all confused!
   */
  private loadModulesSynchronously(
    modules: Promise<any>[],
    resolve: (val: any) => any
  ) {
    if (modules.length === 0) {
      resolve(this.mapping);
    } else {
      this.loadModule(modules.shift()) // load next module and then call recursively until done.
        .then(() => this.loadModulesSynchronously(modules, resolve));
    }
  }

  /**
   * Based on https://stackoverflow.com/questions/60971689/how-to-dynamically-lazy-load-module-without-router-angular-9
   */
  private loadModule(modulePromise: Promise<any>): Promise<ComponentMapping> {
    return modulePromise
      .then((moduleObj) => moduleObj[Object.keys(moduleObj)[0]])
      .then((module) => this.compiler.compileModuleAsync(module))
      .then((moduleFactory) => moduleFactory.create(this.injector))
      .then((moduleRef: NgModuleRef<unknown>) => {
        const dynamicModule = moduleRef.instance as DynamicModule;
        Object.assign(this.mapping, dynamicModule.getComponentMapping());
        return this.mapping;
      })
      .catch((e) => {
        logger.error('Failed to load module', modulePromise, e);
      })
      .then(() => this.mapping);
  }

  private getComponentsPresent(page: Page): string[] {
    const componentNames = [page.getComponent().getParameters().layout];
    const components: any[] = [...page.getComponent().getChildren()];
    this.loadComponentsPresent(components, componentNames);
    return componentNames.filter((i) => i !== undefined);
  }

  private loadComponentsPresent(
    components: Component[],
    componentNames: string[]
  ): void {
    components.forEach((item) => {
      if (isContainerItem(item)) {
        const label = (item as ContainerItem).getLabel();
        componentNames.push(label);
      }

      const children = item.getChildren();
      if (children?.length) {
        this.loadComponentsPresent(children, componentNames);
      }
    });
  }

  // TODO move to new page-container.service
  initializeFootnotes(page: Page, caveatService: CaveatService) {
    caveatService.initialiseFootnotesByComponent(
      page,
      Object.keys(this.mapping)
    );
  }

  /**
   * Load set of module dependencies.
   * Note these should not be other modules as dependencies are loaded in parallel to the modules
   * but modules need to loaded sequentially.
   */
  private loadDependencies(
    modules: ModuleLoaderConfig[]
  ): Observable<boolean[]> {
    const dependenciesToLoad: Observable<boolean>[] = [];
    modules
      .filter((module) => module.dependencies)
      .map((module) => module.dependencies)
      .flat()
      .forEach((dependency) => {
        if (!this.loadedDependencies.includes(dependency)) {
          this.loadedDependencies.push(dependency); // dont try to reload more than once
          switch (dependency) {
            default:
              logger.error('Unknown dependency', dependency);
              break;
          }
        }
      });
    if (dependenciesToLoad.length > 0) {
      return forkJoin(dependenciesToLoad);
    }
    return of([]);
  }
}
