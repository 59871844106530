import { TranslateService } from '@shared/translate/translate.service';
import { Injectable } from '@angular/core';
import { Logger } from '@utils/logger';
import { ResourceBundleModels } from '@types';
import { DebugService } from '@services/debug.service';
import { SiteConfigService } from '@services/site-config.service';
import { EnvConfig, EnvConfigService } from '@services/env-config.service';

const logger = Logger.getLogger('LabelLoader');

export interface TranslateLabels {
  products?: any;
  pds?: any;
  literature?: any;
  signin?: any;
  ftiCommon?: any;
  ftiVideoArticle?: any;
  ftiFeedback?: any;
  ftiInterested?: any;
  ftiFlashMessages?: any;
  ftiMetaData?: any;
  ftiBacklinks?: any;
  ftiLayouts?: any;
  ftiLoginRegister?: any;
  ftiNewInvestor?: any;
  accountsNotification?: any;
  languages?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LabelLoader {
  static showKeys = false;
  private readonly envConfig: EnvConfig;

  constructor(
    private translateService: TranslateService,
    private envConfigService: EnvConfigService,
    private debugService: DebugService,
    private siteConfig: SiteConfigService
  ) {
    this.debugService
      .isShowLabelKeys$()
      ?.subscribe((flag) => (LabelLoader.showKeys = flag));

    // loading config
    if (this.envConfigService) {
      this.envConfig = this.envConfigService.getEnvConfig();
    }
    // TODO: Get profile information to cover PROFILE tokens in labels.
  }

  /**
   * To set labels for translation
   * Extract the resourcebundles for labels from models Object
   * and pass it to setTranslation method of NGX-Translate with namespaces
   * @param models ResourceBundleModels Object
   */
  setLabelTranslation(models: ResourceBundleModels) {
    // check to see if they are already loaded.
    if (!this.translateService.isLoaded()) {
      const productLabels = models['ft.india.products'];
      const literatureLabels = models['ft.india.literature'];
      const ftiCommonLabels = models['ft.core.ftiCommon'];
      const ftiVideoArticleLabels = models['ft.core.ftiVideoArticle'];
      const ftiFeedbackLabels = models['ft.core.ftiFeedback'];
      const ftiInterestedLabels = models['ft.core.ftiInterested'];
      const ftiFlashMessagesLabels = models['ft.core.ftiFlashMessages'];
      const ftiMetaDataLabels = models['ft.core.ftiMetaData'];
      const ftiBacklinksLabels = models['ft.core.ftiBacklinks'];
      const ftiLayoutsLabels = models['ft.core.ftiLayouts'];
      const ftiLoginRegisterLabels = models['ft.core.ftiLoginRegister'];
      const ftiNewInvestorLabels = models['ft.core.ftiNewinvestor'];

      const translateLabels: TranslateLabels = {
        products: productLabels,
        literature: literatureLabels,
        ftiCommon: ftiCommonLabels,
        ftiVideoArticle: ftiVideoArticleLabels,
        ftiFeedback: ftiFeedbackLabels,
        ftiInterested: ftiInterestedLabels,
        ftiFlashMessages: ftiFlashMessagesLabels,
        ftiMetaData: ftiMetaDataLabels,
        ftiBacklinks: ftiBacklinksLabels,
        ftiLayouts: ftiLayoutsLabels,
        ftiLoginRegister: ftiLoginRegisterLabels,
        ftiNewInvestor: ftiNewInvestorLabels,
      };
      this.addLabels(translateLabels);
    }
  }

  loadPdsTranslations(pdsLabels: Record<string, string>) {
    this.addLabels({ pds: pdsLabels }, false);
    logger.debug('PDS Labels loaded');
  }

  loadLabelOverrides() {
    this.translateService.setFundOverrides(
      this.siteConfig.product?.general?.labelOverrideList
    );
  }

  private addLabels(labels: TranslateLabels, ignoreOverrides = true) {
    // if showKeys then let MissingLabelHandler kick in.
    if (!LabelLoader.showKeys) {
      this.translateService.setTranslation(
        labels,
        ignoreOverrides,
        this.envConfig
      );
    }
  }
}
