import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  Component as BrComponent,
  Page,
  Document,
  Reference,
} from '@bloomreach/spa-sdk';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Logger } from '@utils/logger';
import { getPageUrl } from '@utils/pagemodel-utils';

const logger = Logger.getLogger('QuickLinkListComponent');

@Component({
  selector: 'ft-quick-link-list',
  templateUrl: './quick-link-list.component.html',
  styleUrls: ['./quick-link-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickLinkListComponent implements OnInit {
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  showNavigationArrows = false;
  showNavigationIndicators = false;
  quickLinksBackgroundImage: any;
  @Input() component!: BrComponent;
  @Input() page: Page;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.quickLinksBackgroundImage = this.translateService.instant(
      'ftiCommon.quickLinksBackgroundImage'
    );

    logger.debug('quicklinksImage>>', this.quickLinksBackgroundImage);
  }

  getUrl() {
    return "url('" + this.quickLinksBackgroundImage + "')";
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  get content() {
    return this.document?.getData();
  }

  get linkLists() {
    return this.content?.linkList;
  }

  get title() {
    return this.content?.titleBlock?.title;
  }

  getPageUrl(ref: Reference) {
    return getPageUrl(this.page, ref);
  }

  get isPreview() {
    return this?.page?.isPreview() || {};
  }
}
