<div class="displayList">
  <ng-container *ngIf="contentType === 'SIP Calculator'">
    <ft-sip-calculator [page]="page" [title]="title"></ft-sip-calculator>
  </ng-container>
  <div class="blogList" *ngIf="contentType === 'LUMPSUM Calculator'">
    <ft-lumpsum-calculator
      [page]="page"
      [title]="title"
    ></ft-lumpsum-calculator>
  </div>
</div>
