import * as Highcharts from 'highcharts';

export type HighchartThemeFonts = {
  colors: {
    grey: string;
    orange: string;
  };
  size: {
    // 16px reduced to 12
    p: string;
    small: string;
  };
  fontFamily: string;
  fontFamilyNormal: string;
  fontFamilyVerdana: string;
};

export abstract class HighchartsThemeService {
  protected abstract themeColors: string[];

  // optionally subclass can override this list if it defines a short list
  protected themeColorsShort: string[] = [];

  // specific color to use for benchmark columns
  // WARNING: color should be different from colors used in themeColors and themeColorsShort
  public abstract readonly benchmarkColor: string;

  // replaces FrkMapColors
  // TODO: should this be exposed publically?
  public abstract readonly mapColors: string[];

  // replaces FrkThemeMonoChromaticColors
  public abstract readonly monochromaticColors: string[];

  // replaces FrkThemeGreyScaleColors
  public abstract readonly greyScaleColors: string[];

  // NGC-11924. Tooltip BG Color. For now, all brands use same color
  // can be overridden in brand theme service if brand specific color needed
  protected tooltipBGColor = '#F8F8F8';

  // replaces line chart colors
  public abstract readonly lineGraphThemeColors: string[];

  // replaces FrkFont
  public abstract readonly themeFonts: HighchartThemeFonts;

  // replaces getFrkThemeColor()
  public getPointColor(index: number, totalPoints?: number): string {
    return totalPoints && totalPoints <= this.themeColorsShort.length
      ? this.themeColorsShort[index]
      : this.themeColors[index % this.themeColors.length]; // % means we'll go back to start of color list if not enough colors defined
  }

  // replaces FrkThemeOptions
  public get themeOptions(): Highcharts.Options {
    const themeScope = this;
    return {
      colors: this.themeColors,
      chart: {
        // set base font for entire chart - prevents chart from defaulting to highcharts root/ Lucidia font
        style: {
          fontFamily: this.themeFonts.fontFamilyNormal,
        },
        reflow: true,
        backgroundColor: 'transparent',
        events: {
          load(): void {
            // When 6 or less data points, use a different list of colors
            try {
              // wrap in try catch, so any unexpected failures won't break chart
              const numColors: number = themeScope.themeColorsShort.length;
              if (
                this.series.length <= numColors &&
                (this.series[0].data.length <= numColors ||
                  !(this.series[0].options as Highcharts.SeriesBarOptions)
                    .colorByPoint)
              ) {
                this.update({ colors: themeScope.themeColorsShort });
              }
            } catch (e) {
              // fail silently, and keep long list
            }
          },
        },
      },
      title: {
        style: {
          color: this.themeFonts.colors.grey,
          fontFamily: this.themeFonts.fontFamily,
        },
      },
      subtitle: {
        style: {
          color: this.themeFonts.colors.grey,
          fontFamily: this.themeFonts.fontFamilyNormal,
        },
      },
      // sets default xAxis title and labels
      xAxis: {
        title: {
          style: {
            color: this.themeFonts.colors.grey,
            fontSize: this.themeFonts.size.p,
            fontFamily: this.themeFonts.fontFamilyNormal,
          },
        },
        labels: {
          style: {
            color: this.themeFonts.colors.grey,
            fontSize: this.themeFonts.size.p,
            fontFamily: this.themeFonts.fontFamilyNormal,
          },
        },
      },
      // sets default yAxis title and labels
      yAxis: {
        title: {
          style: {
            color: this.themeFonts.colors.grey,
            fontSize: this.themeFonts.size.p,
            fontFamily: this.themeFonts.fontFamilyNormal,
          },
        },
        labels: {
          style: {
            color: this.themeFonts.colors.grey,
            fontSize: this.themeFonts.size.p,
            fontFamily: this.themeFonts.fontFamilyNormal,
          },
        },
      },
      // sets default "hightcharts" legend styling
      legend: {
        itemStyle: {
          fontFamily: this.themeFonts.fontFamilyNormal,
          fontSize: this.themeFonts.size.p,
          // color is defined as #212121 (black)
          color: this.themeFonts.colors.grey,
          // design spec is not bold
          fontWeight: 'normal',
        },
        itemHoverStyle: {
          color: 'gray',
        },
      },
      credits: {
        text: '',
      },
      // pie chart default font settings
      plotOptions: {
        pie: {
          dataLabels: {
            style: {
              color: this.themeFonts.colors.grey,
              fontSize: this.themeFonts.size.p,
              fontFamily: this.themeFonts.fontFamilyNormal,
              fontWeight: 'normal',
            },
          },
        },
      },
      tooltip: {
        backgroundColor: this.tooltipBGColor,
      },
    };
  }

  // replaces FrkMapOptions
  public get mapOptions(): Highcharts.Options {
    return {
      chart: {
        reflow: true,
        borderWidth: 0,
        height: 500,
        style: {
          fontFamily: this.themeFonts.fontFamilyNormal,
        },
      },
      tooltip: {
        backgroundColor: '#fff',
        borderWidth: 0,
        style: {
          padding: '20px',
          width: 200,
        },
      },
      title: {
        text: '',
        style: {
          fontFamily: this.themeFonts.fontFamily,
          // fontWeight: '400',
        },
      },
      subtitle: {
        text: '',
        align: 'left',
      },

      legend: {
        enabled: false,
      },

      mapNavigation: {
        enabled: true,
      },
    };
  }
}
