<div
  class="container-xl p-0"
  [ngClass]="{ 'has-edit-button': page?.isPreview() }"
>
  <ng-container
    *ngIf="page?.isPreview()"
    [brManageContentButton]="document"
    documentTemplateQuery="new-ftindia-document"
    folderTemplateQuery="new-ftindia-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
  <div
    *ngIf="contentBlock"
    [ngStyle]="{
      'background-image': getImageURL(
        contentBlock?.widenAssetCompound?.widenDocument
      )
    }"
    class="contentContainerPGNC"
    [innerHTML]="htmlCode(contentBlock?.html?.value)"
  ></div>
</div>
