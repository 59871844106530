<div
  class="richTextContent"
  [ngClass]="{
    'online-services': onlineServices,
    'way-to-transact-with-us': waysToTransactWithUs,
    'about-us': aboutus
  }"
>
  <div
    class="position-relative"
    [ngClass]="{
      container: onlineServices,
      'fti-container': !onlineServices && !aboutus
    }"
  >
    <div class="section-inner-txt">
      <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
        <ng-container
          *ngIf="page?.isPreview()"
          [brManageContentButton]="document"
          documentTemplateQuery="new-ftindia-document"
          folderTemplateQuery="new-ftindia-folder"
          parameter="document"
          [relative]="false"
          root=""
        >
        </ng-container>
        <ng-container *ngFor="let content of item; let i = index">
          <ng-container
            *ngIf="
              content?.widenAssetCompound?.contentType ==
              'ftcore:WidenAssetCompound'
            "
          >
            <div
              class="cams-image"
              *ngIf="content?.widenAssetCompound?.widenDocument"
            >
              <img [src]="getWiden(content)" alt="imageWiden" />
            </div>
          </ng-container>
          <h3
            class=""
            *ngIf="
              content?.title && currentPathValue != 'why-franklin-templeton'
            "
          >
            {{ content?.title }}
          </h3>
          <div
            *ngIf="document"
            class="section-wrp"
            [ngClass]="{ newToInvesting: isNewToInvesting }"
          >
            <div
              [ngClass]="{ 'text-center': isNewToInvesting }"
              [innerHTML]="htmlCode(content?.content?.content?.value)"
            ></div>
            <div
              *ngIf="content?.html?.value && content?.html?.value !== ''"
              [innerHTML]="htmlCode(content?.html?.value)"
            ></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
