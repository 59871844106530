import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component as BrComponent } from '@bloomreach/spa-sdk';
import { LOCATION, WINDOW } from '@ng-web-apis/common';
import { AppStateService } from '@services/app-state.service';
import { CommonService } from '@services/common.service';
import { EnvConfig } from '@services/env-config.service';
import { LoginService, OtpOption } from '@services/login.service';
import { SessionService } from '@services/session.service';
import { LOCATION_LABL } from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { Constant } from '../../../ft-constants/constant';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@shared/translate/translate.service';
import { StorageService } from '@services/storage.service';
import { FormService } from '@services/form.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SafeHtml } from '@angular/platform-browser';
import { InUserType } from '@types';

const logger = Logger.getLogger('LoginRegisterFormComponent');

@Component({
  selector: 'ft-login-register-form',
  templateUrl: './login-register-form.component.html',
  styleUrls: ['./login-register-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginRegisterFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page;
  @Input() loginRegisterData;

  // Login variables
  public loginFormInvestorUserID: FormGroup;
  public loginFormInvestorUserPAN: FormGroup;
  public investorOTPForm: FormGroup;
  public investorPANOTPForm: FormGroup;
  public investorBankAccFrm: FormGroup;
  public distOTPForm: FormGroup;
  public loginFormDistributor: FormGroup;
  public investorTab = true;
  public distributorTab = false;
  public useidTab = true;
  public usepanTab = false;
  public successStatus = '';
  public errors: any = [];
  public validated: boolean;
  public loginWrapper = true;
  public maintenance$: Observable<SafeHtml>;
  public otpError = false;
  public passwordFieldType = 'password';
  // Private variables
  private loader: any;
  private env: EnvConfig;
  private panOtpRemember: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public registerPan: boolean;
  @ViewChild('radioButtonUserId') radioButtonUserId;
  @ViewChild('radioButtonPan') radioButtonPan;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 needs mor than 7 parameters in constructor
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private commonService: CommonService,
    private sessionService: SessionService,
    private changeDetector: ChangeDetectorRef,
    private appStateService: AppStateService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private formService: FormService,
    @Inject(DOCUMENT) readonly documentRef: Document,
    @Inject(LOCATION) readonly locationRef: Location,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    this.createLoginInvestorUserID();
    this.createLoginInvestorUserPAN();
    this.createLoginDistributor();
    this.createInvestorOTPForm();
    this.createBankAccountForm();
    this.maintenance$ = this.loginService.checkMaintenance();

  }

  ngOnInit(): void {
    this.loginService
      .getValidated$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((validated) => {
        this.validated = validated;
        this.changeDetector.detectChanges();
      });
    this.env = this.appStateService.getEnvConfig();
    this.loader = this.documentRef.getElementById('loader');
  }

  ngAfterViewInit() {
    this.defaultRadioChecked();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public defaultRadioChecked(): void {
    // We are not showing the form until accounts validation
    this.loginService
      .getValidated$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((valid: boolean) => {
        if (valid) {
          this.radioButtonUserId.nativeElement.checked = true;
        }
      });
  }

  /**
   * Set Selected Tab
   * @param selectedValue - string value from tab
   */
  public setSelectedTab(selectedValue: string): void {
    this.loginFormsReset();
    const investor = 'investor';
    const distributor = 'distributor';
    if (selectedValue === investor) {
      this.investorTab = true;
      this.distributorTab = false;
    }
    if (selectedValue === distributor) {
      this.investorTab = false;
      this.distributorTab = true;
    }
  }

  /**
   * Set Clicked Radio
   * @param value - string radio value
   */
  public setClickRadio(value: string): void {
    this.loginFormsReset();
    if (value === 'useid') {
      this.useidTab = true;
      this.usepanTab = false;
    }
    if (value === 'usepan') {
      this.usepanTab = true;
      this.useidTab = false;
    }
  }

  get document() {
    if (this.loginRegisterData) {
      this.component = this.loginRegisterData;
    }

    const { document } = this.component?.getModels() || {};
    const content = document && this.page?.getContent(document);
    logger.debug('Return Content: ', content);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get isPreview() {
    return this.page.isPreview();
  }

  /**
   * Check numeric entry
   * @param event - key up event
   */
  public onlyNumeric(event: KeyboardEvent): boolean {
    // TODO: update deprecated symbols.
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  public loginUserID(isDistributor?): void {
    this.loader.style.display = 'block';
    const submitObject = {
      signInForm: isDistributor
        ? this.loginFormDistributor.value
        : this.loginFormInvestorUserID.value,
    };
    const loginForm = {
      userId: submitObject?.signInForm?.userid,
      password: submitObject?.signInForm?.password,
      userType: isDistributor ? InUserType.ADVISOR : InUserType.INVESTOR,
    };
    const loginDetails = JSON.stringify(loginForm);
    this.sessionService
      .authenticateUser$(loginDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.errors = null;
          this.loader.style.display = 'none';
          this.loginService.trackIdLogIn(
            isDistributor ? InUserType.ADVISOR : InUserType.INVESTOR,
            loginForm.userId
          );
          if (response.body.hasOwnProperty('userTokenResponse')) {
            this.loginService.trackIdLoginSuccess(
              isDistributor ? InUserType.ADVISOR : InUserType.INVESTOR
            );
            this.loginService.logIn(response.body);
          } else {
            this.storageService.setCookieByName(
              response.body,
              'guId',
              this.env.baseCookieVal
            );
            if (response.body.userRedirectURI) {
              this.windowRef.location.href = response.body.userRedirectURI;
            }
            const isDistOtp: OtpOption | undefined = isDistributor
              ? OtpOption.DISTOTP
              : undefined;
            this.setOtpForm(response, isDistOtp);
            this.successStatus = response.body.status;
            this.changeDetector.detectChanges();
          }
        },
        (error: any) => {
          this.loginService.trackLoginIdFailure(
            isDistributor ? InUserType.ADVISOR : InUserType.INVESTOR
          );
          this.errorHandling(error);
        }
      );
  }

  public generateOTP(otpOption?: OtpOption): void {
    this.loader.style.display = 'block';
    this.sessionService
      .generateOfflineOTP$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.errors = null;
          this.loader.style.display = 'none';
          this.loginService.trackOtpResend(otpOption);
          if (response.body.successFlag === 'Y') {
            this.storageService.setCookieByName(
              response.body,
              'guId',
              this.env.baseCookieVal
            );
          } else if (response.successFlag === 'N') {
            this.errors = this.loginService.errorStatus(
              null,
              response.body.status
            );
            this.changeDetector.detectChanges();
          }
        },
        (error: any) => {
          this.errorHandling(error);
        }
      );
  }

  public checkPAN(): void {
    this.registerPan = false;
    this.documentRef.getElementById('btnRegisterOtpMenu').style.display =
      'none';
    const val = this.loginFormInvestorUserPAN.value.userpan.toUpperCase();
    const loginForm = {
      panNo: val,
      userType: InUserType.INVESTOR,
      panDistId: '',
      sourceFlag: '',
    };
    const loginDetails = JSON.stringify(loginForm);
    if (val.length === 10 && Constant.panRegex.test(val)) {
      this.loader.style.display = 'block';
      this.sessionService
        .validatePAN$(loginDetails)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            this.errors = null;
            this.loader.style.display = 'none';
            logger.debug('response:::::', response);
            if (
              response &&
              response.status === 201 &&
              response.headers.get(LOCATION_LABL)
            ) {
              this.windowRef.location.href = response.headers.get(
                LOCATION_LABL
              );
            } else {
              if (!response.body.onlineUser) {
                this.registerPan = true;
                this.documentRef.getElementById(
                  'btnRegisterOtpMenu'
                ).style.display = 'block';
              } else {
                logger.debug(response);
                this.loginService.trackPan(loginForm.panNo);
                this.storageService.clearCookies(this.env.baseCookieVal);
                this.loginService.setResponseCookies(response.body);
                this.panOtpRemember = response.body?.rememberMe;
                this.documentRef.getElementById(
                  'investorPANOTPForm'
                ).style.display = 'block';
                this.documentRef.getElementById('editPan').style.display =
                  'block';
                this.loginFormInvestorUserPAN.get('userpan').disable();
                this.successStatus =
                  response.body.successFlag === 'Y'
                    ? response.body.status
                    : null;
                this.loginService.trackOtpSent();
              }
              this.changeDetector.detectChanges();
            }
          },
          (error: any) => {
            this.documentRef.getElementById('editPan').style.display = 'block';
            this.loginFormInvestorUserPAN.get('userpan').disable();
            this.loginService.trackPanLoginFailure();
            this.errorHandling(error);
          }
        );
    }
  }

  public validateOTP(otpOption?: OtpOption): void {
    this.loader.style.display = 'block';
    const submitObject = this.getOtpOption(otpOption);
    const rememberMeVal = this.setOtpRememberMeCheck(otpOption);
    const loginForm = {
      OTP: submitObject?.signInForm?.otp.toUpperCase(),
      rememberMe: rememberMeVal,
    };
    const loginDetails = JSON.stringify(loginForm);
    this.sessionService
      .validateOTP$(loginDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.loader.style.display = this.errors = null;
          this.storageService.clearCookies(this.env.baseCookieVal);
          this.loginService.trackOtpSubmit(otpOption);
          if (response.body.hasOwnProperty('userTokenResponse')) {
            if (
              otpOption === OtpOption.PANOTP &&
              response.body?.isRememberMe === false
            ) {
              this.setBankAccountForm();
            } else {
              const validateResponse = this.loginService.validateSessionResponse(
                response.body.userTokenResponse
              );
              if (!validateResponse.isValid) {
                this.errors = validateResponse.errorMsg;
                this.changeDetector.detectChanges();
                return;
              }
              this.loginService.trackOtpLogin(otpOption);
              this.loginService.logIn(response.body);
            }
          } else {
            if (otpOption === OtpOption.PANOTP) {
              this.setBankAccountForm();
            } else {
              this.setOtpForm(response, OtpOption.DISTOTP);
              this.successStatus = response.body.status;
              this.changeDetector.detectChanges();
            }
          }
        },
        (error: any) => {
          this.otpError = true;
          if (otpOption === OtpOption.PANOTP) {
            this.loginService.trackPanLoginFailure();
          } else if (otpOption === OtpOption.DISTOTP) {
            this.loginService.trackLoginIdFailure(InUserType.ADVISOR);
          } else {
            this.loginService.trackLoginIdFailure(InUserType.INVESTOR);
          }
          this.errorHandling(error);
        }
      );
  }

  public validateBankAcc(): void {
    this.loader.style.display = 'block';
    const submitObject = {
      signInForm: this.investorBankAccFrm.value,
    };

    const rememberMeVal = (this.documentRef.getElementById(
      'userLoginRememberMeBankAcc'
    ) as HTMLInputElement).checked
      ? 'Y'
      : 'N';
    const loginForm = {
      bankAccountNumber: submitObject?.signInForm?.accountNo,
      rememberMe: rememberMeVal,
    };
    const loginDetails = JSON.stringify(loginForm);
    this.sessionService
      .validateBankAcc$(loginDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.errors = null;
          this.loader.style.display = 'none';
          this.storageService.clearCookies(this.env.baseCookieVal);
          this.loginService.trackBankAccSubmit(loginForm.bankAccountNumber);
          if (response.body.hasOwnProperty('userTokenResponse')) {
            this.loginService.trackPanSuccess();
            this.loginService.logIn(response.body);
          }
        },
        (error: any) => {
          this.loginService.trackPanLoginFailure();
          this.errorHandling(error);
        }
      );
  }

  public backToPAN(): void {
    this.loginFormsReset();
    this.errors = null;
    this.documentRef.getElementById('investorPANOTPForm').style.display =
      'none';
    this.documentRef.getElementById('investorLoginBankAccFrm').style.display =
      'none';
    this.documentRef.getElementById('editPan').style.display = 'none';
    this.documentRef.getElementById('investorLoginPANFrm').style.display =
      'block';
    this.loginWrapper = true;
    this.usepanTab = true;
    this.changeDetector.detectChanges();
    this.radioButtonPan.nativeElement.checked = true;
  }

  public backToLogin(isDistributor?: boolean): void {
    this.loginFormsReset();
    this.errors = null;
    this.loginWrapper = true;
    this.changeDetector.detectChanges();
    this.radioButtonUserId.nativeElement.checked = true;
    if (isDistributor) {
      this.documentRef.getElementById('distOTPForm').style.display = 'none';
      this.documentRef.getElementById('loginFormDistributor').style.display =
        'block';
      return;
    }
    this.documentRef.getElementById('investorOTPForm').style.display = 'none';
    this.documentRef.getElementById('investorLoginForm').style.display =
      'block';
  }

  public registerNow(event: MouseEvent, userType: string): void {
    event.preventDefault();
    this.loginService.trackRegisterNow(userType as InUserType);
    this.windowRef.location.href = this.translateService.instant(
      'ftiLoginRegister.investorRegisterUrl'
    );
  }

  public forgotUid(userType: string): void {
    this.loginService.trackForgotUser(userType as InUserType);
  }

  public forgotPass(userType: string): void {
    this.loginService.trackForgotPass(userType as InUserType);
  }

  /**
   * Disabling copy paste for server functionality
   * @param event - ClipboardEvent
   */
  public onPaste(event: ClipboardEvent) {
    this.formService.onPasteForms(event);
  }

  public checkboxAction(checkboxID: string): void {
    this.loginService.checkboxAction(this.documentRef, checkboxID);
  }

  public changePassType(): void {
    if (this.passwordFieldType === 'text') {
      this.passwordFieldType = 'password';
      return;
    }
    this.passwordFieldType = 'text';
  }

  ///////////////////
  // Private methods
  ///////////////////

  /**
   * Set Remember Me for OTP form
   * @param otpOption - OtpOption type
   */
  private setOtpRememberMeCheck(otpOption: OtpOption): string {
    if (otpOption === OtpOption.DISTOTP) {
      return (this.documentRef.getElementById(
        'userLoginRememberMeDist'
      ) as HTMLInputElement).checked
        ? 'Y'
        : 'N';
    }
    if (otpOption === OtpOption.PANOTP) {
      return this.panOtpRemember ? 'Y' : 'N';
    }
    return (this.documentRef.getElementById(
      'userLoginRememberMe'
    ) as HTMLInputElement).checked
      ? 'Y'
      : 'N';
  }

  /**
   * Set
   * @param response - service response
   * @param otpOption - OtpOption (PANOTP | DISTOTP)
   */
  private setOtpForm(response: any, otpOption?: OtpOption): void {
    this.loginService.trackOtpSent(otpOption);
    if (otpOption === OtpOption.DISTOTP) {
      this.documentRef.getElementById('distOTPForm').style.display = 'block';
      this.documentRef.getElementById('loginFormDistributor').style.display =
        'none';
    } else {
      this.documentRef.getElementById('investorOTPForm').style.display =
        'block';
      this.documentRef.getElementById('investorLoginForm').style.display =
        'none';
    }
    this.loginWrapper = false;
    this.changeDetector.detectChanges();
  }

  /**
   * Set Bank Account form
   */
  private setBankAccountForm(): void {
    this.documentRef.getElementById('investorLoginPANFrm').style.display =
      'none';
    this.documentRef.getElementById('investorPANOTPForm').style.display =
      'none';
    this.documentRef.getElementById('investorLoginBankAccFrm').style.display =
      'block';
    this.loginWrapper = false;
    this.changeDetector.detectChanges();
    this.loginService.trackBankAccEnter();
  }

  /**
   * Login Investor - User ID
   */
  private createLoginInvestorUserID(): void {
    this.loginFormInvestorUserID = this.formBuilder.group({
      userid: ['', [Validators.required, Validators.minLength(1)]],
      password: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  /**
   * Login Investor - User PAN
   */
  private createLoginInvestorUserPAN(): void {
    this.loginFormInvestorUserPAN = this.formBuilder.group({
      userpan: ['', [Validators.required, Validators.minLength(10)]],
    });
  }

  /**
   * Login Investor - OTP
   */
  private createInvestorOTPForm() {
    this.investorOTPForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.investorPANOTPForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.distOTPForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.investorBankAccFrm = this.formBuilder.group({
      accountNo: [
        '',
        [Validators.required, Validators.pattern('^[0-9 ]{1,13}$')],
      ],
    });
  }

  /**
   * Login - Distributor
   */
  private createLoginDistributor(): void {
    this.loginFormDistributor = this.formBuilder.group({
      userid: ['', [Validators.required, Validators.minLength(1)]],
      password: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  /**
   * Bank Account
   */
  private createBankAccountForm(): void {
    this.investorBankAccFrm = this.formBuilder.group({
      accountNo: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  // Handling error for service calls
  private errorHandling(error): void {
    this.loader.style.display = 'none';
    this.errors = this.loginService.errorStatus(error);
    this.changeDetector.detectChanges();
  }

  /**
   * Resetting login forms
   */
  private loginFormsReset(): void {
    this.errors = null;
    this.loginFormInvestorUserID.reset();
    this.loginFormDistributor.reset();
    this.loginFormInvestorUserPAN.reset();
    this.investorPANOTPForm.reset();
    this.investorOTPForm.reset();
    this.distOTPForm.reset();
    this.loginFormInvestorUserPAN.get('userpan').enable();
    this.documentRef.getElementById('investorPANOTPForm').style.display =
      'none';
  }

  /**
   * Get OTP option for form
   * @param otpOption - OTP Option PANOTP | DISTOTP
   */
  private getOtpOption(otpOption: OtpOption): { signInForm: any } {
    switch (otpOption) {
      case OtpOption.PANOTP:
        return { signInForm: this.investorPANOTPForm.value };
      case OtpOption.DISTOTP:
        return { signInForm: this.distOTPForm.value };
      default:
        return { signInForm: this.investorOTPForm.value };
    }
  }
}
