import { Observable } from 'rxjs';
import { Logger } from '@utils/logger';
import { ConfigurationId, FundId, ShareClassCode } from '@types';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from '@apollo/client/core';
import { getOperationName } from '@apollo/client/utilities';
import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import { SiteConfigService } from '@services/site-config.service';
import { PageMonitor } from '@services/page-monitor';

const logger = Logger.getLogger('GraphQLFundDataService');

// TODO: need other params for SMAs etc
export enum ProductTypeParameter {
  MUTUAL_FUNDS = 'mf',
  CLOSED_END_FUNDS = 'cef',
  VARIABLE_INSURANCES = 'variable-insurance',
  MONEY_MARKET_FUNDS = 'mmf',
  PORTFOLIOS_529 = '529',
  ETFS = 'etf',
  TIFs = 'tif',
  SMAs = 'sma',
}

export interface FundDataServiceParams {
  configurationName?: ConfigurationId;
  productType?: ProductTypeParameter;
  fundId?: FundId;
  shareClassCode?: ShareClassCode;
  fromDate?: string;
  toDate?: string;
  year?: number;
  asofdate?: number;
  startdate?: number;
  enddate?: number;
  asofdatequarterly?: number; // Specifically, for attribution.
  asofdateyearly?: number; // Specifically, for attribution.
}

@Injectable({
  providedIn: 'root',
})
export class GraphQLFundDataService {
  constructor(
    private apollo: Apollo,
    protected siteConfigService: SiteConfigService
  ) {}

  public register(
    gqlQuery: DocumentNode,
    variablesObj: Record<string, any> = {}
  ): Observable<any> {
    const req = PageMonitor.startAjaxRequest(getOperationName(gqlQuery));
    return this.apollo
      .query<any>({
        query: gqlQuery,
        variables: variablesObj,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        tap(() => {
          PageMonitor.endAjaxRequest(req);
        }),
        shareReplay(1) // TODO do we really want this shareReplay?
      );
  }

  public getCountry() {
    return this.siteConfigService.getPdsCountry();
  }

  public getLanguage() {
    return this.siteConfigService.getPdsLanguage();
  }

  public getDateFormat() {
    return this.siteConfigService.common.dateFormat;
  }
}
