<div class="search-block">
  <div class="container">
    <div class="col-12">
      <h1 class="filter-title">
        {{ "ftiCommon.search-filters" | translate }}
      </h1>
      <div class="search-filter-section">
        <div class="filter-results">
          <strong>{{ "ftiCommon.search-results" | translate }}</strong>
          <a
            class="search-filter-links"
            id="all_filter"
            (click)="onClickOfSearchFilter($event)"
            [ngClass]="{ selected: showSections?.all }"
          >
            <span class="filter-sub-title">{{
              "ftiCommon.search-all-filter" | translate
            }}</span>
            <span class="pl-5">-</span>
            <span class="count">{{ totalResults }}</span>
            <span class="ml-10">|</span>
          </a>

          <a
            class="search-filter-links"
            id="funds_filter"
            (click)="onClickOfSearchFilter($event)"
            [ngClass]="{ selected: showSections?.funds }"
          >
            <span class="filter-sub-title">{{
              "ftiCommon.search-funds" | translate
            }}</span>
            <span class="pl-5">-</span>
            <span class="count">{{ totalCount?.funds }}</span>
            <span class="ml-10">|</span>
          </a>
          <a
            class="search-filter-links"
            id="literature_filter"
            (click)="onClickOfSearchFilter($event)"
            [ngClass]="{ selected: showSections?.literature }"
          >
            <span class="filter-sub-title">{{
              "ftiCommon.search-forms-documents" | translate
            }}</span>
            <span class="pl-5">-</span>
            <span class="count">{{ totalCount?.literature }}</span>
            <span class="ml-10">|</span>
          </a>
          <a
            class="search-filter-links"
            id="general_filter"
            (click)="onClickOfSearchFilter($event)"
            [ngClass]="{ selected: showSections?.pages }"
          >
            <span class="filter-sub-title">{{
              "ftiCommon.search-general" | translate
            }}</span>
            <span class="pl-5">-</span>
            <span class="count">{{ totalCount?.pages }}</span>
            <span class="ml-10">|</span>
          </a>

          <a
            class="search-filter-links"
            id="investor_filter"
            (click)="onClickOfSearchFilter($event)"
            [ngClass]="{ selected: showSections?.investor }"
            *ngIf="!isAdvisor"
          >
            <span class="filter-sub-title">{{
              "ftiCommon.search-investor-filter" | translate
            }}</span>
            <span class="pl-5">-</span>
            <span class="count">{{ totalCount?.investor }}</span>
          </a>
          <span class="ml-10" *ngIf="!isInvestor">|</span>
          <a
            class="search-filter-links"
            id="advisor_filter"
            (click)="onClickOfSearchFilter($event)"
            [ngClass]="{ selected: showSections?.advisor }"
            *ngIf="!isInvestor"
          >
            <span class="filter-sub-title">{{
              "ftiCommon.search-advisor-filter" | translate
            }}</span>
            <span class="pl-5">-</span>
            <span class="count">{{ totalCount?.advisor }}</span>
          </a>
        </div>
      </div>
      <div class="all-search-result-section">
        <div
          class="search-result-section all"
          id="funds_filter_section"
          *ngIf="showSections?.all || showSections?.funds"
        >
          <ft-search-results-funds
            *ngIf="fundsItems"
            [results]="fundsItems"
            [totalCount]="totalCount?.funds"
            [valueToShow]="valueToShow?.funds"
            [isViewAll]="isViewAll?.funds"
            (viewAllClick)="viewSearchResults($event)"
            (loadMoreFunds)="loadMore($event)"
          ></ft-search-results-funds>
        </div>

        <div
          class="search-result-section all"
          id="literature_filter_section"
          *ngIf="showSections?.all || showSections?.literature"
        >
          <ft-search-results-literature
            *ngIf="literatureItems"
            [results]="literatureItems"
            [totalCount]="totalCount?.literature"
            [valueToShow]="valueToShow?.literature"
            [isViewAll]="isViewAll?.literature"
            (viewAllClick)="viewSearchResults($event)"
            (loadMoreForms)="loadMore($event)"
          ></ft-search-results-literature>
        </div>

        <div
          class="search-result-section all"
          id="general_filter_section"
          *ngIf="showSections?.all || showSections?.pages"
        >
          <ft-search-results-general
            *ngIf="generalItems"
            [results]="generalItems"
            [totalCount]="totalCount?.pages"
            [valueToShow]="valueToShow?.pages"
            [isViewAll]="isViewAll?.pages"
            (viewAllClick)="viewSearchResults($event)"
            (loadMoreGeneral)="loadMore($event)"
          ></ft-search-results-general>
        </div>
        <div
          class="search-result-section all"
          id="investor_filter_section"
          *ngIf="(showSections?.all || showSections?.investor) && !isAdvisor"
        >
          <ft-search-results-investor
            *ngIf="investorResults"
            [results]="investorResults"
            [totalCount]="totalCount?.investor"
            [valueToShow]="valueToShow?.investor"
            [isViewAll]="isViewAll?.investor"
            (viewAllClick)="viewSearchResults($event)"
            (loadMoreGeneral)="loadMore($event)"
          >
          </ft-search-results-investor>
        </div>
        <div
          class="search-result-section all"
          id="advisor_filter_section"
          *ngIf="(showSections?.all || showSections?.advisor) && !isInvestor"
        >
          <ft-search-results-advisor
            *ngIf="advisorResults"
            [results]="advisorResults"
            [totalCount]="totalCount?.advisor"
            [valueToShow]="valueToShow?.advisor"
            [isViewAll]="isViewAll?.advisor"
            (viewAllClick)="viewSearchResults($event)"
            (loadMoreGeneral)="loadMore($event)"
          >
          </ft-search-results-advisor>
        </div>
      </div>
    </div>
  </div>
</div>
