<div class="blogSection">
  <div class="fti-container position-relative">
    <div class="row m-0">
      <div class="col-sm-12 col-md-4 selectCategoryOuter">
        <div class="row m-0">
          <div class="col-sm-12 selectCategoryInner">
            <div ngbDropdown class="d-inline-block w-100">
              <button
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                {{ selectedCategory }}
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="dropdownSection"
              >
                <button
                  ngbDropdownItem
                  *ngFor="let category of categories"
                  (click)="selectCategory(category?.key)"
                >
                  {{ category?.label }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 contentCategory">
        <div class="row">
          <ng-container *ngFor="let blog of articleArray; index as i">
            <div class="col-sm-12 col-sm-6 col-md-4 insights">
              <!-- <ng-container *ngIf="i < recordsPerPage"> -->
              <div
                class="insights-inner"
                [ngClass]="{ 'has-edit-button': page?.isPreview() }"
              >
                <div class="insights-card">
                  <div class="image-container">
                    <a [routerLink]="blog?.url" target="_blank">
                      <img
                        *ngIf="blog?.image"
                        [src]="blog?.image"
                        alt="{{ blog?.data?._source?.pageTitle }}"
                        title="{{ blog?.data?._source?.pageTitle }}"
                      />
                    </a>
                  </div>
                  <div class="content-container">
                    <h3>
                      <a [routerLink]="blog?.url" target="_blank">
                        <span>{{ blog?.data?._source?.pageTitle }}</span>
                      </a>
                    </h3>
                    <p>
                      <ng-container *ngIf="blog?.data?._source?.description">
                        <span
                          [innerHtml]="
                            getContentBlockData(
                              blog?.data?._source?.description,
                              140
                            )
                          "
                        ></span>
                        <span>
                          ...
                          <a
                            *ngIf="blog?.url"
                            [routerLink]="blog?.url"
                            target="_blank"
                            >load more</a
                          >
                        </span>
                      </ng-container>
                    </p>
                    <span class="dateStamp" *ngIf="isVisibleDate === 'true'">{{
                      blog?.date
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="showButton" class="col-sm-12">
        <div class="text-center">
          <button
            class="btn loadMore"
            value="Load More"
            aria-label="Load More"
            (click)="loadMore('filters', articleArray?.length, 6)"
          >
            Load More
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
