export interface FundPerformanceAnnualizedState {
  perfAsOfDate: string;
  sinceInceptionReturnDate: string;
  lumpsumData: FundPerformanceLumpsumData;
  sipData: FundPerformanceTableData[];
  isLoaded?: boolean;
  showDiscretePerformance?: boolean;
}

export interface FundPerformanceLumpsumData {
  annualized: FundPerformanceTableContent;
  cumulative: FundPerformanceTableContent;
  discrete?: FundPerformanceTableContent;
}

export interface FundPerformanceTableContent {
  performance: FundPerformanceTableData;
  bmPerformance: FundPerformanceTableData[];
}

export interface FundPerformanceTableData {
  bmName?: string;
  year1Return: string;
  year2Return?: string;
  year3Return: string;
  year4Return?: string;
  year5Return: string;
  year10Return?: string;
  year15Return?: string;
  sinceInceptionReturn?: string;
}

export enum FundPerformanceSIPRowName {
  'PERF_TOTAL_INVESTED',
  'PERF_TOTAL_VALUE',
  'PERF_RETURNS',
  'BM_PERF_TOTAL_VALUE_INSTA_1',
  'BM_PERF_TOTAL_VALUE_INSTA_2',
  'BM_PERF_RETURNS_INSTA_1',
  'BM_PERF_RETURNS_INSTA_2',
}

export interface FundPerformanceCummulativeState {
  inceptionDate: string;
  sinceInceptionReturnDate: string;
  isLoaded?: boolean;
  basecurrcode?: string;
}
