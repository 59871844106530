import { Component, Input, OnInit } from '@angular/core';
import { TabList } from '../vertical-tabs.types';

@Component({
  selector: 'ft-vertical-tabs-table-list',
  templateUrl: './vertical-tabs-table-list.component.html',
  styleUrls: ['./vertical-tabs-table-list.component.scss'],
})
export class VerticalTabsTableListComponent {
  @Input() title: string;
  @Input() borderColor: string;
  @Input() tableData: TabList[];
}
