import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Logger } from '@utils/logger';
import { SessionService } from '@services/session.service';
import { InPersonalInfo } from '@services/profile.interface';
import { FormService } from '@services/form.service';
import { Subscription } from 'rxjs';
import { InUserType } from '@types';
import { EnvConfigService } from '@services/env-config.service';
import { StorageService } from '@services/storage.service';
import { LoginService } from '@services/login.service';

const logger = Logger.getLogger('FeedbackComponent');

@Component({
  selector: 'ft-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;

  feedbackForm: FormGroup;
  formFieldsDisable = false;
  isThankYou = false;
  errMsg: any = [];
  url = '';
  isFormSubmitted = false;
  urlPrefix = '';
  subscriptions: Subscription[] = [];
  hasFeedback = true;

  // Resource Bundle
  flashMessage: string;
  labelName: string;
  labelEmail: string;
  labelFeedback: string;
  labelPan: string;
  labelSubmit: string;
  labelReset: string;
  specialCharactersNotAllowed: string;

  // prettier-ignore
  constructor( // NOSONAR - typescript:S107 needs mor than 7 parameters in constructor
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private formService: FormService,
    private cdr: ChangeDetectorRef,
    private envConfig: EnvConfigService,
    private storageService: StorageService,
    private loginService: LoginService
  ) {
    this.setForm();
  }

  ngOnInit(): void {
    // Get values from resource bundle
    this.urlPrefix = this.envConfig.getEnvConfig()?.ftiApiDomain;
    this.url =
      this.urlPrefix + this.translateService.instant('ftiFeedback.formURL');
    this.flashMessage = this.translateService.instant(
      'ftiFeedback.flashMessage'
    );
    this.labelName = this.translateService.instant('ftiFeedback.labelName');
    this.labelEmail = this.translateService.instant('ftiFeedback.labelEmail');
    this.labelFeedback = this.translateService.instant(
      'ftiFeedback.labelFeedback'
    );
    this.labelSubmit = this.translateService.instant('ftiFeedback.labelSubmit');
    this.specialCharactersNotAllowed = this.translateService.instant(
      'ftiFeedback.specialCharactersNotAllowed'
    );
    this.hasUserFeedback().then((feedback) => {
      this.hasFeedback = feedback;
      if (!this.hasFeedback) {
        this.labelSubmit = this.translateService.instant(
          'ftiFeedback.labelSubmitLogout'
        );
      }
    });
    this.labelReset = this.translateService.instant('ftiFeedback.labelReset');
    this.setPanLabel().then(() => {
      logger.debug('Setting PAN label');
    });
    this.createFeedbackForm().then(() => {
      logger.debug('Create feedback form');
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
  //////////////////
  // Private methods
  //////////////////

  /**
   * Setting PAN label
   */
  private async setPanLabel(): Promise<void> {
    // Setting default PAN label
    this.labelPan = this.translateService.instant('ftiFeedback.labelPan');
    // Checking userType label
    const userType = await this.sessionService.getUserType();
    if (userType === InUserType.INVESTOR) {
      this.labelPan = this.translateService.instant(
        'ftiFeedback.labelPanInvestor'
      );
      return;
    }
    if (userType === InUserType.ADVISOR) {
      this.labelPan = this.translateService.instant(
        'ftiFeedback.labelPanAdvisor'
      );
    }
  }
  /**
   * Set Feedback form
   * @param userInfo - India PersonalInfo object
   * @param userType - User type string ('10' - investor; '20' - advisor)
   */
  private setForm(userInfo?: InPersonalInfo, userType?: string): void {
    let userPan = userInfo?.pan;
    if (userType === InUserType.ADVISOR) {
      userPan = userInfo.ARN;
    }
    this.feedbackForm = this.formBuilder.group({
      name: this.formService.setFormField(1, userInfo?.userFirstName),
      emailId: this.formService.setFormField(1, userInfo?.email),
      accountNo: this.formService.setFormField(0, userPan, false),
      feedback: this.formService.setFormField(1),
    });
  }

  /**
   * Email validation
   * @param emailId - input string
   */
  private testEmail(emailId: string): void {
    if (!/^(.|\s){1,100}$/.test(emailId)) {
      this.errMsg.push({
        emailId: this.translateService.instant('ftiFeedback.labelEmailErrMsg'),
      });
    } else if (
      !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
        emailId
      )
    ) {
      this.errMsg.push({
        emailId: this.translateService.instant(
          'ftiFeedback.labelEmailFormatErrMsg'
        ),
      });
    }
  }

  /**
   * Name validation
   * @param name - input string
   */
  private testName(name: string): void {
    if (!/^(.|\s){1,80}$/.test(name)) {
      this.errMsg.push({
        name: this.translateService.instant('ftiFeedback.labelNameErrMsg'),
      });
    }
  }

  /**
   * Account No validation
   * @param accountNo - input string
   */
  private testAccountNo(accountNo: string): void {
    if (accountNo && !/^(.|\s){1,13}$/.test(accountNo)) {
      this.errMsg.push({
        accountNo: this.translateService.instant(
          'ftiFeedback.labelAccNoErrMsg'
        ),
      });
    }
  }

  /**
   * Feedback validation
   * @param feedback - input string
   */
  private testFeedback(feedback: string): void {
    if (!/^(.|\s){1,500}$/.test(feedback)) {
      this.errMsg.push({
        feedback: this.translateService.instant(
          'ftiFeedback.labelFeedbackErrMsg'
        ),
      });
    }
  }

  private async hasUserFeedback(): Promise<boolean> {
    const userFeedback = await this.storageService
      .retrieve('userFeedbackStatus')
      .then();
    if (userFeedback === 'false') {
      return false;
    }
    return true;
  }

  /////////////////
  // Public methods
  /////////////////

  /**
   * Create pre-filled form
   */
  public async createFeedbackForm(): Promise<void> {
    const info$ = await this.sessionService.validateUser$();
    const userType = await this.sessionService.getUserType();
    if (info$) {
      const personalInfo: Subscription = info$.subscribe(
        (userInfo: InPersonalInfo) => {
          if (userInfo) {
            this.setForm(userInfo, userType);
            // If user is identified, disable form.
            if (userInfo?.userFirstName && userInfo?.email) {
              this.formFieldsDisable = true;
            }
            this.cdr.detectChanges();
          }
        },
        (error) => {
          logger.error('PersonalInfo error:', error);
        }
      );
      this.subscriptions.push(personalInfo);
    }
  }

  /**
   * Filter form inputs
   * @param $event - key event
   * @param fieldName - fiels name string
   */
  public onKeyUp($event: any, fieldName: string): void {
    let enteredCharacter = $event.target.value;
    enteredCharacter = enteredCharacter.replace(/[\/\\$'"<>]/g, '');
    this.feedbackForm.controls[fieldName].setValue(enteredCharacter);
  }

  /**
   * Form reset
   */
  public reset(): void {
    this.errMsg = [];
    if (this.formFieldsDisable) {
      this.feedbackForm.get('feedback').reset();
      return;
    }
    this.feedbackForm.reset();
  }

  /**
   * Disable paste
   * @param event - ClipboardEvent
   */
  public onPaste(event): void {
    this.formService.onPasteForms(event);
  }

  /**
   * Form submit
   */
  public feedbackSubmit(): boolean {
    this.errMsg = [];
    this.isFormSubmitted = true;

    const name = this.feedbackForm.value.name;
    const emailId = this.feedbackForm.value.emailId;
    const accountNo = this.feedbackForm.value.accountNo;
    const feedback = this.feedbackForm.value.feedback;

    if (name && emailId && feedback) {
      this.testName(name);
      this.testEmail(emailId);
      this.testAccountNo(accountNo);
      this.testFeedback(feedback);

      if (this.errMsg.length) {
        this.isFormSubmitted = false;
        return false;
      }
    }

    const data = {
      name,
      emailId,
      accountNo,
      feedback,
    };

    const options =
      this.envConfig.getEnvConfig().environment === 'pre' ||
      this.envConfig.getEnvConfig().environment === 'prod'
        ? this.sessionService.apiHttpOptionsWithCredentials
        : this.sessionService.apiHttpOptions;

    const formSubmit: Subscription = this.http
      .post(this.url, data, options)
      .subscribe(
        () => {
          this.isThankYou = true;
          this.isFormSubmitted = false;
          this.changeDetector.markForCheck();
        },
        (error) => {
          logger.error('error >>>> ', error);
        }
      );
    this.subscriptions.push(formSubmit);
    if (!this.hasFeedback) {
      this.storageService.store('feedbackSubmitted', true, true);
      this.loginService.logOut();
    }
  }
}
