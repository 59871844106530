import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FundId } from '@types';
import { TranslateService } from '@shared/translate/translate.service';
import { Logger } from '@utils/logger';
import cloneDeep from 'lodash/cloneDeep';
import { HighchartsThemeService } from '../services/highchart/highcharts-theme/highcharts-theme.service';

const logger = Logger.getLogger('BarChartComponent');

/**
 * Interface defines a chart data point and optional nested child points
 */
export interface ChartDataPoint {
  /**
   * e.g. 'Fixed Income'
   */
  label: string;

  /**
   * Formatted Breakdown percent string e.g. '89.24%'
   */
  breakdown?: string;

  /**
   * Full Breakdown percent number value for Highcharts to plot with e.g. 89.24
   */
  breakdownStd?: number;

  /**
   * Formatted Benchmark percent string e.g. '89.24%'
   */
  benchmark?: string;

  /**
   * Full Benchmark percent number value for Highcharts to plot with e.g. 89.24
   */
  benchmarkStd?: number;

  /**
   * Formatted Gross Exposure percent string e.g. '89.24%'
   */
  grossExposure?: string;

  /**
   * Full Gross Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  grossExposureStd?: number;

  /**
   * Formatted Long Exposure percent string e.g. '89.24%'
   */
  longExposure?: string;

  /**
   * Full Long Exposure percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  longExposureStd?: number;

  /**
   * Formatted Net Exposure percent string e.g. '89.24%'
   */
  netExposure?: string;

  /**
   * Full Net Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  netExposureStd?: number;

  /**
   * Formatted Short Exposure percent string e.g. '89.24%'
   */
  shortExposure?: string;

  /**
   * Full Short Exposure percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  shortExposureStd?: number;

  /**
   * Formatted Target Allocation percent range string e.g. '30% - 70%'
   */
  targetAllocation?: string;

  /**
   * Full Target Allocation percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  targetAllocationStd?: number;

  /**
   * Formatted Actual Allocation percent string e.g. '89.24%'
   */
  actualAllocation?: string;

  /**
   * Full Actual Allocation percent number value for Highcharts to plot with e.g. 89.24
   */
  actualAllocationStd?: number;

  /**
   * Formatted Risk Contribution Allocation percent string e.g. '89.24%'
   */
  riskContributionAllocation?: string;

  /**
   * Formatted Performance Contribution Allocation percent string e.g. '89.24%'
   */
  performanceContributionAllocation?: string;

  /**
   * (optional) child data points
   * only applies to tables
   */
  children?: ChartDataPoint[];
}

/**
 * Interface defines a table data point and optional nested child points
 */
export interface ChartTableDataPoint extends ChartDataPoint {
  /**
   * color for the legend box. Root nodes only
   */
  pointColor?: string;

  /**
   * indicates if this node has nested child nodes
   */
  hasChildren?: boolean;

  /**
   * indicates if this node is expaned i.e. child nodes are visible
   * Note: only applies to nodes with children
   */
  isExpanded?: boolean;

  /**
   * indicates if this table node is visible i.e. parent node is expanded
   * note: doesn't apply to root nodes
   */
  isHidden?: boolean;

  /**
   * (optional) child data points
   * only applies to tables
   */
  children?: ChartTableDataPoint[];
}

export interface ChartData {
  /**
   * fund id
   */
  fundId: FundId;

  // SectionHeader config ----------------------------------------

  /**
   * Title
   */
  chartTitle: string;

  /**
   * Footnote placement id
   */
  caveatPlacement?: string;

  /**
   * FIMES Footnote placement id
   */
  secondaryCaveatPlacement?: string;

  /**
   * Text or html content for a tooltip
   */
  tooltip?: string;

  /**
   * e.g. `'As of'`
   */
  asOfLabel?: string;

  /**
   * Preformatted date string e.g. `'12 March 2020'`
   */
  asOfDate?: string;

  /**
   * e.g. `'(Market Value)'`
   */
  calculationBasis?: string;

  /**
   * e.g. `'(% of Total)'`
   */
  calculationType?: string;

  /**
   * e.g. `'(updated monthly)'`
   */
  updateFrequency?: string;

  // TODO: is this still used?
  /**
   * Formatted Performance Contribution date range string e.g. '830/11/2020 - 31/12/2020'
   */
  contributionDateRange?: string;

  /**
   * determines if Y axis shows % instead of just value
   */
  isPercent?: boolean;

  // Show/hide config ----------------------------------------

  /**
   * Use to hide chart
   */
  hideChart?: boolean;

  /**
   * Use to hide table
   */
  hideTable?: boolean;

  /**
   * Use to show Exposure type chart (i.e. 2 data series) instead of default options
   */
  showExposureChart?: boolean;

  /**
   * Use to show the percentage bar component
   */
  showTotalPercentageBar?: boolean;

  /**
   * Use to hide the default legend colored squares in the table
   */
  hideTableLegend?: boolean;

  /**
   * Use to hide the default % breakdown column in table
   */
  hideBreakdownCol?: boolean;

  /**
   * Use to show the benchmark column in table
   */
  showBenchmarkCol?: boolean;

  /**
   * Use to show the Duration column in table
   */
  showDurationCol?: boolean;

  /**
   * Use to show the Gross Exposure column in table
   */
  showGrossExposureCol?: boolean;

  /**
   * Use to show the Long Exposure column in table
   */
  showLongExposureCol?: boolean;

  /**
   * Use to show the Net Exposure column in table
   */
  showNetExposureCol?: boolean;

  /**
   * Use to show the Short Exposure column in table
   */
  showShortExposureCol?: boolean;

  /**
   * Use to show the Target Allocation column in table
   */
  showTargetAllocationCol?: boolean;

  /**
   * Use to show the ActualAllocation column in table
   */
  showActualAllocationCol?: boolean;
  /**
   * Use to show the Risk Contributio column in table
   */
  showRiskContributionAllocationCol?: boolean;
  /**
   * Use to show the Performance Contribution column in table
   */
  showPerformanceContributionAllocationCol?: boolean;

  /**
   * Use to set a title on the label column in table
   */
  labelColumnTitle?: string;

  /**
   * Use override the default title ("Fund") on the breakdown column in table
   */
  breakdownColumnTitle?: string;

  // Caveat config ----------------------------------------

  /**
   * Top proximal placement id
   */
  proximalTopPlacement?: string;

  /**
   * Bottom proximal placement id
   */
  proximalBottomPlacement?: string;

  /**
   * Top secondary proximal placement id (for derivatives proximals etc)
   */
  secondaryProximalTopPlacement?: string;

  /**
   * Bottom secondary proximal placement id (for derivatives proximals etc)
   */
  secondaryProximalBottomPlacement?: string;

  // Chart + table data ----------------------------------------

  /**
   * Data to be used in chart and table
   */
  dataPoints: ChartDataPoint[];

  /**
   * Data to be used for percentage bar on top 10 charts
   */
  totalPercent?: number;
  isPiChart?: boolean;
}

export const hasChildren = (point: ChartDataPoint): boolean =>
  point.children?.length > 0;

export const expandNode = (
  node: ChartTableDataPoint,
  recursive = false
): void => {
  // expanding a node sets isExpanded true, and isHidden to false on child nodes
  node.isExpanded = true;
  node.children?.forEach((childNode: ChartTableDataPoint): void => {
    childNode.isHidden = false;
    if (recursive) {
      expandNode(childNode, true);
    }
  });
};

export const collapseNode = (
  node: ChartTableDataPoint,
  recursive = false
): void => {
  // collapsing a node sets isExpanded false, and isHidden to true on child nodes
  node.isExpanded = false;
  node.children?.forEach((childNode: ChartTableDataPoint): void => {
    childNode.isHidden = true;
    if (recursive) {
      collapseNode(childNode, true);
    }
  });
};

export const hasExpandedNodes = (node: ChartTableDataPoint): boolean =>
  node.hasChildren &&
  (node.isExpanded || node.children?.some(hasExpandedNodes));

export const hasCollapsedNodes = (node: ChartTableDataPoint): boolean =>
  node.hasChildren &&
  (!node.isExpanded || node.children?.some(hasCollapsedNodes));

// constants for calculating chart height
// TODO: these may need reviewed, possibly including responsive service to adapt for different screen sizes
const MIN_CHART_HEIGHT = 435; // minimum height of chart
const MAX_CHART_HEIGHT = 1000; // maximum height of chart
const CHART_BAR_HEIGHT = 15; // height for each horizontal bar in chart
const CHART_OFFSET_HEIGHT = 50; // height of other chart elements e.g. axis and labels

@Component({
  selector: 'ft-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  highcharts;
  private chartInstance: Highcharts.Chart;

  @Input() data: ChartData;

  public isPopulated = false;
  public chartOptions: Highcharts.Options;
  public hasNestedData = false;
  public tablePoints: ChartTableDataPoint[];

  // table is collapsed by default
  public expandDisabled = false;
  public collapseDisabled = true;

  updateChart = false;
  private chartHeight: number;
  labelsFontSize = '10'; // Default font size for chart labels
  /**
   * controlling the table toggle for expand collapse state
   */
  toggle = false;
  tooltips: { [name: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    // private responsiveService: ResponsiveService,
    private highchartsTheme: HighchartsThemeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.labelsFontSize = '10';
    this.updateChart = true;

    this.tooltips[
      'products.sector-duration-column-header'
    ] = this.translateService.tooltip('products.sector-duration-column-header');

    if (this.data) {
      import(/* webpackChunkName: "highcharts-async" */ 'highcharts').then(
        (highchartsModule) => {
          highchartsModule.setOptions(this.highchartsTheme.themeOptions);
          this.highcharts = highchartsModule;

          this.processData(highchartsModule);
        }
      );
    }
  }

  /**
   * called by Highcharts component after it inits, to pass a reference to the chart instance
   */
  public chartCallback = (chart: Highcharts.Chart): void => {
    this.chartInstance = chart;
  };

  /**
   * this causes the chart to redraw when called.
   * Used to fix dynamic chart size issues
   */
  public resizeChart(): void {
    setTimeout(() => {
      if (this.chartInstance?.options) {
        this.chartInstance?.reflow();
      }
    });
  }

  /**
   * When data is passed into this component, this function processes it into suitable chart and table data
   * Called direct from Entry component, as ngOnInit and ngOnchanges not called when dynamic component
   */
  public processData(highchartsModule): void {
    // calculate chart height
    let h: number =
      this.data.dataPoints.length * CHART_BAR_HEIGHT * 2.5 +
      CHART_OFFSET_HEIGHT;
    h = Math.max(h, MIN_CHART_HEIGHT); // apply min height
    this.chartHeight = h;

    // work out if nested data
    this.hasNestedData = this.data.dataPoints.some(hasChildren);

    // get data for table
    this.tablePoints = this.getTableData();

    this.chartOptions = this.getDefaultChartOptions(highchartsModule);
    logger.debug('chart options', this.chartOptions);

    // display component
    this.isPopulated = true;

    this.cdr.detectChanges();
  }

  /**
   * This returns (nested) data in a format suitable for generating a table to accompany the chart
   */
  private getTableData(): ChartTableDataPoint[] {
    // map point colors
    return this.data.dataPoints.map(
      (point: ChartDataPoint, index: number): ChartTableDataPoint => ({
        ...point,
        pointColor: this.highchartsTheme.getPointColor(
          index,
          this.data.dataPoints.length
        ),
        hasChildren: hasChildren(point),
        children: point.children?.map(
          (child: ChartDataPoint): ChartTableDataPoint => ({
            ...child,
            hasChildren: hasChildren(child),
          })
        ),
      })
    );
  }

  /**
   * This creates a Highcharts Options object with options used by all types of chart
   */
  private getBaseChartOptions(highchartsModule): Highcharts.Options {
    const valueSuffix: string = this.data.isPercent ? '%' : '';
    return cloneDeep({
      chart: {
        height: this.chartHeight,
        spacingLeft: 5,
        spacingRight: 0,
        marginRight: 5,
        events: {
          render(this) {
            const that: Highcharts.Chart = this;
            setTimeout(() => {
              if (that?.options) {
                that.reflow();
              }
            }, 0);
          },
        },
        reflow: true,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: CHART_BAR_HEIGHT,
          dataLabels: {
            enabled: true,
          },
        },
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: this.data.dataPoints.map(
          (point: ChartDataPoint) => point.label
        ),
        labels: {
          enabled: true,
          style: {
            color: '#606060',
            fontSize: this.labelsFontSize,
            wordBreak: 'break-all',
            textOverflow: 'allow',
            fontFamily: 'Verdana, sans-serif',
            fontWeight: 'bold',
          },
        },
      },
      yAxis: {
        lineWidth: 1,
        title: {
          text: null,
        },
        gridLineColor: '#FFFFFF',
        labels: {
          enabled: false,
          distance: 80,
        },
      },
    });
  }

  /**
   * This creates a Highcharts Options object suitable for displaying most Portfolio Charts
   */
  private getDefaultChartOptions(highchartsModule): Highcharts.Options {
    const opts: Highcharts.Options = this.getBaseChartOptions(highchartsModule);
    opts.series = [
      {
        type: 'bar',
        colorByPoint: true,
        // return actual allocation if data, otherwise default to breakdown
        // This logic may need strengthened in future
        data: this.data.dataPoints.map((point: ChartDataPoint) => ({
          name: point.actualAllocation || point.breakdown,
          y: point.actualAllocationStd || point.breakdownStd,
          dataLabels: {
            formatter() {
              return point.actualAllocation || point.breakdown;
            },
          },
        })),
      },
    ];
    opts.tooltip = {
      formatter() {
        return `${this.point.category}<br/>${this.point.name}`;
      },
    };
    return opts;
  }
}
