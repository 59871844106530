import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  Component as BrComponent,
  Page,
  Document,
  Reference,
} from '@bloomreach/spa-sdk';
import { Constant } from '../../../ft-constants/constant';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@components/shared/translate/translate.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppStateService } from '@services/app-state.service';
import { Router } from '@angular/router';
import { CommonService } from '@services/common.service';
import { Logger } from '@utils/logger';
import { Location } from '@angular/common';
import { InvestNowService } from '@services/invest-now.service';
import { Observable } from 'rxjs';
import { EnvConfigService } from '@services/env-config.service';
import { StorageService } from '@services/storage.service';
import { FundHeaderService } from '../../products/overview/services/fund-header.service';
import { WINDOW } from '@ng-web-apis/common';
import { getMenus, getPageContent, getPageUrl } from '@utils/pagemodel-utils';
const logger = Logger.getLogger('VideoArticlesComponent');
@Component({
  selector: 'ft-video-articles',
  templateUrl: './video-articles.component.html',
  styleUrls: ['./video-articles.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoArticlesComponent implements OnChanges, OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  urlSafe: SafeResourceUrl;

  mailBackForm: FormGroup;
  documentData: any = {};
  @ViewChild('htmlContainer') container;
  shareButtonUrl: string;
  shareUrlRefresh: string;
  showShare = false;
  submitted = false;
  focusValue: boolean;
  backLink = '';
  focusValueName: boolean;
  focusValueEmail: boolean;
  totalPercentage: any;
  hostName: string;
  successmsg = false;
  showDropdown = false;
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  layout: string;
  tabContentList = [];
  smallTabsLayout: string = Constant.isSmallTabsLayout;
  largeTabsLayout: string = Constant.isLargeTabsLayout;
  relatedVideo1: any = {};
  relatedVideo2: any = {};
  quizDocument: any = {};
  commonLabels: any;
  quizAnswer: any;
  quizForm: FormGroup;
  quizUserName: any;
  step3error = false;
  step3errorvalue: any;
  chooseLanguage: any;
  formErrorMessage: any;
  tryAgain: any;
  formSorryMessage: any;
  eligibleForCertificate: any;
  formSuccessMessage: any;
  newToInvestBackLink: any;
  answerQuestionToProceed: any;
  clickToContinue: any;
  downloadCertificate: any;
  relatedVideoLabel: any;
  congratulationsLabel: any;
  nameLabel: any;
  testYourLearningLabel: any;
  startDownloadingLabel: any;
  videoMarketInsights: any;
  askForDistributor: any;
  speakTous: any;
  investNow: any;
  currentPath: any;
  investNowLink$: Observable<string>;
  backUrl = false;
  url = '';
  isScrom = false;
  isFormSubmitted = false;
  urlPrefix = '';
  public subscription: Subscription;
  ftIndiaBrandName: any;
  currentPageUrl: any;
  textTitle: any;
  scromVid: any;
  constructor(
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private appStateService: AppStateService,
    config: NgbNavConfig,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private investNowService: InvestNowService,
    private location: Location,
    private http: HttpClient,
    private storageService: StorageService,
    private envConfig: EnvConfigService,
    private metaService: Meta,
    public pagetitle: Title
  ) {
    config.destroyOnHide = false;
    this.quizForm = this.formBuilder.group({
      quizAnswer: ['', [Validators.required]],
    });
    this.quizAnswer = new Array<Comment>();
  }
  ngOnChanges() {}

  ngOnInit(): void {
    const arrURL = [];

    const footerMenus = getMenus(
      this.page,
      this.page?.getComponent('footer-content', 'footer')
    );
    footerMenus?.siteMenuItems[0]?.childMenuItems.forEach((result) => {
      if (result?.childMenuItems?.length > 0) {
        // Level 2 i.e Family Solutions
        result?.childMenuItems.forEach((index) => {
          if (index?.childMenuItems?.length > 0) {
            // Level 3 i.e Child's Education
            index?.childMenuItems.forEach((val) => {
              if (val?.childMenuItems?.length === 0) {
                const menuName = val?.name.toLowerCase();
                arrURL.push(menuName.replace(/ /g, '-'));
              }
            });
          } else {
            const menuName = index?.name.toLowerCase();
            arrURL.push(menuName.replace(/ /g, '-'));
          }
        });
      }
    });

    // Current URL
    const currentURL = this.location.path();
    this.currentPageUrl =
      this.envConfig.getEnvConfig()?.baseUrl + currentURL.substring(1);

    // Resource bundle
    const prefix = 'ftiBacklinks.';
    let resourceBundleKey = '';
    for (const i of arrURL) {
      if (currentURL.indexOf(i) !== -1) {
        const link = i.split('-').join('');
        resourceBundleKey = prefix + link + 'backlink';
      }
    }
    this.backLink = this.translateService.instant(resourceBundleKey);

    const modelKey = 'model';
    this.layout = this.page?.getComponent().getParameters().layout;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.content?.iframeURL
    );
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.content?.scromUrl
    );
    if (this.content?.relatedVideo1) {
      this.relatedVideo1 = getPageContent(
        this.page,
        this.content.relatedVideo1
      );
    }
    if (this.content?.relatedVideo2) {
      this.relatedVideo2 = getPageContent(
        this.page,
        this.content.relatedVideo2
      );
    }
    if (this.content?.testYourLearningDocument !== null) {
      this.quizDocument = this.getQuizDocument(
        this.content?.testYourLearningDocument
      );
    }
    this.commonLabels = this.commonService.getLabels()?.ftiCommon;

    this.mailBackForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.shareButtonUrl = Constant.shareBtnUrl;
    this.renderScript(this.shareButtonUrl);
    this.commentFormValidation();
    this.step3errorvalue = this.translateService.instant(
      'ftiCommon.step3errorvalue'
    );
    this.nameLabel = this.translateService.instant('ftiCommon.nameLabel');
    this.tryAgain = this.translateService.instant('ftiCommon.tryAgain');
    this.eligibleForCertificate = this.translateService.instant(
      'ftiCommon.eligibleForCertificate'
    );
    this.chooseLanguage = this.translateService.instant(
      'ftiCommon.chooseLanguage'
    );
    this.formErrorMessage = this.translateService.instant(
      'ftiCommon.formErrorMessage'
    );
    this.formSorryMessage = this.translateService.instant(
      'ftiCommon.formSorryMessage'
    );
    this.formSuccessMessage = this.translateService.instant(
      'ftiCommon.formSuccessMessage'
    );
    this.clickToContinue = this.translateService.instant(
      'ftiCommon.clickToContinue'
    );
    this.relatedVideoLabel = this.translateService.instant(
      'ftiCommon.relatedVideoLabel'
    );
    this.answerQuestionToProceed = this.translateService.instant(
      'ftiCommon.answerQuestionToProceed'
    );
    this.newToInvestBackLink = this.translateService.instant(
      'ftiCommon.newToInvestBackLink'
    );
    this.congratulationsLabel = this.translateService.instant(
      'ftiCommon.congratulationsLabel'
    );
    this.downloadCertificate = this.translateService.instant(
      'ftiCommon.downloadCertificate'
    );
    this.startDownloadingLabel = this.translateService.instant(
      'ftiCommon.startDownloadingLabel'
    );
    this.videoMarketInsights = this.translateService.instant(
      'ftiCommon.videoMarketInsights'
    );
    this.testYourLearningLabel = this.translateService.instant(
      'ftiCommon.testYourLearningLabel'
    );
    this.ftIndiaBrandName = this.translateService.instant(
      'ftiCommon.ftIndiaBrandName'
    );
    this.askForDistributor = this.translateService.instant(
      'ftiCommon.askForDistributor'
    );
    this.setMetaData();
    this.speakTous = this.translateService.instant('ftiCommon.speakTous');
    this.investNow = this.translateService.instant('ftiCommon.investNow');
    this.investNowLink$ = this.investNowService.getInvestNowLink$();

    this.backUrl =
      this.location.path().indexOf('market-insights') > 0 ? true : false;
    this.urlPrefix = this.envConfig.getEnvConfig()?.ftiApiDomain;
    this.url =
      this.urlPrefix + this.translateService.instant('ftiFeedback.formURL');

    const videos = this.videoInfoSize;
    const vidId = videos ? videos[0]?.videoid : '';
    if (videos?.length <= 1 && vidId.indexOf('/static') !== -1) {
      this.isScrom = true;
    }
    this.scromVid =
      '<iframe class="scrom-iframe" id="vid_frame1" src="' +
      vidId +
      '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
  }
  get videoInfoSize() {
    const data = this.content?.videoArticleInfo;
    return data;
  }
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    const data = this.document?.getData();
    this.textTitle = data?.title;
    return data;
  }
  setMetaData() {
    const pageData = this.content?.ftindiaPageMetaData;
    this.metaService.updateTag(
      {
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      "property='twitter:card'"
    );
    this.metaService.updateTag(
      {
        property: 'twitter:title',
        content: pageData?.metaTitle
          ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
          : '',
      },
      "property='twitter:title'"
    );
    this.metaService.updateTag(
      {
        property: 'og:title',
        content: pageData?.metaTitle
          ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
          : '',
      },
      "property='og:title'"
    );
    const PageTitle = pageData?.metaTitle
      ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
      : '';
    this.pagetitle.setTitle(PageTitle);
    this.metaService.updateTag(
      {
        property: 'twitter:image',
        content: '',
      },
      "property='twitter:image'"
    );
    this.metaService.updateTag(
      {
        property: 'twitter:description',
        content: pageData?.metaDescription ? pageData?.metaDescription : '',
      },
      "property='twitter:description'"
    );
  }

  getThumbnailImage(ref: any) {
    const imageURL = JSON.parse(ref)?.url;
    return imageURL;
  }
  getQuizDocument(ref: any) {
    const pageUrl = this.page?.getContent(ref)?.getData();
    pageUrl?.quizQuestions?.forEach((element) => {
      const options = [
        element.option1,
        element.option2,
        element.option3,
        element.option4,
      ];
      element.options = options;
    });
    logger.debug('QuizDocuments');
    logger.debug(pageUrl);
    return pageUrl;
  }

  getPageUrl(ref: Reference) {
    return getPageUrl(this.page, ref);
  }

  getRelatedPageUrl(ref: any) {
    return this.page?.getContent(ref)?.getUrl();
  }

  getImageURL(data) {
    const imageURL = JSON.parse(data)?.url;
    return imageURL;
  }

  internalLink(data) {
    const linkCollection = data?.linkCollection[0] || {};
    if (linkCollection?.length !== 0) {
      const document = linkCollection?.document;
      return this.page?.getContent(document)?.getUrl();
    }
  }

  externalLink(data) {
    const linkCollection = data?.linkCollection[0] || {};
    const document = linkCollection?.document;
    if (linkCollection?.length !== 0) {
      const extUrl = linkCollection?.url;
      return extUrl;
    }
  }

  public loadScript(url: string) {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  renderScript(shareUrl) {
    this.hostName = this.appStateService.getEnvConfig()?.environment;
    if (this.hostName === 'dev' || this.hostName === 'staging') {
      this.shareUrlRefresh = this.translateService.instant(
        'ftiVideoArticle.devstage-shareurl-path'
      );
    } else {
      this.shareUrlRefresh = this.translateService.instant(
        'ftiVideoArticle.shareurl-path'
      );
    }

    setTimeout(() => {
      if (this.container === undefined) {
        this.loadScript(shareUrl);
        this.loadScript(this.shareUrlRefresh);
      } else {
        this.loadScript(shareUrl);
        this.loadScript(this.shareUrlRefresh);
      }
    });
  }

  open(content) {
    this.mailBackForm.reset();
    this.successmsg = false;
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'mailModelWindow',
    });
  }
  resetQuiz() {
    this.quizForm.reset();

    [].forEach.call(document.querySelectorAll('.quizste1error'), (el) => {
      logger.debug('elementNews');
      logger.debug(el);

      el.style.display = 'none';
    });
  }
  Quizopen(content) {
    this.quizForm.reset();
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.successmsg = false;
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
  }
  scromOpenModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      modalDialogClass: 'scrom-xxl',
    });
  }
  get f() {
    return this.mailBackForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.submitted && this.mailBackForm.valid) {
      this.successmsg = true;
    } else {
      this.successmsg = false;
    }
    return false;
  }

  labelMoveIn(e: any) {
    this.focusValue = true;
  }

  labelMoveOut(e: any) {
    if (e.target.value !== '') {
      this.focusValue = true;
    } else {
      this.focusValue = false;
    }
    logger.debug(e.target.value);
  }
  labelMoveInName(e: any) {
    this.focusValueName = true;
  }

  labelMoveOutName(e: any) {
    if (e.target.value !== '') {
      this.focusValueName = true;
    } else {
      this.focusValueName = false;
    }
    logger.debug(e.target.value);
  }
  labelMoveInEmail(e: any) {
    this.focusValueEmail = true;
  }

  labelMoveOutEmail(e: any) {
    if (e.target.value !== '') {
      this.focusValueEmail = true;
    } else {
      this.focusValueEmail = false;
    }
    logger.debug(e.target.value);
  }

  iframeUrl(videoid) {
    const videoUrl =
      'https://www.youtube.com/embed/' + videoid + '?rel=0&enablejsapi=1';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    return this.urlSafe;
  }

  scromUrl(path) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(path);
    return this.urlSafe;
  }

  getBackLinkUrl(backLink) {
    const location = window.location.href;
    if (location.includes('frankly-speaking-webinars')) {
      backLink = 'market-insights/frankly-speaking-webinars';
    }
    this.storageService.storeSelectedTab('tab-area-1');
    return '/' + backLink;
  }

  showDrop() {
    this.showDropdown = !this.showDropdown;
  }
  commentFormValidation() {
    this.quizForm = this.formBuilder.group({
      quizAnswer: ['', [Validators.required]],
    });

    let i = 0;

    this.quizDocument?.quizQuestions?.forEach((post) => {
      this.quizForm.addControl(
        'quizAnswer' + String(post.long),
        new FormControl(this.quizAnswer[i++], [Validators.required])
      );
    });
  }
  checkForError(post: any) {
    const inputForm = this.quizForm.get('quizAnswer' + post.long);
    if (inputForm.errors && (inputForm.dirty || inputForm.touched)) {
      return true;
    }
    return false;
  }

  validateQuiz() {
    // Set score and total number of questions
    const total = 5;
    const score = 0;
    // Get user input for each question
    logger.debug('quizForm');
    const questionArray = [];

    this.quizDocument?.quizQuestions?.forEach((element, index) => {
      const quizinc = index + 1;
      // tslint:disable-next-line
      const quizAnswer = document.forms['quizForm'][
        'question-' + quizinc
      ].value.toString();
      questionArray.push(quizAnswer);
    });

    // Validation
    const hiddenArray = [];
    for (let i = 0; i < questionArray.length; i++) {
      const quizinc = i + 1;
      if (questionArray[i] === null || questionArray[i] === '') {
        document.getElementById('quizerror' + quizinc).style.display = 'block';
        hiddenArray.push(quizinc);
      } else {
        document.getElementById('quizerror' + quizinc).style.display = 'none';
      }
    }
    if (hiddenArray.length === 0) {
      this.calculateQuizPercentage();
    }

    return false;
  }
  calculateQuizPercentage() {
    let count = 0;
    let total = 0;
    this.quizDocument?.quizQuestions?.forEach((element, index) => {
      const quizinc = index + 1;
      // tslint:disable-next-line
      const quizAnswer = document.forms['quizForm'][
        'question-' + quizinc
      ].value.toString();
      const optionsAnswer = element.option5;
      if (quizAnswer === optionsAnswer) {
        count++;
      }
    });
    if (count === 0) {
      this.totalPercentage = 0;
    } else {
      const allQuestionsTotal = this.quizDocument?.quizQuestions?.length;
      total = (count * 100) / allQuestionsTotal;
      this.totalPercentage = Math.round(total);
    }
    if (Math.round(total) <= 80) {
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
      this.step4 = false;
    } else {
      this.step1 = false;
      this.step2 = false;
      this.step3 = true;
      this.step4 = false;
    }
  }
  submitQuiz() {
    // NGC-14390 : removed email field
    if (this.quizUserName == null) {
      this.step3error = true;
      this.step3errorvalue = this.translateService.instant(
        'ftiCommon.userNameErrMsg'
      );
    } else {
      this.step3error = false;
      this.step1 = false;
      this.step2 = false;
      this.step3 = false;
      this.step4 = true;
      this.quizCertificateDownload();
    }
  }
  quizCertificateDownload() {
    const headers = new HttpHeaders({
      Accept: 'application/pdf',
      'user-name': this.quizUserName,
      'program-name': this.quizDocument?.displayName
        ? this.quizDocument.displayName
        : 'Empty',
      'Ocp-Apim-Subscription-Key': this.envConfig.getEnvConfig()
        .ocpApimSubscriptionKey,
      'Ocp-Apim-Trace': this.envConfig.getEnvConfig().ocpApimTrace,
    });
    const urlprefix = this.envConfig.getEnvConfig()?.ftiApiDomain;
    const quizCertificateDownloadUrl = this.translateService.instant(
      'ftiCommon.quiz-certificate-download-url'
    );
    const url = urlprefix + quizCertificateDownloadUrl;
    const options = { headers };
    this.subscription = this.http
      .get(url, {
        responseType: 'arraybuffer',
        headers,
      })
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        a.download = this.quizDocument?.displayName + ' Certificate.pdf';
        document.body.appendChild(a);
        a.click();
      });
  }
}
