<div class="row investment-glossary">
  <div *ngIf="page?.isPreview()" class="has-edit-button float-right">
    <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root=""
    >
    </ng-container>
  </div>
  <div class="col-12">
    <div class="searchBox">
      <label for="siteSearch" class="sr-only">Search</label>
      <input
        type="text"
        maxlength="64"
        id="siteSearch"
        autocomplete="off"
        placeholder="Search"
        #searchBarInput
        [(ngModel)]="searchTerm"
        name="searchBar"
      />
      <button
        aria-label="Search Glossary"
        class="btn btn-default pull-right"
        type="submit"
        (click)="onSearchClick()"
      >
        <i class="icon-fti_search searchIcon"></i>
      </button>
    </div>
    <div
      class="suggestion-list"
      *ngIf="!isSearchItemClicked && !isSearch && searchTerm"
    >
      <ul>
        <ng-container *ngIf="finalResults && finalResults?.length > 0">
          <li
            class="suggestion-list-item"
            *ngFor="let resultItem of finalResults"
            (click)="onSearchItemClick(resultItem)"
          >
            {{ resultItem?.title }}
          </li>
        </ng-container>
        <ng-container *ngIf="noResultsFound && searchTerm">
          <li class="suggestion-list-item" (click)="noItemSearch(searchTerm)">
            No Results found for '{{ searchTerm }}'
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="col-12">
    <div class="glossary-main">
      <div class="glossaryFor-holder d-none d-md-block" #stickyHeader>
        <ul>
          <li
            class="glossary-li"
            *ngFor="let glossaryItem of data; let i = index"
            (click)="clickedIndex = i; onAlphabetClick($event)"
            [ngClass]="{
              active: clickedIndex === i,
              disabled: !(glossaryItem?.glossaryContent?.length > 0)
                ? true
                : null
            }"
            [attr.disabled]="
              !(glossaryItem?.glossaryContent?.length > 0) ? true : null
            "
          >
            <a>{{ glossaryItem?.alphabets }}</a>
          </li>
        </ul>
      </div>
      <ul
        class="pl-0 list-unstyled glossary-list"
        *ngIf="!isSearchItemClicked && !isSearch"
      >
        <ng-container *ngFor="let glossaryItem of data">
          <li
            class="glossary-title-sec"
            #li_div
            [attr.id]="glossaryItem?.alphabets + '-pattern'"
          >
            <ul class="pl-0 list-unstyled">
              <li *ngFor="let glossaryContent of glossaryItem?.glossaryContent">
                <h6>{{ glossaryContent?.title }}</h6>
                <p>{{ glossaryContent?.description }}</p>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
      <ng-container *ngIf="isSearch">
        <ul class="pl-0 list-unstyled">
          <li *ngFor="let glossaryContent of finalResults">
            <h6>{{ glossaryContent?.title }}</h6>
            <p>{{ glossaryContent?.description }}</p>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="isSearchItemClicked">
        <ul class="pl-0 list-unstyled">
          <li *ngFor="let glossaryContent of filteredSearchResults">
            <h6>{{ glossaryContent?.title }}</h6>
            <p>{{ glossaryContent?.description }}</p>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
