import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leavingSite',
})
export class LeavingSitePipe implements PipeTransform {
  transform(menuItem?: any): string {
    return;
  }
}
