<div class="quicklinksSection">
  <div class="position-relative">
    <div class="section-inner-txt">
      <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
        <ng-container
          *ngIf="page?.isPreview()"
          [brManageContentButton]="document"
          documentTemplateQuery="new-ftindia-document"
          folderTemplateQuery="new-ftindia-folder"
          parameter="document"
          [relative]="false"
          root=""
        >
        </ng-container>
        <section
          class="fti-bgcolor fti-bg-gray--primary fti-quickActions"
          [ngStyle]="{ 'background-image': getUrl() }"
        >
          <div class="container">
            <div class="fti-quickActionContent">
              <h2>{{ title }}</h2>
              <link
                rel="stylesheet"
                href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
              />
              <div class="row">
                <div
                  class="col-xs-6 col-sm-4 col-md-4 col-lg-2"
                  *ngFor="let content of linkLists"
                >
                  <a
                    class="analytics-quick-link quicAction-link redirectGuestUrl"
                    [href]="content?.linkCompound?.url"
                    [target]="content?.linkCompound?.target"
                    data-action="Quick Links"
                  >
                    <span [ngClass]="content?.iconClass"></span
                    >{{ content?.linkCompound?.displayText }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
