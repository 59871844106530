import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import { GlobalConfigService } from '@services/global-config-service';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Logger } from '@utils/logger';
import { DropdownItem } from '@products/types';
import { TranslateService } from '@shared/translate/translate.service';
import { ActivatedRoute } from '@angular/router';
const logger = Logger.getLogger('Literature Search Results');

@Component({
  selector: 'ft-literature-search',
  templateUrl: './literature-search.component.html',
  styleUrls: ['./literature-search.component.scss'],
})
export class LiteratureSearchComponent implements OnInit, OnDestroy {
  constructor(
    private globalConfig: GlobalConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}
  @Input() literatureNames: string[];
  @Input() literatureDocuments: any;
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropDownChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() resultsFound: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deeplinkFilters: EventEmitter<any> = new EventEmitter<any>();
  @Input() dropdownItems: any;
  @Input() hasMultipleFilters: boolean;
  // pre selected primary filter
  @Input() primarySelectedFilter: string;
  @Input() secondarySelectedFilter: string;
  @Input() hasDeepLinkedFilter?: boolean;

  searchedInput = '';
  selectedDocuments: any;
  searchedInput$: Subscriber<string>;
  dataSubscription: Subscription;
  searchSuggestions: string[] = [];
  showNoSearchSuggestionMessage = false;
  searchedInputAgainstNoSearchSuggestionMessage: string;
  filterDropdowns: DropdownItem[];
  secondaryFilters: any;
  showDocumentPanel = true;
  clearTextBox = false;
  count: number;
  resultLimit = 10;

  public selectedFilterItem: string;
  public selectedSecondaryFilterItem: string;

  @ViewChild('primaryFilter') primaryFilterElem: ElementRef;
  @ViewChild('secondaryFilter') secondaryFilterDropdown: ElementRef;

  // Capture file extension from document url (if filetype is not present)

  /**
   * Triggered when any text in entered in search input.
   * @param event generated input event
   */
  onSearchInput(event: InputEvent): void {
    const searchedText: string = (event.target as HTMLInputElement).value;

    // Reset clearTextBox value to false so that suggestions will be visible
    this.clearTextBox = false;

    // Create new observable and assign its subscriber to search text subscriber.
    // This will indirectly be used to debounce the key input to currently 500 ms.
    // Also, not to omit event when the new value is not distinct.
    if (!this.searchedInput$) {
      this.dataSubscription = new Observable<string>((observer) => {
        this.searchedInput$ = observer;
      })
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((fullSearchedText) => {
          this.searchedInput = fullSearchedText.trim(); // Remove empty spaces in the search input.

          if (this.searchedInput) {
            this.searchSuggestions = this.literatureNames.filter((fundName) =>
              fundName.toLowerCase().includes(this.searchedInput.toLowerCase())
            );

            // Show "showNoSearchSuggestionMessage" only when filter returns empty list.
            if (this.searchSuggestions.length) {
              this.showNoSearchSuggestionMessage = false;
              // this.showDocumentPanel = true;
            } else {
              this.searchedInputAgainstNoSearchSuggestionMessage = this.searchedInput;
              this.showNoSearchSuggestionMessage = true;
              // this.showDocumentPanel = false;
            }
          } else {
            this.showNoSearchSuggestionMessage = false; // For blank search input, need not to show message.
            this.searchSuggestions = []; // Make it blank, as it can be case, user cleared the search input.

            this.resultsFoundEmitter();
            this.showDocumentPanel = true;
            this.filterEvent.next({
              searchedText: this.searchedInput,
            });
          }
          // Reset limit
          this.resultLimit = 10;
          this.cdr.detectChanges();
        });
    }

    this.searchedInput$.next(searchedText);
  }

  /**
   * Trigger search filter based upon selected search suggestion.
   * @param selectedSuggestion selected suggestion from the search suggestion list
   */
  onSearchSuggestionSelected(selectedSuggestion: any): void {
    if (selectedSuggestion.length) {
      this.showDocumentPanel = false;
      this.resultsFoundEmitter();
      this.searchSuggestions = [];

      if (typeof selectedSuggestion !== 'string') {
        this.selectedDocuments = this.getSearchedDocuments(
          selectedSuggestion,
          []
        );
      } else {
        this.searchedInput = selectedSuggestion;
        this.selectedDocuments = filter(
          this.literatureDocuments,
          (doc) => doc.dctermsTitle === selectedSuggestion
        );
      }

      this.count = this.selectedDocuments.length;

      this.filterEvent.next({
        searchedText: selectedSuggestion,
      });
    }
  }

  getSearchedDocuments(input, results) {
    forEach(input, (docs) => {
      if (input.length > 0) {
        forEach(this.literatureDocuments, (doc) => {
          if (doc.dctermsTitle === docs) {
            results.push(doc);
          }
        });
      }
    });
    return results;
  }

  // Add properties required for Drop Down
  simplifyDropDownItems = (input, defaultCategory) => {
    return input?.map((dropDown) => ({
      ...dropDown,
      label: dropDown.id
        ? this.translateService.instant('literature.' + dropDown.id)
        : this.translateService.instant('literature.' + dropDown.name),
      value: dropDown.id ? dropDown.id : dropDown.name,
      selected:
        dropDown.name === defaultCategory
          ? dropDown.name === defaultCategory
          : false,
    }));
  };
  // END

  ngOnInit(): void {
    if (this.dropdownItems?.hasOwnProperty('PageType')) {
      this.hasMultipleFilters = true;

      // Set default dropdown
      this.selectedFilterItem = this.primarySelectedFilter;

      // Set filter dropdown data
      this.filterDropdowns = [
        this.simplifyDropDownItems(
          this.dropdownItems.PageType,
          this.primarySelectedFilter
        ),
      ];

      // Add 'All' dropdown item in second dropdown
      const all = {
        dataRecords: { linkdata: [] },
        id: 'ALL',
        isCategoryDocContent: false,
        secondDropDown: [],
        label: this.translateService.instant('literature.ALL'),
        value: 'ALL',
        selected: true,
      };

      forEach(this.filterDropdowns, (dropdownItem) => {
        forEach(dropdownItem, (item) => {
          // Check if there is more than one subcategory
          if (item.FirstDropDown.length > 1) {
            item.FirstDropDown = this.simplifyDropDownItems(
              item.FirstDropDown,
              null
            );
            item.FirstDropDown.unshift(all);
          }
        });
      });
    } else {
      this.filterDropdowns = [
        this.simplifyDropDownItems(
          this.dropdownItems?.FirstDropDown,
          this.primarySelectedFilter
        ),
      ];
    }

    if (!this.secondaryFilters) {
      this.secondaryFilters = this.populateFilterValues(
        this.filterDropdowns,
        this.primarySelectedFilter
      );
    }
    // Get filter from router and load the components accordingly
    this.getDeepLinkingFilterParams();
  }

  resultsFoundEmitter() {
    this.resultsFound.emit(this.showDocumentPanel);
  }

  onPrimaryDropdownChange($event: DropdownItem, secondaryDropDown) {
    this.secondaryFilters = this.populateFilterValues(
      this.filterDropdowns,
      $event?.value
    );

    // update secondary dropdown values
    this.selectedSecondaryFilterItem = this.secondaryFilters;
    this.dropDownChanged.emit({
      dropdown: $event,
      elm: secondaryDropDown,
    });
  }

  onDropdownChange($event: DropdownItem, element) {
    this.dropDownChanged.emit({
      dropdown: $event,
      elm: element,
    });
  }

  public fileExtension(input) {
    const extensionIndex = input?.literatureHref.lastIndexOf('.');
    return input?.literatureHref?.slice(extensionIndex + 1).toLowerCase();
  }

  ngOnDestroy(): void {
    // Unsubscribe search text subscriber.
    this.dataSubscription?.unsubscribe();
  }

  showAllResults(): void {
    this.resultLimit = this.count;
  }

  populateFilterValues(input, selector) {
    return flatten(input).filter((data) => {
      return data?.name === selector;
    })[0];
  }

  onClearSearch($event: MouseEvent) {
    this.searchedInput = '';
    this.clearTextBox = true;
    this.searchSuggestions = [];
    this.showDocumentPanel = true;
    this.resultsFoundEmitter();
  }

  getDeepLinkingFilterParams = (): any => {
    this.hasDeepLinkedFilter = this.route.snapshot.queryParams?.hasOwnProperty(
      'firstFilter'
    );

    if (this.hasDeepLinkedFilter) {
      const firstFilter = parseInt(
        this.route.snapshot.queryParams?.firstFilter,
        10
      );
      let secondFilter = parseInt(
        this.route.snapshot.queryParams?.secondFilter,
        10
      );

      // check if filter identification is valid
      if (!this.secondaryFilters) {
        // Single dropdown
        if (firstFilter <= this.dropdownItems?.FirstDropDown?.length) {
          this.primarySelectedFilter = this.dropdownItems.FirstDropDown[
            firstFilter - 1
          ]?.id;
          this.selectedFilterItem = this.primarySelectedFilter;
          this.deeplinkFilters.emit(this.primarySelectedFilter);
        }
      } else {
        // multi dropdowns
        if (firstFilter <= this.dropdownItems?.PageType?.length) {
          this.primarySelectedFilter = this.dropdownItems.PageType[
            firstFilter - 1
          ]?.name;
        }
        if (secondFilter <= this.secondaryFilters?.FirstDropDown?.length) {
          this.secondaryFilters = this.populateFilterValues(
            this.filterDropdowns,
            this.primarySelectedFilter
          );
        } else {
          secondFilter = 1;
        }
        this.selectedFilterItem = this.primarySelectedFilter;
        this.selectedSecondaryFilterItem = this.secondaryFilters?.FirstDropDown[
          secondFilter - 1
        ]?.id;
        this.deeplinkFilters.emit({
          primaryFilter: this.primarySelectedFilter,
          secondaryFilter: this.selectedSecondaryFilterItem,
        });
      }
      this.cdr.detectChanges();
    }
  };
}
