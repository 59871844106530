import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DashIfEmptyPipe } from './dash-if-empty.pipe';
import { FormatPercentagePipe } from './format-percentage.pipe';
import { IsExternalLinkPipe } from './is-external-link.pipe';
import { IsInternalLinkPipe } from './is-internal-link.pipe';
import { IsDataTargetPipe, DataTargetValuePipe } from './data-target.pipe';
import { LeavingSitePipe } from './leaving-site.pipe';
import { ToNumberPipe } from './to-number.pipe';
import { SpacingPipe } from './spacing.pipe';
import { InrSymbolPipe } from './inr-symbol.pipe';
import { IsNewPagePipe } from './is-new-page.pipe';

// Components
@NgModule({
  declarations: [
    DashIfEmptyPipe,
    FormatPercentagePipe,
    LeavingSitePipe,
    SafeHtmlPipe,
    ToNumberPipe,
    IsExternalLinkPipe,
    IsInternalLinkPipe,
    SpacingPipe,
    InrSymbolPipe,
    IsDataTargetPipe,
    DataTargetValuePipe,
    IsNewPagePipe,
  ],
  imports: [],
  exports: [
    DashIfEmptyPipe,
    FormatPercentagePipe,
    LeavingSitePipe,
    IsExternalLinkPipe,
    IsInternalLinkPipe,
    SafeHtmlPipe,
    ToNumberPipe,
    SpacingPipe,
    InrSymbolPipe,
    IsDataTargetPipe,
    DataTargetValuePipe,
    IsNewPagePipe,
  ],
  providers: [DashIfEmptyPipe, InrSymbolPipe],
})
export class PipesModule {}
