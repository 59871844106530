import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@shared/translate/translate.service';

@Component({
  selector: 'ft-document-panel',
  templateUrl: './document-panel.component.html',
  styleUrls: ['./document-panel.component.scss'],
})
export class DocumentPanelComponent implements OnChanges {
  @Input() fundDocuments: any;
  @Input() errorInService: boolean;
  @Input() hasFilters = false;
  @Input() selectedFilter: string;
  @Input() allDocs: any;
  @Input() notFundsDocumentsData = false;

  public hasCustomLinkInput: boolean;
  public isLinksGenerated = false;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.loadCustomLinks();
    this.composeCustomDocumentLinks();
  }
  private composeCustomDocumentLinks() {
    // Get custom document links from BR labels
    const customDocumentLinksGroup = JSON.parse(
      this.translateService.instant('literature.customDocumentLinksGroup')
    );

    if (customDocumentLinksGroup && !this.isLinksGenerated) {
      this.fundDocuments?.forEach((doc) => {
        customDocumentLinksGroup?.find((category) => {
          if (category?.id === doc.id) {
            if (category.hasOwnProperty('secondDropDown')) {
              category.secondDropDown.forEach((secondaryCategory) => {
                doc.secondDropDown.find((secondaryDocs) => {
                  if (secondaryDocs.id === secondaryCategory.id) {
                    secondaryCategory.dataRecords.linkdata.forEach((input) => {
                      if (input.position === 'top') {
                        secondaryDocs.dataRecords.linkdata.unshift(input);
                      } else {
                        secondaryDocs.dataRecords.linkdata.push(input);
                      }
                    });
                  }
                });
              });
              this.isLinksGenerated = true;
            } else {
              category.dataRecords.linkdata.forEach((group) => {
                if (group.position === 'top') {
                  doc.dataRecords.linkdata.unshift(group);
                } else {
                  doc.dataRecords.linkdata.push(group);
                }
              });
              this.isLinksGenerated = true;
            }
          }
        });
      });
    }
  }

  private loadCustomLinks() {
    if (
      this.selectedFilter === 'Mutual Fund Reports' ||
      this.selectedFilter === 'FORTNIGHT-PORTFOLIO-DEBT-SCHEMES' ||
      this.selectedFilter === 'MONTHLY-PORTFOLIO-DSCLR'
    ) {
      this.hasCustomLinkInput = true;
    } else {
      this.hasCustomLinkInput = false;
    }
  }

  public getDocumentURL(input) {
    return input?.literatureHref.indexOf('http') > -1
      ? input.literatureHref
      : 'download' + input.literatureHref;
  }

  // Capture file extension from document url (if filetype is not present)
  public fileExtension(input) {
    const extensionIndex = input?.fileExtension
      ? input?.fileExtension
      : input?.literatureHref.lastIndexOf('.');
    if (input?.fileExtension !== 'NA') {
      if (input?.fileExtension && input?.fileExtension !== '') {
        return extensionIndex?.toLowerCase();
      } else {
        return input?.literatureHref?.slice(extensionIndex + 1).toLowerCase();
      }
    }
  }
}
