<div class="container position-relative">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
      <ng-container
        *ngIf="page?.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-ftindia-document"
        folderTemplateQuery="new-ftindia-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div class="col-xl-12 content-container fund-caveat">
        <div *ngIf="document">
          <div [innerHTML]="content.content.value"></div>
        </div>
      </div>
    </div>
  </div>
</div>
