import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Endpoints } from '../../../ft-constants/endpoints';
import { TranslateService } from '@components/shared/translate/translate.service';
import { Logger } from '@utils/logger';
import { FLASH_MESSAGE } from 'src/app/ft-constants/flash-message.constant';
import { EnvConfigService } from '@services/env-config.service';
import { SessionService } from '@services/session.service';

const logger = Logger.getLogger('FlashMessagesComponent');

@Component({
  selector: 'ft-flash-messages',
  templateUrl: './flash-messages.component.html',
  styleUrls: ['./flash-messages.component.scss'],
})
export class FlashMessagesComponent implements OnInit {
  @Input() flashPage: string;

  url = '';
  name = '';
  email = '';
  title = '';
  optId = '';
  content = '';
  urlPrefix = '';
  ocpApimTrace = '';
  ocpApimSubscriptionKey = '';
  environment = '';

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private envConfig: EnvConfigService,
    private sessionService: SessionService
  ) {
    this.environment = this.envConfig.getEnvConfig()?.environment;
  }

  ngOnInit(): void {
    this.ocpApimSubscriptionKey = this.envConfig.getEnvConfig()?.ocpApimSubscriptionKey;
    this.ocpApimTrace = this.envConfig.getEnvConfig()?.ocpApimTrace;
    this.urlPrefix = this.envConfig.getEnvConfig()?.ftiApiDomain;
    // WOPT / SOPT/ VRM-SIGUNUP / VRM-SIGNOUT
    this.activatedRoute.queryParams?.subscribe((params) => {
      this.optId = params.optId;
    });

    // Get values from resource bundle
    this.url =
      this.urlPrefix +
      this.translateService.instant('ftiFlashMessages.feedback_url');
    this.email = this.translateService.instant(
      'ftiFlashMessages.feedback_email'
    );

    switch (this.flashPage) {
      case FLASH_MESSAGE.wopt:
        this.title = this.translateService.instant(
          'ftiFlashMessages.wopt_title'
        );
        this.content = this.translateService.instant(
          'ftiFlashMessages.wopt_content'
        );
        this.name = this.translateService.instant('ftiFlashMessages.wopt_name');
        break;

      case FLASH_MESSAGE.sopt:
        this.title = this.translateService.instant(
          'ftiFlashMessages.sopt_title'
        );
        this.content = this.translateService.instant(
          'ftiFlashMessages.sopt_content'
        );
        this.name = this.translateService.instant('ftiFlashMessages.sopt_name');
        break;

      case FLASH_MESSAGE['vrm-signup']:
        this.title = this.translateService.instant(
          'ftiFlashMessages.vrm_signup_title'
        );
        this.content = this.translateService.instant(
          'ftiFlashMessages.vrm_signup_content'
        );
        this.name = this.translateService.instant(
          'ftiFlashMessages.vrm_signup_name'
        );
        break;

      case FLASH_MESSAGE['vrm-signout']:
        this.title = this.translateService.instant(
          'ftiFlashMessages.vrm_signout_title'
        );
        this.content = this.translateService.instant(
          'ftiFlashMessages.vrm_signout_content'
        );
        this.name = this.translateService.instant(
          'ftiFlashMessages.vrm_signout_name'
        );
        break;
    }

    if (this.optId) {
      this.sendFeedback();
    }
  }

  sendFeedback() {
    const data = {
      name: this.name,
      emailId: this.email,
      accountNo: '',
      feedback: this.optId,
    };

    const options =
      this.environment === 'pre' || this.environment === 'prod'
        ? this.sessionService.apiHttpOptionsWithCredentials
        : this.sessionService.apiHttpOptions;

    this.http.post(this.url, data, options).subscribe(
      (response) => {
        logger.debug('response >>>> ', response);
      },
      (error) => {
        logger.error('error >>>> ', error);
      }
    );
  }
}
