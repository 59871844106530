<div class="row csr-initiatives-container" *ngIf="displayedData">
  <!-- csr initiatives title and pdf icon -->
  <div class="title-container">
    <h2>
      {{ "products.csr-initiatives-title" | translate }}
      <div class="pull-right csr-div">
        <a [href]="csrPolicyUrl" target="_blank" class="pull-right csr-policy"
          ><span>{{ "products.csr-policy" | translate }} </span
          ><img alt="Click to download" src="/assets/images/pdf.png"
        /></a>
      </div>
    </h2>
  </div>

  <!-- initiative container -->
  <div class="initiative-container" *ngFor="let item of displayedData">
    <!-- heading with blue background-->
    <div class="panel-heading" *ngIf="(isHandheld$ | async) === false">
      <h4 class="panel-title">{{ item?.title }}</h4>
    </div>

    <!-- list of initiative details -->
    <div class="panel-body" *ngIf="(isHandheld$ | async) === false">
      <div class="csr-ul row">
        <div class="csr-li col-lg-3 location">
          <div class="location-span colored-border"></div>
          <div class="content">
            <h6>{{ labels?.locationLabel }}</h6>
            {{ item?.location }}
          </div>
        </div>
        <div class="csr-li col-xs-12 col-md-4 col-lg-3 partner">
          <div class="partner-span colored-border"></div>
          <div class="content">
            <h6>{{ labels?.partnerLabel }}</h6>
            {{ item?.partner }}
          </div>
        </div>
        <div class="csr-li col-xs-12 col-md-4 col-lg-3 schedule">
          <div class="schedule-span colored-border"></div>
          <div class="content">
            <h6>{{ labels?.implementationScheduleLabel }}</h6>
            {{ item?.implementationSchedule }}
          </div>
        </div>
      </div>
    </div>

    <!-- responsive stylings for handheld devices -->
    <div *ngIf="(isHandheld$ | async) === true">
      <ngb-accordion class="global-contact-accordion blue-accordion">
        <ngb-panel class="accordion-panel">
          <ng-template ngbPanelTitle class="panel-title">
            <div class="d-flex justify-content-between">
              <div class="d-flex accordion-header-responsive">
                {{ item?.title }}
              </div>
              <div class="d-flex">
                <i class="icon-fti_downArrow pull-right"></i>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent class="panel-content">
            <div class="csr-ul row">
              <div class="csr-li col-12 location csr-li-responsive">
                <div class="location-span colored-border"></div>
                <div class="content">
                  <h6 class="responsive-table-title">
                    {{ labels?.locationLabel }}
                  </h6>
                  {{ item?.location }}
                </div>
              </div>
              <div class="csr-li partner csr-li-responsive col-12">
                <div class="partner-span colored-border"></div>
                <div class="content">
                  <h6 class="responsive-table-title">
                    {{ labels?.partnerLabel }}
                  </h6>
                  {{ item?.partner }}
                </div>
              </div>
              <div class="csr-li schedule col-12 csr-li-responsive">
                <div class="schedule-span colored-border"></div>
                <div class="content">
                  <h6 class="responsive-table-title">
                    {{ labels?.implementationScheduleLabel }}
                  </h6>
                  {{ item?.implementationSchedule }}
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

    <!-- more details on initiative -->
    <div class="info-content">
      <p *ngFor="let item of item?.content" class="margin-bottom">{{ item }}</p>
    </div>

    <div class="dotted-border"></div>
  </div>

  <!-- more initiatives button -->
  <div class="more-details" *ngIf="showMoreInitiatives">
    <button (click)="loadInitiative()">{{ labels?.moreInitiatives }}</button>
  </div>
</div>
