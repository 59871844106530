<div class="">
  <div class="position-relative">
    <div class="section-inner-txt">
      <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
        <ng-container
          *ngIf="page?.isPreview()"
          [brManageContentButton]="document"
          documentTemplateQuery="new-ftindia-document"
          folderTemplateQuery="new-ftindia-folder"
          parameter="document"
          [relative]="false"
          root=""
        >
        </ng-container>
        <div
          *ngIf="contentType === 'Homepage View'"
          class="col-sm-12 col-md-6 p0 fti-bg-gray--2 pull-right fti-listGroup-wrapper marketBlogs ftl"
        >
          <section
            class="fti-listGroup pull-left"
            data-fti-component="Updates/Blog"
          >
            <h2>From our blogs</h2>
            <ul class="list-group thumbList">
              <li
                class="list-group-item imgThumb"
                *ngFor="let mksites of BlogLists"
              >
                <img
                  [alt]="mksites?.title"
                  [title]="mksites?.title"
                  [src]="mksites?.miblogDescription[0]?.imageURL"
                />
                <span class="dotted-divide">
                  <h5 class="list-group-item-heading">{{ mksites?.title }}</h5>
                  <p class="list-group-item-text">
                    <a
                      class="analytics-link"
                      [href]="mksites?.miblogDescription[0]?.linkURL"
                      [target]="
                        getTarget(mksites?.miblogDescription[0]?.target)
                      "
                      data-event-name="Event Market Insights"
                      data-category="Market Insights"
                      data-action="Blogs"
                      [attr.data-label]="
                        mksites?.miblogDescription[0]?.linkText
                      "
                      >{{ mksites?.miblogDescription[0]?.linkText }}</a
                    >
                  </p>
                  <small>{{ mksites?.miblogDescription[0]?.date }}</small>
                </span>
              </li>
            </ul>
          </section>
        </div>
        <div
          class="fti-container blogs-container"
          *ngIf="contentType !== 'Homepage View'"
        >
          <div class="fti-mediagrid">
            <div class="row" *ngIf="BlogLists?.length">
              <div class="col-12 col-md-6 col-sm-12 col-lg-6">
                <h4>{{ BlogLists[0]?.title }}</h4>
                <div
                  class="mediaItem blogItem mb+"
                  *ngFor="let mkBlog of BlogLists[0]?.miblogDescription"
                >
                  <a
                    class="analytics-link"
                    [href]="mkBlog?.linkURL"
                    [target]="getTarget(mkBlog?.target)"
                    data-event-name="Event Market Insights"
                    data-category="Market Insights"
                    data-action="Blogs"
                    [attr.data-label]="mkBlog?.linkText"
                  >
                    <img
                      [alt]="mkBlog?.linkText"
                      [title]="mkBlog?.linkText"
                      [src]="mkBlog?.imageURL"
                    />
                    <span class="dateStamp">{{ mkBlog?.date }}</span>
                    <span class="gridCaption blogTitle">{{
                      mkBlog?.linkText
                    }}</span>
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-sm-12 col-lg-6">
                <h4>{{ BlogLists[1]?.title }}</h4>
                <div
                  class="mediaItem blogItem mb+"
                  *ngFor="let mkBlog of BlogLists[1]?.miblogDescription"
                >
                  <a
                    class="analytics-link"
                    [href]="mkBlog?.linkURL"
                    [target]="getTarget(mkBlog?.target)"
                    data-event-name="Event Market Insights"
                    data-category="Market Insights"
                    data-action="Blogs"
                    [attr.data-label]="mkBlog?.linkText"
                  >
                    <img
                      [alt]="mkBlog?.linkText"
                      [title]="mkBlog?.linkText"
                      [src]="mkBlog?.imageURL"
                    />
                    <span class="dateStamp">{{ mkBlog?.date }}</span>
                    <span class="gridCaption blogTitle">{{
                      mkBlog?.linkText
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
