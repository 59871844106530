<ng-container *ngIf="page">
  <ng-container *ngIf="brConfig?.fundId; else previewBlock">
    <div class="fund-documents-wrapper">
      <div class="row">
        <div class="col-sm-12 col-md-7">
          <ng-container
            *ngFor="let fundDocument of fundDocuments; let i = index"
          >
            <div class="panel firstAcc" *ngIf="fundDocument?.documents">
              <div class="panel-heading" [id]="'heading' + i">
                <h4 class="panel-title">
                  <a
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#accordion' + i"
                    [attr.aria-controls]="'accordion' + i"
                    class="show"
                  >
                    <span class="acc-content1">{{
                      "literature." + fundDocument.fundDocCategory | translate
                    }}</span>
                    <i class="indicator icon-fti_rightArrow pull-right"></i>
                  </a>
                </h4>
              </div>
              <div
                [id]="'accordion' + i"
                class="accordion-collapse collapse show"
                [attr.aria-labelledby]="'heading' + i"
              >
                <div class="panel-body">
                  <ul>
                    <li *ngFor="let document of fundDocument?.documents">
                      <a
                        [href]="'download' + document.literatureHref"
                        target="_blank"
                      >
                        <ng-container
                          *ngIf="
                            fundDocument.fundDocCategory !== 'FUND-DOCUMENTS'
                          "
                        >
                          {{ document.dctermsTitle }}
                        </ng-container>
                        <ng-container
                          *ngIf="
                            fundDocument.fundDocCategory === 'FUND-DOCUMENTS'
                          "
                        >
                          {{
                            "literature.custom-label-" +
                              document.contentGrouping | translate
                          }}
                        </ng-container>
                        <span class="pull-right">
                          <img
                            *ngIf="document.literatureHref"
                            [src]="
                              '/assets/images/' +
                              fileExtension(document) +
                              '.png'
                            "
                            alt="icon"
                          />
                          <span *ngIf="!document.literatureHref">N/A</span>
                        </span>
                      </a>
                    </li>
                    <li
                      *ngIf="fundDocument.fundDocCategory !== 'FUND-DOCUMENTS'"
                    >
                      <a
                        *ngIf="
                          fundDocument.fundDocCategory ===
                            'Dis-Portfolio-Fortnightly' ||
                          fundDocument.fundDocCategory ===
                            'Dis-Portfolio-Monthly'
                        "
                        style="text-decoration: underline"
                        [href]="
                          'literature.link-' + fundDocument.fundDocCategory
                            | translate
                        "
                        target="_blank"
                      >
                        {{ "literature.reportArchiveLinkText" | translate }}
                      </a>
                      <a
                        *ngIf="
                          fundDocument.fundDocCategory ===
                          'Dis-Portfolio-Halfyearly'
                        "
                        style="text-decoration: underline"
                        [href]="
                          'literature.link-' + fundDocument.fundDocCategory
                            | translate
                        "
                        target="_blank"
                      >
                        {{ "literature.reportArchiveLinkText" | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
          <div *ngIf="docsLoaded && !fundDocuments && !errorInService">
            {{ "literature.literatureStatus" | translate }}
          </div>
          <div *ngIf="errorInService">
            {{ "literature.literatureErrorStatus" | translate }}
          </div>
        </div>
        <div class="col-sm-12 col-md-5 fti-mediagrid">
          <div *ngIf="fundVideo">
            <h6 class="mt0">
              {{ "products.overview-fund-video" | translate }}
            </h6>
            <div class="fundresource_Img mediaItem mb0">
              <a
                data-src="S9vtkOfQRMc"
                data-videotitle="Fund Video"
                class="market-outlook-video"
                (click)="openVideoModal(videoModal)"
              >
                <span class="icon icon-fti_play"></span>
                <img
                  [src]="fundVideoImage"
                  alt="Fund Video"
                  title="Fund Video"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #previewBlock>
  <h5 *ngIf="page && page.isPreview()">Fund Documents Component</h5>
</ng-template>

<ng-template #videoModal let-modal [ngIf]="popvisible">
  <div class="modal-in" id="fti-modal">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Fund Video</h4>
      <a
        type="button"
        class="fill-circle-close close-video"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span
          class="icon-fti_close"
          (click)="modal.dismiss('Cross click'); closePopup()"
        ></span>
      </a>
    </div>
    <div class="modal-body videoWrapper">
      <div class="fundresource_Img mediaItem mb+">
        <object height="100%" width="100%" [attr.data]="fundVideo"></object>
      </div>
    </div>
    <div class="modal-footer"></div>
  </div>
</ng-template>
