import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { EnvConfigService } from '../../../services/env-config.service';
import { AppStateService } from '@services/app-state.service';
@Component({
  selector: 'ft-beginers-guide',
  templateUrl: './beginers-guide.component.html',
  styleUrls: ['./beginers-guide.component.scss'],
})
export class BeginersGuideComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  documentData: any = {};
  linkData: any = {};
  linkUrl: string;
  linkTarget: string;
  url: any;
  constructor(
    private envConfig: EnvConfigService,
    private appStateService: AppStateService
  ) {}

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};

    return document && this.page?.getContent<Document>(document);
  }

  get data() {
    const data = this.document?.getData<DocumentData>();
    this.documentData = data;
    return this.documentData;
  }

  ngOnInit(): void {
    this.externalLink(this.data);
    this.url =
      this.appStateService.getftiAccountsUrl() +
      this.appStateService.getftiTrasactlogin();
  }

  get params() {
    return this.component?.getParameters();
  }

  externalLink(data) {
    if (!data?.enquiryButtons?.button3link?.linkCollection?.length) {
      return;
    }
    this.linkData = data?.enquiryButtons?.button3link?.linkCollection[0];
    this.linkUrl = this.linkData?.url;
    this.linkTarget = this.linkData?.target === '_blank' ? '_blank' : '_self';
  }
}
