<div class="carouselSection">
  <div class="position-relative">
    <div class="section-inner-txt">
      <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
        <ng-container
          *ngIf="page?.isPreview()"
          [brManageContentButton]="document"
          documentTemplateQuery="new-ftindia-document"
          folderTemplateQuery="new-ftindia-folder"
          parameter="document"
          [relative]="false"
          root=""
        >
        </ng-container>
        <ng-container [ngSwitch]="contentType">
          <ng-container *ngSwitchDefault>
            <ngb-carousel
              [showNavigationArrows]="navigationArrow"
              [showNavigationIndicators]="navigationIndicators"
              [interval]="content?.dataInterval"
              [animation]="true"
              [wrap]="true"
              [pauseOnHover]="true"
              *ngIf="CarouselImages"
            >
              <ng-template
                ngbSlide
                *ngFor="let result of CarouselImages; let i = index"
              >
                <div class="picsum-img-wrapper">
                  <!-- Desktop view -->
                  <a
                    *ngIf="result?.linkCompound?.linkCollection[0]?.url"
                    title="{{ result?.title }}"
                    class="analytics-carousel-link quicAction-link redirectGuestUrl a1"
                    data-action="Banner click"
                    [class.external]="
                      result?.linkCompound?.linkCollection[0]?.external === true
                    "
                    [target]="result?.linkCompound?.linkCollection[0]?.target"
                    [href]="result?.linkCompound?.linkCollection[0]?.url"
                  >
                    <ng-container
                      [ngTemplateOutlet]="imageMdTemplate"
                      [ngTemplateOutletContext]="{ result: result }"
                    ></ng-container>
                  </a>
                  <a
                    *ngIf="result?.linkCompound?.linkCollection[0]?.document"
                    title="{{ result?.title }}"
                    class="analytics-carousel-link quicAction-link redirectGuestUr a2"
                    data-action="Banner click"
                    [class.external]="
                      result?.linkCompound?.linkCollection[0]?.external === true
                    "
                    [target]="result?.linkCompound?.linkCollection[0]?.target"
                    [routerLink]="
                      getPageUrl(
                        result?.linkCompound?.linkCollection[0]?.document
                      )
                    "
                  >
                    <ng-container
                      [ngTemplateOutlet]="imageMdTemplate"
                      [ngTemplateOutletContext]="{ result: result }"
                    ></ng-container>
                  </a>
                  <!-- Mobile view -->
                  <a
                    *ngIf="result?.linkCompound?.linkCollection[0]?.url"
                    title="{{ result?.title }}"
                    class="analytics-carousel-link quicAction-link redirectGuestUrl a3"
                    data-action="Banner click"
                    [class.external]="
                      result?.linkCompound?.linkCollection[0]?.external === true
                    "
                    [target]="result?.linkCompound?.linkCollection[0]?.target"
                    [href]="result?.linkCompound?.linkCollection[0]?.url"
                  >
                    <ng-container
                      [ngTemplateOutlet]="imageSmTemplate"
                      [ngTemplateOutletContext]="{ result: result }"
                    ></ng-container>
                  </a>
                  <a
                    *ngIf="result?.linkCompound?.linkCollection[0]?.document"
                    title="{{ result?.title }}"
                    class="analytics-carousel-link quicAction-link redirectGuestUrl a4"
                    data-action="Banner click"
                    [class.external]="
                      result?.linkCompound?.linkCollection[0]?.external === true
                    "
                    [target]="result?.linkCompound?.linkCollection[0]?.target"
                    [routerLink]="
                      getPageUrl(
                        result?.linkCompound?.linkCollection[0]?.document
                      )
                    "
                  >
                    <ng-container
                      [ngTemplateOutlet]="imageSmTemplate"
                      [ngTemplateOutletContext]="{ result: result }"
                    ></ng-container>
                  </a>
                </div>
              </ng-template>
            </ngb-carousel>
          </ng-container>
          <ng-container *ngSwitchCase="'IND-carousel-RFB'">
            <div class="carousel-rfb">
              <ngb-carousel
                [showNavigationArrows]="true"
                [showNavigationIndicators]="false"
                [interval]="6000"
                [animation]="true"
                [wrap]="true"
                [pauseOnHover]="true"
                *ngIf="contentBlocks"
              >
                <ng-template
                  ngbSlide
                  *ngFor="let result of contentBlocks; let i = index"
                >
                  <section class="rfb-pages">
                    <div class="container">
                      <div class="row rfb-content">
                        <ng-container
                          *ngIf="
                            getImageURL(
                              result?.widenAssetCompound?.widenDocument
                            );
                            else fullwidth
                          "
                        >
                          <div class="col-8">
                            <div
                              [innerHTML]="result?.html.value | safeHtml"
                            ></div>
                          </div>
                          <div class="col-4">
                            <div class="video-container">
                              <div class="video-brightcove">
                                <div
                                  style="
                                    position: relative;
                                    display: block;
                                    max-width: 300%;
                                  "
                                >
                                  <div style="padding-top: 56.25%">
                                    <img
                                      style="
                                        position: absolute;
                                        top: 0px;
                                        right: 0px;
                                        bottom: 0px;
                                        left: 0px;
                                        width: 100%;
                                        height: 145%;
                                        border: 3px solid #fff;
                                      "
                                      class="pd-ifvid"
                                      src="{{
                                        getImageURL(
                                          result?.widenAssetCompound
                                            ?.widenDocument
                                        )
                                      }}"
                                      width="300"
                                      height="150"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-template #fullwidth>
                          <div class="col-12">
                            <div [innerHTML]="result?.html.value"></div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </section>
                </ng-template>
              </ngb-carousel>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- Image templates -->
<ng-template #imageMdTemplate let-result="result">
  <img
    class="d-none d-sm-none d-md-block d-xl-block maxwidthImage"
    src="{{ getImageURL(result?.imageForDesktop?.widenDocument) }}"
    alt="{{ result?.altTitle }}"
    title="{{ result?.title }}"
  />
</ng-template>
<ng-template #imageSmTemplate let-result="result">
  <img
    class="d-block d-sm-block d-md-none d-xl-none d-lg-none minwidthImage"
    src="{{ getImageURL(result?.imageForMobile?.widenDocument) }}"
    alt="{{ result?.altTitle }}"
    title="{{ result?.title }}"
  />
</ng-template>
