<div class="row" style="display: block">
  <div
    class="fundDocuments firstdropdownClass break-column-page break-columns-width"
  >
    <ng-container *ngFor="let fundDocument of fundDocuments; let i = index">
      <!-- Group categories where required e.g. Fund FactSheets and Addenda -->
      <ng-container
        *ngIf="
          (fundDocument.literatureFrequency && selectedFilter === 'ALL') ||
          selectedFilter === 'ADDENDUMS' ||
          selectedFilter === 'FUND-FACTSHEETS'
        "
      >
        <div
          class="panel firstAcc col-sm-12 col-md-6"
          *ngFor="
            let secondaryCategory of fundDocument.secondDropDown;
            let y = index
          "
        >
          <div class="panel-heading">
            <h4 class="panel-title">
              <a
                data-bs-toggle="collapse"
                [attr.data-bs-target]="
                  '#groupAccordion-' + secondaryCategory.id + '-' + y
                "
                [attr.aria-controls]="
                  '#groupAccordion-' + secondaryCategory.id + '-' + y
                "
                [ngClass]="{
                  show: y === 0 && selectedFilter === fundDocument.id
                }"
              >
                <span class="acc-content1">{{
                  "literature." + secondaryCategory.id | translate
                }}</span>
                <i class="indicator icon-fti_rightArrow pull-right"></i>
              </a>
            </h4>
          </div>

          <div
            class="accordion-collapse collapse"
            [id]="'groupAccordion-' + secondaryCategory.id + '-' + y"
            [ngClass]="{ show: y === 0 && selectedFilter === fundDocument.id }"
          >
            <div class="panel-body">
              <ng-container *ngIf="!secondaryCategory.isContent">
                <ul
                  *ngIf="secondaryCategory?.dataRecords?.linkdata?.length > 0"
                >
                  <li
                    *ngFor="
                      let document of secondaryCategory?.dataRecords?.linkdata
                    "
                  >
                    <a [href]="getDocumentURL(document)" target="_blank">
                      <span class="doc-name">{{ document.dctermsTitle }}</span>
                      <span class="pull-right">
                        <ng-container
                          *ngIf="fileExtension(document) !== undefined"
                        >
                          <img
                            *ngIf="document.literatureHref"
                            [src]="
                              '/assets/images/' +
                              fileExtension(document) +
                              '.png'
                            "
                            alt="icon"
                          />
                        </ng-container>
                        <span *ngIf="!document.literatureHref">N/A</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </ng-container>
              <ng-container *ngIf="secondaryCategory.isContent">
                <div
                  class="docContentContainer"
                  [innerHTML]="secondaryCategory.markup"
                ></div>
              </ng-container>
              <ng-container
                *ngIf="!(secondaryCategory?.dataRecords?.linkdata?.length > 0)"
                [ngTemplateOutlet]="secondLevelPanel"
                [ngTemplateOutletContext]="{
                  secondLevelPanels: secondaryCategory
                }"
              ></ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- END -->
      <div
        class="panel firstAcc col-sm-12 col-md-6"
        *ngIf="
          fundDocument &&
            hasFilters &&
            selectedFilter === 'ALL' &&
            !fundDocument.literatureFrequency;
          else showDocumentsForSelectedCategory
        "
        [id]="fundDocument.id"
      >
        <div class="panel-heading">
          <h4 class="panel-title">
            <a
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#accordion' + i"
              [attr.aria-controls]="'#accordion' + i"
              [ngClass]="{ show: i === 0 }"
            >
              <span class="acc-content1">{{
                "literature." + fundDocument.id | translate
              }}</span>
              <i class="indicator icon-fti_rightArrow pull-right"></i>
            </a>
          </h4>
        </div>
        <div
          class="accordion-collapse collapse"
          [ngClass]="{ show: i === 0 }"
          [id]="'accordion' + i"
        >
          <div class="panel-body">
            <ng-container *ngIf="!fundDocument.isContent">
              <ul *ngIf="fundDocument?.dataRecords?.linkdata?.length > 0">
                <li
                  *ngFor="let document of fundDocument?.dataRecords?.linkdata"
                >
                  <a [href]="getDocumentURL(document)" target="_blank">
                    <span class="doc-name">{{ document.dctermsTitle }}</span>
                    <span class="pull-right">
                      <ng-container
                        *ngIf="fileExtension(document) !== undefined"
                      >
                        <img
                          *ngIf="document.literatureHref"
                          [src]="
                            '/assets/images/' + fileExtension(document) + '.png'
                          "
                          alt="icon"
                        />
                      </ng-container>
                      <span *ngIf="!document.literatureHref">N/A</span>
                    </span>
                  </a>
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="fundDocument.isContent">
              <div
                class="docContentContainer"
                [innerHTML]="fundDocument.markup"
              ></div>
            </ng-container>
            <ng-container
              *ngIf="!(fundDocument?.dataRecords?.linkdata?.length > 0)"
              [ngTemplateOutlet]="secondLevelPanel"
              [ngTemplateOutletContext]="{ secondLevelPanels: fundDocument }"
            ></ng-container>
          </div>
        </div>
      </div>
      <!-- Show Documents For Selected Category -->
      <ng-template #showDocumentsForSelectedCategory>
        <!-- Individual updates to be pulled out of 'Year' category -->
        <ng-container
          *ngIf="
            selectedFilter !== 'ADDENDUMS' &&
            selectedFilter !== 'FUND-FACTSHEETS' &&
            selectedFilter?.indexOf(selectedFilter) > -1
          "
        >
          <ng-container
            *ngFor="
              let secondaryCategory of fundDocument.secondDropDown;
              let y = index
            "
          >
            <div
              class="panel firstAcc col-sm-12 col-md-6"
              *ngIf="secondaryCategory.id?.indexOf(selectedFilter) > -1"
            >
              <div class="panel-heading">
                <h4 class="panel-title">
                  <a
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="
                      '#groupAccordion-' + secondaryCategory.id + '-' + y
                    "
                    [attr.aria-controls]="
                      '#groupAccordion-' + secondaryCategory.id + '-' + y
                    "
                    [ngClass]="{
                      show: y === 0 && selectedFilter === fundDocument.id
                    }"
                  >
                    <span class="acc-content1">{{
                      "literature." + secondaryCategory.id | translate
                    }}</span>
                    <i class="indicator icon-fti_rightArrow pull-right"></i>
                  </a>
                </h4>
              </div>

              <div
                class="accordion-collapse collapse"
                [id]="'groupAccordion-' + secondaryCategory.id + '-' + y"
                [ngClass]="{
                  show: y === 0 && selectedFilter === fundDocument.id
                }"
              >
                <div class="panel-body">
                  <ng-container *ngIf="!secondaryCategory.isContent">
                    <ul
                      *ngIf="
                        secondaryCategory?.dataRecords?.linkdata?.length > 0
                      "
                    >
                      <li
                        *ngFor="
                          let document of secondaryCategory?.dataRecords
                            ?.linkdata
                        "
                      >
                        <a [href]="getDocumentURL(document)" target="_blank">
                          <span class="doc-name">{{
                            document.dctermsTitle
                          }}</span>
                          <span class="pull-right">
                            <ng-container
                              *ngIf="fileExtension(document) !== undefined"
                            >
                              <img
                                *ngIf="document.literatureHref"
                                [src]="
                                  '/assets/images/' +
                                  fileExtension(document) +
                                  '.png'
                                "
                                alt="icon"
                              />
                            </ng-container>
                            <span *ngIf="!document.literatureHref">N/A</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </ng-container>
                  <ng-container *ngIf="secondaryCategory.isContent">
                    <div
                      class="docContentContainer"
                      [innerHTML]="secondaryCategory.markup"
                    ></div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !(secondaryCategory?.dataRecords?.linkdata?.length > 0)
                    "
                    [ngTemplateOutlet]="secondLevelPanel"
                    [ngTemplateOutletContext]="{
                      secondLevelPanels: secondaryCategory
                    }"
                  ></ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <!-- END -->
        <!-- prevent duplicate records for ADDENDUMS and FUND-FACTSHEETS -->
        <ng-container
          *ngIf="
            selectedFilter !== 'ADDENDUMS' &&
            selectedFilter !== 'FUND-FACTSHEETS' &&
            selectedFilter?.indexOf('UPDATES-') < 0
          "
        >
          <div
            class="panel firstAcc col-sm-12 col-md-6"
            *ngIf="
              fundDocument &&
                hasFilters &&
                selectedFilter === fundDocument.id &&
                !fundDocument.literatureFrequency;
              else updatesDocumentTemplate
            "
            [id]="fundDocument.id"
          >
            <div class="panel-heading">
              <h4 class="panel-title">
                <a
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#accordion' + i"
                  [attr.aria-controls]="'#accordion' + i"
                  class="show"
                >
                  <span class="acc-content1">{{
                    "literature." + fundDocument.id | translate
                  }}</span>
                  <i class="indicator icon-fti_rightArrow pull-right"></i>
                </a>
              </h4>
            </div>
            <div
              class="accordion-collapse collapse show"
              [id]="'accordion' + i"
            >
              <div class="panel-body">
                <ng-container *ngIf="!fundDocument.isContent">
                  <ul *ngIf="fundDocument?.dataRecords?.linkdata?.length > 0">
                    <li *ngIf="hasCustomLinkInput">
                      <a
                        [href]="'literature.customLinkURL' | translate"
                        target="_blank"
                        style="text-decoration: underline"
                      >
                        {{ "literature.customLinkText" | translate }}
                      </a>
                    </li>
                    <li
                      *ngFor="
                        let document of fundDocument?.dataRecords?.linkdata
                      "
                    >
                      <a [href]="getDocumentURL(document)" target="_blank">
                        <span class="doc-name">{{
                          document.dctermsTitle
                        }}</span>
                        <span class="pull-right">
                          <ng-container
                            *ngIf="fileExtension(document) !== undefined"
                          >
                            <img
                              *ngIf="document.literatureHref"
                              [src]="
                                '/assets/images/' +
                                fileExtension(document) +
                                '.png'
                              "
                              alt="icon"
                            />
                          </ng-container>
                          <span *ngIf="!document.literatureHref">N/A</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </ng-container>
                <ng-container *ngIf="fundDocument.isContent">
                  <div
                    class="docContentContainer"
                    [innerHTML]="fundDocument.markup"
                  ></div>
                </ng-container>
                <ng-container
                  *ngIf="!(fundDocument?.dataRecords?.linkdata?.length > 0)"
                  [ngTemplateOutlet]="secondLevelPanel"
                  [ngTemplateOutletContext]="{
                    secondLevelPanels: fundDocument
                  }"
                ></ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #updatesDocumentTemplate>
        <div
          class="panel firstAcc col-sm-12 col-md-6"
          *ngIf="
            fundDocument &&
            hasFilters &&
            selectedFilter === fundDocument.id &&
            fundDocument.literatureFrequency
          "
          [id]="fundDocument.id"
        >
          <div class="panel-heading">
            <h4 class="panel-title">
              <a
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#accordion' + i"
                [attr.aria-controls]="'#accordion' + i"
                class="show"
              >
                <span class="acc-content1">{{
                  "literature." + fundDocument.id | translate
                }}</span>
                <i class="indicator icon-fti_rightArrow pull-right"></i>
              </a>
            </h4>
          </div>
          <div class="accordion-collapse collapse show" [id]="'accordion' + i">
            <div class="panel-body">
              <ng-container *ngIf="!fundDocument.isContent">
                <ul *ngIf="fundDocument?.dataRecords?.linkdata?.length > 0">
                  <li
                    *ngFor="let document of fundDocument?.dataRecords?.linkdata"
                  >
                    <a [href]="getDocumentURL(document)" target="_blank">
                      <span class="doc-name">{{ document.dctermsTitle }}</span>
                      <span class="pull-right">
                        <ng-container
                          *ngIf="fileExtension(document) !== undefined"
                        >
                          <img
                            *ngIf="document.literatureHref"
                            [src]="
                              '/assets/images/' +
                              fileExtension(document) +
                              '.png'
                            "
                            alt="icon"
                          />
                        </ng-container>
                        <span *ngIf="!document.literatureHref">N/A</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </ng-container>
              <ng-container *ngIf="fundDocument.isContent">
                <div
                  class="docContentContainer"
                  [innerHTML]="fundDocument.markup"
                ></div>
              </ng-container>
              <ng-container
                *ngIf="!(fundDocument?.dataRecords?.linkdata?.length > 0)"
                [ngTemplateOutlet]="secondLevelPanel"
                [ngTemplateOutletContext]="{ secondLevelPanels: fundDocument }"
              ></ng-container>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Display list of documents OTHER than "FUND Documents" used on
    /market-insights/winding-up-of-specific-schemes > Latest Updates -->
      <div
        class="panel firstAcc col-sm-12 col-md-6"
        *ngIf="fundDocument && notFundsDocumentsData"
        [id]="fundDocument.id"
      >
        <div class="panel-heading">
          <h4 class="panel-title">
            <a
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#accordion' + i"
              [attr.aria-controls]="'#accordion' + i"
            >
              <span class="acc-content1">{{
                "literature." + fundDocument.id | translate
              }}</span>
              <i class="indicator icon-fti_rightArrow pull-right"></i>
            </a>
          </h4>
        </div>
        <div class="accordion-collapse collapse" [id]="'accordion' + i">
          <div class="panel-body">
            <ng-container>
              <ul
                *ngIf="
                  fundDocument?.dataRecords?.linkdata?.length > 0;
                  else noDocumentsFound
                "
              >
                <li
                  *ngFor="let document of fundDocument?.dataRecords?.linkdata"
                >
                  <a
                    *ngIf="document.externalLink"
                    [href]="document.literatureHref"
                    target="_blank"
                  >
                    <span class="doc-name">{{ document.dctermsTitle }}</span>
                    <span class="pull-right">
                      <img
                        *ngIf="document.fileExtension"
                        [src]="
                          '/assets/images/' + fileExtension(document) + '.png'
                        "
                        alt="icon"
                      />
                      <span *ngIf="!document.literatureHref">N/A</span>
                    </span>
                  </a>
                  <a
                    *ngIf="!document.externalLink"
                    [href]="getDocumentURL(document)"
                    target="_blank"
                  >
                    <span class="doc-name">{{ document.dctermsTitle }}</span>
                    <span class="pull-right">
                      <ng-container
                        *ngIf="fileExtension(document) !== undefined"
                      >
                        <img
                          *ngIf="document.literatureHref"
                          [src]="
                            '/assets/images/' + fileExtension(document) + '.png'
                          "
                          alt="icon"
                        />
                      </ng-container>
                      <span *ngIf="!document.literatureHref">N/A</span>
                    </span>
                  </a>
                </li>
              </ul>
            </ng-container>
            <ng-container
              *ngIf="!(fundDocument?.dataRecords?.linkdata?.length > 0)"
              [ngTemplateOutlet]="secondLevelPanel"
              [ngTemplateOutletContext]="{ secondLevelPanels: fundDocument }"
            ></ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noDocumentsFound>
  <div class="p-3">No documents found for this category.</div>
</ng-template>
<div *ngIf="!fundDocuments && !errorInService">
  {{ "literature.literatureStatus" | translate }}
</div>
<div *ngIf="errorInService">
  {{ "literature.literatureErrorStatus" | translate }}
</div>

<ng-template #secondLevelPanel let-secondLevelPanels="secondLevelPanels">
  <ng-container
    *ngFor="let fundDocument of secondLevelPanels.secondDropDown; let i = index"
  >
    <div
      class="panel firstAcc"
      *ngIf="fundDocument?.dataRecords?.linkdata?.length"
      style="padding: 20px 20px 0 20px"
      [id]="fundDocument.id"
    >
      <div class="panel-heading">
        <h4 class="panel-title">
          <a
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#secondAccordion' + i"
            [attr.aria-controls]="'#secondAccordion' + i"
            class="show"
          >
            <span class="acc-content1">{{
              "literature." + fundDocument.id | translate
            }}</span>
            <i class="indicator icon-fti_rightArrow pull-right"></i>
          </a>
        </h4>
      </div>
      <div
        class="accordion-collapse collapse show"
        [id]="'secondAccordion' + i"
      >
        <div class="panel-body">
          <ul
            *ngIf="
              fundDocument?.dataRecords?.linkdata?.length > 0;
              else noDocumentsFound
            "
          >
            <li
              *ngIf="
                hasCustomLinkInput &&
                fundDocument.id === 'Half-Yearly-Portfolio-Statement'
              "
            >
              <a
                [href]="'literature.customLinkURL' | translate"
                target="_blank"
                style="text-decoration: underline"
              >
                {{ "literature.customLinkText" | translate }}
              </a>
            </li>
            <li *ngFor="let document of fundDocument?.dataRecords?.linkdata">
              <a [href]="getDocumentURL(document)" target="_blank">
                <span class="doc-name">{{ document.dctermsTitle }}</span>
                <span class="pull-right">
                  <ng-container *ngIf="fileExtension(document) !== undefined">
                    <img
                      *ngIf="document.literatureHref"
                      [src]="
                        '/assets/images/' + fileExtension(document) + '.png'
                      "
                      alt="icon"
                    />
                  </ng-container>
                  <span *ngIf="!document.literatureHref">N/A</span>
                </span>
              </a>
            </li>
          </ul>
          <ng-container
            *ngIf="!(fundDocument?.dataRecords?.linkdata?.length > 0)"
            [ngTemplateOutlet]="secondLevelPanel"
            [ngTemplateOutletContext]="{ panel: fundDocument }"
          ></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
