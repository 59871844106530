import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FundCompareDetail } from '@components/products/fund-compare/fund-compare.type';
import { FundCompareService } from '@components/products/fund-compare/services/fund-compare.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '@services/analytics.service';
import {
  DropdownItem,
  FundId,
  FundListingComponentType,
  FundListingDataFormat,
  FundListingShareClassPerformanceDataFormat,
} from '@types';
import { Subscription } from 'rxjs';
import { InvestNowService } from '@services/invest-now.service';

@Component({
  selector: 'ft-fund-card',
  templateUrl: './fund-card.component.html',
  styleUrls: ['./fund-card.component.scss'],
})
export class FundCardComponent implements OnInit, OnDestroy {
  @Input() componentType: FundListingComponentType;
  @Input() fundListingCard: FundListingDataFormat;
  @Input() fundCompareIds: string[];
  @Input() showClosebutton = false;
  @Output() closeButtonClicked = new EventEmitter<string>();

  @Output() addFund = new EventEmitter<FundCompareDetail>();
  @Output() removeFund = new EventEmitter<string>();
  @Output() isDisabledCheckboxClicked = new EventEmitter<boolean>();
  dataSubscriptions: Subscription[] = [];
  isFundChecked = false;
  disableComparisonCheckbox = false;
  showPopover = false;

  selectedPpssPeriodDropdownValue = '';
  // PPSS specific declaration(s).
  ppssPerfValue: string;

  // NAVs specific declarations.
  defaultSetForChunksDisplay = 0;
  totalChunksCount: number;
  disablePreviousToggleNav: boolean;
  disableNextToggleNav: boolean;

  // IDCW specific declarations.
  distributionDataAsLists: [[string, string][]] = [[]];
  defaultOptionForShareClassName = 0;

  constructor(
    private fundCompareService: FundCompareService,
    private analyticsService: AnalyticsService,
    private investNowService: InvestNowService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // NAVs specific initialisations.
    if (this.componentType === FundListingComponentType.NAV) {
      this.totalChunksCount = this.fundListingCard?.shareClassesChunksData.length;

      this.disablePreviousToggleNav = true;
      this.disableNextToggleNav = this.totalChunksCount > 1 ? false : true;
    }

    // IDCW specific initialisations.
    if (this.componentType === FundListingComponentType.IDCW) {
      // Delete the first empty element created during initialisation.
      this.distributionDataAsLists.shift();

      this.fundListingCard?.shareClassesData.forEach((shareClass) => {
        const distributionDataAsList: [string, string][] = Object.entries(
          shareClass.distributionData
        );

        this.distributionDataAsLists.push(distributionDataAsList);
      });
    }

    // Generic initialisations.
    // Load the fund card with checbox checked, if the fund id matches in the compare list supplied.
    // Else, disable the checkbox if total funds added to compare has reached the limit of three.
    if (this.fundCompareIds?.includes(this.fundListingCard.fundId)) {
      this.isFundChecked = true;
    } else {
      if (this.fundCompareIds?.length === 3) {
        this.disableComparisonCheckbox = true;
      }
    }

    // Uncheck the fund comparison checkbox, when an update is received from below subscription.
    // When fund id not received, clear the checkbox for every fund. That means, reset is triggered.
    // Else, clear the checkbox for current fund, if fund id matches.
    this.dataSubscriptions.push(
      this.fundCompareService.getResetActionUpdate()?.subscribe((fundId) => {
        if (!fundId || fundId === this.fundListingCard?.fundId) {
          this.isFundChecked = false;
        }
      })
    );

    // Disable the fund comparison checkbox, when an update is received from below subscription.
    // That means, already three funds has been selected.
    // But, note that current one is not in selected fund list.
    this.dataSubscriptions.push(
      this.fundCompareService
        .getComparisonLimitUpdate()
        ?.subscribe((limitReached) => {
          if (limitReached) {
            this.disableComparisonCheckbox = this.isFundChecked ? false : true;
          } else {
            this.disableComparisonCheckbox = false;
          }
        })
    );

    // Default Selected Dropdown
    this.selectedPpssPeriodDropdownValue =
      this.fundListingCard?.ppssPeriodDropdownItems?.find(
        (item) => item?.selected
      )?.value || '';
  }

  /**
   * Triggered when an option is changed in performance period select dropdown.
   * @param event change event generated from select dropdown
   */
  onPerfPeriodChange(dropdownItem: DropdownItem): void {
    const selectedPerfPeriod: string = dropdownItem.value;
    const perfObj: FundListingShareClassPerformanceDataFormat = this
      .fundListingCard?.shareClassData?.performanceData;
    switch (selectedPerfPeriod) {
      case 'averageAnnualReturnSinceInception':
        this.ppssPerfValue = perfObj.averageAnnualReturnSinceInception;
        break;
      default:
        this.ppssPerfValue = perfObj[selectedPerfPeriod];
    }

    // Tracking analytics on dropdown change of Annualized Returns
    this.analyticsService.trackEvent({
      event: 'Event Fund Explorer',
      category: 'Fund Explorer',
      action:
        'Annualised Returns | ' +
        this.fundListingCard?.ppssPeriodDropdownItems.find((item) => {
          return item.value === selectedPerfPeriod;
        }).label,
      label: this.fundListingCard.fundName,
    });
  }

  /**
   * Toggle previous/next NAVs listing based upon length and contents of default NAVs display.
   * @param selectedToggle previous/next value
   */
  toggleNavs(selectedToggle: string): void {
    if (selectedToggle === 'previous') {
      if (this.defaultSetForChunksDisplay - 1 >= 0) {
        this.defaultSetForChunksDisplay -= 1;
      }

      if (this.defaultSetForChunksDisplay === 0) {
        this.disablePreviousToggleNav = true;
      } else {
        this.disablePreviousToggleNav = false;
      }

      this.disableNextToggleNav =
        this.defaultSetForChunksDisplay + 1 < this.totalChunksCount
          ? false
          : true;
    } else {
      if (this.defaultSetForChunksDisplay + 1 < this.totalChunksCount) {
        this.defaultSetForChunksDisplay += 1;
      }

      if (this.defaultSetForChunksDisplay + 1 === this.totalChunksCount) {
        this.disableNextToggleNav = true;
      } else {
        this.disableNextToggleNav = false;
      }

      this.disablePreviousToggleNav =
        this.defaultSetForChunksDisplay - 1 >= 0 ? false : true;
    }
  }

  /**
   * Triggered when an option is changed in share class select dropdown.
   * @param event change event generated from select dropdown
   */
  onShareClassNameChange(event: Event): void {
    const selectedShareClassName: string = (event.target as HTMLSelectElement)
      .value;

    this.defaultOptionForShareClassName = this.fundListingCard.shareClassesData.findIndex(
      (shareClass) => shareClass.shareClassName === selectedShareClassName
    );
  }

  /**
   * Called on click of fund name.
   * @param event click event generated by anchor tag
   */
  navigateToFundDetail(event: Event): void {
    const label = (event.target as HTMLAnchorElement).classList;

    // Tracking Analytics on navigation
    this.analyticsService.trackEvent({
      event: 'Event Fund Explorer',
      category: 'Fund Explorer',
      action: label.contains('fund-name') ? 'click' : 'Fund Details',
      label: this.fundListingCard.fundName,
    });
  }

  /**
   * Trigger event to block the checkbox being checked when comparison limit reached.
   * Subsequently, alert parent component to action on 'isDisabledCheckboxClicked' being triggered.
   */
  onComparisonCheckboxClicked(): void | boolean {
    if (this.disableComparisonCheckbox) {
      // Alert parent component that disabled checkbox is clicked.
      this.isDisabledCheckboxClicked.next();

      return false; // This will block checkbox being checked and further execution of 'change' event.
    }
  }

  /**
   * Trigger event to add fund to the comparison list.
   * @param fundId fund id of the fund selected
   * @param fundName fund name of the fund selected
   */
  onFundSelectionForComparison(fundId: FundId, fundName: string): void {
    if (this.isFundChecked) {
      this.addFund.next({
        fundId,
        fundName,
      });
      // Tracking Analytics on add to compare
      this.analyticsService.trackEvent({
        event: 'Event Fund Explorer',
        category: 'Fund Explorer',
        action: 'Compare',
        label: this.fundListingCard.fundName,
      });
    } else {
      this.removeFund.next(fundName);
    }
  }

  onCloseButtonClicked(fundId: string): void {
    this.closeButtonClicked.emit(fundId);
  }

  ngOnDestroy(): void {
    this.dataSubscriptions.forEach((dataSubscription) =>
      dataSubscription.unsubscribe()
    );
  }

  investNow() {
    if (this.fundListingCard.enableInvestNow) {
      this.analyticsService.trackEvent({
        event: 'Event Fund Explorer',
        category: 'Fund Explorer',
        action: 'Invest Now',
        label: this.fundListingCard.fundName,
      });

      this.investNowService.investNow(
        this.fundListingCard?.fundId,
        this.fundListingCard?.fundName
      );
    }
  }
}
