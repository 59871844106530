export class Constant {
  public static channelName = 'en-in';
  public static isSmallTabsLayout = 'small-tabs';
  public static isLargeTabsLayout = 'large-tabs';
  public static isLayoutWithSelect = 'smalltabtwoselect';
  public static isNewToInvesting = 'new-to-investing';
  public static isNewToMutualFunds = 'new-to-mutual-funds';
  public static isWomenAndInvesting = 'women-and-investing';
  public static isYourChildsFuture = 'your-childs-future';
  public static isSmartTaxPlanning = 'smart-tax-planning';
  public static isWebinars = 'webinars';
  public static isManageVolatilityPitfalls =
    'managing-volatility-and-pitfalls-of-investing';
  public static isMoreAboutMutualFunds = 'more-about-mutual-funds';
  public static isPlanningforRetirement = 'planning-for-retirement';
  public static isFranklinTempleton = 'why-franklin-templeton';
  public static recordsPerPage = 8;
  public static recordsPerPageBlog = 6;
  public static panRegex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
  public static usFeederPage = 'us-feeder-campaign';
  public static campaign = 'campaigns';
  public static blueChipPage = 'blue-chip-campaign';
  public static primaFundPage = 'prima-fund-campaign';
  public static tfi = 'tfi';
  public static elssFundPage = 'elss-fund-campaign';
  public static smallCompaniesPage = 'small-companies-campaign';
  public static shareBtnUrl =
    'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a3233742f2d1bc1';
  public static ftPlayStoreLink =
    'https://play.google.com/store/apps/details?id=com.franklintempletonindia.IDE.app&amp;hl=en';
  public static ftAppStoreLink =
    'https://itunes.apple.com/in/app/franklin-templeton-india/id1274986031?mt=8';
  public static ftiWhatsApp = 'http://bit.ly/FTIWhatsApp';
  public static ftiFblink = 'http://www.facebook.com/FranklinTempletonIndia';
  public static ftiTwitterLink = 'http://twitter.com/ftiindia';
  public static ftiYoutubeLink = 'http://www.youtube.com/templetonindia';
  public static ftiLinkedInLink = 'http://www.linkedin.com/company/2470432';
  public static dateFormat = 'MMMM DD, YYYY';
  public static latestDateFormat = 'DD-MM-YYYY';
  public static articleDetailShareUrlRefresh =
    '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a3233742f2d1bc1';
}
