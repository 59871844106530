import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '@services/storage.service';
import { Subject } from 'rxjs';
import { SessionService } from '@services/session.service';
import { Logger } from '@utils/logger';
import { EnvConfig } from '@services/env-config.service';
import { AppStateService } from '@services/app-state.service';

const logger = Logger.getLogger('LoginService');

@Injectable({
  providedIn: 'root',
})
export class RefreshService implements OnDestroy {
  public renewTimeout = 600; // 600 s
  private env: EnvConfig;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private appStateService: AppStateService,
    private storageService: StorageService,
    private sessionService: SessionService
  ) {
    this.env = this.appStateService.getEnvConfig();
    this.storageService.retrieveIsLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) {
        this.refreshInterval();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Runs refresh Token every renewTimeout in s
   */
  private refreshInterval(): void {
    setInterval(() => {
      this.refreshToken();
    }, this.renewTimeout * 1000);
  }

  /**
   * Refresh accessToken
   */
  private refreshToken(): void {
    this.storageService.retrieve('guId').then((guId: string) => {
      const loginForm = {
        guId,
      };
      const loginDetails = JSON.stringify(loginForm);
      this.sessionService
        .refreshToken$(loginDetails)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            logger.debug('Refreshing Token');
            // Setting accessToken cookie after renew.
            this.storageService.setCookieByName(
              response.body,
              'accessToken',
              this.env.baseCookieVal + ';expires=0'
            );
            this.storageService.setCookieByName(
              response.body,
              'guId',
              this.env.baseCookieVal + ';expires=0'
            );
          },
          (error) => {
            logger.debug('Refresh error:', error);
          }
        );
    });
  }
}
