import { Inject, Injectable, OnDestroy } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';
import {
  BypassRole,
  DashboardConfig,
  FirmRole,
  ISegmentCharacteristics,
  IUserProfile,
  IUserProfileInfo,
  LoginSource,
  MaintenanceSession,
  ProfileSummary,
  UserRole,
} from '@services/profile.interface';
import { SegmentService } from '@services/segment.service';
import { SessionService } from '@services/session.service';
import { SegmentId } from '@types';
import { BYPASS, ROLE } from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AppStateService } from './app-state.service';
import { ProfileConfig } from './profile.config';
import { StorageService } from './storage.service';
import { SiteConfigService } from './site-config.service';
import { TranslateService } from '@shared/translate/translate.service';

const logger = Logger.getLogger('ProfileService');

@Injectable({
  providedIn: 'root',
})
export class ProfileService implements OnDestroy {
  static readonly roleToSegmentMap = {
    distributor: SegmentId.DISTRIBUTOR,
    FA: SegmentId.FINANCIAL_PROFESSIONALS,
    FP: SegmentId.FINANCIAL_PROFESSIONALS,
    GK: SegmentId.GATEKEEPER,
    INV: SegmentId.INVESTOR,
    // In Profile service for Investor role is SH
    SH: SegmentId.INVESTOR,
    INST: SegmentId.INSTITUTIONAL,
    shariah: SegmentId.SHARIAH,
    // Myfunds role mapping
    INVESTOR: SegmentId.INVESTOR,
    ADVISOR: SegmentId.FINANCIAL_PROFESSIONALS,
  };

  private unsubscribe$: Subject<void> = new Subject<void>();
  private profile: IUserProfile;
  private unValidatedProfileSubject$ = new ReplaySubject<IUserProfile>(1);
  private readonly validatedProfile$: Observable<IUserProfile>;
  private userProfileKeySubject$: ReplaySubject<string> = new ReplaySubject<string>(
    1
  );
  private maintenanceSession$: ReplaySubject<MaintenanceSession> = new ReplaySubject<MaintenanceSession>(
    1
  );
  private validated$ = new ReplaySubject<boolean>(1);
  private profileKey: string;
  private toolsTermsCookie: string;
  private readonly isAccountsAvailable$: ReplaySubject<string>;
  private healthMessage = '';

  constructor(
    private sessionService: SessionService,
    private segmentService: SegmentService,
    private storageService: StorageService,
    private appStateService: AppStateService,
    private siteConfigService: SiteConfigService,
    private translateService: TranslateService,
    @Inject(LOCATION) readonly locationRef: Location
  ) {
    this.validatedProfile$ = combineLatest([
      this.unValidatedProfileSubject$,
      this.validated$,
    ]).pipe(map(([profile, isValidated]): IUserProfile => profile));

    // debug profile changes
    this.validated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isValidated: boolean): void => {
        logger.debug('Is Validated', isValidated);
      });
    this.getUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userProfile: IUserProfile): void => {
        logger.debug('Validated Profile Change', userProfile);
      });
    this.getUnvalidatedUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userProfile: IUserProfile): void => {
        logger.debug('Un-validated Profile Change', userProfile);
      });
    this.getProfileChanges$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((key: string): void => {
        logger.debug('Profile Key Change', key);
      });

    this.isAccountsAvailable$ = new ReplaySubject(1);
    this.isAccountsAvailable$.next(this.healthMessage); // assume available until proven otherwise
    this.initiateValidateSession();

    // listen for segment changes
    this.segmentService
      .getCurrentSegmentId$()
      .subscribe((segment: SegmentId) => {
        if (
          this.profile &&
          this.getSegmentRole(this.profile.profileInfo.role) !== segment
        ) {
          this.setProfile(
            ProfileConfig.getDefaultConfig(segment),
            LoginSource.SEGMENT_SWITCH
          );
        }
      });

    // Syncing cookies during page load due to issue with loading configuration in storage service constructor.
    this.storageService.syncCookieOnStartup(
      this.appStateService.getEnvConfig()?.baseCookieVal
    );
    // Set profile after syncing cookies
    this.setInitialProfile(this.locationRef.search);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Returns SegmentId
   * @param role - role from profileInfo
   */
  public getSegmentRole(role: string): SegmentId {
    // role in profile could ne lower or upper case
    if (role) {
      return (
        ProfileService.roleToSegmentMap[role.toUpperCase()] ||
        ProfileService.roleToSegmentMap[role.toLowerCase()]
      );
    }
  }

  /**
   * set the initial profile based on:
   * 1. bypass
   * 2. debug
   * 3. storage
   * 3. default
   * in that priority order.
   */
  private setInitialProfile(queryString: string) {
    // first check bypass
    const bypassRole =
      this.getBypassRole(queryString) || this.storageService.getBypassCookie();
    if (bypassRole) {
      let config = ProfileConfig.getConfigWithBypass(
        bypassRole as BypassRole | UserRole
      );
      if (!config) {
        config = ProfileConfig.getConfigWithFirm(bypassRole as FirmRole);
        if (config) {
          config.profileInfo.firm = bypassRole; // FIXME probably need to look up proper firm setting
        }
      }
      if (config) {
        this.setProfile(config, LoginSource.BYPASS);
      } else {
        logger.error('Invalid bypass role:', bypassRole);
      }
    }
    if (!this.profile) {
      // no bypass
      // try debug
      this.storageService
        .retrieve<string>('testProfileId', true)
        .then((name) => {
          const profileConfig: ProfileConfig = ProfileConfig.getConfigWithName(
            name
          );
          // NB: some configs only exist in debug component, so won't be found
          if (profileConfig) {
            this.setProfile(profileConfig, LoginSource.DEBUG);
          } else {
            // storage
            this.getProfileFromStorage().then((profile) => {
              if (profile) {
                this.setProfile(profile, LoginSource.STORAGE, false);
              } else {
                // default
                this.storageService.retrieveSegment().then((segment) => {
                  let segmentId = this.segmentService.getCurrentSegmentId(true);
                  // trying to get segment from cookie
                  if (segment) {
                    segmentId = segment;
                  }
                  this.setProfile(
                    ProfileConfig.getDefaultConfig(segmentId),
                    LoginSource.NEW
                  );
                });
              }
            });
          }
        });
    }
  }

  private setProfile(
    profile: IUserProfile,
    loginSource: LoginSource,
    updateLocalStorage = true
  ) {
    logger.debug('setProfile()', profile, loginSource, updateLocalStorage);
    if (profile) {
      if (profile.profileInfo) {
        this.syncProfileDataWithAnalyticsStorage(
          profile.profileInfo,
          this.siteConfigService?.product?.site?.country
        );
      }
      this.profile = profile;
      this.profile.loginSource = profile.loginSource
        ? profile.loginSource
        : loginSource;
      this.unValidatedProfileSubject$.next(profile);
      if (updateLocalStorage) {
        this.storageService.storeProfileSummary(this.getProfileSummary());
      }

      this.checkSegmentCompatible();

      const profileKey = this.getSegmentationCharacteristicsString();
      if (this.profileKey !== profileKey) {
        this.profileKey = profileKey;
        this.segmentService.setSegmentCharacteristicsString(this.profileKey);
        this.userProfileKeySubject$.next(this.profileKey);
      }
      if (profile.profileInfo?.firm && this.isBypass()) {
        logger.debug('set dashboard redirect for ', profile.profileInfo.firm);
        const firmDashboardUrl = this.getPartnerDashboardUrl(
          profile.profileInfo.firm
        );
        if (
          firmDashboardUrl &&
          !this.locationRef.pathname.includes(firmDashboardUrl)
        ) {
          // TODO: Just simply redirect. Convert to navigation service.
          this.locationRef.href =
            firmDashboardUrl + '?bypass=' + profile.profileInfo.firm;
        }
      }
    }
  }

  public getPartnerDashboardUrl(firm: string): string {
    return ProfileConfig.DASHBOARD_REDIRECTS.find(
      (dashboardConfig: DashboardConfig) => {
        return dashboardConfig?.firm === firm;
      }
    )?.dashBoardUrl;
  }

  private checkSegmentCompatible() {
    const segmentId: SegmentId = this.segmentService.getCurrentSegmentId(true);
    const profileSegmentId: SegmentId = this.getSegmentRole(
      this.profile.profileInfo.role
    );
    logger.debug(
      'checkSegmentCompatible()',
      segmentId,
      profileSegmentId,
      this.profile.profileInfo.role
    );
    if (segmentId !== profileSegmentId) {
      // assumption: Profile ALWAYS takes precedence over segment
      // could be wrong but need to start somewhere
      this.segmentService.setSegment(profileSegmentId, true, this.isBypass());
    } else if (
      this.isBypass() ||
      this.isRole() ||
      this.storageService.getBypassCookie()
    ) {
      // Set segment storage if bypassing user.
      this.segmentService.setSegment(profileSegmentId, true, true);
    }
  }

  private getProfileFromStorage(): Promise<IUserProfile> {
    return this.storageService
      .retrieveProfileSummary()
      .then((profile: ProfileSummary) => {
        if (profile) {
          const profileSummary: IUserProfile = {
            name: 'Retrieved from Storage',
            isLoggedIn: profile.isLoggedIn,
            // isIdentified: profile.isIdentified === 'true',
            loginSource: profile.source as LoginSource | LoginSource.STORAGE,
            profileInfo: {
              role: profile.role,
              webExperience: profile.webExperience,
              firm: profile.firm,
            },
          };
          // Adding accountsAccess and dashboardUrl when they exists in profile.
          if (profile.accountsAccess && profile.dashboardUrl) {
            profileSummary.profileInfo.accountsAccess = profile.accountsAccess;
            profileSummary.profileInfo.dashboardUrl = profile.dashboardUrl;
          }
          // Adding dummy data for bypassed users.
          if (this.checkProfileSource(profile)) {
            // TODO: Check if any other options required for Literature
            profileSummary.profileInfo.userId = 'bypassuser';
            profileSummary.profileInfo.displayName = 'bypass user';
            profileSummary.profileInfo.businessKey = '1234';
            profileSummary.profileInfo.dealerNo = '01234';
          }
          return profileSummary;
        }
        return null;
      });
  }

  private checkProfileSource(profileSummary: ProfileSummary): boolean {
    return (
      profileSummary.source === LoginSource.BYPASS && profileSummary.isLoggedIn
    );
  }

  /**
   * returns an Observable that can be subscribed to for user profile changes.
   * Will return earlier with best guess of profile before validateSession returns.
   */
  public getUnvalidatedUserProfile$(): Observable<IUserProfile> {
    return this.unValidatedProfileSubject$.asObservable();
  }

  /**
   * returns an Observable that can be subscribed to for user profile changes
   * only returns after validateSession has returned
   */
  public getUserProfile$(): Observable<IUserProfile> {
    return this.validatedProfile$;
  }

  /**
   * Returns the current value of the user profile
   * WARNING: Try and use observable instead as value can change.
   */
  public getUserProfile(): IUserProfile {
    return this.profile;
  }

  /**
   * returns an Observable that can be subscribed to for login status changes
   */
  public isLoggedIn$(): Observable<boolean> {
    return this.getUnvalidatedUserProfile$().pipe(
      map((userProfile: IUserProfile): boolean => !!userProfile.isLoggedIn)
    );
  }

  /**
   * Returns true if user is currently logged in
   * WARNING: Try and use observable instead as value can change.
   */
  public isLoggedIn(): boolean {
    return this.profile?.isLoggedIn;
  }

  /**
   * Segmentation characteristics for Bloomreach to target content.
   */
  private getSegmentationCharacteristicsString(): string {
    const characteristics: ISegmentCharacteristics = {
      isLoggedIn: this.profile.isLoggedIn ? 'true' : 'false',
      dashboardAccess:
        this.profile.profileInfo?.dashboardUrl &&
        this.profile.profileInfo?.dashboardUrl !== ''
          ? 'true'
          : 'false',
      // isIdentified: this.profile.isIdentified ? 'true' : 'false',
      segment: this.segmentService.getCurrentSegmentId(true),
      subsegment: this.profile.profileInfo.webExperience,
    };
    if (this.profile.profileInfo.firm) {
      characteristics.firm = this.profile.profileInfo.firm;
    }
    return JSON.stringify(characteristics);
  }

  private getProfileSummary(): ProfileSummary {
    const summary: ProfileSummary = {
      isLoggedIn: this.profile.isLoggedIn,
      source: this.profile.loginSource,
      // isIdentified: this.profile.isIdentified ? 'true' : 'false',
      role: this.profile.profileInfo.role,
      webExperience: this.profile.profileInfo.webExperience,
      firm: this.profile.profileInfo.firm,
      accountsAccess: this.profile.profileInfo.accountsAccess,
      dashboardUrl: this.profile.profileInfo.dashboardUrl,
    };
    return summary;
  }
  /**
   * Handle response from validateSession to update the profile.
   * This can cause a segment switch if it doesnt match the current segment
   */
  private setProfileFromAccounts(profileInfo: IUserProfileInfo) {
    logger.debug('Entering setProfileFromAccounts', profileInfo);
    const isLoggedIn = this.isUserSignedIn(profileInfo);
    if (isLoggedIn) {
      this.setProfile(
        {
          // isIdentified: true,
          isLoggedIn: true,
          name: 'Logged In From Accounts',
          profileInfo,
        },
        LoginSource.OAUTH
      );
      // NEW_AUTH_SESSION is availble means, consider the login api triggerd and redirect accounts dashboard if dashboardUrl availble.
      // this.storageService
      //   .isSet(NEW_AUTH_SESSION)
      //   .then((isSet: boolean): void => {
      //     if (isSet && this.appStateService.isHomePage()) {
      //       // dashboardUrl is availble means, need to redirect accounts dashboard.
      //       if (profileInfo.dashboardUrl) {
      //         this.locationRef.href = profileInfo.dashboardUrl;
      //       }
      //     }
      //     this.storageService.remove(NEW_AUTH_SESSION);
      //   });
    } else if (this.profile?.loginSource === LoginSource.OAUTH) {
      // logged out from accounts
      // keep role and web experience but lose the rest.
      this.setProfile(
        {
          isLoggedIn: false,
          name: 'Logged Out From Accounts',
          profileInfo: {
            role: this.profile.profileInfo.role,
            webExperience: this.profile.profileInfo.webExperience,
            businessKey: '',
          },
        },
        LoginSource.OAUTH
      );
    } else {
      // need to force new emit as now is validated
      this.unValidatedProfileSubject$.next(this.profile);
    }
    // NGC-10438 moved this from start of method to end, as we shouldn't emit validated profile until we've set the profileInfo
    this.validated$.next(true);
  }

  /**
   * Validating bypass profile for loggged-in users
   * @param storageProfile - profile retrieved from storage. If client uses bypass it should be stored on init.
   * @param profileData - profile retrieved form accounts API.
   */
  private validateBypassProfile(
    storageProfile: ProfileSummary, // null if not found
    profileData: IUserProfileInfo
  ): void {
    logger.debug('Entering validateBypassProfile', profileData);
    if (
      storageProfile?.source === LoginSource.BYPASS &&
      this.isUserSignedIn(profileData)
    ) {
      // Need to check if Bypassed user is in storage and compare it with really logged in user
      if (storageProfile.role !== profileData.role) {
        // Set required for login to null to use bypass profile.
        profileData.userId = null;
        profileData.dealerNo = null;
      }
    }
    this.setProfileFromAccounts(profileData);
  }

  /**
   * Check if user is signed-in
   * @param profileInfo - User Profile Info
   */
  private isUserSignedIn(profileInfo: IUserProfileInfo): boolean {
    return profileInfo?.userId && profileInfo.userSysNo !== null;
  }

  /**
   * Set profile through debug mode
   */
  public setProfileFromDebug(profile: ProfileConfig): void {
    this.setProfile(profile, LoginSource.DEBUG);
  }

  /**
   * used to manually trigger a profile update
   * This is used by gateway banner to trigger page refresh when the profile doesn't change from default
   * i.e. choosing investor
   */
  public triggerProfileUpdate(segmentId): void {
    this.userProfileKeySubject$.next(this.profileKey);
    this.segmentService.setSegment(segmentId, true);
  }

  /**
   * This can return before the user profile has been validated.
   */
  public getProfileChanges$(): Observable<string> {
    return this.userProfileKeySubject$.asObservable();
  }

  private getBypassRole(queryString: string): BypassRole | UserRole | FirmRole {
    const params = new URLSearchParams(queryString.substring(1));
    let ret: BypassRole | UserRole | FirmRole;
    ret = params.get(BYPASS) as BypassRole;
    if (!ret) {
      ret = params.get(ROLE) as UserRole;
    }
    return ret;
  }
  /**
   * Check for internal user.
   * @param userGroup String
   * @returns boolean
   */
  public checkIfUserIsInternal(userGroup): boolean {
    return userGroup === 'FPEMPLOYEEGROUP' || userGroup === 'FP_INTERNAL_USER';
  }

  /**
   *
   * @param zipCode String
   */
  get5DigitZip(zip: string): string {
    return zip && zip.substring(0, 5);
  }

  /**
   * Check if profile is bypassed
   * @param dontCheckUrlParam - false in don't need to check param in URL
   */
  public isBypass(dontCheckUrlParam?: boolean): boolean {
    const bypassParam =
      this.locationRef.search.includes(BYPASS) || dontCheckUrlParam;
    return this.profile.loginSource === LoginSource.BYPASS && bypassParam;
  }

  /**
   * Check if role is bypassed
   */
  private isRole(): boolean {
    return (
      this.profile.loginSource === LoginSource.BYPASS &&
      this.locationRef.search.includes(ROLE)
    );
  }

  /**
   * Set Tools Terms Cookie name
   * @param dialogConfig - Dialog service config
   */
  private setToolsTermsCookie$(dialogConfig): void {
    this.getUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile: IUserProfile) => {
        this.toolsTermsCookie =
          (profile.profileInfo?.userSysNo || '') +
          dialogConfig.eventName +
          '_termsaccepted';
      });
  }

  /**
   * Get Tools Terms Cookie name
   * @param dialogConfig - Dialog service config
   */
  public getToolsTermsCookie(dialogConfig): string {
    this.setToolsTermsCookie$(dialogConfig);
    return this.toolsTermsCookie;
  }

  /**
   * Whenever profile data changes,update analytics data to localstorage.
   * If user has been logged out then do not remove the data,persist it as it
   * @param profile IUserProfileInfo
   * @returns void
   */
  public syncProfileDataWithAnalyticsStorage(
    profile: IUserProfileInfo,
    siteCountry: string
  ): void {
    const { businessKey, userSysNo } = profile;
    switch (siteCountry) {
      case 'US':
        if (businessKey || userSysNo) {
          this.storageService.storeAnalyticsData({
            expressNo: businessKey || '',
            userSysNo: userSysNo || '',
          });
        }
        break;
      default:
    }
  }

  private initiateValidateSession() {
    if (this.appStateService.hasAuthentication()) {
      this.sessionService
        .validateSession$()
        .pipe(first())
        .subscribe(
          (sessionData: MaintenanceSession) => {
            if (
              // sessionData.maintainanceFlag === 'true' &&
              sessionData?.maintenanceFor &&
              (sessionData.maintenanceFor.indexOf('all') !== -1 ||
                sessionData.maintenanceFor.indexOf('web') !== -1)
            ) {
              this.validated$.next(false);
            } else {
              this.validated$.next(true);
            }
            this.maintenanceSession$.next(sessionData);
          },
          (error) => {
            if (error.status === 0 || error.status >= 500) {
              logger.error(
                'validateSession error',
                error?.status,
                error?.statusText
              );
              if (!this.healthMessage) {
                this.healthMessage = this.translateService.instant(
                  'ftiLoginRegister.maintenanceWebContent'
                );
                this.maintenanceSession$.next({
                  maintenanceWebContent: this.healthMessage,
                });
              }
            }
            this.validated$.next(true);
          }
        );
    } else {
      this.validated$.next(true);
    }
  }

  public getIsAccountsAvailable$(): Observable<string> {
    return this.isAccountsAvailable$.asObservable();
  }

  /**
   *  Get Maintenance Session details
   */
  public getMaintenanceSession$(): Observable<MaintenanceSession> {
    return this.maintenanceSession$.asObservable();
  }

  /**
   * Get Validated Maintenance
   */
  public getValidated$(): Observable<boolean> {
    return this.validated$.asObservable();
  }
}
