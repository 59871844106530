<div
  class="table-list col-12"
  [ngClass]="{
    'red-border': borderColor === 'red',
    'blue-border': borderColor === 'blue',
    'green-border': borderColor === 'green',
    'orange-border': borderColor === 'orange'
  }"
>
  <h6 class="table-list-title">{{ title }}</h6>
  <div class="row">
    <div
      *ngFor="let item of tableData; let i = index"
      class="col-lg-3 col-md-3 col-sm-6 custom-width"
    >
      <ul>
        <li *ngIf="item?.listTitle">
          <b>{{ item.listTitle }}</b>
        </li>
        <li *ngFor="let item of item.list">{{ item }}</li>
      </ul>
    </div>
  </div>
</div>
