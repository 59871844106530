<div *ngIf="isPopulated" class="bar-chart">
  <div class="panel">
    <div class="panel-heading">
      <h4 class="panel-title">
        {{ data.chartTitle }}&nbsp;<span class="smalltext">{{
          data.calculationType
        }}</span>
        <span class="pull-right smalltext"
          >{{ data.asOfLabel }} {{ data.asOfDate }}</span
        >
      </h4>
    </div>
    <div class="panel-collapse in accordion-content">
      <div class="panel-body">
        <div id="portfolio-bar-chart" *ngIf="chartOptions">
          <highcharts-chart
            [Highcharts]="highcharts"
            [options]="chartOptions"
            [(update)]="updateChart"
            [callbackFunction]="chartCallback"
            style="width: 100%; display: block"
          >
          </highcharts-chart>
        </div>
      </div>
    </div>
  </div>

  <!--
  <ft-proximal [placement]="data.proximalTopPlacement"></ft-proximal>
  <ft-proximal
    *ngIf="data.secondaryProximalTopPlacement"
    [placement]="data.secondaryProximalTopPlacement"
  ></ft-proximal>
  <ft-proximal [placement]="data.proximalBottomPlacement"></ft-proximal>
  <ft-proximal
    *ngIf="data.secondaryProximalBottomPlacement"
    [placement]="data.secondaryProximalBottomPlacement"
  ></ft-proximal> -->
</div>
