<div class="feedbackForm">
  <div class="fti-container position-relative">
    <div class="row">
      <div *ngIf="isThankYou" class="col-sm-12 col-xs-12 mt-3">
        <div class="thankYou">
          <h5>
            <span class="success-icon icon icon-fti_tick"></span>
            {{ flashMessage }}
          </h5>
        </div>
      </div>
      <div *ngIf="!isThankYou">
        <div class="col-sm-12">
          <form
            *ngIf="feedbackForm"
            [formGroup]="feedbackForm"
            (ngSubmit)="feedbackSubmit()"
            autocomplete="off"
            novalidate="true"
            method="post"
          >
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="field-wrapper">
                  <div
                    class="form-group"
                    [ngClass]="{
                      focused: this.feedbackForm.value?.name
                    }"
                  >
                    <label class="control-label" for="namefield">
                      {{ labelName }}
                      <sup>*</sup>
                    </label>
                    <input
                      id="namefield"
                      formControlName="name"
                      maxlength="80"
                      type="text"
                      class="form-field form-control"
                      [ngClass]="{ disabled: formFieldsDisable }"
                      (keyup)="onKeyUp($event, 'name')"
                      (paste)="onPaste($event)"
                    />
                  </div>
                  <div *ngIf="errMsg" class="clsRed">
                    <span *ngFor="let err of errMsg">
                      {{ err.name }}
                    </span>
                  </div>
                </div>
                <div class="field-wrapper">
                  <div
                    class="form-group"
                    [ngClass]="{
                      focused: this.feedbackForm.value?.emailId
                    }"
                  >
                    <label class="control-label" for="email">
                      {{ labelEmail }}
                      <sup>*</sup>
                    </label>
                    <input
                      id="email"
                      formControlName="emailId"
                      maxlength="100"
                      type="text"
                      class="form-field form-control"
                      [ngClass]="{ disabled: formFieldsDisable }"
                      (keyup)="onKeyUp($event, 'emailId')"
                      (paste)="onPaste($event)"
                    />
                  </div>
                  <div *ngIf="errMsg" class="clsRed">
                    <span *ngFor="let err of errMsg">
                      {{ err.emailId }}
                    </span>
                  </div>
                </div>
                <div class="field-wrapper">
                  <div
                    class="form-group"
                    [ngClass]="{
                      focused: this.feedbackForm.value?.accountNo
                    }"
                  >
                    <label class="control-label" for="pannumber">
                      {{ labelPan }}
                    </label>
                    <input
                      id="pannumber"
                      formControlName="accountNo"
                      maxlength="13"
                      type="text"
                      class="form-field form-control"
                      [ngClass]="{ disabled: formFieldsDisable }"
                      (keyup)="onKeyUp($event, 'accountNo')"
                      (paste)="onPaste($event)"
                    />
                  </div>
                  <div *ngIf="errMsg" class="clsRed">
                    <span *ngFor="let err of errMsg">
                      {{ err.accountNo }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="field-wrapper">
                  <div
                    class="form-group"
                    [ngClass]="{
                      focused: this.feedbackForm.value.feedback
                    }"
                  >
                    <label class="control-label" for="feedbackformlabel">
                      {{ labelFeedback }}
                      <sup>*</sup>
                    </label>
                    <textarea
                      id="feedbackformlabel"
                      formControlName="feedback"
                      maxlength="500"
                      aria-label="feedback"
                      class="form-field form-control"
                      (keyup)="onKeyUp($event, 'feedback')"
                      (paste)="onPaste($event)"
                    ></textarea>
                  </div>
                  <div *ngIf="errMsg" class="clsRed">
                    <span *ngFor="let err of errMsg">
                      {{ err.feedback }}
                    </span>
                  </div>
                  <div class="clsRed">
                    {{ specialCharactersNotAllowed }}
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <button
                  [disabled]="!feedbackForm.valid || isFormSubmitted"
                  type="submit"
                  class="btn btn-default submit-btn"
                >
                  {{ labelSubmit }}
                  <span
                    *ngIf="isFormSubmitted"
                    class="icon glyphicon glyphicon-repeat fast-right-spinner"
                  ></span>
                </button>

                <button
                  type="button"
                  (click)="reset()"
                  class="btn btn-default reset-btn"
                >
                  {{ labelReset }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
