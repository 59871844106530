import { OnInit, Component, Input } from '@angular/core';
import { Component as BrComponent } from '@bloomreach/spa-sdk/';
import { Page } from '@bloomreach/spa-sdk';
import {
  ConfigurationId,
  FundId,
  ProductDetailConfiguration,
  ShareClassCode,
} from '@types';

@Component({ template: '' })
export class BaseEntryProductComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  fundId: FundId;
  shareClassCode: ShareClassCode;
  configurationName: ConfigurationId;
  label: Map<string, string>;
  isPopulated = false;
  brConfig: ProductDetailConfiguration;

  constructor() {}

  ngOnInit(): void {
    this.brConfig = this.component?.getModels()?.config;
    this.configurationName = this.brConfig?.configurationName;
    this.fundId = this.brConfig?.fundId;
    this.shareClassCode = this.brConfig?.shareClassCode;
  }
}
