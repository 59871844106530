<div class="container position-relative">
  <div class="section-inner-txt">
    <div [ngClass]="{ 'has-edit-button': page.isPreview() }">
      <ng-container
        *ngIf="page.isPreview()"
        [brManageContentButton]="document"
        documentTemplateQuery="new-ContentBlocks-document"
        folderTemplateQuery="new-ContentBlocks-folder"
        parameter="document"
        [relative]="false"
        root=""
      >
      </ng-container>
      <div *ngIf="document" class="secton-wrp">
        <h2>{{ content.displayName }}</h2>
        <div *ngFor="let contentBlock of contentBlocks">
          <div *ngFor="let item of contentBlock?.items">
            <div [innerHTML]="item.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
