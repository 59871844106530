<div class="blogSection" *ngIf="articleArray">
  <div class="container position-relative custom-cb" id="nav-videos">
    <div class="row m-2 cb-content">
      <div class="col-sm-12 col-md-12 selectCategoryOuter">
        <div class="row m-0 work-dropdown">
          <div class="col-sm-12 text-center">
            <h2
              class="marketing-article__content-header atoms-insights-content-header--page-header"
            >
              Career Boosters
            </h2>
          </div>
          <div class="col-sm-12 selectCategoryInner">
            <div ngbDropdown class="d-inline-block w-100">
              <button
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                {{ selectedCategory?.label }}
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="dropdownSection"
              >
                <button
                  ngbDropdownItem
                  *ngFor="let category of cbCategories"
                  (click)="selectCategory(category)"
                >
                  {{ category?.label }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 contentCategory">
        <div class="row">
          <ng-container *ngFor="let blog of articleArray; index as i">
            <div class="col-sm-12 col-sm-6 col-md-6 col-lg-4 insights">
              <!-- <ng-container *ngIf="i < recordsPerPage"> -->
              <div
                class="insights-inner"
                [ngClass]="{ 'has-edit-button': page?.isPreview() }"
              >
                <div class="insights-card">
                  <div class="image-container">
                    <a
                      (click)="
                        openVideoModal(
                          modalRef,
                          blog?.data?._source?.youTubeURL
                        )
                      "
                    >
                      <img
                        *ngIf="blog?.image"
                        [src]="blog?.image"
                        alt="{{ blog?.data?._source?.pageTitle }}"
                        title="{{ blog?.data?._source?.pageTitle }}"
                      />
                      <span class="icon icon-fti_play"></span>
                    </a>
                  </div>
                  <div class="content-container">
                    <h3>
                      <a
                        (click)="
                          openVideoModal(
                            modalRef,
                            blog?.data?._source?.youTubeURL
                          )
                        "
                      >
                        <span>{{ blog?.data?._source?.pageTitle }}</span>
                      </a>
                    </h3>
                    <p>
                      <ng-container *ngIf="blog?.description">
                        <span
                          [innerHtml]="
                            blog?.showFullText
                              ? blog?.fullDescription
                              : blog?.description
                          "
                        ></span>
                        <span>
                          <a
                            *ngIf="!blog.showFullText"
                            (click)="blog.showFullText = true"
                            >load more</a
                          >
                        </span>
                      </ng-container>
                    </p>
                    <!-- <span class="dateStamp">{{ blog?.date }}</span> -->
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="isArticleCB"
            class="col-sm-12 text-center no-filter-alert-vid"
          >
            <p>Coming soon...</p>
          </div>
        </div>
      </div>
      <div *ngIf="showButton" class="col-sm-12">
        <div class="text-center">
          <button
            class="btn loadMore ch-loadmore"
            [ngClass]="{ test: showmoreText === true }"
            value="Load More"
            aria-label="Load More"
            (click)="loadMoreCB('filters', articleArray?.length, 3)"
          >
            Load More
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="blogSection kh-background" *ngIf="articleKHArray">
  <div class="container position-relative custom-cb kh-padding">
    <div class="row m-2 mb-0 kh-content" id="nav-articles">
      <div class="col-sm-12 col-md-12 selectCategoryOuter">
        <div class="row m-2 work-dropdown">
          <div class="col-sm-12 text-center">
            <h2
              class="marketing-article__content-header atoms-insights-content-header--page-header"
            >
              Knowledge Hub
            </h2>
          </div>
          <div class="col-sm-12 selectCategoryInner">
            <div ngbDropdown class="d-inline-block w-100">
              <button
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                {{ selectedKHCategory?.label }}
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="dropdownSection"
              >
                <button
                  ngbDropdownItem
                  *ngFor="let category of kHCategories"
                  (click)="kHSelectCategory(category)"
                >
                  {{ category?.label }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 contentCategory">
        <div class="row">
          <ng-container *ngFor="let blog of articleKHArray; index as i">
            <div class="col-sm-12 col-sm-6 col-md-6 col-lg-4 insights">
              <!-- <ng-container *ngIf="i < recordsPerPage"> -->
              <div
                class="insights-inner"
                [ngClass]="{ 'has-edit-button': page?.isPreview() }"
              >
                <div class="insights-card">
                  <div class="image-container">
                    <a [routerLink]="blog?.url" target="_blank">
                      <img
                        *ngIf="blog?.image"
                        [src]="blog?.image"
                        alt="{{ blog?.data?._source?.pageTitle }}"
                        title="{{ blog?.data?._source?.pageTitle }}"
                      />
                    </a>
                  </div>
                  <div class="content-container kh-border">
                    <h3>
                      <a [routerLink]="blog?.url" target="_blank">
                        <span>{{ blog?.data?._source?.pageTitle }}</span>
                      </a>
                    </h3>
                    <p>
                      <ng-container *ngIf="blog?.description">
                        <span
                          [innerHtml]="
                            blog?.showFullText
                              ? blog?.fullDescription
                              : blog?.description
                          "
                        ></span>
                        <span>
                          <a
                            *ngIf="!blog.showFullText"
                            (click)="blog.showFullText = true"
                            >load more</a
                          >
                        </span>
                      </ng-container>
                    </p>
                    <!-- <span class="dateStamp">{{ blog?.date }}</span> -->
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="isArticleKH"
            class="col-sm-12 text-center no-filter-alert-vid"
          >
            <p>Coming soon...</p>
          </div>
        </div>
      </div>
      <div *ngIf="showKHButton" class="col-sm-12 kh-margin">
        <div class="text-center">
          <button
            class="btn loadMore"
            value="Load More"
            aria-label="Load More"
            (click)="loadMoreKH('filters', articleKHArray?.length, 3)"
          >
            Load More
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalRef let-modal>
  <div class="modal-header">
    <a
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span class="icon-fti_close"></span>
    </a>
  </div>
  <div class="modal-body">
    <div class="fth-player">
      <iframe
        [src]="videoUrl | safe: 'resourceUrl'"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </div>
</ng-template>
