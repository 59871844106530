export const POLYLINE = [
  {
    latitude: 39.8282,
    longitude: -98.5795,
    speed: 50,
  },
  {
    latitude: 38.8282,
    longitude: -108.5795,
    speed: 50,
  },
  {
    latitude: 37.772,
    longitude: -122.214,
    speed: 20,
  },
  {
    latitude: 21.291,
    longitude: -157.821,
    speed: 20,
  },
  {
    latitude: -18.142,
    longitude: 178.431,
    speed: 20,
  },
  {
    latitude: -27.467,
    longitude: 153.027,
    speed: 25,
  },
];
