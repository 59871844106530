import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TwoColLeftWideCustomService {
  hasLeftColumnContent$ = new Subject<boolean>();

  constructor() {}

  hasLeftColumnContent(flag: boolean) {
    this.hasLeftColumnContent$.next(flag);
  }

  getHasLeftColumnContent$(): Observable<boolean> {
    return this.hasLeftColumnContent$.asObservable();
  }
}
