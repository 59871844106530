<div class="breadcrumbContent" *ngIf="isBreadcrumbShown">
  <div class="fti-container position-relative">
    <div class="row">
      <div class="col-sm-12">
        <ul>
          <li>
            <a [routerLink]="page?.getUrl('/')" aria-label="Home">
              <span class="icon icon-fti_home"></span>
            </a>
          </li>
          <ng-container *ngIf="breadcrumbs?.length > 0">
            <li *ngFor="let item of breadcrumbs; let last = last">
              <a *ngIf="item?.link && !last" [routerLink]="item.link">
                {{ item?.name }}
              </a>
              <a *ngIf="!item?.link && !last">
                {{ item?.name }}
              </a>
              <ng-container *ngIf="last">{{ item?.name }}</ng-container>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
