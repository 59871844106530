<div
  id="outage-content"
  class="fti-bg-primaryBlue"
  *ngIf="FtiOutagemessage == 'true' && showMessage"
>
  <div class="container">
    <div class="row">
      <div
        class="col-sm-9 fti-numberGrid"
        [innerHtml]="ftiOutageMessageValue"
      ></div>
      <div
        class="col-sm-3 text-outage-close"
        *ngIf="FtiOutageMessageCloseOption == 'true'"
      >
        <a
          id="close-outage-content"
          class="close-outage"
          (click)="closeMessage()"
          >{{ FtiOutageMessageCloseValue }}</a
        >
      </div>
    </div>
  </div>
</div>

<router-outlet> </router-outlet>
