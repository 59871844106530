import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';

@Component({
  selector: 'ft-fund-caveat',
  templateUrl: './fund-caveat.component.html',
  styleUrls: ['./fund-caveat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FundCaveatComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  constructor() {}

  ngOnInit(): void {}
  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    return this.document?.getData();
  }

  get contentBlock() {
    return this.content?.contentBlock || [];
  }

  get isPreview() {
    return this.page?.isPreview();
  }
}
