import { Component, OnInit, Input } from '@angular/core';
import { Component as BrComponent, Page, Reference } from '@bloomreach/spa-sdk';
import { OriginalBlogs } from '@types';
import { Logger } from '@utils/logger';
import { getPagination } from '@utils/pagemodel-utils';
const logger = Logger.getLogger('IndSimpleDisplayComponent');

@Component({
  selector: 'ft-ind-simple-display',
  templateUrl: './ind-simple-display.component.html',
  styleUrls: ['./ind-simple-display.component.scss'],
})
export class IndSimpleDisplayComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  originalBlogs: OriginalBlogs[] = [];
  categories: any = [];
  contentType = '';
  title = '';
  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    const modelKey = 'model';
    const component: BrComponent = this.page
      ?.getComponent('main-content')
      ?.getComponent('IND-simple-display');
    const componentParams: any = component?.getParameters();
    const componentModels: any = component?.getModels();
    logger.debug('data::::', componentParams);
    // Content Type
    this.contentType = componentParams?.contentType;
    this.title = componentParams?.title;
    logger.debug('BlogList::::', this.contentType);
    if (this.contentType === 'BlogList') {
      // Blog categories
      const categoryData = this.page?.getContent(
        componentModels?.chooseCategories
      );
      this.categories = categoryData?.[modelKey]?.items;

      // Blogs
      this.originalBlogs = [];
      getPagination(this.page, this.component)?.items.forEach(
        (element: Reference) => {
          const elementData = this.page?.getContent(element);
          this.originalBlogs.push({
            ref: element,
            data: elementData?.getData(),
          });
        }
      );
    }
    logger.debug('Contentyeps>>>', this.contentType);
  }
}
