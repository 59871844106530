import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalConfigService } from '@services/global-config-service';
import { Logger } from '@utils/logger';
import { EnvConfigService } from '@services/env-config.service';
const logger = Logger.getLogger('FTI Literature Service');

@Injectable({
  providedIn: 'root',
})
export class LiteratureService {
  literatureBRConfig: any;

  constructor(
    private envConfig: EnvConfigService,
    private httpClient: HttpClient,
    private globalConfig: GlobalConfigService
  ) {
    this.literatureBRConfig = this.globalConfig.config.siteConfiguration?.indiaLiterature;
  }

  private getLiteratureApiUrl(): string {
    return this.envConfig.getEnvConfig()?.literatureApi;
  }

  public getData$(parameters?: string): Observable<any> {
    return this.httpClient?.get(this.getLiteratureApiUrl() + parameters, {
      responseType: 'json',
    });
  }
}
