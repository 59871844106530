import deAtConfig from 'moment';
import deChConfig from 'moment';
import deDeConfig from 'moment';
import enAuConfig from 'moment';
import enGbConfig from 'moment';
import ptBrConfig from 'moment';
import nlBeConfig from 'moment';
import zhTwConfig from 'moment';

// Cloned / Modified Locales

// eslint-disable-next-line no-unused-vars
import csCzConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import elGrConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import esEsConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import frCaConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import frConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import huHuConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import itConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import jpJpConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import plPlConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import ptPtConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import roRoConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import skSkConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import nlNlConfig from 'moment';
// eslint-disable-next-line no-unused-vars
import zhHkConfig from 'moment';

// WORKAROUND: en-ch is very weird see custom moment locale for details
import { customEnChConfig } from './custom-moment-locales/en-ch';

// WORKAROUND: es-max is very weird see custom moment locale for details
import { customEsMxConfig } from './custom-moment-locales/es-mx';

import {
  cloneMomentLocale,
  updateMomentLocale,
} from './custom-moment-locales/moment-utils';

/**
 * config for all localisation for web-platform
 *
 * NUMBER LOCALE ARTICLE
 * @see https://en.wikipedia.org/wiki/Decimal_separator
 *
 * DATE LOCALE ARTICLE
 * @see https://en.wikipedia.org/wiki/Date_format_by_country
 *
 * Nice database of both
 * https://www.ibm.com/support/knowledgecenter/en/SSS28S_8.1.0/XFDL/i_xfdl_r_locale_quick_reference.html
 * (es-MX example) https://www.ibm.com/support/knowledgecenter/en/SSS28S_8.1.0/XFDL/i_xfdl_r_formats_es_MX.html
 */

export const HIGHCHARTS_DEFAULT_CONFIG = {
  decimalPoint: '.',
  thousandsSep: ',',
};

export const HIGHCHARTS_EUROPEAN_CONFIG = {
  decimalPoint: ',',
  thousandsSep: '.',
};

export const DEFAULT_CONFIG = {
  decimalPoint: '.',
  thousandsSep: ',',
  fractionPrecision: 2,
};

export const EUROPEAN_CONFIG = {
  decimalPoint: ',',
  thousandsSep: '.',
  fractionPrecision: 2,
};

// Examples of what delimiters should be can be found in many il8n projects
//    https://github.com/svenfuchs/rails-i18n/tree/master/rails/locale
//    https://github.com/angular/angular/tree/master/packages/common/locales
//    https://en.wikipedia.org/wiki/Common_Locale_Data_Repository
//
// If we ever wanted to we could automate this using this
//    https://github.com/angular/angular/tree/master/tools/gulp-tasks/cldr

// NOTE : Currency / Percentage can have different formatting
// aka switzerland currency == dot(.) and percentage == comma(,)
// COREWEB-3902 Switzerland - Decimal separator for NAV should be (.) in Pricing chart
//   The currency value will use Decimal Separator as dot(.) and the Percentage value will use Decimal Separator as comma(,).

/**
 * Locale settings for
 * see components\common\elements\charting\ft-charts-configs.js for implementation of actually global option config
 * // WORKAROUND: could set Highcharts.setOptions({lang: xxx}) directly here but might want to lazy load high charts in future
 */
export const LANG_CONFIG = {
  'cs-cz': {
    // Czech (Czech Republic) [cs-cz]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('cs-cz', 'cs'),
  },
  'de-at': {
    // German (Austria) [de-at]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: deAtConfig,
  },
  'de-de': {
    // German (Germany) [de]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: deDeConfig,
  },
  'de-ch': {
    // German (Switzerland) [de-ch]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: deChConfig,
  },
  // Greek (Greece) [el-gr] COREWEB-7027 - changed to default config to meet data preferences, but needs to be checked if data is OK.
  'el-gr': {
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('el-gr', 'el'),
  },
  en: {
    // English (United States) [en-us]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: 'default-locale-hardcoded-into-framework',
  },
  'en-au': {
    // English (Australia) [en-au]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: enAuConfig,
  },
  'en-ca': {
    // English (Canada) [en-ca]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: updateMomentLocale('en-ca', {
      longDateFormat: {
        // COREWEB-3355 : YYYY-MM-DD -> 'MM/DD/YYYY'
        L: 'MM/DD/YYYY',
      },
    }),
  },
  'en-ch': {
    // English (Switzerland) [en-ch]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      // COREWEB-3902 Switzerland - Decimal separator for NAV should be (.) in Pricing chart
      // The currency value will use Decimal Separator as dot(.) and the Percentage value will use Decimal Separator as comma(,).
      standard: DEFAULT_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: customEnChConfig,
  },
  'en-cy': {
    // English (Cyprus) [en-cy]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('en-cy', 'en-gb'),
  },
  'en-hk': {
    // English (Hong Kong) [en-hk]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-hk', 'en-gb'),
  },
  'en-gb': {
    // English (United Kingdom) [en-gb]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: enGbConfig,
  },
  'en-my': {
    // English (Malaysia) [en-my]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-my', 'en-gb'),
  },
  'en-sg': {
    // English (Singapore) [en-sg]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-sg', 'en-gb'),
  },
  'en-os': {
    // English (Offshore US English) [en-os]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-os', 'en'),
  },
  'en-za': {
    // English (South Africa) [en-za]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('en-za', 'en-gb', {
      longDateFormat: {
        //  COREWEB-5508: 18/11/1982 -> 18.11.1982
        L: 'DD.MM.YYYY',
      },
    }),
  },
  'es-es': {
    // Spanish (Spain) [es-es]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('es-es', 'es', {
      longDateFormat: {
        //  COREWEB-5508: 18/11/1982 -> 18.11.1982
        L: 'DD.MM.YYYY',
      },
    }),
  },
  'es-mx': {
    // Spanish (Mexico / Mexican) [es-mx]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: customEsMxConfig,
  },
  'es-os': {
    // Spanish (Offshore Mexican) [es-os]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('es-os', 'es-mx', {
      longDateFormat: {
        //  COREWEB-5508: 18/11/1982 -> 18.11.1982
        L: 'DD/MM/YYYY',
      },
    }),
  },
  'eu-inst': {
    // EU Institutional (English) [eu-inst]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('eu-inst', 'en-gb'),
  },
  'fr-be': {
    // French (Belgium French / Walloon) [fr-be]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('fr-be', 'fr'),
  },
  'fr-ca': {
    // French (Canada) [fr-ca]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: updateMomentLocale('fr-ca', {
      months: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
        '_'
      ),
      monthsShort: 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split(
        '_'
      ),
      weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split(
        '_'
      ),
      weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
      longDateFormat: {
        // COREWEB-4801 : YYYY-MM-DD -> 'DD/MM/YYYY'
        L: 'DD/MM/YYYY',
      },
    }),
  },
  'fr-fr': {
    // French (France) [fr-fr]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: updateMomentLocale('fr', {
      months: 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split(
        '_'
      ),
      monthsShort: 'Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.'.split(
        '_'
      ),
      weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split(
        '_'
      ),
      weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
      longDateFormat: {
        // COREWEB-4966 : DD/MM/YYYY -> DD.MM.YYYY
        L: 'DD.MM.YYYY',
      },
    }),
  },
  'hu-hu': {
    // Hungarian (Hungary) [hu-hu]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('hu-hu', 'hu'),
  },
  'it-it': {
    // Italian (Italy) [it]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: updateMomentLocale('it', {
      months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split(
        '_'
      ),
      monthsShort: 'Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic'.split('_'),
      weekdays: 'Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato'.split(
        '_'
      ),
      weekdaysShort: 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
      longDateFormat: {
        // COREWEB-3144 : DD/MM/YYYY -> 'DD.MM.YYYY'
        L: 'DD.MM.YYYY',
      },
    }),
  },
  'ja-ja': {
    // Japanese (Japan) [ja-ja]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('ja-ja', 'ja'), // COREWEB-7350 - use "moment" date format for Japan
  },
  'nl-be': {
    // Dutch (Belgium Dutch / Flemish) [nl-be]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: nlBeConfig,
  },
  'nl-nl': {
    // Dutch (Netherlands) [nl]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('nl-nl', 'nl', {
      longDateFormat: {
        // COREWEB-5508: YYYY-MM-DD -> 'DD.MM.YYYY'
        L: 'DD.MM.YYYY',
      },
    }),
  },
  nordics: {
    // Nordics (SE, NO,DK,FI) (English) [nordics]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: cloneMomentLocale('nordics', 'en-gb'),
  },
  'pl-pl': {
    // Polish (Poland) [pl]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('pl-pl', 'pl'),
  },
  'pt-br': {
    // Portuguese (Brazil) ['pt-br']
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: ptBrConfig,
  },
  'pt-os': {
    // Portuguese (Off Brazil Shore) ['pt-os']
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('pt-os', 'pt-br'),
  },
  'pt-pt': {
    // Portuguese (Portugal) ['pt-pt']
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('pt-pt', 'pt', {
      longDateFormat: {
        // COREWEB-5508: DD/MM/YYYY -> 'DD.MM.YYYY'
        L: 'DD.MM.YYYY',
      },
    }),
  },
  'ro-ro': {
    // Romanian (Romania) [ro-ro]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('ro-ro', 'ro'),
  },
  'sk-sk': {
    // Slovakian (Slovakia) [sk-sk]
    highcharts: HIGHCHARTS_EUROPEAN_CONFIG,
    numbers: {
      standard: EUROPEAN_CONFIG,
      percentage: EUROPEAN_CONFIG,
    },
    moment: cloneMomentLocale('sk-sk', 'sk'),
  },
  'zh-hk': {
    // Chinese (Traditional, Hong Kong) [zh-hk]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: updateMomentLocale('zh-hk', {
      longDateFormat: {
        // COREWEB-5006, COREWEB-5007 YYYY/MM/DD -> 'DD/MM/YYYY'
        L: 'DD/MM/YYYY',
      },
    }),
  },
  'zh-tw': {
    // Chinese (Traditional, Taiwan) [zh-tw]
    highcharts: HIGHCHARTS_DEFAULT_CONFIG,
    numbers: {
      standard: DEFAULT_CONFIG,
      percentage: DEFAULT_CONFIG,
    },
    moment: zhTwConfig,
  },
};
