import { Inject, Injectable } from '@angular/core';
import { AppStateService } from './app-state.service';
import {
  DataDogConfig,
  EnvConfig,
  EnvConfigService,
  Environments,
} from './env-config.service';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { UserAgentService } from './user-agent.service'; // deliberately not using @services as causes circular dependency

@Injectable({ providedIn: 'root' })
export class DatadogService {
  private rum: any;
  private logs: any;
  private rumEnabled: boolean; // true if enabled else false.

  constructor(private userAgentService: UserAgentService) {}

  /**
   * Initialised by AppStateService
   */
  public initDataDog(
    envConfigService: EnvConfigService,
    appStateService: AppStateService,
    locationRef: Location
  ): void {
    const envConfig: EnvConfig = envConfigService.getEnvConfig();
    const params = new URLSearchParams(locationRef.search);
    const userOverride = params.get('datadog');
    const env = this.getEnv(envConfigService, envConfig);
    const config: DataDogConfig = envConfig?.datadog;
    if (this.userAgentService.isNotPrerenderOrSmarsh()) {
      this.initLogging(config, env, appStateService);
      this.initRum(
        userOverride,
        config,
        env,
        envConfigService,
        locationRef,
        appStateService,
        true
      );
    }
  }

  private initRum(
    userOverride: string,
    config: DataDogConfig,
    env: any,
    envConfigService: EnvConfigService,
    locationRef: Location,
    appStateService: AppStateService,
    recordingOk: boolean
  ): void {
    if (config.rumEnabled) {
      this.rumEnabled = true;
      const sampleRate =
        userOverride ||
        envConfigService.isPreEnv() ||
        envConfigService.isContentPreview()
          ? 100
          : config.sampleRate;
      // initialize RUM
      this.rum = datadogRum;
      this.rum?.init({
        applicationId: config.applicationId,
        clientToken: config.token,
        site: config.site,
        service: config.service,
        silentMultipleInit: true,
        env,
        allowedTracingOrigins: [
          'http://' + locationRef.hostname,
          'https://' + locationRef.hostname,
        ],
        sampleRate,
        premiumSampleRate: config.premiumSampleRate,
        trackInteractions: config.trackInteractions,
        trackFrustrations: config.trackFrustrations,
        defaultPrivacyLevel: 'mask-user-input',
        version: appStateService.getBuildVersion(),
      });
      this.rum?.addRumGlobalContext('channel', appStateService.getChannel());
      this.rum?.setUser({
        name: userOverride || 'anon',
      });
      if (sampleRate > 0 && recordingOk) {
        this.rum?.startSessionReplayRecording();
      }
    } else {
      this.rumEnabled = false;
    }
  }

  private initLogging(
    config: DataDogConfig,
    env: any,
    appStateService: AppStateService
  ): void {
    if (config?.logsEnabled) {
      this.logs = datadogLogs;
      this.logs?.init({
        clientToken: config.token,
        site: config.site,
        service: config.service,
        env,
        forwardErrorsToLogs: false,
        sampleRate: 100,
      });

      this.logs?.addLoggerGlobalContext(
        'channel',
        appStateService.getChannel()
      );
    }
  }

  private getEnv(
    envConfigService: EnvConfigService,
    envConfig: EnvConfig
  ): Environments {
    // we need to distinguish users from pre and content preview separately in datadog, but they share config with Prod
    if (envConfigService.isContentPreview()) {
      return Environments.CONTENT_PREVIEW;
    } else if (envConfigService.isPreEnv()) {
      return Environments.PRE;
    } else {
      return envConfig.environment as Environments;
    }
  }

  // public methods
  public getRum(): any {
    return this.rum;
  }

  public getLogs(): any {
    return this.logs;
  }

  public isRumEnabled(): boolean {
    return this.rumEnabled;
  }

  // sends a custom action to datadog
  public addAction(name: string, data: any): void {
    this.rum?.addAction(name, data);
  }

  public updateUser(userData: any): void {
    Object.getOwnPropertyNames(userData).forEach((prop) =>
      this.rum?.setUserProperty(prop, userData[prop])
    );
  }
}
