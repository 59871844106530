<div class="page-layout latest-updates-wrapper">
  <div class="row">
    <div class="col-sm-12">
      <div class="selected-category-ALL">
        <h3 class="headResources">
          {{ "literature.latestUpdatesHeading" | translate }}
        </h3>
        <ft-document-panel
          [fundDocuments]="latestDocuments"
          [notFundsDocumentsData]="true"
          [errorInService]="errorInService"
        ></ft-document-panel>
      </div>
    </div>
  </div>
</div>
