<sup
  *ngIf="refs$ | async"
  class="footnote"
  [innerHTML]="refs$ | async"
  [attr.placement]="placement"
></sup>
<sup
  *ngIf="placement && (debug$ | async)"
  class="footnote__debug"
  [title]="placement"
  >&nbsp;[F]</sup
>
