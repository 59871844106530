import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalConfigService } from '@services/global-config-service';
import { Logger } from '@utils/logger';
import { EnvConfigService } from '@services/env-config.service';
import { LiteratureService } from '@services/literature.service';

const logger = Logger.getLogger('Fund Documents Service');

export interface FundDocumentCategoryDTO {
  fundDocCategory: string;
  fundDocTypeToDisplay_csv: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class FundDocumentsService {
  fundLiterature: any;

  constructor(
    private envConfig: EnvConfigService,
    private httpClient: HttpClient,
    private globalConfig: GlobalConfigService,
    private litService: LiteratureService
  ) {
    this.fundLiterature = this.litService.literatureBRConfig?.indiaFundDocuments;
  }

  public getFundLiteratureDocument$(param: string): Observable<any> {
    return this.litService.getData$(param);
  }
}
