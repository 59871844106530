<div class="existing-investor-login">
  <div class="row" *ngIf="!validated">
    <div class="errorPage maintenancePage">
      <img
        class="mapImage"
        src="/assets/images/maintenace_icon.png"
        alt="Maintenance"
      />
      <div
        [ngClass]="'maintenancePage--content'"
        [innerHTML]="maintenance$ | async"
      ></div>
    </div>
  </div>
  <div class="row" *ngIf="validated">
    <div class="col-lg-6 col-md-6 col-sm-12 transactCol1 fti-bg-gray--1">
      <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
        <ng-container
          *ngIf="page?.isPreview()"
          [brManageContentButton]="document"
          documentTemplateQuery="new-ftindia-document"
          folderTemplateQuery="new-ftindia-folder"
          parameter="document"
          [relative]="false"
          root=""
        >
        </ng-container>
        <div class="transact-headiing d-lg-none d-md-none">Transact Now</div>
        <h6>{{ content?.title }}</h6>
        <p class="transact-header">{{ content?.subTitle }}</p>
        <div class="tab-content" *ngIf="document">
          <!-- Investor Tab -->
          <div
            class="tab-pane content-border"
            [ngClass]="{ active: investorTab }"
          >
            <div
              class="nav nav-tabs tab-inner"
              role="tablist"
              *ngIf="loginWrapper"
            >
              <div id="login-use-id" class="radio-item w-46">
                <input
                  #loginRadioButton
                  id="optUser"
                  name="login-intervaltype"
                  checked
                  type="radio"
                  (click)="clickRadio('useid')"
                />
                <label for="optUser">
                  <span class="icon l-19 glyphicon glyphicon-ok"></span>
                  {{ content?.labelinvestoruseuserid }}
                </label>
              </div>
              <div id="login-use-pan" class="radio-item userPan-login">
                <input
                  #panRadioButton
                  id="optPan"
                  name="login-intervaltype"
                  type="radio"
                  (click)="clickRadio('usepan')"
                />
                <label for="optPan">
                  <span class="icon l-20 glyphicon glyphicon-ok"></span>
                  {{ content?.labelinvestorusepan }}
                </label>
              </div>
            </div>

            <div class="tab-content">
              <div
                *ngIf="useidTab"
                class="tab-pane"
                [ngClass]="{ active: useidTab }"
              >
                <form
                  id="loginFrm"
                  [formGroup]="loginFormInvestorUserID"
                  (ngSubmit)="loginUserID()"
                  autocomplete="off"
                  novalidate="true"
                  method="post"
                >
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.loginFormInvestorUserID.value.userid
                      }"
                    >
                      <label class="control-label" for="invesetorid"
                        >{{ content?.labelinvestoruserid }}
                        <sup>*</sup>
                      </label>
                      <input
                        id="invesetorid"
                        formControlName="userid"
                        maxlength="60"
                        type="text"
                        class="form-field form-control"
                        (paste)="onPaste($event)"
                      />
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.loginFormInvestorUserID.value.password
                      }"
                    >
                      <label class="control-label" for="investorpwd"
                        >{{ content?.labelinvestorpassword }}
                        <sup>*</sup>
                      </label>
                      <input
                        id="investorpwd"
                        formControlName="password"
                        maxlength="64"
                        type="password"
                        class="form-field form-control"
                        (paste)="onPaste($event)"
                        [class.is-invalid]="errors?.errorDescription"
                      />
                    </div>
                    <div *ngIf="errors?.errorDescription" class="text-danger">
                      {{ errors.errorDescription }}
                    </div>
                  </div>
                  <div class="login-buttons row">
                    <div
                      class="right-border button-width float-left col-md-6 col-lg-6"
                    >
                      <button
                        [disabled]="!loginFormInvestorUserID.valid"
                        type="submit"
                        class="btn btn-default next-btn"
                      >
                        {{ content?.labelinvestornext }}
                      </button>
                    </div>
                    <div class="button-width col-md-6 col-lg-6">
                      <a
                        (click)="registerNow()"
                        href="{{ tokenizedLabels.labelInvestorRegisterUrl }}"
                        class="anchorBtn btn btn-gray register-btn"
                      >
                        {{ content?.labelinvestorregister?.text }}
                      </a>
                    </div>
                  </div>
                  <div class="mt-4 text-center">
                    <a
                      (click)="forgotUid()"
                      href="{{ tokenizedLabels.labelInvestorForgotUserUrl }}"
                      class="forgot-username"
                      >{{ content?.labelinvestorforgotuser?.text }}</a
                    >
                    <span class="forgot-or">OR</span>
                    <a
                      (click)="forgotPass()"
                      href="{{
                        tokenizedLabels.labelInvestorForgotPasswordUrl
                      }}"
                      class="forgot-password"
                      >{{ content?.labelinvestorforgotpassword?.text }}</a
                    >
                  </div>
                </form>
              </div>
              <div
                *ngIf="usepanTab"
                class="tab-pane"
                [ngClass]="{ active: usepanTab }"
              >
                <form
                  style="padding-bottom: 0"
                  id="loginPANFrm"
                  [formGroup]="loginFormInvestorUserPAN"
                  autocomplete="off"
                  novalidate="true"
                  (ngSubmit)="redirectToRegisterNow()"
                >
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.loginFormInvestorUserPAN.value.userpan
                      }"
                    >
                      <label class="control-label" for="pannumber"
                        >Enter PAN
                        <sup>*</sup>
                      </label>
                      <input
                        id="pannumber"
                        formControlName="userpan"
                        maxlength="10"
                        type="text"
                        class="form-field form-control text-uppercase"
                        (paste)="onPaste($event)"
                        (input)="checkPAN()"
                      />
                      <span
                        id="editPanFrm"
                        class="editPan float-right"
                        (click)="backToPan()"
                        >Edit</span
                      >
                    </div>
                    <div
                      *ngIf="errors?.errorDescription && !otpError"
                      class="text-danger"
                    >
                      {{ errors.errorDescription }}
                    </div>
                    <div *ngIf="registerPan" class="text-danger">
                      The entered PAN isn't registered with Franklin Templeton
                      online account. To create your account click REGISTER NOW.
                    </div>
                  </div>
                  <div class="login-buttons">
                    <div
                      class="button-width col-md-12 col-lg-12"
                      id="btnRegisterOtp"
                      style="display: none"
                    >
                      <button type="submit" class="btn btn-default next-btn">
                        REGISTER NOW
                      </button>
                    </div>
                    <div
                      class="button-width col-md-12 col-lg-12 d-none"
                      id="btnInvestOtp"
                    >
                      <a
                        type="submit"
                        style="
                          width: 95%;
                          font-size: 14px;
                          font-family: 'Trade Gothic W01 Cn_18', Arial,
                            Helvetica, sans-serif;
                        "
                        class="btn btn-default next-btn"
                        [href]="env?.transactNowDomain + '/transactnowlogin'"
                      >
                        INVEST NOW
                      </a>
                    </div>
                  </div>
                </form>
              </div>
              <!-- OTP form -->
              <form
                style="display: none; padding-top: 10px"
                id="investorOTPFrm"
                [formGroup]="investorOTPForm"
                autocomplete="off"
                novalidate="true"
                (ngSubmit)="validateOTP()"
              >
                <div class="field-wrapper">
                  <div
                    class="form-group"
                    [ngClass]="{
                      focused: this.investorOTPForm.value.otp
                    }"
                  >
                    <label class="control-label" for="otpnumber"
                      >Enter OTP
                      <sup>*</sup>
                    </label>
                    <input
                      id="otpnumber"
                      formControlName="otp"
                      maxlength="6"
                      type="password"
                      class="form-field form-control"
                      (paste)="onPaste($event)"
                      [class.is-invalid]="errors?.errorDescription"
                      (keyup)="onlyNumeric($event)"
                    />
                  </div>
                  <div *ngIf="successStatus" class="otpStatus">
                    {{ successStatus }}
                  </div>
                  <div *ngIf="errors?.errorDescription" class="text-danger">
                    {{ errors.errorDescription }}
                  </div>
                  <div class="login-user-resendotp pt+">
                    <a
                      href="javascript:;"
                      class="loginPANOTP"
                      id="loginPan-form-otpresendId"
                      (click)="generateOTP()"
                      >Resend OTP</a
                    >
                  </div>
                </div>
                <div
                  id="otpRememberMe"
                  class="form-group checkbox new-check"
                  (click)="checkboxAction('existUserLoginRememberMe')"
                >
                  >
                  <input
                    type="checkbox"
                    id="existUserLoginRememberMe"
                    aria-label="userLoginRememberMe"
                    class="form-field elEncodeCobrowse"
                    value="rememberMe"
                    style="margin-left: 0px"
                    checked=""
                    data-gcb-service-node="true"
                  />
                  <label for="existUserLoginRememberMe">Remember Me</label>
                  <div class="tooltip-container">
                    <a
                      type="button"
                      id="main-login-tooltip"
                      data-toggle="main-login-tooltip"
                      data-placement="bottom"
                      title=""
                      ><img
                        class="remember-info-icon"
                        alt="image"
                        src="https://franklintempletonprod.widen.net/content/zv4trbj28o/jpeg/icn_info-hallow.jpeg"
                    /></a>
                    <div class="pan-tool-tip-container">
                      {{ "ftiLoginRegister.rememberMeTooltip" | translate }}
                    </div>
                  </div>
                </div>
                <div *ngIf="usepanTab" class="login-buttons row">
                  <div class="float-left col-md-12 col-lg-12">
                    <button
                      type="submit"
                      class="btn btn-default next-btn"
                      style="width: 100% !important"
                      [disabled]="!investorOTPForm.valid"
                    >
                      NEXT
                    </button>
                  </div>
                </div>
                <div *ngIf="useidTab" class="login-buttons row">
                  <div
                    class="right-border button-width float-left col-md-6 col-lg-6"
                  >
                    <button
                      type="submit"
                      class="btn btn-default next-btn"
                      style="width: 100%"
                      [disabled]="!investorOTPForm.valid"
                    >
                      NEXT
                    </button>
                  </div>
                  <div class="button-width col-md-6 col-lg-6">
                    <button
                      type="button"
                      class="btn register-btn btn-grey anchorBtn next-btn"
                      style="width: 100%"
                      (click)="backToLogin()"
                    >
                      BACK
                    </button>
                  </div>
                </div>
              </form>
              <!-- BANK ACCOUNT form -->
              <form
                *ngIf="usepanTab"
                style="display: none; padding-top: 10px"
                id="investorBankAccFrm"
                [formGroup]="investorBankAccFrm"
                autocomplete="off"
                novalidate="true"
                (ngSubmit)="validateBankAcc()"
              >
                <div class="field-wrapper">
                  <div
                    class="form-group"
                    [ngClass]="{
                      focused: this.investorBankAccFrm.value.accountNo
                    }"
                  >
                    <label class="control-label" for="bankaccount"
                      >Bank Account Number
                      <sup>*</sup>
                    </label>
                    <input
                      id="bankaccount"
                      formControlName="accountNo"
                      maxlength="30"
                      [type]="passwordFieldType"
                      class="form-field form-control"
                      (paste)="onPaste($event)"
                      [class.is-invalid]="errors?.errorDescription"
                      (keyup)="onlyNumeric($event)"
                    />
                    <span>
                      <img
                        alt="icon-eye"
                        class="field-icon toggle-password"
                        [src]="
                          passwordFieldType === 'text'
                            ? './assets/images/icn_Show@1x.png'
                            : './assets/images/icn_Show.png'
                        "
                        (click)="changePassType()"
                      />
                    </span>
                  </div>
                  <div *ngIf="errors?.errorDescription" class="text-danger">
                    {{ errors.errorDescription }}
                  </div>
                  <div class="otpStatus">
                    Please enter any of the bank account number(s) registered
                    with us
                  </div>
                </div>
                <div
                  class="form-group checkbox new-check"
                  (click)="checkboxAction('existUserLoginRememberMeBankAcc')"
                >
                  <input
                    type="checkbox"
                    id="existUserLoginRememberMeBankAcc"
                    name="userLoginRememberMe"
                    aria-labelledby="userLoginRememberMe"
                    class="form-field elEncodeCobrowse"
                    value="rememberMe"
                    style="margin-left: 0px"
                    checked=""
                    data-gcb-service-node="true"
                  />
                  <label for="existUserLoginRememberMeBankAcc"
                    >Remember Me</label
                  >
                  <div class="tooltip-container">
                    <a
                      type="button"
                      id="main-login-tooltip-bank-acc"
                      data-toggle="main-login-tooltip"
                      data-placement="bottom"
                      title=""
                      ><img
                        class="remember-info-icon"
                        alt="image"
                        src="https://franklintempletonprod.widen.net/content/zv4trbj28o/jpeg/icn_info-hallow.jpeg"
                    /></a>
                    <div class="pan-tool-tip-container">
                      {{ "ftiLoginRegister.rememberMeTooltip" | translate }}
                    </div>
                  </div>
                </div>
                <div class="login-buttons row">
                  <div
                    class="right-border button-width float-left col-md-6 col-lg-6"
                  >
                    <button
                      type="submit"
                      class="btn btn-default next-btn"
                      style="width: 100%"
                      [disabled]="!investorBankAccFrm.valid"
                    >
                      LOGIN
                    </button>
                  </div>
                  <div class="button-width col-md-6 col-lg-6">
                    <button
                      type="button"
                      class="btn register-btn btn-grey anchorBtn next-btn"
                      style="width: 100%"
                      (click)="backToPan()"
                    >
                      BACK
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 transactCol2--1 fti-bg-gray--1">
      <ft-new-investor-login></ft-new-investor-login>
    </div>
  </div>
</div>
