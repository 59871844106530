<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <div
    [style.marginTop.px]="params?.marginTop | spacing"
    [style.marginBottom.px]="params?.marginBottom | spacing"
  >
    <ng-container
      [brManageContentButton]="document"
      class="has-edit-button float-right"
    ></ng-container>
    <div class="enquiry-block">
      <a
        href="javascript:void(0);"
        class="btn-dark-blue"
        data-target="#advisor"
        data-toggle="modal"
      >
        <span class="btn panel-orange-btn txt">ASK FOR A DISTRIBUTOR</span>
      </a>
      <a
        href="javascript:void(0);"
        class="btn-dark-blue"
        data-target="#speaktous"
        data-toggle="modal"
      >
        <span class="btn panel-orange-btn txt">SPEAK TO US</span>
      </a>
      <a class="loadtransactNowPage btn-dark-blue" [href]="url">
        <span class="btn panel-orange-btn txt">INVEST NOW</span>
      </a>
    </div>
  </div>
</div>
