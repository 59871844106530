import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LiteratureService } from '@services/literature.service';
import { LiteratureDTO } from '@types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ft-update-list',
  templateUrl: './update-list.component.html',
  styleUrls: ['./update-list.component.scss'],
})
export class UpdateListComponent implements OnInit, OnDestroy {
  // Limit the number of rows to be shown on the component. Default is 3
  @Input() limit = 3;
  updateList: LiteratureDTO;
  subscription: Subscription;

  constructor(private litService: LiteratureService) {}

  ngOnInit(): void {
    // Call Literature Service to pull the data
    this.subscription = this.litService
      .getData$(
        '?channel=en-in&contentGrouping=Latest%20Updates&limitIndex=' +
          this.limit
      )
      ?.subscribe((response: LiteratureDTO): void => {
        this.updateList = response;
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
