<div class="banner-container" *ngIf="bannerData?.length">
  <div class="numberGrid">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="border-orange count">
          {{ bannerData[0]?.value
          }}<sup *ngIf="bannerData[0]?.superscript">{{
            bannerData[0]?.superscript
          }}</sup>
        </div>
        <div class="grid-text">{{ bannerData[0]?.label }}</div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="border-green count">
          {{ bannerData[1]?.value
          }}<sup *ngIf="bannerData[1]?.superscript">{{
            bannerData[1]?.superscript
          }}</sup>
        </div>
        <div class="grid-text">{{ bannerData[1]?.label }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="border-red count">
          {{ bannerData[2]?.value
          }}<sup *ngIf="bannerData[2]?.superscript">{{
            bannerData[2]?.superscript
          }}</sup>
        </div>
        <div class="grid-text">{{ bannerData[2]?.label }}</div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="border-yellow count">
          {{ bannerData[3]?.value
          }}<sup *ngIf="bannerData[3]?.superscript">{{
            bannerData[3]?.superscript
          }}</sup>
        </div>
        <div class="grid-text">{{ bannerData[3]?.label }}</div>
      </div>
    </div>
  </div>
</div>
