import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Logger, LogLevel } from '@utils/logger';
import { environment } from '../../environments/environment';
import prodEnvConfig from '../../assets/data/config.ENVPROD.json';
import preEnvConfig from '../../assets/data/config.ENVPRE.json';
import { WINDOW } from '@ng-web-apis/common';

export enum Environments {
  DEV = 'dev',
  STG = 'staging',
  CONTENT_PREVIEW = 'content-preview',
  PRE = 'pre',
  PROD = 'prod',
}

export interface DataDogConfig {
  rumEnabled?: boolean;
  logsEnabled?: boolean;
  applicationId?: string;
  service?: string;
  site?: string;
  token?: string;
  allowedOrigins?: (string | RegExp)[];
  sampleRate?: number;
  premiumSampleRate?: number;
  trackInteractions?: boolean;
  trackFrustrations?: boolean;
}

export interface EnvConfig {
  apiPort?: number;
  apiScheme?: string;
  apiUrl?: string;
  auth?: string;
  bookApptBaseUrl?: string;
  cmsBaseUrl?: string;
  commentaryContentUrl?: string;
  cookieApiUrl?: string;
  defaultLogLevel?: LogLevel;
  defaultPds?: string;
  environment?: string;
  searchEnvironment?: string;
  fundCommentaryKey?: string;
  literatureApi?: string;
  downloadsApi?: string;
  hypoBaseUrl?: string;
  hypoSavedPresentationsUrl?: string;
  insightsUrl?: string;
  instrumentationKey?: string;
  literatureApiUrl?: string;
  literatureBaseApiUrl?: string;
  literatureDownloadBaseUrl?: string;
  literatureDownloadFinraUrl?: string;
  literatureDownloadUrl?: string;
  literatureDownloadZipUrl?: string;
  literatureImageUrl?: string;
  literatureOrderHistoryList?: string;
  literatureRecentOrderHistoryList?: string;
  profileDomain?: string;
  searchStatusUrl?: string;
  searchUrl?: string;
  singleHost?: boolean;
  spabaseurl?: string;
  ssoUrl?: string;
  ftiTrasactlogin?: string;
  ftitransactPaperLessKyc?: string;
  ftitransactPaperLessinvest?: string;
  ftingGuestUrl?: string;
  ssoPing?: string;
  widenUrl?: string;
  widenProxyUrl?: string;
  resourceApiTimeoutMs?: number;
  errorPage?: string;
  salesTeamUrl?: string;
  useLiteratureAPIMFlow?: boolean;
  orderHistoryAPIMUrl?: string;
  orderHistoryDetailAPIMUrl?: string;
  recentOrderAPIMUrl?: string;
  submitOrderAPIMUrl?: string;
  literatureAuthAPIAPIMKey?: string;
  literatureAPIMKey?: string;
  salesTeamListUrl?: string;
  dynamicsClientId?: string;
  dynamicsClientSecret?: string;
  LiteratureBaseAPIMUrl?: string;
  LiteratureEmailDocsAPIMUrl?: string;
  LiteratureDownloadZipAPIMUrl?: string;
  myfundsApiBase?: string;
  myfundsApiKey?: string;
  germanCalculatorOnetimeInvestmentApiUrl?: string;
  germanCalculatorSavingPlanApiUrl?: string;
  germanCalculatorWithdrawlPlanApiUrl?: string;
  toGetFavoriteFundApiUrl?: string;
  toSetFavoriteFundApiUrl?: string;
  useLocalStorageFavoriteFunds?: boolean;
  perfCummulativeUrl?: string;
  baseCookieVal?: string;
  transactNowDomain?: string;
  baseUrl?: string;
  ftiApiDomain?: string;
  ftiAccountsUrl?: string;
  ftiSearchUrl?: string;
  ftiArticleList?: string;
  ftichatBotUrl?: string;
  ftichatModuleUrl?: string;
  ftiChatBotEnv?: string;
  ftiAccountsValidate?: string;
  ftiMaintenanceValidation?: string;
  ftiAskAdvisorApi?: string;
  ftiProfile?: string;
  ftiKYCOnboarding?: string;
  advInvUrlForSearch?: string;
  autoCompleteUrl?: string;
  firstHiveUrl?: string;
  searchAccountContent?: string;
  ngGuestAccountsUrl?: string;
  ngGuestTransactNowExt?: string;
  ngGuestInvDashboardExt?: string;
  ngGuestAdvDashboardExt?: string;
  ocpApimSubscriptionKey?: string;
  ocpApimTrace?: string;
  datadog?: DataDogConfig;
  prodPdsApiUrl?: string;
  devPdsApiUrl?: string;
}

/**
 * Environmental Config loaded from config.json
 */
@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  private configName: string = environment.config;
  private envConfig: EnvConfig;
  private readonly isProd: boolean;
  private readonly isPre: boolean;
  private readonly isContentPre: boolean;
  private loadedPromise: Promise<boolean>;

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    const hostname = windowRef.location.hostname;
    this.isContentPre = hostname.startsWith('content-preview');
    this.isProd = hostname.startsWith('www');
    this.isPre = hostname.startsWith('pre');
    if (this.isProd) {
      this.initialize(prodEnvConfig);
      this.loadedPromise = Promise.resolve(true);
    }
    if (this.isPre) {
      this.initialize(preEnvConfig);
      this.loadedPromise = Promise.resolve(true);
    }
  }

  public loadEnvConfig(): Promise<boolean> {
    if (!this.loadedPromise) {
      this.loadedPromise = new Promise((resolve) => {
        this.http
          .get<EnvConfig>('/assets/data/' + this.configName)
          .subscribe((data) => {
            this.initialize(data);
            resolve(true);
          });
      });
    }
    return this.loadedPromise;
  }

  private initialize(data: EnvConfig) {
    // ----------------- Decode and replace the encoded keys from config JSON's
    data.prodPdsApiUrl = atob(data.prodPdsApiUrl);
    data.devPdsApiUrl = atob(data.devPdsApiUrl);
    data.datadog.token = atob(data.datadog.token);
    // ------------------
    this.envConfig = data;
    Logger.addConfiguration({
      defaultLevel: this.envConfig.defaultLogLevel || LogLevel.DEBUG,
    });
  }

  public getEnvConfig() {
    return this.envConfig;
  }

  public isProduction(): boolean {
    return this.isProd;
  }

  public isPreProduction(): boolean {
    return this.isPre;
  }

  public isContentPreview(): boolean {
    return this.isContentPre;
  }

  public isPreEnv(): boolean {
    return this.isPre;
  }
}
