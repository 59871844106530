import { Component, Input, OnInit } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { ResponsiveService } from '@services/responsive.service';
import { Observable } from 'rxjs';
import {
  VerticalTabContentType,
  VerticalTabsBlocks,
  VerticalTabsContent,
} from './vertical-tabs.types';

const rawDataType = {
  table: 'table',
  banner: 'banner',
  indiaOffices: 'indiaOffices',
  accordion: 'accordion',
  tabImage: 'tabImage',
};

@Component({
  selector: 'ft-vertical-tabs',
  templateUrl: './vertical-tabs.component.html',
  styleUrls: ['./vertical-tabs.component.scss'],
})
export class VerticalTabsComponent implements OnInit {
  @Input() page: Page;
  @Input() component: BrComponent;

  tabImageUrl: string;

  // structured data
  tabData: VerticalTabsBlocks[] = [];
  // block displayed currently
  displayedSection: VerticalTabsBlocks;
  // number of displayed block
  selectedBlock: number;
  isHandheld$: Observable<boolean>;
  selectedTab: string;

  get document() {
    const { document } = this.component.getModels<DocumentModels>();
    const content = document && this.page.getContent<Document>(document);
    return content;
  }

  constructor(public responsiveService: ResponsiveService) {}

  ngOnInit(): void {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    if (document?.$ref) {
      const content = this.page?.getContent(document)?.getData();
      this.mapState(content);
    }
    this.isHandheld$ = this.responsiveService.isMobile$();
  }

  /**
   * maps raw incoming data into usable dataStructure
   * @param content Content revieved from bloomreach
   */
  private mapState(content: any): void {
    if (content) {
      // iterating content to extract individual blocks data
      content?.contentBlock?.forEach((block, index) => {
        const blockData: VerticalTabsContent[] = [];
        // iterating block items to store them into usable dataStructure
        block?.items.forEach((blockItem) => {
          // this is temp, remove this
          const initialBlockItem: VerticalTabsContent = {};
          // Using ParagraphBlock as a key to take in json data and parsing it, if its not paragraphBlock
          // render the incoming content as [innerHtml]
          if (blockItem?.type === 'ParagraphBlock') {
            // removing <p> and </p> tags
            const rawJsonString = blockItem?.content.slice(
              3,
              blockItem?.content.length - 4
            );
            const rawData = JSON.parse(rawJsonString);

            // map data according to blockType recieved
            if (rawData?.type === rawDataType.table) {
              initialBlockItem.tableContent = {
                title: rawData?.title,
                borderColor: rawData?.borderColor,
                tableData: rawData?.tableData,
              };
              initialBlockItem.blockType = VerticalTabContentType.Table;
            } else if (rawData?.type === rawDataType.banner) {
              initialBlockItem.bannerContent = rawData?.bannerData;
              initialBlockItem.blockType = VerticalTabContentType.Banner;
            } else if (rawData?.type === rawDataType.indiaOffices) {
              initialBlockItem.accordionContent = rawData?.contactData;
              initialBlockItem.blockType = VerticalTabContentType.IndiaOffices;
            } else if (rawData?.type === rawDataType.accordion) {
              initialBlockItem.accordionContent = rawData?.contactData;
              initialBlockItem.blockType = VerticalTabContentType.Accordion;
            } else if (rawData?.type === rawDataType.tabImage) {
              this.tabImageUrl = rawData?.url;
            }
          } else {
            if (blockItem?.contentType === 'ftcore:IntroBlock') {
              initialBlockItem.content = blockItem?.content;
              initialBlockItem.blockType = VerticalTabContentType.Intro;
            } else if (blockItem?.contentType === 'ftcore:ImageCompound') {
              initialBlockItem.blockType = VerticalTabContentType.Image;
              if (blockItem?.widenAsset) {
                const imageURL = JSON.parse(blockItem?.widenAsset)?.url;
                initialBlockItem.imageUrl = imageURL;
              }
            }
          }
          blockData.push(initialBlockItem);
        });
        const contentBlockData: VerticalTabsBlocks = {
          sectionId: block?.sectionId,
          blockId: index,
          block: blockData,
          selected: false,
        };
        this.tabData.push(contentBlockData);
      });

      // First sectionId will be selected
      this.tabData[0].selected = true;
      this.selectedTab = this.tabData[0].sectionId;
      this.displayedSection = this.tabData[0];
      this.selectedBlock = 0;
    }
  }

  /**
   * switches between selected vertical tab and its data
   * @param tabNumber id of next vertical block to be displayed
   */
  public updateSection(tabNumber: number): void {
    if (tabNumber < this.tabData.length) {
      this.tabData[this.selectedBlock].selected = false;
      this.tabData[tabNumber].selected = true;
      this.displayedSection = this.tabData[tabNumber];
      this.selectedBlock = tabNumber;
      this.selectedTab = this.tabData[tabNumber].sectionId;
    }
  }
}
