import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
const logger = Logger.getLogger('AccordionComponent');

const PATH_NAME = {
  help: 'help',
  openAnAccount: 'open an account',
  securityAndPrivacy: 'security-and-privacy',
  securityPrivacy: 'security & privacy',
  termsAndConditions: 'terms-and-conditions',
};

@Component({
  selector: 'ft-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  currentPath = '';
  openFirstAccordion = false;
  hashNavigation: any;
  acordionKey: any;

  constructor(private location: Location, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.currentPath = this.location.path().split('/')[1];
    if (this.currentPath === PATH_NAME.help) {
      this.currentPath = PATH_NAME.openAnAccount;
    } else if (this.currentPath === PATH_NAME.securityAndPrivacy) {
      this.currentPath = PATH_NAME.securityPrivacy;
    } else if (this.currentPath === PATH_NAME.termsAndConditions) {
      this.openFirstAccordion = true;
    }
    this.hashNavigation = this.route.snapshot?.fragment;
  }
  ngAfterViewInit() {
    if (this.hashNavigation !== undefined) {
      if (this.currentPath === 'faqs') {
        this.smoothScroll(this.acordionKey);
      } else {
        this.smoothScroll(this.hashNavigation);
      }
    }
    if (this.currentPath === 'faqs') {
      this.smoothScroll(this.acordionKey);
    }
  }
  getAccordionTitle(accordionName: any, acordionKey: any) {
    this.acordionKey = acordionKey;
    return accordionName;
  }
  smoothScroll(target) {
    let stra2 = document.querySelector(`#${target}`);
    stra2 = stra2 === undefined ? document.querySelector(`#${target}`) : stra2;
    const doScrolling = (elementY, duration) => {
      const startingY = window.pageYOffset;
      const diff = elementY - startingY;
      let start;

      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) {
          start = timestamp;
        }
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        const percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent + 30);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    };

    if (stra2 != null) {
      doScrolling(stra2.getBoundingClientRect().top + window.scrollY - 40, 10);
    }
  }
  get document() {
    const { document } = this.component?.getModels() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    logger.debug('accordion data::::', this.document?.getData().contentBlocks);
    return this.document?.getData().contentBlocks;
  }

  get isPreview() {
    return this.page.isPreview();
  }
}
