import { LoggerOutput } from './logger-output';
// for datadogLogs
import { EnvConfigService } from '@services/env-config.service';
import { DatadogService } from '@services/datadog.service';

export class DataDogLoggerOutput implements LoggerOutput {
  datadogLogs: any;
  datadogRum: any;

  constructor(datadogService: DatadogService) {
    this.datadogLogs = datadogService.getLogs();
    if (datadogService.isRumEnabled()) {
      this.datadogRum = datadogService.getRum();
    }
  }

  getLogger() {
    return this.datadogLogs?.logger;
  }

  debug(...data: any) {
    this.getLogger()?.debug(data);
  }

  info(...data: any) {
    this.getLogger()?.info(data);
  }

  warn(...data: any) {
    this.getLogger()?.warn(data);
  }

  error(...data: any) {
    this.getLogger()?.error(data);
    this.datadogRum?.addError(data);
  }

  exception(...data: any) {
    // TODO
    this.datadogRum?.addError(data);
  }

  event(...data: any) {
    // TODO
  }
}
