<ng-container *ngIf="addFundsData">
  <button
    type="button"
    class="btn btn-default panel-orange-btn add-funds-button"
    (click)="openAddFundsModal(modalRef)"
  >
    {{ addFundsData.addFundsButtonlabel }}
  </button>
  <ng-template #modalRef let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{ addFundsData.modalTitle }}</h4>
      <a
        type="button"
        class="fill-circle-close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span class="icon-fti_close"></span>
      </a>
    </div>
    <div class="modal-body">
      <form [formGroup]="addFundsForm" class="searchBox-section clearfix row">
        <div
          class="col-12 col-sm-12 col-md-3 search-dropdown"
          *ngIf="addFundsData.assetClassFilter"
        >
          <ft-select
            [id]="addFundsData.assetClassFilter.filterName"
            formControlName="assetClassFormControl"
            [dropdownList]="addFundsData.assetClassFilter.filterItems"
            (dropDownChange)="onDropdownchange($event)"
          ></ft-select>
        </div>

        <div class="searchBox col-12 col-sm-12 col-md-9">
          <label for="addFundsData.searchInput.name" class="sr-only">{{
            addFundsData.searchInput.label
          }}</label>
          <input
            type="text"
            autocomplete="off"
            placeholder="Search"
            [id]="addFundsData.searchInput.name"
            [name]="addFundsData.searchInput.name"
            [placeholder]="addFundsData.searchInput.placeholder"
            formControlName="searchInputControl"
            class="typeahead inputText SearchInput"
          />
        </div>
      </form>
      <div class="exceded-fund-error" *ngIf="isMaxLimitExceed">
        {{ addFundsData.maxLimitExceedMessage }}
      </div>
      <div class="fund-list-scroll-container">
        <ul class="add-fund-list" *ngIf="currentFundList">
          <ng-container *ngFor="let fd of currentFundList">
            <li class="add-new-fund" *ngIf="fd.show">
              <div class="checkbox checkbox-inline">
                <input
                  type="checkbox"
                  class="add-fund-to-watchlist"
                  [(ngModel)]="fd.selected"
                  id="{{ fd.fundId }}-label"
                  name="{{ fd.fundId }}-name"
                  (change)="checkMaxLimit()"
                />
                <label for="{{ fd.fundId }}-label"
                  ><span class="chbox"></span>{{ fd.fundName }}</label
                >
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <input
        aria-label="submitbutton"
        type="button"
        id="addfundToBtn"
        class="submit btn btn-default panel-orange-btn"
        [value]="addFundsData.submitLabel"
        (click)="onAddedFunds(modal)"
        [disabled]="disableSubmit()"
      />
      <input
        aria-label="addfunds"
        type="reset"
        id="resetAddFunds"
        class="btn btn-default panel-gray-btn"
        [value]="addFundsData.resetlabel"
        (click)="resetModal()"
      />
    </div>
  </ng-template>
</ng-container>
