<div *ngIf="isPopulated" class="pi-chart">
  <div class="panel">
    <div class="panel-heading">
      <h4 class="panel-title">
        {{ data.chartTitle
        }}<span class="pull-right smalltext"
          >{{ data.asOfLabel }} {{ data.asOfDate }}</span
        >
      </h4>
    </div>
    <div class="panel-collapse in accordion-content">
      <div class="panel-body">
        <div id="portfolio-pie-chart">
          <highcharts-chart
            [Highcharts]="highcharts"
            [options]="chartOptions"
            [(update)]="updateChart"
            [callbackFunction]="chartCallback"
            style="width: 100%; display: block"
          >
          </highcharts-chart>
        </div>

        <div id="pie-chart-legend">
          <li *ngFor="let point of tablePoints">
            <span
              class="color-block"
              [style.background]="point.pointColor"
            ></span
            >{{ point.label
            }}<span class="pie-legent-val pull-right">{{
              point.actualAllocation || point.breakdown
            }}</span>
          </li>
        </div>
      </div>
    </div>
  </div>

  <!--
  <ft-proximal [placement]="data.proximalTopPlacement"></ft-proximal>
  <ft-proximal
    *ngIf="data.secondaryProximalTopPlacement"
    [placement]="data.secondaryProximalTopPlacement"
  ></ft-proximal>
  <ft-proximal [placement]="data.proximalBottomPlacement"></ft-proximal>
  <ft-proximal
    *ngIf="data.secondaryProximalBottomPlacement"
    [placement]="data.secondaryProximalBottomPlacement"
  ></ft-proximal> -->
</div>
