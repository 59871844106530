<div class="col-lg-12 col-md-12 col-sm-12 transactCol2">
  <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
    <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root=""
    >
    </ng-container>
    <div class="bg-color"></div>
    <div class="custom-margin">
      <h6 class="investor-header">{{ "ftiNewInvestor.title" | translate }}</h6>
      <p class="transact-header">{{ "ftiNewInvestor.subTitle" | translate }}</p>
    </div>
    <div class="tab-pane">
      <div class="tab-content new-investor-border">
        <div class="tab-pane active">
          <form
            id="newInvestorForm"
            [formGroup]="loginFormNewInvestor"
            (ngSubmit)="registerUser()"
            autocomplete="off"
            novalidate="true"
            method="post"
          >
            <div class="field-wrapper resident-type">
              <div class="form-group">
                <div
                  id="res-indian"
                  class="radio-item col-xs-12 col-sm-12 col-md-6"
                >
                  <input
                    id="indian"
                    name="investorType"
                    checked
                    (click)="clickResidentType('RESIND')"
                    type="radio"
                  />
                  <label for="indian"
                    ><span class="icon l-19 glyphicon glyphicon-ok"></span
                    >Resident Indian</label
                  >
                </div>
                <div
                  id="non-res"
                  class="radio-item col-xs-12 col-sm-12 col-md-6"
                >
                  <input
                    id="nri"
                    name="investorType"
                    (click)="clickResidentType('NRI')"
                    type="radio"
                  />
                  <label for="nri"
                    ><span class="icon l-19 glyphicon glyphicon-ok"></span>Non
                    Resident Indian</label
                  >
                </div>
              </div>
            </div>
            <div class="field-wrapper" *ngIf="isNRI">
              <div class="form-group dd-nriType">
                <label for="nriType" class="control-label select-focus"
                  >Select NRI Type</label
                >
                <select
                  class="nriType-select"
                  #restype
                  (change)="onSelectedRes(restype.value)"
                  name="nriType"
                  id="type-nri"
                >
                  <option value="NRIR">NRI-Repatriable</option>
                  <option value="NRINR">NRI-Non Repatriable</option>
                </select>
              </div>
            </div>
            <div class="field-wrapper d-flex">
              <div
                class="form-group"
                [ngClass]="{
                  focused: this.loginFormNewInvestor.value.userid
                }"
              >
                <label class="control-label" for="fieldlabel"
                  >{{ "ftiNewInvestor.panInputFieldLabel" | translate }}
                  <sup>*</sup>
                </label>
                <input
                  id="fieldlabel"
                  formControlName="userid"
                  maxlength="10"
                  type="text"
                  class="form-field form-control text-uppercase"
                  (paste)="onPaste($event)"
                />
              </div>
              <div class="tooltip-container">
                <a type="button"><span class="icon icon-fti_Info"></span></a>
                <div class="pan-tool-tip-container">
                  {{ "ftiNewInvestor.panHintLabel" | translate }}
                </div>
              </div>
            </div>
            <div class="field-wrapper">
              <div
                class="form-group"
                [ngClass]="{
                  focused: this.loginFormNewInvestor.value.email
                }"
              >
                <label class="control-label" for="emailfield"
                  >{{ "ftiNewInvestor.emailInputBoxLabel" | translate }}
                  <sup>*</sup>
                </label>
                <input
                  id="emailfield"
                  formControlName="email"
                  maxlength="60"
                  type="text"
                  class="form-field form-control"
                  (paste)="onPaste($event)"
                />
              </div>
            </div>
            <div class="field-wrapper" [ngClass]="isNRI ? 'd-flex' : ''">
              <div
                class="form-group"
                [ngClass]="{
                  focused: this.loginFormNewInvestor.value.mobile
                }"
              >
                <label class="control-label" for="mobilefield"
                  >{{ mobileNolabel }}
                  <sup *ngIf="!isNRI">*</sup>
                </label>
                <input
                  id="mobilefield"
                  formControlName="mobile"
                  maxlength="10"
                  type="text"
                  class="form-field form-control"
                  (paste)="onPaste($event)"
                  (keypress)="keyPress($event)"
                />
              </div>
              <div class="tooltip-container" *ngIf="isNRI">
                <a type="button"><span class="icon icon-fti_Info"></span></a>
                <div class="pan-tool-tip-container nri-tooltip">
                  You may enter 10 digit Indian mobile number. Kindly leave this
                  field blank in case you do not have one.
                  <!--{{ "ftiNewInvestor.panHintLabel" | translate }}-->
                </div>
              </div>
            </div>
            <span *ngIf="errors" class="text-danger">
              <p
                class="invalidfeedback"
                *ngIf="errors.errorDescription == '4041'"
              >
                You already have an investment with us. Please login to your
                online account using your user ID or PAN. In case you don't have
                an online account set up, then
                <a href="javascript:void(0)" (click)="redirectRegisterFlow()"
                  >click here</a
                >
                to register the same.
              </p>
              {{
                errors.errorDescription != "4041" ? errors.errorDescription : ""
              }}
            </span>
            <span *ngIf="kycNotRegistered" class="text-danger">
              <p class="invalidfeedback">
                Your KYC isn’t registered or is under progress, please
                <a
                  href="https://www.franklintempletonindia.com/investor/customer-services/find-franklin-templeton/locate-us"
                  >click here</a
                >
                to check your KYC status. For more details, please feel free to
                contact us.
              </p>
            </span>
            <div class="login-buttons justify-content-between row pad-t10">
              <div
                class="float-left button-width p-0 col-xs-5 col-md-5 col-lg-5"
              >
                <button
                  [disabled]="!loginFormNewInvestor.valid"
                  type="submit"
                  class="btn btn-default next-btn"
                >
                  {{ "ftiNewInvestor.proceed_button" | translate }}
                </button>
              </div>
              <div class="col-xs-6 col-md-6 button-width col-lg-6 text-right">
                <a
                  (click)="openModal(watchVideoLabelUrl)"
                  type="button"
                  class="custom-video analytics-link"
                  data-event-name="Event Homepage"
                  data-category="Homepage"
                  data-action="Transact Now"
                  data-label="Existing Investor with Login| Watch Video"
                >
                  <span class="icon circle new-play_video-img"></span>
                  <span class="video-text">{{
                    "ftiNewInvestor.watchVideoLabel" | translate
                  }}</span>
                </a>
              </div>
            </div>
          </form>
          <!-- OTP form -->
          <form
            style="display: none; padding-top: 10px"
            id="newInvestorOTPFrm"
            [formGroup]="investorOTPForm"
            autocomplete="off"
            novalidate="true"
            (ngSubmit)="validateOTP()"
          >
            <div class="field-wrapper">
              <div
                *ngIf="successStatus"
                class="otpStatus mb-3"
                style="color: rgb(79, 126, 14)"
              >
                {{ successStatus }}
              </div>
              <div
                class="form-group"
                [ngClass]="{
                  focused: this.investorOTPForm.value.otp
                }"
              >
                <label class="control-label" for="investorotpfield"
                  >Enter OTP
                  <sup>*</sup>
                </label>
                <input
                  id="investorotpfield"
                  formControlName="otp"
                  maxlength="6"
                  type="password"
                  class="form-field form-control"
                  (paste)="onPaste($event)"
                  [class.is-invalid]="errors?.errorDescription"
                  (keyup)="keyPress($event)"
                />
              </div>
              <div *ngIf="errors" class="text-danger">
                {{ errors.errorDescription }}
              </div>
              <div class="login-user-resendotp pt+">
                <a
                  href="javascript:;"
                  class="loginPANOTP"
                  id="loginPan-form-otpresendId"
                  (click)="registerUser()"
                  >Resend OTP</a
                >
              </div>
            </div>
            <div class="login-buttons row" style="width: 95%">
              <div class="float-left col-xs-12 col-md-6 col-lg-6 pr0">
                <button
                  type="submit"
                  class="btn btn-default next-btn"
                  style="width: 100%"
                  [disabled]="!investorOTPForm.valid"
                >
                  SUBMIT
                </button>
              </div>
              <div class="float-left col-xs-12 col-md-6 col-lg-6">
                <button
                  type="button"
                  class="btn register-btn btn-grey anchorBtn next-btn"
                  style="width: 100%"
                  (click)="backToLoign()"
                >
                  BACK
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #videoContent let-modal>
  <a
    type="button"
    class="fill-circle-close close-video"
    data-dismiss="modal"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span class="icon-fti_close"></span>
  </a>
  <div class="modal-header"></div>
  <div class="modal-body fti-video-player">
    <iframe
      width="796"
      height="360px"
      allowfullscreen="allowfullscreen"
      id="vid_frame"
      frameborder="0"
      [src]="urlSafe"
      loading="lazy"
      __genesys_cobrowse_iframe_id__="3"
    ></iframe>
  </div>
  <div class="modal-footer"></div>
</ng-template>
