import { Observable, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { Logger } from '@utils/logger';
import { DocumentNode } from '@apollo/client/core';
import {
  GraphQLFundDataService,
  FundDataServiceParams,
} from './graphql-fund-data.service';
import { Apollo } from 'apollo-angular';
import { map, take } from 'rxjs/operators';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { FundListing, Product } from '@models';
import { ProductsMapper } from '@products/utils/mappers/product.type.mapper';
import { MapperParams } from '@products/utils/mappers/type.mapper';
import { ProductDTO } from '@types';
import { SiteConfigService } from '@services/site-config.service';
import merge from 'lodash/merge';

const logger = Logger.getLogger('PPSSFundDataService');

@Injectable({
  providedIn: 'root',
})
export class PPSSFundDataService extends GraphQLFundDataService {
  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private mapperFactory: MapperFactory
  ) {
    super(apollo, siteConfigService);
  }

  public fetchData(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<FundListing> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((rawPpssData) => {
        const rawPpssFunds: ProductDTO[] = rawPpssData?.data?.PPSS
          ? rawPpssData.data.PPSS
          : [];
        const mapperParams: MapperParams = {
          config: this.siteConfigService,
          configurationName: fundDataServiceParams.configurationName,
        };
        const products: Product[] = this.mapperFactory
          .createMapper(ProductsMapper, mapperParams)
          .toDomain(rawPpssFunds);

        return new FundListing(products);
      })
    );
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      productType: fundDataServiceParams.productType,
    };
  }
}
