import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppStateService } from '@services/app-state.service';
import { CommonService } from '@services/common.service';
import { Logger } from '@utils/logger';
import { Constant } from '../../../ft-constants/constant';

const logger = Logger.getLogger('ShareOptionsComponent');

@Component({
  selector: 'ft-share-options',
  templateUrl: './share-options.component.html',
  styleUrls: ['./share-options.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShareOptionsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() nonDragComponent: any;
  mailBackForm: FormGroup;
  documentData: any = {};
  @ViewChild('htmlContainer') container;
  shareButtonUrl: string;
  shareUrlRefresh: string;
  showShare = false;
  submitted = false;
  focusValue: boolean;
  hostName: string;
  successmsg = false;
  commonLabels: any;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private appStateService: AppStateService,
    private commonService: CommonService
  ) {}

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};

    return document && this.page?.getContent<Document>(document);
  }

  get data() {
    if (this.nonDragComponent !== undefined) {
      return this.nonDragComponent;
    } else {
      return this.component?.getParameters();
    }
  }

  get params() {
    return this.component?.getParameters();
  }

  ngOnInit(): void {
    this.commonLabels = this.commonService.getLabels()?.ftiCommon;
    this.mailBackForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.shareButtonUrl = Constant.shareBtnUrl;
    this.renderScript(this.shareButtonUrl);
  }

  public loadScript(url: string) {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  renderScript(shareUrl) {
    this.hostName = this.appStateService.getEnvConfig()?.environment;
    if (this.hostName === 'dev' || this.hostName === 'staging') {
      this.shareUrlRefresh = './en-in/assets/js/addthis/addthis_refresh.js';
    } else {
      this.shareUrlRefresh = './assets/js/addthis/addthis_refresh.js';
    }

    setTimeout(() => {
      if (this.container === undefined) {
        this.renderScript(shareUrl);
      } else {
        this.loadScript(shareUrl);
        this.loadScript(this.shareUrlRefresh);
      }
    });
  }

  open(content) {
    this.mailBackForm.reset();
    this.successmsg = false;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  get f() {
    return this.mailBackForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.submitted && this.mailBackForm.valid) {
      this.successmsg = true;
    } else {
      this.successmsg = false;
    }
    return false;
  }

  labelMoveIn(e: any) {
    this.focusValue = true;
  }

  labelMoveOut(e: any) {
    if (e.target.value !== '') {
      this.focusValue = true;
    } else {
      this.focusValue = false;
    }
    logger.debug(e.target.value);
  }
}
