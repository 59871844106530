<div class="sticky-footer">
  <div class="title-bar">
    <span class="title-bar-label">Compare Funds</span>
    <span class="title-bar-toggle-button" (click)="toggleCompareDetails()">
      <svg
        *ngIf="showCompareDetails"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-chevron-down"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
        />
      </svg>
      <svg
        *ngIf="!showCompareDetails"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-chevron-up"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>
    </span>
  </div>

  <div *ngIf="showCompareDetails" class="compare-details">
    <div class="compare-details-fund-names">
      <ul>
        <ng-container *ngFor="let fundCompareDetail of fundCompareDetails">
          <li
            [ngClass]="
              fundCompareDetail.isValid ? 'valid-fund' : 'invalid-fund'
            "
          >
            {{ fundCompareDetail.fundName }}
          </li>
          <svg
            *ngIf="fundCompareDetail.isValid"
            (click)="onFundDeselect(fundCompareDetail.fundName)"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
            />
            <path
              fill-rule="evenodd"
              d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
            />
          </svg>
        </ng-container>
      </ul>
    </div>

    <div class="compare-details-action-buttons">
      <span class="action-button-compare" (click)="onCompare()">Compare</span>
      <span class="action-button-reset" (click)="onReset()">Reset</span>
    </div>
  </div>
</div>
