import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FundCompareDetail } from '@components/products/fund-compare/fund-compare.type';

@Component({
  selector: 'ft-compare-sticky-footer',
  templateUrl: './compare-sticky-footer.component.html',
  styleUrls: ['./compare-sticky-footer.component.scss'],
})
export class CompareStickyFooterComponent implements OnChanges {
  @Input() fundNames: string[] = [];
  @Input() showCompareDetails: boolean;
  @Output() removeFund = new EventEmitter<string>();
  @Output() compareFunds = new EventEmitter();
  @Output() resetFundCompareList = new EventEmitter();
  fundCompareDetails: FundCompareDetail[] = [];

  ngOnChanges(): void {
    // Empty the compare list for every change.
    this.fundCompareDetails = [];

    // Push the valid fund, if any.
    this.fundNames.forEach((fundName) => {
      this.fundCompareDetails.push({
        fundName,
        isValid: true,
      });
    });

    // Handle the fund comparison list with dummy name, when length is not three.
    if (this.fundNames.length !== 3) {
      let dummyFundNeeded = 3 - this.fundNames.length;

      while (dummyFundNeeded > 0) {
        this.fundCompareDetails.push({
          fundName: `Fund ${4 - dummyFundNeeded}`, // Make dynamic fund count, like, Fund 1, Fund 2...
          isValid: false,
        });

        dummyFundNeeded--;
      }
    }
  }

  /**
   * Toggle the visibility of fund compare details section.
   */
  toggleCompareDetails(): void {
    this.showCompareDetails = !this.showCompareDetails;
  }

  /**
   * Trigger fund deselect event to remove fund from comparison list, when clicked.
   * @param fundName fund selected to remove from the list
   */
  onFundDeselect(fundName: string): void {
    this.removeFund.next(fundName);
  }

  /**
   * Trigger fund comparison event, when clicked.
   */
  onCompare(): void {
    this.compareFunds.next();
  }

  /**
   * Trigger reset event for clearing up the funds from the fund comparison list, when clicked.
   */
  onReset(): void {
    this.resetFundCompareList.next();
  }
}
