<div class="container position-relative login-popup">
  <div class="section-inner-txt">
    <div>
      <div class="login-wrapper" *ngIf="!validated">
        <div class="errorPage maintenancePage">
          <img
            class="mapImage"
            src="/assets/images/maintenace_icon.png"
            alt="Maintenance"
          />
          <div
            [ngClass]="'maintenancePage--content'"
            [innerHTML]="maintenance$ | async"
          ></div>
        </div>
      </div>
      <div class="login-wrapper" *ngIf="validated">
        <!-- Tab (Investor / Distributor) -->
        <ul class="nav" *ngIf="loginWrapper">
          <li [ngClass]="{ active: investorTab }">
            <a (click)="setSelectedTab('investor')">
              <span class="login-tab-text">{{
                "ftiLoginRegister.investorTabLabel" | translate
              }}</span>
            </a>
          </li>
          <li [ngClass]="{ active: distributorTab }">
            <a (click)="setSelectedTab('distributor')">
              <span class="login-tab-text">{{
                "ftiLoginRegister.distributorTabLabel" | translate
              }}</span>
            </a>
          </li>
        </ul>

        <!-- Tab Content (Investor / Distributor) -->
        <div class="tab-content">
          <!-- Investor Tab -->
          <div class="tab-pane" [ngClass]="{ active: investorTab }">
            <div
              class="nav nav-tabs tab-inner"
              role="tablist"
              *ngIf="loginWrapper"
            >
              <div id="login-use-id" class="radio-item userId-login">
                <input
                  #radioButtonUserId
                  name="intervaltype"
                  type="radio"
                  id="Button"
                  (click)="setClickRadio('useid')"
                />
                <label for="Button">
                  <span class="icon l-19 glyphicon glyphicon-ok"></span>
                  {{ "ftiLoginRegister.useUserRadioLabel" | translate }}
                </label>
              </div>
              <div id="login-use-pan" class="radio-item userPan-login">
                <input
                  #radioButtonPan
                  name="intervaltype"
                  type="radio"
                  id="panbutton"
                  (click)="setClickRadio('usepan')"
                />
                <label for="panbutton">
                  <span class="icon l-20 glyphicon glyphicon-ok"></span>
                  {{ "ftiLoginRegister.usePANRadioLabel" | translate }}
                </label>
              </div>
            </div>

            <div class="tab-content">
              <div class="tab-pane" [ngClass]="{ active: useidTab }">
                <form
                  id="investorLoginForm"
                  [formGroup]="loginFormInvestorUserID"
                  (ngSubmit)="loginUserID()"
                  autocomplete="off"
                  novalidate="true"
                  method="post"
                >
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.loginFormInvestorUserID.value.userid
                      }"
                    >
                      <label class="control-label" for="investoruserid"
                        >{{
                          "ftiLoginRegister.investorUserIdInput" | translate
                        }}
                        <sup>*</sup>
                      </label>
                      <input
                        id="investoruserid"
                        formControlName="userid"
                        maxlength="60"
                        type="text"
                        class="form-field form-control"
                        (paste)="onPaste($event)"
                        [class.is-invalid]="errors?.errorDescription"
                      />
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.loginFormInvestorUserID.value.password
                      }"
                    >
                      <label class="control-label" for="userpassword"
                        >{{
                          "ftiLoginRegister.investorPasswordInput" | translate
                        }}
                        <sup>*</sup>
                      </label>
                      <input
                        id="userpassword"
                        formControlName="password"
                        maxlength="64"
                        type="password"
                        class="form-field form-control"
                        (paste)="onPaste($event)"
                        [class.is-invalid]="errors?.errorDescription"
                      />
                    </div>
                    <div
                      *ngIf="errors"
                      class="login-form-error text-danger pb-3"
                    >
                      {{ errors.errorDescription }}
                    </div>
                  </div>
                  <div class="login-buttons row">
                    <div
                      class="right-border float-left pr0 col-xs-6 col-md-6 col-lg-6"
                    >
                      <button
                        [disabled]="!loginFormInvestorUserID.valid"
                        type="submit"
                        class="btn btn-default next-btn"
                      >
                        {{ "ftiLoginRegister.investorNextBtn" | translate }}
                      </button>
                    </div>
                    <div class="register p-0 col-xs-6 col-md-6 col-lg-6">
                      <button
                        (click)="registerNow($event, '10')"
                        class="anchorBtn btn btn-gray register-btn"
                      >
                        {{ "ftiLoginRegister.investorRegisterBtn" | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="mt-3 text-center">
                    <a
                      (click)="forgotUid('10')"
                      href="{{
                        'ftiLoginRegister.investorForgotUserUrl' | translate
                      }}"
                      class="forgot-username"
                      >{{
                        "ftiLoginRegister.investorForgotUserId" | translate
                      }}</a
                    >
                    <span class="forgot-or">OR</span>
                    <a
                      (click)="forgotPass('10')"
                      href="{{
                        'ftiLoginRegister.investorForgotPasswordUrl' | translate
                      }}"
                      class="forgot-password"
                      >{{
                        "ftiLoginRegister.investorForgotPassword" | translate
                      }}</a
                    >
                  </div>
                </form>
                <!-- INVESTOR USER OTP form -->
                <form
                  style="display: none"
                  id="investorOTPForm"
                  [formGroup]="investorOTPForm"
                  autocomplete="off"
                  novalidate="true"
                  (ngSubmit)="validateOTP()"
                >
                  <div class="text-center loginCatHead text-secondary">
                    <div
                      class="icon-fti_arrowRight login-arrowLeft"
                      id="investorLogin"
                      (click)="backToLogin()"
                    ></div>
                    <div class="loginHead">LOGIN</div>
                    <div class="loginCate">(Investor)</div>
                  </div>
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.investorOTPForm.value.otp
                      }"
                    >
                      <label class="control-label" for="investorotp"
                        >Enter OTP
                        <sup>*</sup>
                      </label>
                      <input
                        id="investorotp"
                        formControlName="otp"
                        maxlength="6"
                        type="password"
                        class="form-field form-control"
                        (paste)="onPaste($event)"
                        [class.is-invalid]="errors?.errorDescription"
                        (keyup)="onlyNumeric($event)"
                      />
                    </div>
                    <div *ngIf="successStatus" class="otpStatus">
                      {{ successStatus }}
                    </div>
                    <div *ngIf="errors" class="login-form-error text-danger">
                      {{ errors.errorDescription }}
                    </div>
                    <div *ngIf="successStatus" class="login-user-resendotp pt+">
                      <a
                        href="javascript:;"
                        class="loginPANOTP"
                        id="loginPan-form-otpresendId"
                        (click)="generateOTP()"
                        >Resend OTP</a
                      >
                    </div>
                  </div>
                  <div
                    class="form-group checkbox new-check"
                    (click)="checkboxAction('userLoginRememberMe')"
                  >
                    <input
                      type="checkbox"
                      id="userLoginRememberMe"
                      class="form-field elEncodeCobrowse"
                      value="rememberMe"
                      style="margin-left: 0px"
                      checked=""
                      data-gcb-service-node="true"
                    />
                    <label for="userLoginRememberMe">Remember Me</label>
                    <div class="tooltip-container">
                      <a
                        id="main-login-tooltip"
                        data-toggle="main-login-tooltip"
                        data-placement="bottom"
                        title=""
                        ><img
                          class="remember-info-icon"
                          alt="imageRememberInfo"
                          src="https://franklintempletonprod.widen.net/content/zv4trbj28o/jpeg/icn_info-hallow.jpeg"
                      /></a>
                      <div class="pan-tool-tip-container">
                        {{ "ftiLoginRegister.rememberMeTooltip" | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="login-buttons">
                    <div class="float-left p-0 col-xs-12 col-md-12 col-lg-12">
                      <button
                        [disabled]="!investorOTPForm.valid"
                        type="submit"
                        class="btn btn-default next-btn"
                        style="width: 100%"
                      >
                        LOGIN
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane mb-5" [ngClass]="{ active: usepanTab }">
                <form
                  id="investorLoginPANFrm"
                  style="padding-bottom: 0"
                  [formGroup]="loginFormInvestorUserPAN"
                  autocomplete="off"
                  novalidate="true"
                  (ngSubmit)="registerNow($event, '10')"
                >
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.loginFormInvestorUserPAN.value.userpan
                      }"
                    >
                      <label class="control-label" for="panfield"
                        >{{ "ftiLoginRegister.investorPanInput" | translate }}
                        <sup>*</sup>
                      </label>
                      <input
                        id="panfield"
                        formControlName="userpan"
                        maxlength="10"
                        type="text"
                        class="form-field form-control text-uppercase"
                        (paste)="onPaste($event)"
                        (input)="checkPAN()"
                      />
                      <span
                        id="editPan"
                        class="editPan float-right"
                        (click)="backToPAN()"
                        >Edit</span
                      >
                    </div>
                    <div
                      *ngIf="errors && !otpError"
                      class="login-form-error text-danger"
                    >
                      {{ errors.errorDescription }}
                    </div>
                    <div
                      *ngIf="registerPan"
                      class="login-form-error text-danger"
                    >
                      The entered PAN isn't registered with Franklin Templeton
                      online account. To create your account click REGISTER NOW.
                    </div>
                    <div class="login-buttons">
                      <div
                        class="button-width p-0 col-xs-12 col-md-12 col-lg-12"
                        id="btnRegisterOtpMenu"
                        style="display: none"
                      >
                        <button
                          type="submit"
                          class="btn btn-default next-btn"
                          style="width: 100%"
                        >
                          REGISTER NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <!-- INVESTOR PAN OTP form -->
                <form
                  style="display: none; padding-top: 0"
                  id="investorPANOTPForm"
                  [formGroup]="investorPANOTPForm"
                  autocomplete="off"
                  novalidate="true"
                  (ngSubmit)="validateOTP(0)"
                >
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.investorPANOTPForm.value.otp
                      }"
                    >
                      <label class="control-label" for="investorpanotp"
                        >Enter OTP
                        <sup>*</sup>
                      </label>
                      <input
                        id="investorpanotp"
                        formControlName="otp"
                        maxlength="6"
                        type="password"
                        class="form-field form-control"
                        (paste)="onPaste($event)"
                        [class.is-invalid]="errors?.errorDescription"
                        (keyup)="onlyNumeric($event)"
                      />
                    </div>
                    <div *ngIf="successStatus" class="otpStatus">
                      {{ successStatus }}
                    </div>
                    <div *ngIf="errors" class="login-form-error text-danger">
                      {{ errors.errorDescription }}
                    </div>
                    <div class="login-user-resendotp pt+">
                      <a
                        href="javascript:;"
                        class="loginPANOTP"
                        id="loginPan-pan-form-otpresendId"
                        (click)="generateOTP(0)"
                        >Resend OTP</a
                      >
                    </div>
                  </div>
                  <div class="login-buttons">
                    <div class="float-left p-0 col-xs-12 col-md-12 col-lg-12">
                      <button
                        [disabled]="!investorPANOTPForm.valid"
                        type="submit"
                        class="btn btn-default next-btn"
                        style="width: 100%"
                      >
                        NEXT
                      </button>
                    </div>
                  </div>
                </form>
                <!-- INVESTOR BANK ACCOUNT form -->
                <form
                  style="display: none"
                  id="investorLoginBankAccFrm"
                  [formGroup]="investorBankAccFrm"
                  autocomplete="off"
                  novalidate="true"
                  (ngSubmit)="validateBankAcc()"
                >
                  <div class="text-center loginCatHead text-secondary">
                    <div
                      class="icon-fti_arrowRight login-arrowLeft"
                      id="investorLoginBankAcc"
                      (click)="backToPAN()"
                    ></div>
                    <div class="loginHead">LOGIN</div>
                    <div class="loginCate">(Investor)</div>
                  </div>
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.investorBankAccFrm.value.accountNo
                      }"
                    >
                      <label class="control-label" for="accountnumber"
                        >Bank Account Number
                        <sup>*</sup>
                      </label>
                      <input
                        id="accountnumber"
                        formControlName="accountNo"
                        maxlength="30"
                        [type]="passwordFieldType"
                        class="form-field form-control"
                        (paste)="onPaste($event)"
                        [class.is-invalid]="errors?.errorDescription"
                        (keyup)="onlyNumeric($event)"
                      />
                      <img
                        alt="icon-eye"
                        class="field-icon toggle-password"
                        [src]="
                          passwordFieldType === 'text'
                            ? './assets/images/icn_Show@1x.png'
                            : './assets/images/icn_Show.png'
                        "
                        (click)="changePassType()"
                      />
                    </div>
                    <div *ngIf="errors" class="login-form-error text-danger">
                      {{ errors.errorDescription }}
                    </div>
                    <div class="otpStatus">
                      Please enter any of the bank account number(s) registered
                      with us
                    </div>
                  </div>
                  <div
                    class="form-group checkbox new-check"
                    (click)="checkboxAction('userLoginRememberMeBankAcc')"
                  >
                    <input
                      type="checkbox"
                      id="userLoginRememberMeBankAcc"
                      class="form-field elEncodeCobrowse"
                      value="rememberMe"
                      style="margin-left: 0px"
                      checked=""
                      data-gcb-service-node="true"
                    />
                    <label for="userLoginRememberMeBankAcc">Remember Me</label>
                    <div class="tooltip-container">
                      <a
                        id="main-login-tooltip-bank-acc"
                        data-toggle="main-login-tooltip"
                        data-placement="bottom"
                        title=""
                        ><img
                          class="remember-info-icon"
                          alt="imageicon"
                          src="https://franklintempletonprod.widen.net/content/zv4trbj28o/jpeg/icn_info-hallow.jpeg"
                      /></a>
                      <div class="pan-tool-tip-container">
                        {{ "ftiLoginRegister.rememberMeTooltip" | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="login-buttons">
                    <div class="float-left p-0 col-xs-12 col-md-12 col-lg-12">
                      <button
                        [disabled]="!investorBankAccFrm.valid"
                        type="submit"
                        class="btn btn-default next-btn"
                        style="width: 100%"
                      >
                        LOGIN
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!-- Distributor Tab -->
          <div class="tab-pane" [ngClass]="{ active: distributorTab }">
            <div class="tab-content">
              <div class="tab-pane active">
                <form
                  id="loginFormDistributor"
                  [formGroup]="loginFormDistributor"
                  (ngSubmit)="loginUserID(true)"
                  autocomplete="off"
                  novalidate="true"
                  method="post"
                >
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.loginFormDistributor.value.userid
                      }"
                    >
                      <label class="control-label" for="distributorid"
                        >{{
                          "ftiLoginRegister.distributorUserIdInput" | translate
                        }}
                        <sup>*</sup>
                      </label>
                      <input
                        id="distributorid"
                        formControlName="userid"
                        maxlength="60"
                        type="text"
                        class="form-field form-control"
                        [class.is-invalid]="errors?.errorDescription"
                        (paste)="onPaste($event)"
                      />
                    </div>
                  </div>
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.loginFormDistributor.value.password
                      }"
                    >
                      <label class="control-label" for="distributorpassword"
                        >{{
                          "ftiLoginRegister.distributorPasswordInput"
                            | translate
                        }}
                        <sup>*</sup>
                      </label>
                      <input
                        id="distributorpassword"
                        formControlName="password"
                        maxlength="64"
                        type="password"
                        class="form-field form-control"
                        [class.is-invalid]="errors?.errorDescription"
                        (paste)="onPaste($event)"
                      />
                    </div>
                  </div>
                  <div *ngIf="errors" class="login-form-error text-danger pb-3">
                    {{ errors.errorDescription }}
                  </div>
                  <div class="login-buttons row">
                    <div
                      class="right-border float-left pr0 col-xs-6 col-md-6 col-lg-6"
                    >
                      <button
                        [disabled]="!loginFormDistributor.valid"
                        type="submit"
                        class="btn btn-default next-btn"
                      >
                        {{ "ftiLoginRegister.distributorNextBtn" | translate }}
                      </button>
                    </div>
                    <div class="register p-0 col-xs-6 col-md-6 col-lg-6">
                      <button
                        (click)="registerNow($event, '20')"
                        class="anchorBtn btn btn-gray register-btn"
                      >
                        {{
                          "ftiLoginRegister.distributorRegisterBtn" | translate
                        }}
                      </button>
                    </div>
                  </div>
                  <div class="mt-3 text-center">
                    <a
                      (click)="forgotUid('20')"
                      href="{{
                        'ftiLoginRegister.distributorForgotUserUrl' | translate
                      }}"
                      class="forgot-username"
                      >{{
                        "ftiLoginRegister.distributorForgotUserId" | translate
                      }}</a
                    >
                    <span class="forgot-or">OR</span>
                    <a
                      (click)="forgotPass('20')"
                      href="{{
                        'ftiLoginRegister.distributorForgotPasswordUrl'
                          | translate
                      }}"
                      class="forgot-password"
                      >{{
                        "ftiLoginRegister.distributorForgotPassword" | translate
                      }}</a
                    >
                  </div>
                </form>
                <form
                  style="display: none"
                  id="distOTPForm"
                  [formGroup]="distOTPForm"
                  autocomplete="off"
                  novalidate="true"
                  (ngSubmit)="validateOTP(1)"
                >
                  <!--validateOTP$(1): pass true if distributor-->
                  <div class="text-center loginCatHead text-secondary">
                    <div
                      class="icon-fti_arrowRight login-arrowLeft"
                      id="distributorLogin"
                      (click)="backToLogin(true)"
                    ></div>
                    <div class="loginHead">LOGIN</div>
                    <div class="loginCate">(Distributor)</div>
                  </div>
                  <div class="field-wrapper">
                    <div
                      class="form-group"
                      [ngClass]="{
                        focused: this.distOTPForm.value.otp
                      }"
                    >
                      <label class="control-label" for="distotp"
                        >Enter OTP
                        <sup>*</sup>
                      </label>
                      <input
                        id="distotp"
                        formControlName="otp"
                        maxlength="6"
                        type="password"
                        class="form-field form-control distributor"
                        (paste)="onPaste($event)"
                        [class.is-invalid]="errors?.errorDescription"
                        (keyup)="onlyNumeric($event)"
                      />
                    </div>
                    <div *ngIf="successStatus" class="otpStatus">
                      {{ successStatus }}
                    </div>
                    <div *ngIf="errors" class="login-form-error text-danger">
                      {{ errors.errorDescription }}
                    </div>
                    <!--generateOTP(true): pass true if distributor-->
                    <div *ngIf="successStatus" class="login-user-resendotp pt+">
                      <a
                        href="javascript:;"
                        class="loginPANOTP"
                        id="loginPan-dist-form-otpresendId"
                        (click)="generateOTP(1)"
                        >Resend OTP</a
                      >
                    </div>
                  </div>
                  <div
                    class="form-group checkbox new-check distributor"
                    (click)="checkboxAction('userLoginRememberMeDist')"
                  >
                    <input
                      type="checkbox"
                      id="userLoginRememberMeDist"
                      class="form-field elEncodeCobrowse"
                      value="rememberMe"
                      style="margin-left: 0px"
                      checked=""
                      data-gcb-service-node="true"
                    />
                    <label for="userLoginRememberMeDist">Remember Me</label>
                    <div class="tooltip-container">
                      <a
                        id="main-login-tooltip-dist"
                        data-toggle="main-login-tooltip"
                        data-placement="bottom"
                        title=""
                        ><img
                          class="remember-info-icon"
                          alt="imageRememberInfo"
                          src="https://franklintempletonprod.widen.net/content/zv4trbj28o/jpeg/icn_info-hallow.jpeg"
                      /></a>
                      <div class="pan-tool-tip-container">
                        {{ "ftiLoginRegister.rememberMeTooltip" | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="login-buttons">
                    <div class="float-left p-0 col-xs-12 col-md-12 col-lg-12">
                      <button
                        [disabled]="!distOTPForm.valid"
                        type="submit"
                        class="btn btn-default next-btn"
                        style="width: 100%"
                      >
                        LOGIN
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
