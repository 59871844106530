<div class="articleList">
  <div class="container position-relative">
    <div class="row">
      <ng-container>
        <ng-container
          *ngIf="videoArray.length && params?.contentType === 'video list'"
        >
          <div
            class="col-sm-12 col-md-4 col-lg-3 main-grid-div"
            *ngFor="let videoItem of videoArray; index as i"
          >
            <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
              <ng-container
                *ngIf="page?.isPreview()"
                [brManageContentButton]="videoItem"
              ></ng-container>

              <div
                class="gridItem"
                title="{{ videoItem?.data?._source?.title }}"
              >
                <a
                  [routerLink]="page.getUrl(videoItem?.url)"
                  target="_self"
                  (click)="setAnalytics(videoItem?.data?._source?.title)"
                >
                  <img
                    src="{{ videoItem?.image }}"
                    [alt]="videoItem?.pageTitle"
                  />
                  <span
                    class="gridCaption green-bg"
                    *ngIf="!(videoItem?.pageTitle?.length > 35); else elseBlock"
                    >{{ videoItem?.pageTitle }}</span
                  >
                  <ng-template #elseBlock>
                    <span
                      class="gridCaption green-bg"
                      *ngIf="videoItem?.pageTitle?.length > 35"
                      >{{
                        videoItem?.pageTitle.toString()?.substring(0, 35)
                      }}...</span
                    >
                  </ng-template>
                  <span class="icon icon-fti_play"></span>
                  <span
                    class="time-stamp"
                    *ngIf="videoItem?.data?._source?.videoTimeStamp"
                    >{{ videoItem?.data?._source?.videoTimeStamp }}</span
                  >
                </a>
              </div>
            </div>
          </div>
          <div *ngIf="showButtonVideo" class="text-center">
            <button
              class="btn loadMore"
              value="Show More"
              aria-label="Show More"
              (click)="loadMoreVideo()"
            >
              Show More
            </button>
          </div>
        </ng-container>
        <ng-container
          *ngIf="pages?.count && params?.contentType === 'article list'"
        >
          <div
            class="col-sm-12 col-md-4 col-lg-3 main-grid-div"
            *ngFor="let articleItem of articleArray; index as i"
          >
            <!-- <ng-container *ngIf="i < recordsPerPageArticle"> -->
            <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
              <ng-container
                *ngIf="page.isPreview()"
                [brManageContentButton]="articleItem"
              ></ng-container>

              <div class="gridItem">
                <ng-container *ngIf="!articleItem?.pdfUrl">
                  <a
                    [routerLink]="page.getUrl(articleItem?.url)"
                    target="_self"
                  >
                    <ng-container *ngIf="articleItem?.image">
                      <img
                        src="{{ articleItem?.image }}"
                        alt="{{ articleItem?.data?._source?.pageTitle }}"
                        title="{{ articleItem?.data?._source?.pageTitle }}"
                      />
                    </ng-container>
                    <ng-container *ngIf="!articleItem?.image">
                      <img src="{{ defaultArticleBannerImage }}" alt="Image" />
                    </ng-container>

                    <span
                      class="gridTitle blue-bg"
                      *ngIf="
                        !(articleItem?.data?._source?.pageTitle?.length > 35);
                        else elseBlock
                      "
                      >{{ articleItem?.data?._source?.pageTitle }}</span
                    >
                    <ng-template #elseBlock>
                      <span
                        class="gridTitle blue-bg"
                        *ngIf="
                          articleItem?.data?._source?.pageTitle?.length > 35
                        "
                        >{{
                          articleItem?.data?._source?.pageTitle
                            .toString()
                            ?.substring(0, 35)
                        }}...</span
                      >
                    </ng-template>
                  </a>
                </ng-container>
                <ng-container *ngIf="articleItem?.pdfUrl">
                  <a
                    [href]="articleItem?.data?._source?.pdfURL"
                    target="_blank"
                  >
                    <ng-container *ngIf="articleItem?.image">
                      <img
                        src="{{ articleItem?.image }}"
                        alt="{{ articleItem?.data?._source?.title }}"
                        title="{{ articleItem?.data?._source?.title }}"
                      />
                    </ng-container>
                    <ng-container *ngIf="!articleItem?.image">
                      <img src="{{ defaultArticleBannerImage }}" alt="Image" />
                    </ng-container>
                    <span class="fti-pdf"></span>
                    <span
                      class="gridTitle blue-bg"
                      *ngIf="
                        !(articleItem?.data?._source?.title?.length > 35);
                        else elseBlock
                      "
                      >{{ articleItem?.data?._source?.title }}</span
                    >
                    <ng-template #elseBlock>
                      <span
                        class="gridTitle blue-bg"
                        *ngIf="articleItem?.data?._source?.title?.length > 35"
                        >{{
                          articleItem?.data?._source?.title
                            .toString()
                            ?.substring(0, 35)
                        }}...</span
                      >
                    </ng-template>
                  </a>
                </ng-container>
              </div>
            </div>
            <!-- </ng-container> -->
          </div>
          <div *ngIf="showButtonArticle" class="text-center">
            <button
              class="btn loadMore"
              value="Show More"
              aria-label="Show More"
              (click)="loadMoreArticle()"
            >
              Show More
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
