import { Inject, Injectable } from '@angular/core';
import { Logger } from '@utils/logger';
import { AppInsightsLoggerOutput } from '@utils/logging/app-insights-logger-output';
import { ConsoleLoggerOutput } from '@utils/logging/console-logger-output';
import { EnvConfigService } from './env-config.service';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { FavoritesConfig, SegmentId } from '@types';
import { Brand } from '../types/brand.enum';
import { Configuration, PageModel } from '@bloomreach/spa-sdk';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DataDogLoggerOutput } from '@utils/logging/datadog-logger-output';
import { DatadogService } from './datadog.service';

const logger = Logger.getLogger('AppStateService');

declare var appInitChannelConfig; // appInitChannelConfig is defined in index.html

export interface ApiUrls {
  cmsBaseUrl?: string;
  spaBaseUrl: string;
  origin: string;
  endpoint?: string;
  apiVersion?: string;
}

/**
 * TODO refactor later
 * Temporary solution to cater for application state until we decide what we really want to do
 */
@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  channel = 'ft-core';
  apiUrls: ApiUrls;
  preview: boolean;
  token: string;
  auth: string;
  sharedCookieDomain: string;
  private authState: Observable<any>;
  private isGateway: boolean;
  private debugPageModel = false;

  constructor(
    private envConfig: EnvConfigService,
    @Inject(DOCUMENT) private documentRef: Document,
    @Inject(WINDOW) readonly windowRef: Window,
    private httpClient: HttpClient,
    private datadogService: DatadogService
  ) {
    const params = new URLSearchParams(this.windowRef.location.search);

    this.token = params.get('token');
    this.auth = envConfig.getEnvConfig()?.auth;
    this.debugPageModel = params.get('debug') === 'pagemodel';

    // appInitConfig is a global variable set in the channel specific index html during the build process
    this.channel = appInitChannelConfig
      ? appInitChannelConfig.channelId
      : this.channel;
    this.sharedCookieDomain = appInitChannelConfig?.sharedCookieDomain;
    // this.initializeLogging();
  }

  // TODO: temp hacky way to work out if on home page
  isHomePage(): boolean {
    const spaBaseUrl: string = this.getApiUrls().spaBaseUrl;
    const path: string = this.windowRef.location.pathname.replace(
      spaBaseUrl,
      ''
    );
    return ['/', ''].includes(path);
  }

  /**
   * returns the Brand theme for this site e.g. 'clearbridge' or 'cpreif'
   * Defaults to Frk brand theme if not set
   */
  getBrand(): Brand {
    return appInitChannelConfig.brand || Brand.FRK;
  }

  /**
   * if set, enables personalisation banner on site
   * The url points to the gateway homepage content
   */
  getGatewayUrl(): string {
    logger.debug('getGatewayUrl()', appInitChannelConfig);
    return appInitChannelConfig.gatewayUrl || null;
  }

  /**
   * setter isGateway value
   * @param isGateway - boolean param is user on gateway
   */
  public setIsGateway(isGateway: boolean): void {
    this.isGateway = isGateway;
  }

  /**
   * getter isGateway value
   */
  public getIsGateway(): boolean {
    return this.isGateway;
  }

  /**
   * returns the default segment for this site
   */
  getDefaultSegmentId(): SegmentId {
    logger.debug('getDefaultSegmentId()', appInitChannelConfig);
    return appInitChannelConfig.defaultSegment || null;
  }

  /**
   * returns true if site has sign-in i.e. US initially
   * if false, we don't need to bother making accounts calls etc
   */
  hasAuthentication(): boolean {
    logger.debug('hasAuthentication()', appInitChannelConfig);
    return Boolean(appInitChannelConfig.authentication);
  }

  getAuthenticationType(): string {
    return appInitChannelConfig.authentication;
  }

  /**
   * Get the homepage url for this channel
   */
  getHomePageUrl(): string {
    logger.debug(this);
    return `${this.getApiUrls().cmsBaseUrl}`;
  }

  /**
   * Rewrites absolute home page url to relative
   * @param url - string to check
   */
  relativeHomeURL(url: string): string {
    // NGC-3177 - according to tracking requirement we need replace absolute URL to the domain to relative URL to / in logo link
    const absoluteDomainRegexp: RegExp = /^(http|https):\/\/([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9.]))+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]+(?:[:0-9]+)?(\/)?([a-z0-9_-]+)?(\/)?$/g;
    if (absoluteDomainRegexp.test(url)) {
      const relativeUrl: string = url.replace(absoluteDomainRegexp, '$3$4$5');
      return relativeUrl ? relativeUrl : '/';
    }
    return url;
  }

  getChannel(): string {
    return this.channel;
  }

  getBaseResourceApiUrl(): string {
    return `${this.getApiUrls().cmsBaseUrl}/resourceapi/`;
  }

  getToken(): string {
    return this.token;
  }

  /*initializeLogging() {
    Logger.addConfiguration({
      defaultOutputs: [
        new AppInsightsLoggerOutput(this.envConfig, 'India'),
        new ConsoleLoggerOutput(),
      ],
    });
  }*/

  initializeLogging() {
    this.datadogService.initDataDog(
      this.envConfig,
      this,
      this.windowRef.location
    );
    if (this.envConfig.getEnvConfig().datadog.logsEnabled) {
      Logger.addConfiguration({
        defaultOutputs: Logger.getConfiguration().defaultOutputs.concat([
          new DataDogLoggerOutput(this.datadogService),
        ]),
      });
    }
  }

  getCookieApiUrl(): string {
    return this.envConfig.getEnvConfig().cookieApiUrl;
  }

  getPdsDefaultEnv(): string {
    return this.envConfig.getEnvConfig().defaultPds;
  }

  getPdsApiUrl(env: string): string {
    return this.envConfig.getEnvConfig()[`${env}PdsApiUrl`];
  }

  getLiteratureApiUrl(): string {
    return this.envConfig.getEnvConfig().literatureApiUrl;
  }

  getLiteratureDownloadFinraUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadFinraUrl;
  }

  getLiteratureDownloadUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadUrl;
  }

  getLiteratureBaseApiUrl(): string {
    return this.envConfig.getEnvConfig().literatureBaseApiUrl;
  }

  getLiteratureOrderHistoryListUrl(): string {
    return this.envConfig.getEnvConfig().literatureOrderHistoryList;
  }

  getRecentOrderHistoryListUrl(): string {
    return this.envConfig.getEnvConfig().literatureRecentOrderHistoryList;
  }

  getSearchAPIUrl(): string {
    return this.envConfig.getEnvConfig().searchUrl;
  }

  getSearchStatusAPIUrl(): string {
    return this.envConfig.getEnvConfig().searchStatusUrl;
  }

  getInsightsAPIUrl(): string {
    return this.envConfig.getEnvConfig().insightsUrl;
  }

  getEnvironment(): string {
    return this.envConfig.getEnvConfig()?.environment;
  }

  getSearchEnvironment(): string {
    return this.envConfig.getEnvConfig().searchEnvironment || '';
  }

  getCommentaryContentUrl(): string {
    return this.envConfig.getEnvConfig().commentaryContentUrl;
  }

  getFundCommentaryKey(): string {
    return this.envConfig.getEnvConfig().fundCommentaryKey;
  }

  getftiTrasactlogin(): string {
    return this.envConfig.getEnvConfig()?.ftiTrasactlogin;
  }

  getftingGuestUrl(): string {
    return (
      this.envConfig.getEnvConfig().ftiAccountsUrl +
      this.envConfig.getEnvConfig().ftingGuestUrl
    );
  }

  getftiftitransactPaperLessKyc(): string {
    return this.envConfig.getEnvConfig().ftitransactPaperLessKyc;
  }

  getftitransactPaperLessinvest(): string {
    return this.envConfig.getEnvConfig().ftitransactPaperLessinvest;
  }

  getOrderHistoryAPIMUrl(): string {
    return this.envConfig.getEnvConfig().orderHistoryAPIMUrl;
  }

  getOrderHistoryDetailAPIMUrl(): string {
    return this.envConfig.getEnvConfig().orderHistoryDetailAPIMUrl;
  }

  getSubmitOrderAPIMUrl(): string {
    return this.envConfig.getEnvConfig().submitOrderAPIMUrl;
  }

  getRecentOrderAPIMUrl(): string {
    return this.envConfig.getEnvConfig().recentOrderAPIMUrl;
  }

  getLiteratureAuthAPIAPIMKey(): string {
    return this.envConfig.getEnvConfig().literatureAuthAPIAPIMKey;
  }

  getLiteratureAPIMKey(): string {
    return this.envConfig.getEnvConfig().literatureAPIMKey;
  }

  getLiteratureBaseAPIMUrl(): string {
    return this.envConfig.getEnvConfig().LiteratureBaseAPIMUrl;
  }

  getLiteratureEmailDocsAPIMUrl(): string {
    return this.envConfig.getEnvConfig().LiteratureEmailDocsAPIMUrl;
  }

  getLiteratureDownloadZipAPIMUrl(): string {
    return this.envConfig.getEnvConfig().LiteratureDownloadZipAPIMUrl;
  }

  getuseAPIM(): boolean {
    return this.envConfig.getEnvConfig().useLiteratureAPIMFlow;
  }

  getGermanCalculatorOnetimeInvestmentApiUrl(): string {
    return this.envConfig.getEnvConfig()
      .germanCalculatorOnetimeInvestmentApiUrl;
  }

  getGermanCalculatorSavingPlanApiUrl(): string {
    return this.envConfig.getEnvConfig().germanCalculatorSavingPlanApiUrl;
  }

  getGermanCalculatorWithdrawlPlanApiUrl(): string {
    return this.envConfig.getEnvConfig().germanCalculatorWithdrawlPlanApiUrl;
  }

  toGetFavoriteFundApiUrl(): string {
    return this.envConfig.getEnvConfig().toGetFavoriteFundApiUrl;
  }

  toSetFavoriteFundApiUrl(): string {
    return this.envConfig.getEnvConfig().toSetFavoriteFundApiUrl;
  }

  /**
   * method to get the Accounts API
   */
  getAccountsApiUrl(): string {
    if (this.getChannel() === 'en-in') {
      return (
        this.envConfig.getEnvConfig().ftiApiDomain +
        this.envConfig.getEnvConfig().ftiMaintenanceValidation
      );
    } else {
      logger.error(`${this.getChannel()} does not have accounts`);
      return null;
    }
  }

  getMyFundsLocale(): string {
    return appInitChannelConfig.myFundsLocale;
  }

  getLiteratureImageUrl(): string {
    return this.envConfig.getEnvConfig().literatureImageUrl;
  }

  getLiteratureDownloadBaseUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadBaseUrl;
  }

  getLiteratureDownloadZipUrl(): string {
    return this.envConfig.getEnvConfig().literatureDownloadZipUrl;
  }

  /**
   * Load Api urls from App Config Service
   */
  getApiUrls(): ApiUrls {
    if (!this.apiUrls) {
      const envConfig = this.envConfig.getEnvConfig();
      if (envConfig) {
        const cmsPort =
          envConfig.apiPort !== undefined &&
          envConfig.apiPort !== 80 &&
          envConfig.apiPort !== 443
            ? ':' + envConfig.apiPort
            : '';
        const origin = `${envConfig.apiScheme}://${envConfig.apiUrl}${cmsPort}`;

        // allows us to pull base urls from channel or environment config if they have been set
        let spaBaseUrl =
          (appInitChannelConfig ? appInitChannelConfig.spabaseurl : false) ||
          envConfig.spabaseurl ||
          '';

        let cmsBaseUrl =
          (appInitChannelConfig ? appInitChannelConfig.cmsbaseurl : false) ||
          envConfig.cmsBaseUrl ||
          `${this.documentRef.location.origin}${spaBaseUrl}`;

        if (envConfig.singleHost) {
          spaBaseUrl = cmsBaseUrl = `/site/${this.getChannel()}`;
        }

        this.apiUrls = {
          spaBaseUrl,
          // cmsBaseUrl,
          origin,
          // remove cmsBaseUrl above and uncomment lines below to switch to v1.0 resourceApi
          apiVersion: '1.0',
          endpoint: cmsBaseUrl + '/resourceapi',
        };
      }
    }
    return this.apiUrls;
  }

  getBrConfiguration(): Configuration {
    return {
      ...this.getApiUrls(),
      httpClient: (
        ...[{ data: body, headers, method, url }]: Parameters<
          Configuration['httpClient']
        >
      ) => {
        return this.httpClient
          .request<PageModel>(method, url, {
            body,
            headers: headers as Record<string, string | string[]>,
            responseType: 'json',
          })
          .pipe(map((data) => ({ data })))
          .toPromise();
      },
    };
  }

  getEnvConfig() {
    return this.envConfig.getEnvConfig();
  }

  /**
   * method to get the Shared Cookie Domain
   */
  getCookieDomain(): string {
    return this.sharedCookieDomain;
  }

  /**
   * returns country local for spa, for example /en-lu
   */
  getSpaBaseUrl(): string {
    return this.apiUrls.spaBaseUrl;
  }

  getWidenUrl(): string {
    return this.envConfig.getEnvConfig().widenUrl;
  }

  getWidenProxyUrl(): string {
    return this.envConfig.getEnvConfig().widenProxyUrl;
  }

  getFavoritesConfig(): FavoritesConfig {
    const hasFavorites = appInitChannelConfig?.hasFavorites || false;
    const toSetFavoriteFundApiUrl = this.envConfig.getEnvConfig()
      .toSetFavoriteFundApiUrl;
    const toGetFavoriteFundApiUrl = this.envConfig.getEnvConfig()
      .toGetFavoriteFundApiUrl;
    const useLocalStorageFavoriteFunds = this.envConfig.getEnvConfig()
      .useLocalStorageFavoriteFunds;
    return {
      hasFavorites,
      loggedInOnly: appInitChannelConfig?.favouriteLoggedInOnly || false,
      useLocalStorage:
        (hasFavorites &&
          toSetFavoriteFundApiUrl &&
          toGetFavoriteFundApiUrl === undefined) ||
        useLocalStorageFavoriteFunds,
    };
  }

  // FT India Site Urls
  getPerformanceCummulativeUrl(): string {
    return (
      this.envConfig.getEnvConfig().ftiApiDomain +
      this.envConfig.getEnvConfig().perfCummulativeUrl
    );
  }

  getftiAccountsUrl(): string {
    return this.envConfig.getEnvConfig()?.ftiAccountsUrl;
  }

  getFTSearchUrl() {
    return (
      this.envConfig.getEnvConfig().baseUrl +
      this.envConfig.getEnvConfig().ftiSearchUrl
    );
  }

  getAutoCompleteUrl() {
    return (
      this.envConfig.getEnvConfig().baseUrl +
      this.envConfig.getEnvConfig().autoCompleteUrl
    );
  }

  getFtiAccountsValidateUrl() {
    return (
      this.envConfig.getEnvConfig().ftiAccountsUrl +
      this.envConfig.getEnvConfig().ftiAccountsValidate
    );
  }

  getFTIArticleListUrl() {
    return (
      this.envConfig.getEnvConfig().baseUrl +
      this.envConfig.getEnvConfig().ftiArticleList
    );
  }

  getngGuestAccountsUrl() {
    return this.envConfig.getEnvConfig().ngGuestAccountsUrl;
  }

  getBuildVersion(): string {
    return appInitChannelConfig?.buildVersion || '1.0';
  }

  isDebugPageModel(): boolean {
    return this.debugPageModel;
  }

  loadStyle(styleName: string, id: string) {
    const head = this.documentRef.getElementsByTagName('head')[0];

    const styleLink = this.documentRef.getElementById(id) as HTMLLinkElement;
    if (styleLink) {
      styleLink.href = styleName;
    } else {
      const style = this.documentRef.createElement('link');
      style.id = id;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
}
