import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';
import { BrPageComponent } from '@bloomreach/ng-sdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ft-layout-video-inner',
  templateUrl: './layout-video-inner.component.html',
  styleUrls: ['./layout-video-inner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutVideoInnerComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  @ViewChild('htmlContainer') container;

  constructor() {}

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};

    return document && this.page?.getContent<Document>(document);
  }

  get data() {
    return this.document?.getData<DocumentData>();
  }

  ngOnInit(): void {}
}
