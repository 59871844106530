import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { CommonService } from '@services/common.service';
import { HTMLDOMElement } from 'highcharts';
import { TranslateService } from '@components/shared/translate/translate.service';
import { AnalyticsService } from '@services/analytics.service';
import { WINDOW } from '@ng-web-apis/common';
import { Location } from '@angular/common';
import { Logger } from '@utils/logger';
import { AppStateService } from '@services/app-state.service';
import { InvestNowService } from '@services/invest-now.service';
import { take } from 'rxjs/operators';
import { PPSSFundDataService } from '@components/products/services/ppss-fund-data.service';
import { DropdownItem, TabName } from '@types';
import { ProductTypeParameter } from '@components/products/services/graphql-fund-data.service';
import SipCalcFundListQuery from './sip-calc-fund-list.graphql';
import { SiteConfigService } from '@services/site-config.service';
import { Router } from '@angular/router';

const logger = Logger.getLogger('SipCalculatorComponent');

@Component({
  selector: 'ft-sip-calculator',
  templateUrl: './sip-calculator.component.html',
  styleUrls: ['./sip-calculator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SipCalculatorComponent implements OnInit, AfterViewInit {
  enabled = true;
  value = 20;
  valueinvest = 500;
  valueinvestrate: any = 12;
  valuegoal: any = 500000;
  rateOfReturn: any = '12%';
  yearsValue: any = 20;
  investAmount: any = 500;
  goalAmount: any = 500000;
  radioValue: any = 'invest';
  returnValue: any;
  investedAmount: any;
  goodemi: boolean;
  totalValue: any;
  urlPrefix = '';
  len: any;
  @Input() component!: BrComponent;
  @Input() page: Page;
  canIntValue = true;
  canGoalValue = false;
  monAmtValue = false;
  sipIntValue = true;
  labelRadioValue1: any;
  labelRadioValue2: any;
  labelSlider1: any;
  labelSlider2: any;
  labelyears: any;
  labelReturn: any;
  calculatorDisclaimer: any;
  disclaimerLabel: any;
  labelMontlySIP: any;
  labelInvested: any;
  labelSipButton: any;
  labelResultant: any;
  popOver = false;
  commonLabels: any;
  sipTitle: any;
  investSIPLink: any;
  fundList: DropdownItem[] = [];

  constructor(
    private commonService: CommonService,
    private translateService: TranslateService,
    private investNowService: InvestNowService,
    private appStateService: AppStateService,
    private analyticsService: AnalyticsService,
    private ppssFundDataService: PPSSFundDataService,
    private siteConfigService: SiteConfigService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    @Inject(WINDOW) readonly windowRef: Window,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.radioValue = 'invest';
    this.rateOfReturn = '12%';
    this.calculateSip();
    this.commonLabels = this.commonService.getLabels()?.ftiCommon;
    logger.debug('common labels::::', this.commonService.getLabels());
    this.urlPrefix = this.appStateService.getftiAccountsUrl() + '/';
    this.sipTitle = this.translateService.instant('ftiCommon.sipTitle');
    this.labelRadioValue1 = this.translateService.instant(
      'ftiCommon.labelRadioValue1'
    );
    this.labelRadioValue2 = this.translateService.instant(
      'ftiCommon.labelRadioValue2'
    );
    this.labelSlider1 = this.translateService.instant('ftiCommon.labelSlider1');
    this.labelSlider2 = this.translateService.instant('ftiCommon.labelSlider2');
    this.labelyears = this.translateService.instant('ftiCommon.labelyears');
    this.labelReturn = this.translateService.instant('ftiCommon.labelReturn');
    this.calculatorDisclaimer = this.translateService.instant(
      'ftiCommon.calculatorDisclaimer'
    );
    this.disclaimerLabel = this.translateService.instant(
      'ftiCommon.disclaimerLabel'
    );
    this.labelMontlySIP = this.translateService.instant(
      'ftiCommon.labelMontlySIP'
    );
    this.labelInvested = this.translateService.instant(
      'ftiCommon.labelInvested'
    );
    this.labelResultant = this.translateService.instant(
      'ftiCommon.labelResultant'
    );
    this.labelSipButton = this.translateService.instant(
      'ftiCommon.labelSipButton'
    );
    this.investSIPLink = this.translateService.instant(
      'ftiCommon.investSIPLink'
    );
    this.goodemi =
      this.location.path().indexOf('sipislikeagoodemi') > 0 ? true : false;

    // Fetch the fund list.
    this.ppssFundDataService
      .fetchData(SipCalcFundListQuery, {
        productType: ProductTypeParameter.MUTUAL_FUNDS,
      })
      ?.subscribe((fundListingData) => {
        this.fundList = fundListingData?.products
          ?.sort((a, b) => a.fundName.localeCompare(b.fundName))
          ?.map((ppssData) => {
            return {
              label: ppssData.fundName,
              value: this.siteConfigService.getFundLink(
                ppssData.fundId,
                TabName.PERFORMANCE,
                ppssData.fundName
              ),
            };
          });

        this.cdr.detectChanges();
      });
  }

  /**
   * Redirect the url to fund performance page as per fund selected.
   * @param selectedOption option selected in dropdown
   */
  handleFundRedirect(selectedOption: DropdownItem): void {
    this.router.navigateByUrl(selectedOption.value);
  }

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }
  ngAfterViewInit() {}
  get content() {
    return this.document?.getData();
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberwithDotOnly(id, event) {
    const values = (document.getElementById(id) as HTMLInputElement).value;

    const charCode = event.which ? event.which : event.keyCode;
    if (charCode === 46) {
      if (values.indexOf('.') === -1) {
        return true;
      } else {
        return false;
      }
    } else if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode !== 46
    ) {
      return false;
    }
    const reg = /[0-9]*[.]{1}[0-9]{2}/i;
    if (values.match(reg)) {
      return false;
    } else {
      return true;
    }
    return true;
  }
  numberOnlyPercent(id, event): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    const values = (document.getElementById(id) as HTMLInputElement).value;
    if (charCode === 46) {
      if (values.indexOf('.') === -1) {
        return true;
      } else {
        return false;
      }
    } else if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode !== 37 &&
      charCode !== 46
    ) {
      return false;
    }
    return true;
  }
  hideShowInvest(element: any) {
    this.radioValue = element;
    this.calculateSip();
    if (element === 'invest') {
      this.canIntValue = true;
      this.canGoalValue = false;
      this.monAmtValue = false;
      this.sipIntValue = true;
    } else {
      this.canIntValue = false;
      this.canGoalValue = true;
      this.monAmtValue = true;
      this.sipIntValue = false;
    }
    this.setAnalytics();
  }
  /**
   * Sets Analytics for SIP calculator page
   * @param radioBtnSelected radio button selected
   */
  setAnalytics(isSas?: boolean) {
    const invest = 'invest';
    const wantToInvest = 'I Want to Invest';
    const knowMyGoal = 'I Know MY Goal';
    let event =
      this.radioValue === invest ? 'Event wenttoinvest' : 'Event knowmygoal';
    if (isSas) {
      event =
        this.radioValue === invest
          ? 'Event  saswanttoinvest'
          : 'Event sasknowmygoal';
    }
    const label = this.radioValue === invest ? wantToInvest : knowMyGoal;
    this.analyticsService.trackEvent({
      event,
      category: 'SIP-Calculator',
      action: isSas ? 'Start A SIP' : 'Select Option',
      label,
    });
  }

  /**
   * Called on click of start a sip button click
   */
  startASIP() {
    this.setAnalytics(true);
    this.investNowService
      .getInvestNowLink$()
      .pipe(take(1))
      .subscribe((url) => this.windowRef.open(url, '_blank'));
  }

  calculateSip() {
    logger.debug('Radiovalue');
    logger.debug(this.radioValue);

    if (this.radioValue === 'invest') {
      logger.debug('invest');

      const ratereturn = this.rateOfReturn.split('%');
      if (ratereturn.length === 0) {
        this.rateOfReturn = this.rateOfReturn;
      } else {
        this.rateOfReturn = ratereturn[0];
      }
      this.returnValue = this.formatNumber(
        Math.round(
          this.investAmount *
            ((Math.pow(1 + this.rateOfReturn / 100 / 12, this.yearsValue * 12) -
              1) /
              (this.rateOfReturn / 100 / 12)) *
            (1 + this.rateOfReturn / 100 / 12)
        )
      );

      const tenture = this.yearsValue * 12;

      this.investedAmount = this.formatNumber(
        Math.round(tenture * this.investAmount)
      );
    } else {
      logger.debug('goal');
      const amount = this.goalAmount;
      const year = this.yearsValue;
      const ratereturn = this.rateOfReturn.split('%');
      if (ratereturn.length === 0) {
        this.rateOfReturn = this.rateOfReturn;
      } else {
        this.rateOfReturn = ratereturn[0];
      }
      const returns = this.rateOfReturn;
      this.returnValue = this.formatNumber(
        Math.round(
          (amount * (returns / 100 / 12)) /
            (Math.pow(1 + returns / 100 / 12, year * 12) - 1)
        )
      );
      this.investedAmount = 0;
    }
    this.totalValue = this.returnValue;
    this.rateOfReturn = this.rateOfReturn + '%';
  }
  investBlurChange(element: any) {
    if (this.investAmount < 500) {
      this.investAmount = 500;
    } else {
      this.investAmount = this.investAmount;
    }
    if (this.investAmount > 500000) {
      this.investAmount = 500000;
    } else {
      this.investAmount = this.investAmount;
    }
    this.valueinvest = this.investAmount;
    this.calculateSip();
  }
  investSliderChange(element: any) {
    logger.debug('Elements');
    logger.debug(element);
    this.investAmount = element.newValue;
    if (this.investAmount < 500) {
      this.investAmount = 500;
    } else {
      this.investAmount = this.investAmount;
    }
    this.calculateSip();
  }
  investInterestChange(element: any) {
    const rateReturn = this.rateOfReturn.split('%');
    logger.debug('rateReturn');
    logger.debug(rateReturn);
    if (rateReturn.length === 0) {
      if (this.rateOfReturn < 2) {
        this.rateOfReturn = '2%';
      } else {
        this.rateOfReturn = this.rateOfReturn + '%';
      }
      if (this.rateOfReturn > 13) {
        this.rateOfReturn = '13%';
      } else {
        this.rateOfReturn = this.rateOfReturn + '%';
      }
    } else {
      if (rateReturn[0] < 2) {
        this.rateOfReturn = '2%';
      } else if (rateReturn[0] > 13) {
        this.rateOfReturn = '13%';
      } else {
        this.rateOfReturn = rateReturn[0] + '%';
      }
    }

    const ratereturn = this.rateOfReturn;
    const returnval = ratereturn.split('%');
    if (returnval.length === 0) {
      this.valueinvestrate = this.rateOfReturn;
    } else {
      this.valueinvestrate = returnval[0];
    }

    this.calculateSip();
  }
  investSliderInterestChange(element: any) {
    this.rateOfReturn = element.newValue;
    if (this.rateOfReturn < 2) {
      this.rateOfReturn = '2%';
    } else {
      this.rateOfReturn = this.rateOfReturn + '%';
    }

    this.calculateSip();
  }
  investYearChange(element: any) {
    if (this.yearsValue < 1) {
      this.yearsValue = 1;
    } else {
      this.yearsValue = this.yearsValue;
    }
    if (this.yearsValue > 30) {
      this.yearsValue = 30;
    } else {
      this.yearsValue = this.yearsValue;
    }
    this.value = this.yearsValue;
    this.calculateSip();
  }
  investSliderYearChange(element: any) {
    this.yearsValue = element.newValue;
    if (this.yearsValue < 1) {
      this.yearsValue = 1;
    } else {
      this.yearsValue = this.yearsValue;
    }
    this.calculateSip();
  }
  formatNumber = (num) => {
    const numberval = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const numberval1 = num.toLocaleString('en-IN');

    return numberval1;
  };
  get Dislaimer() {
    let Disclaimervalue;
    if (this.content?.mirror) {
      Disclaimervalue = this.page?.getContent(this.content.mirror)?.getData()
        ?.content;
    } else {
      Disclaimervalue = '';
    }
    return Disclaimervalue;
  }
  investGoalChange(element: any) {
    if (this.goalAmount < 500000) {
      this.goalAmount = 500000;
    } else {
      this.goalAmount = this.goalAmount;
    }
    if (this.goalAmount > 50000000) {
      this.goalAmount = 50000000;
    } else {
      this.goalAmount = this.goalAmount;
    }
    this.valuegoal = this.goalAmount;
    this.calculateSip();
  }
  investGoalSliderChange(element: any) {
    this.goalAmount = element.newValue;
    if (this.goalAmount < 500000) {
      this.goalAmount = 500000;
    } else {
      this.goalAmount = this.goalAmount;
    }
    this.calculateSip();
  }
  goalChange(element: any) {
    this.goalAmount = element.newValue;
    this.calculateSip();
  }
  interestChange(element: any) {
    this.rateOfReturn = element.newValue;
    this.calculateSip();
  }
  yearsChange(element: any) {
    this.yearsValue = element.newValue;
    this.calculateSip();
  }
  showDisclaimer() {
    this.popOver = true;
  }
  closeDisclaimer() {
    this.popOver = false;
  }
  investChange(element: any) {
    this.investAmount = element.newValue;
    this.calculateSip();
  }
  displayNumber(element: any) {
    const nStr = Math.round(element).toString();
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    let z = 0;
    this.len = String(x1).length;
    const dividetwo = this.len / 2 - 1;
    let num = dividetwo;

    while (rgx.test(x1)) {
      if (z > 0) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      } else {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
        rgx = /(\d+)(\d{2})/;
      }
      z++;
      num--;
      if (num === 0) {
        break;
      }
    }
    return x1 + x2;
  }

  get isPreview() {
    return this.page.isPreview();
  }
}
