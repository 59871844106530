import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Component as BrComponent, Content, Page } from '@bloomreach/spa-sdk';
import { Constant } from '../../../ft-constants/constant';
import { CommonService } from '@services/common.service';
import { Subject } from 'rxjs';
import { AnalyticsService } from '@services/analytics.service';
import { StorageService } from '@services/storage.service';
import { TranslateService } from '@components/shared/translate/translate.service';
import { takeUntil } from 'rxjs/operators';
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import { Router } from '@angular/router';
import { getPagination } from '@utils/pagemodel-utils';

@Component({
  selector: 'ft-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
})
export class ArticleListComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page: Page;
  @Input() contentType: string;
  pagination: any;
  videoList: any = [];
  articleList: any = [];
  selectedTab = '';
  articleArray = [];
  videoArray = [];
  oneTabArray = [];
  articlePageName: string;
  videoPageName: string;
  oneTabPageName: string;
  componentDestroyed$: Subject<boolean> = new Subject();
  start = 0;
  end = 8;
  videoStart = 0;
  videoEnd = 8;
  oneTabStart = 0;
  oneTabEnd = 8;
  pages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  videoPages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  totalResults: number;
  videoTotalResults: number;
  tabTotalResults: number;

  showButtonVideo = false;
  totalRecordsVideo = 0;
  recordsPerPageVideo: number = Constant.recordsPerPage;
  nextRecordsCountVideo: number = Constant.recordsPerPage;

  showButtonArticle = false;
  totalRecordsArticle = 0;
  recordsPerPageArticle: number = Constant.recordsPerPage;
  nextRecordsCountArticle: number = Constant.recordsPerPage;
  isOneTabLayout: boolean;

  showOneTabButton = false;

  // Resource Bundle
  defaultArticleBannerImage: any;

  constructor(
    private commonService: CommonService,
    private searchService: FtsearchService,
    private analyticsService: AnalyticsService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.content();
    const routerUrl = this.router.url;
    let pageType;
    if (routerUrl.includes('index')) {
      pageType = routerUrl.split('/')[
        routerUrl.split('/').indexOf('index') - 1
      ];
    } else {
      pageType = routerUrl.split('/')[routerUrl.split('/').length - 1];
    }
    if (this.params?.contentType === 'video list') {
      this.getFTIVideoData(pageType, 0, 8);
    } else if (this.params?.contentType === 'article list') {
      this.getFTIArticleData(pageType, 0, 8);
    }

    // Get values from resource bundle
    this.defaultArticleBannerImage = this.translateService.instant(
      'ftiArticles.defaultArticleBannerImage'
    );
  }

  get params() {
    return this.component?.getParameters();
  }

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  content() {
    if (!this.pagination) {
      this.pagination = getPagination(this.page, this.component);
    }
    return this.pagination;
  }
  get isPreview() {
    return this.page?.isPreview();
  }

  getImageURL(data) {
    const imageURL = data ? JSON.parse(data)?.url : '';
    return imageURL;
  }

  getImage(data) {
    const imageURL = data ? JSON.parse(data)?.url : '';
    return imageURL;
  }

  getSeletecTab(title) {
    this.storageService.storeSelectedTab(this.selectedTab);
    this.setAnalytics(title, true);
  }

  /**
   * sets anlytics data on click of tiles of articles/videos
   * @param title title of the artcle/video
   */

  setAnalytics(title, fromArticle?: boolean): void {
    const viewArticle = 'View Article';
    const viewVideo = 'View video';
    this.analyticsService.trackEvent({
      event: 'Event InvestorEducation',
      category: 'Investor Education',
      action: fromArticle ? viewArticle : viewVideo,
      label: 'New to Investing | ' + title,
    });
  }

  getFTIArticleData(pageType, start, end) {
    if (start === 0) {
      this.pages.results = [];
    }
    this.end = end;
    this.articlePageName = pageType;
    const filterData = [
      { fieldName: 'documentType.exact', fieldValue: ['INDArticleDetails'] },
      { fieldName: 'pageType', fieldValue: [pageType] },
    ];
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), start, this.end)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.pages.results = data['results']?.response?.hits['hits'] || [];
        this.totalResults = data['results']?.response?.hits?.total?.value;
        this.pages.count = this.pages.results.length;
        this.pages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          this.articleArray.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            pdfUrl: article?._source?.pdfURL,
          });
        });
        if (this.totalResults <= 8 || start + 8 > this.totalResults) {
          this.showButtonArticle = false;
        } else {
          this.showButtonArticle = true;
        }
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }
  getFTIVideoData(pageType, start, end) {
    if (start === 0) {
      this.videoPages.results = [];
    }
    this.videoEnd = end;
    this.videoPageName = pageType;
    const filterData = [
      { fieldName: 'documentType.exact', fieldValue: ['INDVideoArticles'] },
      { fieldName: 'pageType', fieldValue: [pageType] },
    ];
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIArticleResults(JSON.stringify(filterData), start, this.videoEnd)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.videoPages.results = data['results']?.response?.hits['hits'] || [];
        this.videoTotalResults = data['results']?.response?.hits?.total?.value;
        this.videoPages.count = this.videoPages.results.length;
        this.videoPages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          const pageTitle = article?._source?.pageTitle
            ? article?._source?.pageTitle
            : article?._source?.title;
          this.videoArray.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            pageTitle: `${pageTitle}`,
          });
        });
        if (this.videoPages.results.length < 8) {
          this.showButtonVideo = false;
        } else {
          this.showButtonVideo = true;
        }
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }
  loadMoreArticle() {
    this.getFTIArticleData(this.articlePageName, this.articleArray.length, 8);
  }
  loadMoreVideo() {
    this.getFTIVideoData(this.videoPageName, this.videoArray.length, 8);
  }
  ngOnDestroy() {}
}
