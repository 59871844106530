import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnChanges,
  ChangeDetectorRef,
  AfterViewInit,
  Inject,
} from '@angular/core';
import {
  Component as BrComponent,
  Page,
  Document,
  Reference,
  initialize,
} from '@bloomreach/spa-sdk';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppStateService } from '@services/app-state.service';
import { TranslateService } from '@components/shared/translate/translate.service';
import { StorageService } from '@services/storage.service';
import { Logger } from '@utils/logger';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { InvestNowService } from '@services/invest-now.service';
import { Constant } from 'src/app/ft-constants/constant';
import { CommonService } from '../../../services/common.service';
import { FundHeaderService } from '../../products/overview/services/fund-header.service';
import { WINDOW } from '@ng-web-apis/common';
import { Observable } from 'rxjs';
import { EnvConfigService } from '@services/env-config.service';
import { Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { getMenus, getPageContent, getPageUrl } from '@utils/pagemodel-utils';
import get from 'lodash/get';

const logger = Logger.getLogger('ArticleDetailComponent');

@Component({
  selector: 'ft-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleDetailComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() component!: BrComponent;
  @Input() page: Page;
  nextToComeTitle = '';
  hashNavigation: any;
  nextToComeURL = '';
  paginationItems: any = [];
  selectedChapter = '';
  articleChapters = [];
  isChapterOne = false;
  showChapter = false;
  backLink = '';
  tab = 0;
  nonDragData: object = {
    showLearning: 'false',
    showEmail: 'true',
    showSocial: 'true',
  };
  shareUrlRefresh: string;
  hostName: string;
  topReadItems: any = [];
  topRead1: any = {};
  topRead2: any = {};
  enquiryDetails: any = {};
  emailDetails: any = {};
  topReads: any = [];
  author: any;
  // Resource Bundle
  newToInvestBackLink: any;
  askForDistributor: any;
  speakTous: any;
  investNowLink$: Observable<string>;
  investNow: any;
  topReadOne: any;
  topReadTwo: any;
  defaultArticleBannerImage: any;
  chapterLabel: any;
  authorContent: any;
  authorImage: any;
  authorTitle: any;
  ftIndiaBrandName: any;
  currentPageUrl: any;
  textTitle: any;
  constructor(
    @Inject(WINDOW) private _window: Window,
    private http: HttpClient,
    private router: Router,
    private location: Location,
    private changeDetector: ChangeDetectorRef,
    private appState: AppStateService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private investNowService: InvestNowService,
    private sanitizeHtml: DomSanitizer,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private metaService: Meta,
    public pagetitle: Title,
    private envConfig: EnvConfigService
  ) {}

  ngOnInit(): void {
    this.updateAddThis();
    // Get current URL
    this.selectedChapter = this.location.path();
    this.hashNavigation = this.route.snapshot.fragment;
    // Get all values from footer section
    const arrURL = [];
    const footerMenus = getMenus(
      this.page,
      this.page.getComponent('footer-content', 'footer')
    );

    // Level 1 i.e Funds & Solutions
    footerMenus?.siteMenuItems[0]?.childMenuItems.forEach((result) => {
      if (result?.childMenuItems?.length > 0) {
        // Level 2 i.e Family Solutions
        result?.childMenuItems.forEach((index) => {
          if (index?.childMenuItems?.length > 0) {
            // Level 3 i.e Child's Education
            index?.childMenuItems.forEach((val) => {
              if (val?.childMenuItems?.length === 0) {
                const menuName = val?.name.toLowerCase();
                arrURL.push(menuName.replace(/ /g, '-'));
              }
            });
          } else {
            const menuName = index?.name.toLowerCase();
            arrURL.push(menuName.replace(/ /g, '-'));
          }
        });
      }
    });

    // Get values from resource bundle
    const currentURL = this.location.path();
    this.currentPageUrl =
      this.envConfig.getEnvConfig()?.baseUrl + currentURL.substring(1);
    const splitURL = this.location.path().split('/');
    if (splitURL.indexOf('your-childs-future') === 2) {
      arrURL.push('your-childs-future');
    }
    const prefix = 'ftiBacklinks.';
    let resourceBundleKey = '';
    for (const i of arrURL) {
      if (currentURL.indexOf(i) !== -1) {
        const link = i.split('-').join('');
        resourceBundleKey = prefix + link + 'backlink';
      }
    }
    this.backLink = this.translateService.instant(resourceBundleKey);

    this.investNow = this.translateService.instant('ftiCommon.investNow');
    this.speakTous = this.translateService.instant('ftiCommon.speakTous');
    this.chapterLabel = this.translateService.instant('ftiCommon.chapterLabel');
    this.askForDistributor = this.translateService.instant(
      'ftiCommon.askForDistributor'
    );
    this.ftIndiaBrandName = this.translateService.instant(
      'ftiCommon.ftIndiaBrandName'
    );
    this.investNowLink$ = this.investNowService.getInvestNowLink$();

    this.defaultArticleBannerImage = this.translateService.instant(
      'ftiCommon.defaultArticleBannerImage'
    );
    this.topReadOne =
      '/' + this.translateService.instant('ftiCommon.topReadOne');
    this.topReadTwo =
      '/' + this.translateService.instant('ftiCommon.topReadTwo');

    // Call functions
    this.callTopReads();
    this.author = getPageContent(this.page, this.content?.author);
    if (this.author?.contentBlocks) {
      this.authorContent = this.author?.contentBlocks[0]?.content?.content?.value;
      this.authorImage = this.getImageURL(
        this.author?.contentBlocks[0]?.widenAssetCompound?.widenDocument
      );
      this.authorTitle = this.author?.contentBlocks[0]?.title;
    }
    this.setMetaData();
  }

  get document() {
    const { document } = this.component?.getModels<DocumentModels>() || {};
    const content = document && this.page?.getContent<Document>(document);
    return content;
  }

  get content() {
    const data = this.document?.getData();
    this.textTitle = data?.title;
    this.nextPost(data);
    this.getArticleChapters(data);
    this.checkShowChapter(data);
    return data;
  }
  setMetaData() {
    const pageData = this.content?.ftindiaPageMetaData;
    this.metaService.updateTag(
      {
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      "property='twitter:card'"
    );
    this.metaService.updateTag(
      {
        property: 'twitter:title',
        content: pageData?.metaTitle
          ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
          : '',
      },
      "property='twitter:title'"
    );
    this.metaService.updateTag(
      {
        property: 'og:title',
        content: pageData?.metaTitle
          ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
          : '',
      },
      "property='og:title'"
    );
    const PageTitle = pageData?.metaTitle
      ? pageData?.metaTitle + ' ' + this.ftIndiaBrandName
      : '';
    this.pagetitle.setTitle(PageTitle);
    this.metaService.updateTag(
      {
        property: 'twitter:image',
        content: '',
      },
      "property='twitter:image'"
    );
    this.metaService.updateTag(
      {
        property: 'twitter:description',
        content: pageData?.metaDescription ? pageData?.metaDescription : '',
      },
      "property='twitter:description'"
    );
  }
  getArticleChapters(data) {
    const firstValue = data?.articleChapterBlock[0].articleData;
    if (firstValue) {
      this.articleChapters = data?.articleChapterBlock;
    } else {
      if (this.location.path().indexOf('chapter1') > 0) {
        this.isChapterOne = true;
        this.articleChapters = data?.articleChapterBlock;
      } else {
        const arr = [];
        data?.articleChapterBlock.forEach((value, key) => {
          if (key !== 0 && value.articleData) {
            arr.push(value);
          }
        });
        this.articleChapters = arr;
      }
    }
  }

  checkShowChapter(data) {
    if (this.articleChapters.length) {
      let flag = true;
      data?.articleChapterBlock.forEach((value, key) => {
        if (key > 0 && !value.articleData) {
          flag = false;
        }
      });
      if (!flag) {
        this.showChapter = false;
      } else {
        this.showChapter = true;
      }
    } else {
      this.showChapter = false;
    }
  }

  getBackLinkUrl(backLink) {
    this.storageService.storeSelectedTab('tab-area-2');

    if (
      this.location.path().indexOf('women-and-investing') > 0 ||
      this.location.path().indexOf('planning-for-retirement') > 0 ||
      this.location.path().indexOf('your-childs-future') > 0 ||
      this.location.path().indexOf('smart-tax-planning') > 0
    ) {
      this.tab = 1;
      this.navigateTab('tab-area-1');
    }
    return '/' + backLink;
  }

  getPageUrl(ref?: Reference) {
    return getPageUrl(this.page, ref);
  }

  nextPost(data) {
    if (data?.upcomingArticle) {
      const nextToComeData = getPageContent(this.page, data?.upcomingArticle);
      this.nextToComeTitle = nextToComeData?.displayName;
      this.nextToComeURL = nextToComeData?.links?.site?.href;
    }
  }

  onChange(url) {
    this.router.navigateByUrl(url);
  }

  htmlCode(data) {
    return this.sanitizeHtml.bypassSecurityTrustHtml(data);
  }

  getSelectedChapter(selectedChapter, data) {
    let selChapter = '';

    if (this.location.path().indexOf('chapter1/') > 0) {
      if (this.selectedChapter === this.getPageUrl()) {
        selChapter = this.chapterLabel + ' 1';
      }
    } else {
      this.articleChapters.forEach((value, key) => {
        if (selectedChapter === this.getPageUrl(value.articleData)) {
          selChapter = this.chapterLabel + ' ' + (key + 1);
        }
      });
    }
    return selChapter;
  }

  getImageURL(data) {
    let imageURL;

    // TEST CASES FAILURE FIX: Added undefined check before processing data as json.
    if (data) {
      imageURL = JSON.parse(data)?.url;
    }

    return imageURL;
  }

  ngAfterViewInit() {
    const nameElement = document.querySelector('.loadLittle');
    if (this.hashNavigation !== undefined) {
      this.smoothScroll(this.hashNavigation);
    }
  }
  smoothScroll(target) {
    let stra2 = document.querySelector(`#${target}`);
    stra2 = stra2 === undefined ? document.querySelector(`#${target}`) : stra2;
    const doScrolling = (elementY, duration) => {
      const startingY = window.pageYOffset;
      const diff = elementY - startingY;
      let start;

      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) {
          start = timestamp;
        }
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        const percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent + 30);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    };

    if (stra2 != null) {
      doScrolling(stra2.getBoundingClientRect().top + window.scrollY - 40, 10);
      /* setTimeout(() => {
        stra2.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 100);*/
    }
  }
  ngOnChanges() {}

  public loadScript(url: string) {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  updateAddThis() {
    // tslint:disable-next-line
    const addThis = this._window['addthis'];
    if (addThis) {
      addThis.url = this._window.location.href;
      addThis.title = this._window.document.title;
      addThis.toolbox('.addthis_inline_share_toolbox');
    }
    if (addThis?.layers?.refresh) {
      addThis.layers.refresh();
    }
  }

  callTopReads() {
    const topReadOneUrl = this.topReadOne;
    this.getTopReads(topReadOneUrl, this.topReadOne, 1);

    const topReadTwoUrl = this.topReadTwo;
    this.getTopReads(topReadTwoUrl, this.topReadTwo, 2);
  }

  navigateTab(selectedTab) {
    this.storageService.storeSelectedTab(selectedTab);
    this.commonService.changeData(selectedTab);
  }

  getTopReads(url, relativeUrl, counter) {
    initialize({
      ...this.appState.getBrConfiguration(),
      path: url,
    }).then((page: Page) => {
      const doc = page.getContent(
        page.getComponent('main-content', 'IND-article-details').getModels()
          .document
      );
      const data = doc.getData();
      const resp = {
        ...data,
        url: doc.getUrl(),
      };
      if (counter === 1) {
        this.topRead1 = resp;
      } else if (counter === 2) {
        this.topRead2 = resp;
      }
      this.changeDetector.markForCheck();
    });

    // const headers = new HttpHeaders({
    //   Accept: 'application/json',
    //   'Content-Type': 'application/json',
    // });
    // const options = { headers };

    // this.http.get(url, options).subscribe(
    //   (response: any) => {
    //     const docs = response.content;
    //     const match = Object.values(docs).find(
    //       (doc: any) => doc.links?.site?.href === relativeUrl
    //     );
    //     if (counter === 1) {
    //       this.topRead1 = match;
    //     } else if (counter === 2) {
    //       this.topRead2 = match;
    //     }
    //     this.changeDetector.markForCheck();
    //   },
    //   (error) => {
    //     logger.error('error >>>> ', error);
    //   }
    // );
  }
}
