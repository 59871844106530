import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ft-article-pages',
  templateUrl: './article-pages.component.html',
  styleUrls: ['./article-pages.component.scss'],
})
export class ArticlePagesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
