<div class="search-auto-suggestion-list">
  <div>
    <h3>
      Funds<a
        *ngIf="fundItems && fundItems?.length > 0"
        (click)="viewAllFunds()"
        >View All</a
      >
    </h3>
    <ul
      class="funds-suggestion-list"
      *ngIf="fundItems && fundItems?.length > 0"
    >
      <li
        *ngFor="let fundItem of fundItems"
        class="funds-suggestion-list-item siteSearch-suggestion-list fund-search-record"
        style="display: list-item"
        [routerLink]="fundItem?.titleLink"
        [innerHTML]="fundItem?.title"
      ></li>
    </ul>
    <ng-template *ngIf="fundItems && fundItems?.length === 0">
      <ul>
        <li>
          {{ "ftiCommon.search-no-results-desc" | translate }}
          <span class="keyEntered">{{ searchText }}</span>
        </li>
      </ul>
    </ng-template>
  </div>
  <h3>Search Results<a (click)="viewAllGeneral()">View All</a></h3>
  <ul>
    <li>
      Search results for <span class="keyEntered">{{ searchText }}</span> in
      Page results
    </li>
  </ul>
</div>
