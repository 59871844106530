import { ProductData, SiteConfig } from '../../../types';

export interface PPSSState {
  data: PPSSStateData;
  config: PPSSStateConfig;
  siteConfig: SiteConfig;
  lastAction?: string;
  hasLoadedFullFeed?: boolean;
  isSiteIntl?: boolean;
}

export interface PPSSStateData {
  tableRows: ProductData[];
  filteredTableRows: ProductData[];
  asOfDates: AsOfDates;
}

interface AsOfDates {
  navAsOfDate: string;
  performanceAsOfDateMonthly: string;
  performanceAsOfDateMonthlyStd: string;
  performanceAsOfDateQuarterly: string;
  performanceAsOfDateQuarterlyStd: string;
  stdYieldThirtyDayAsofDate?: string;
  ratingsAsOfDate?: string;
}

export interface PPSSStateConfig {
  currentTable?: string;
  tabs: Tabs;
  performanceViewOptions: PerformanceViewOption[];
  performanceView: PerformanceViewOption;
  periodEnd: string;
  calcType: string;
  suppressDetails?: SuppressDetails;
  identifierList?: string[];
  performanceStatusCaveat?: string;
  hideNavChange?: boolean;
  hideFactsheet?: boolean;
}

export interface Tabs {
  showPerformance: boolean;
  showYield: boolean;
  showRatings: boolean;
  showFundIdentifiers: boolean;
}

export interface PerformanceViewOption {
  name: string;
  labelKey: string;
  headerLabelKey: string;
  periodEnds?: string[];
  isDefault?: boolean;
}

export interface SuppressDetails {
  showMDashForUnder1Year?: boolean;
  showMDashForUnder6Months?: boolean;
}

export interface CalculationTypes {
  name: string;
  options: CalculationTypeOption[];
  groupHeaderLabel: string;
}

export interface CalculationTypeOption {
  key: string;
  labelKey: string;
  ariaControl: string;
  isActive: boolean;
}

export interface SwitchProps {
  labelKey: string;
  labelOffKey: string;
  labelOnKey: string;
  state: SwitchState;
}

export enum SwitchState {
  OFF = 'off',
  ON = 'on',
}

export interface ToggleProps {
  labelKey: CalcType;
  options: TogglePropsOption[];
  selected: string;
  applicableValues?: Record<string, string[]>;
  type: 'switch' | 'toggle';
}

export interface TogglePropsOption {
  label: string;
  value: string;
}

export enum CalcType {
  SALES_CHARGES = 'products.sales-charges-toggle',
  NAV_MARKET_PRICE = 'products.nav-market-price-toggle',
  PURE_GROSS_NET = 'products.pure-gross-net-toggle',
}

export interface ToggleOption {
  active: boolean;
  text: string;
  value?: string;
  disabled?: boolean;
}

export interface DropdownOption {
  value?: string;
  label?: string;
  link?: string;
  disabled?: boolean;
}

export interface RatingsCellData {
  ratingsPath: string;
  hybridPath: string;
  numberOfFundsPath?: string;
}

export interface FundNameData {
  fundName: string;
  suffix: string;
  highlightSuffix: boolean;
}
