import {
  AfterViewChecked,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Page, Reference } from '@bloomreach/spa-sdk';

import { Component as BrComponent } from '@bloomreach/spa-sdk/';
import { Link } from '@types';
import { Logger } from '@utils/logger';
import get from 'lodash/get';

/**
 * Logger
 */
const logger = Logger.getLogger('AbstractBaseComponent');

/**
 * Base component
 *
 * This is an abstract component used to read common parameters
 *
 */
@Component({
  template: ``,
})
export class AbstractBaseComponent
  implements OnInit, AfterViewChecked, OnChanges {
  /**
   * The Bloomreach component
   */
  @Input() component!: BrComponent;

  /**
   * The Bloomreach page
   */
  @Input() page!: Page;

  /**
   * Default component padding
   */
  defaultPadding = '0';

  /**
   * Default margin bottom
   */
  defaultMarginBottom = 'var(--space-xxl)';

  /**
   * Last closed Dialog
   */
  closedDialog;

  /**
   * Link clicked from inside the component
   */
  linkClicked: string;

  /**
   * Breadcrumbs
   */
  breadcrumbs: {
    title: string;
    url: string;
    routerLink: any[];
  }[];

  /**
   * Label marker for preview
   */
  labelIcon: string;

  /**
   * BR Menu Item
   */
  menuItem;

  /**
   * Component Name
   */
  componentName: string;

  /**
   * Temporary placeholder
   */
  localImagePlaceholder = '/assets/icons/placeholder.svg';

  breadcrumbItems;
  /**
   * Constructor
   */
  constructor() {}

  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges): void {}

  /**
   * After view checked
   */
  ngAfterViewChecked(): void {}

  /**
   * On initialisation
   */
  ngOnInit() {
    this.componentName = this.component?.getName();
  }

  /**
   * Get container from page model
   */
  get container() {
    const container = this.component.getParameters().container;
    return container || 'container'; // default to 8 column width
  }

  /**
   * Get document from page model
   */
  get document() {
    const { document } = this.component.getModels<DocumentModels>();
    return document && this.page.getContent(document);
  }

  /**
   * Get component background theme
   */
  get background() {
    return this.component.getParameters().background;
  }

  /**
   * Return theme
   */
  get theme() {
    return (
      this.component.getParameters().background ||
      this.component.getParameters().theme
    );
  }

  /**
   * Has Meta (TODO: Needs Fixed)
   * TODO: Improve to use utility classes
   */
  get hasMeta() {
    let ret = this.document.getMeta();
    if (!ret && this.document) {
      this.document.getData().meta = {};
      ret = this.document.getMeta();
    }
    return ret;
  }

  /**
   * Get margin bottom from component parameters
   */
  get marginBottom() {
    return this.component.getParameters().marginBottom;
  }

  /**
   * Remove margin bottom from component parameters
   */
  get removeMarginBottom() {
    return this.component.getParameters().removeMarginBottom;
  }

  /**
   * Get padding from component parameters
   */
  get padding() {
    return this.component.getParameters().padding;
  }

  /**
   * parse widen document json and read url
   */
  getWidenDocumentUrl(widenDocument: any): string {
    let url;
    if (widenDocument) {
      const widenJson = JSON.parse(widenDocument);
      url = widenJson.url;
    }
    return url;
  }

  /**
   * Show breadcrumbs toggle
   */
  get showBreadcrumb() {
    return this.component.getModels()?.showBreadcrumb;
  }

  /**
   * Creates breadcrumbItems for EDS component
   * @param breadcrumbs - Array of breadcrumbs
   */
  createBreadcrumbItems(breadcrumbs) {
    const breadcrumbItems = [];

    breadcrumbs?.forEach((breadcrumb) => {
      breadcrumbItems.push({
        content: breadcrumb.title,
        href: breadcrumb.url,
        routerLink: [breadcrumb.url],
      });
    });
    return breadcrumbItems;
  }

  /**
   * Read menu from data model
   */
  get menu() {
    this.menuItem = this.component.getModels<MenuModels>()?.menu;
    return this.menuItem;
  }

  /**
   * Read the link compound and return the url
   */
  getCTALink(linkCompound: Link, cmsURL?: string): string {
    if (linkCompound) {
      if (linkCompound.document) {
        return this.page.getContent(linkCompound.document)?.getUrl();
      } else if (linkCompound.widenAssetCompound) {
        return this.getWidenDocumentUrl(
          linkCompound.widenAssetCompound?.widenDocument
        );
      } else if (linkCompound.url) {
        const regex = new RegExp('(^http:|^https:|^mailto:)');
        if (regex.test(linkCompound.url)) {
          return linkCompound.url;
        } else {
          if (cmsURL) {
            if (linkCompound.url.startsWith('/')) {
              return `${cmsURL}${linkCompound.url}`;
            } else {
              return `${cmsURL}/${linkCompound.url}`;
            }
          }
        }
      }
    }
  }
}
