<div
  [ngClass]="{ 'has-edit-button': page?.isPreview() }"
  class="video-article-main"
>
  <ng-container
    *ngIf="page?.isPreview()"
    [brManageContentButton]="document"
    documentTemplateQuery="new-ftindia-document"
    folderTemplateQuery="new-ftindia-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
  <ng-container *ngIf="document">
    <div class="row tabcontent">
      <ng-container *ngIf="layout === largeTabsLayout">
        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
          <div class="wrapper" [innerHTML]="content?.html?.value"></div>
        </div>
      </ng-container>

      <ng-container *ngIf="layout === 'video-inner'">
        <div class="row title-row">
          <div class="col-sm-12 col-md-12">
            <a
              *ngIf="!backUrl"
              aria-label="Back"
              class="icon icon-fti_leftArrow float-start videogo-back"
              [routerLink]="getBackLinkUrl(backLink)"
            >
            </a>
            <a
              aria-label="Back"
              class="icon icon-fti_leftArrow float-start videogo-back"
              [routerLink]="getBackLinkUrl(videoMarketInsights)"
              *ngIf="newToInvestBackLink && backUrl"
            >
            </a>
            <h1 class="video-main-title">
              {{ content?.title }}
            </h1>
          </div>
        </div>
        <div class="row lang-row">
          <div [ngClass]="isScrom ? ' ' : 'tab-section'">
            <div *ngIf="!isScrom" class="choose-language">
              <a href="javascript:void(0)" class="truncate">{{
                chooseLanguage
              }}</a>
            </div>
            <div [ngClass]="isScrom ? 'scrom-tl ' : 'tabs-links'">
              <ul
                ngbNav
                #nav="ngbNav"
                class="fti-tabbable parentTabs nav-tabs grid-row"
                [ngClass]="{ showDropDown: showDropdown }"
                [destroyOnHide]="false"
              >
                <li
                  [ngbNavItem]="i"
                  *ngFor="
                    let videoInfo of content.videoArticleInfo;
                    let i = index
                  "
                >
                  <div *ngIf="!isScrom">
                    <a ngbNavLink (click)="showDrop()">
                      {{ videoInfo?.language }}
                    </a>
                  </div>

                  <ng-template ngbNavContent>
                    <div *ngIf="isScrom; else iframeYT" class="Scrom-Vid">
                      <a
                        target="_blank"
                        *ngIf="content?.bannerImage?.widenDocument"
                      >
                        <picture>
                          <source
                            media="(min-width: 593px)"
                            [srcset]="
                              getThumbnailImage(
                                content?.bannerImage?.widenDocument
                              )
                            "
                          />
                          <source
                            media="(min-width: 425px)"
                            [srcset]="
                              getThumbnailImage(
                                content?.bannerImage?.widenDocument
                              )
                            "
                          />
                          <img
                            class="scrom-banner"
                            alt="{{ content?.title }}"
                            title="{{ content?.title }}"
                            [src]="
                              getThumbnailImage(
                                content?.bannerImage?.widenDocument
                              )
                            "
                          />
                        </picture>
                      </a>
                      <span class="scrom-s"></span><br />
                      <a
                        class="btn panel-orange-btn txt scrom-video-launch"
                        (click)="scromOpenModal(content3)"
                        >Launch Video</a
                      >
                    </div>
                    <ng-template #iframeYT>
                      <div class="fti-video-player">
                        <iframe
                          width="1028px"
                          height="332px"
                          allowfullscreen="allowfullscreen"
                          id="vid_frame"
                          frameborder="0"
                          [src]="iframeUrl(videoInfo?.videoid)"
                          loading="lazy"
                          __genesys_cobrowse_iframe_id__="3"
                        ></iframe>
                      </div>
                    </ng-template>
                    <div class="row">
                      <div class="col-md-5 col-sm-12 col-lg-5 learning-part">
                        <ul class="share-options share-options-position">
                          <li
                            *ngIf="content?.testYourLearningDocument !== null"
                          >
                            <a
                              href="javascript:void(0)"
                              data-bs-target="#mailbackModal"
                              data-bs-toggle="modal"
                              (click)="Quizopen(content2)"
                              ><span
                                class="icon icon-fti_testYourLearnings"
                              ></span>
                              {{ testYourLearningLabel }}</a
                            >
                            <ng-template #content2 let-modal>
                              <div
                                class="fti-modal quizModal step1"
                                id="showLearningStage1Modal"
                                *ngIf="step1"
                              >
                                <form
                                  [formGroup]="quizForm"
                                  name="quizForm"
                                  (ngSubmit)="validateQuiz()"
                                >
                                  <div class="modal-body clearfix">
                                    <a
                                      type="button"
                                      class="fill-circle-close"
                                      (click)="modal.dismiss('Cross click')"
                                    >
                                      <span
                                        class="icon-fti_close"
                                        aria-hidden="true"
                                      ></span>
                                    </a>
                                    <h1 class="test-your-learning-quiz">
                                      {{ testYourLearningLabel }}
                                    </h1>
                                    <h2>
                                      {{ quizDocument?.displayName }}
                                    </h2>
                                    <div class="col-12 test-learn-qa">
                                      <p class="test-lrn-error">
                                        {{ formErrorMessage }}
                                      </p>
                                      <div
                                        class="row form-gruop test-qa-block"
                                        *ngFor="
                                          let quiz of quizDocument?.quizQuestions
                                        "
                                      >
                                        <div class="question col-12">
                                          <span
                                            class="fti-fill-circle col-2"
                                            style="
                                              position: relative;
                                              top: 0.5px;
                                            "
                                            >{{ quiz?.long }}</span
                                          ><span class="col-10">{{
                                            quiz?.question
                                          }}</span>
                                        </div>
                                        <div class="choose-option col-12">
                                          <div
                                            class="radio radio-inline"
                                            *ngFor="
                                              let testOption of quiz?.options;
                                              let i = index
                                            "
                                          >
                                            <input
                                              type="radio"
                                              class="quizOption"
                                              [value]="testOption"
                                              *ngIf="testOption"
                                              name="question-{{ quiz?.long }}"
                                              id="question-{{ quiz?.long }}-{{
                                                i
                                              }}"
                                            /><label
                                              for="question-{{ quiz?.long }}-{{
                                                i
                                              }}"
                                              *ngIf="testOption"
                                              ><span class="radiobtn"></span
                                              ><span class="quiz-ans">{{
                                                testOption
                                              }}</span></label
                                            >
                                          </div>
                                        </div>
                                        <p
                                          id="quizerror{{ quiz?.long }}"
                                          class="test-lrn-error quizste1error quizerror{{
                                            quiz?.long
                                          }}"
                                        >
                                          {{ answerQuestionToProceed }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <input
                                      type="submit"
                                      id="submit-quiz"
                                      class="btn btn-default panel-orange-btn"
                                      value="SUBMIT"
                                      aria-label="submit"
                                    /><input
                                      type="reset"
                                      id="reset-quiz"
                                      class="btn btn-default panel-gray-btn"
                                      value="reset"
                                      (click)="resetQuiz()"
                                      aria-label="resetquiz"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div
                                class="fti-modal quizModal step2"
                                *ngIf="step2"
                              >
                                <div class="modal-body clearfix">
                                  <a
                                    type="button"
                                    class="fill-circle-close"
                                    (click)="modal.dismiss('Cross click')"
                                  >
                                    <span
                                      class="icon-fti_close"
                                      aria-hidden="true"
                                    ></span>
                                  </a>
                                  <h1 class="test-your-learning-quiz">
                                    {{ testYourLearningLabel }}
                                  </h1>
                                  <h2>
                                    {{ quizDocument?.displayName }}
                                  </h2>
                                  <p>
                                    {{ formSorryMessage }} '{{
                                      totalPercentage
                                    }}%.' {{ tryAgain }}
                                  </p>
                                </div>
                                <div class="modal-footer">
                                  <a
                                    href="javascript:void(0)"
                                    (click)="modal.dismiss('Cross click')"
                                    data-dismiss="modal"
                                    class="pull-left"
                                    >{{ clickToContinue }}</a
                                  >
                                </div>
                              </div>
                              <div
                                class="fti-modal quizModal step3"
                                *ngIf="step3"
                              >
                                <div class="modal-body clearfix">
                                  <a
                                    type="button"
                                    class="fill-circle-close"
                                    (click)="modal.dismiss('Cross click')"
                                  >
                                    <span
                                      class="icon-fti_close"
                                      aria-hidden="true"
                                    ></span>
                                  </a>
                                  <h1 class="test-your-learning-quiz">
                                    {{ testYourLearningLabel }}
                                  </h1>
                                  <h2>
                                    {{ quizDocument?.displayName }}
                                  </h2>
                                  <h6>{{ congratulationsLabel }}</h6>
                                  <p>
                                    {{ formSuccessMessage }} '{{
                                      totalPercentage
                                    }}%.' {{ eligibleForCertificate }}
                                  </p>
                                  <p class="test-learn-sub-heading">
                                    {{ downloadCertificate }}
                                  </p>
                                  <p
                                    class="test-learn-error step3error"
                                    *ngIf="step3error"
                                  >
                                    {{ step3errorvalue }}
                                  </p>
                                  <div class="col-12">
                                    <div class="row">
                                      <div
                                        class="form-group floatLabels"
                                        [ngClass]="{ focused: focusValueName }"
                                      >
                                        <div class="row download-row">
                                          <label
                                            class="control-label download-input w-auto"
                                            for="scoreCard-name"
                                            >{{ nameLabel }}<sup>*</sup></label
                                          >
                                          <input
                                            name="name"
                                            type="text"
                                            class="form-control"
                                            [(ngModel)]="quizUserName"
                                            required=""
                                            id="scoreCard-name"
                                            onpaste="return false"
                                            (focus)="labelMoveInName($event)"
                                            (focusout)="
                                              labelMoveOutName($event)
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <div class="clearfix form-submit">
                                    <input
                                      id="submitScoreCard"
                                      class="btn btn-default panel-orange-btn pull-left ms-2"
                                      type="button"
                                      value="DOWNLOAD YOUR CERTIFICATE"
                                      (click)="submitQuiz()"
                                      aria-label="submitquiz"
                                    />
                                  </div>
                                  <div
                                    class="clear"
                                    style="
                                      clear: both;
                                      height: 0.1em;
                                      width: 100%;
                                    "
                                  ></div>
                                  <a
                                    href="javascript:void(0)"
                                    (click)="modal.dismiss('Cross click')"
                                    data-dismiss="modal"
                                    class="pull-left"
                                    >{{ clickToContinue }}</a
                                  >
                                </div>
                              </div>
                              <div
                                class="fti-modal quizModal step4"
                                *ngIf="step4"
                              >
                                <div class="modal-body clearfix">
                                  <a
                                    type="button"
                                    class="fill-circle-close"
                                    (click)="modal.dismiss('Cross click')"
                                  >
                                    <span
                                      class="icon-fti_close"
                                      aria-hidden="true"
                                    ></span>
                                  </a>
                                  <h1 class="test-your-learning-quiz">
                                    {{ testYourLearningLabel }}
                                  </h1>
                                  <h2>
                                    {{ quizDocument.displayName }}
                                  </h2>
                                  <p>
                                    {{ startDownloadingLabel }}
                                  </p>
                                </div>
                                <div class="modal-footer">
                                  <a
                                    href="javascript:void(0)"
                                    (click)="modal.dismiss('Cross click')"
                                    data-dismiss="modal"
                                    class="pull-left"
                                    >{{ clickToContinue }}</a
                                  >
                                </div>
                              </div>
                            </ng-template>
                          </li>
                          <li class="fti-shareBtn">
                            <ft-social-media-icons
                              [currentPageUrl]="currentPageUrl"
                              [textTitle]="textTitle"
                            ></ft-social-media-icons>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="col-md-6 col-sm-12 col-xs-10 beginers-guide-buttons-video pull-right col-md-offset-1 ph0"
                      >
                        <a
                          href="#"
                          data-bs-target="#advisor"
                          data-bs-toggle="modal"
                        >
                          <span class="btn panel-orange-btn txt">
                            {{ askForDistributor }}
                          </span>
                        </a>
                        <a
                          href="#"
                          data-bs-target="#speaktous"
                          data-bs-toggle="modal"
                        >
                          <span class="btn panel-orange-btn txt">
                            {{ speakTous }}
                          </span>
                        </a>
                        <a
                          class="loadtransactNowPage"
                          [href]="investNowLink$ | async"
                        >
                          <span class="btn panel-orange-btn txt">
                            {{ investNow }}
                          </span>
                        </a>
                        <!--                        </ng-template>-->
                        <ng-template #content3 let-modal>
                          <div class="fti-modal">
                            <div class="modal-body scrom-modal-body clearfix">
                              <a
                                type="button"
                                class="fill-circle-close"
                                (click)="modal.dismiss('Cross click')"
                              >
                                <span
                                  class="icon-fti_close"
                                  aria-hidden="true"
                                ></span>
                              </a>
                              <h1 class="scrom-modal-title text-center">
                                {{ content?.title }}
                              </h1>
                              <div
                                class="scrom-main-div"
                                [innerHTML]="scromVid | safe: 'html'"
                              ></div>
                              <!-- <iframe
                                allowfullscreen="allowfullscreen"
                                id="vid_frame1"
                                [src]="scromUrl(videoInfo?.videoid)"
                              ></iframe>-->
                            </div>
                          </div>
                        </ng-template>
                      </div>

                      <div class="clearfix"></div>
                      <div class="div-border"></div>
                    </div>
                    <div class="clear" style="clear: both; height: 1em"></div>
                    <div class="main-content-part">
                      <div [innerHtml]="videoInfo.langContent.value"></div>
                    </div>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row video-content-row">
          <div class="col-lg-8 col-md-12 col-sm-12 col-xl-8 video-more-content">
            <div [ngbNavOutlet]="nav" class="mt-0"></div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 col-xl-4">
            <div class="fti-panel panel-group">
              <div class="panel panel-default fti-ifp-pan">
                <div class="panel-heading">
                  <h4 class="panel-title">{{ relatedVideoLabel }}</h4>
                </div>
                <div class="panel-body">
                  <div class="watch-learn">
                    <div
                      class="fti-mediagrid fti-newtoinvest"
                      data-gridcounts="7"
                    >
                      <div class="row">
                        <div
                          class="col-xl-12 col-sm-12 col-md-6 col-lg-12 related-padding"
                        >
                          <div class="mediaItem">
                            <a
                              *ngIf="
                                relatedVideo1?.links?.site?.type == 'internal'
                              "
                              [href]="relatedVideo1?.links?.site?.href"
                            >
                              <img
                                *ngIf="
                                  relatedVideo1?.widenAssetCompound
                                    ?.widenDocument
                                "
                                [src]="
                                  getThumbnailImage(
                                    relatedVideo1?.widenAssetCompound
                                      ?.widenDocument
                                  )
                                "
                                alt="Importance Of Financial Planning"
                              />

                              <span class="gridCaption green-bg">
                                {{ relatedVideo1?.title }}
                              </span>
                              <span class="icon icon-fti_play"></span>
                              <span class="timeStamp">
                                {{ relatedVideo1?.videoTimeStamp }}
                              </span>
                            </a>

                            <a
                              *ngIf="
                                relatedVideo1?.links?.site?.type == 'external'
                              "
                              [href]="relatedVideo1?.links?.site?.href"
                            >
                              <img
                                *ngIf="
                                  relatedVideo1?.widenAssetCompound
                                    ?.widenDocument
                                "
                                [src]="
                                  getThumbnailImage(
                                    relatedVideo1?.widenAssetCompound
                                      ?.widenDocument
                                  )
                                "
                                alt="Importance Of Financial Planning"
                              />
                              <span class="icon icon-fti_play"></span>
                              <span class="gridCaption green-bg">
                                {{ relatedVideo1?.title }}
                              </span>
                              <span class="timeStamp">
                                {{ relatedVideo1?.videoTimeStamp }}
                              </span>
                            </a>

                            <a
                              *ngIf="!relatedVideo1?.links?.site?.type"
                              [href]="relatedVideo1?.links?.site?.href"
                            >
                              <img
                                *ngIf="
                                  relatedVideo1?.widenAssetCompound
                                    ?.widenDocument
                                "
                                [src]="
                                  getThumbnailImage(
                                    relatedVideo1?.widenAssetCompound
                                      ?.widenDocument
                                  )
                                "
                                alt="Importance Of Financial Planning"
                              />
                              <span class="icon icon-fti_play"></span>
                              <span class="gridCaption green-bg">
                                {{ relatedVideo1?.title }}
                              </span>
                              <span class="timeStamp">
                                {{ relatedVideo1?.videoTimeStamp }}
                              </span>
                            </a>
                          </div>
                        </div>
                        <div
                          class="col-xl-12 col-sm-12 col-md-6 col-lg-12 related-padding"
                          *ngIf="
                            relatedVideo2?.widenAssetCompound?.widenDocument
                          "
                        >
                          <div class="mediaItem">
                            <a
                              *ngIf="
                                relatedVideo2?.links?.site?.type == 'internal'
                              "
                              [href]="relatedVideo2?.links?.site?.href"
                            >
                              <img
                                *ngIf="
                                  relatedVideo2?.widenAssetCompound
                                    ?.widenDocument
                                "
                                [src]="
                                  getThumbnailImage(
                                    relatedVideo2?.widenAssetCompound
                                      ?.widenDocument
                                  )
                                "
                                alt="Importance Of Financial Planning"
                              />
                              <span
                                class="gridCaption green-bg"
                                *ngIf="
                                  !(relatedVideo2?.title?.length > 35);
                                  else elseBlock
                                "
                              >
                                {{ relatedVideo2?.title }}
                              </span>
                              <ng-template #elseBlock>
                                <span
                                  class="gridCaption green-bg"
                                  *ngIf="relatedVideo2?.title?.length > 35"
                                  >{{
                                    relatedVideo2?.title
                                      .toString()
                                      ?.substring(0, 35)
                                  }}...</span
                                >
                              </ng-template>
                              <span class="icon icon-fti_play"></span>
                              <span class="timeStamp">
                                {{ relatedVideo2?.videoTimeStamp }}
                              </span>
                            </a>

                            <a
                              *ngIf="
                                relatedVideo2?.links?.site?.type == 'external'
                              "
                              [href]="relatedVideo2?.links?.site?.href"
                            >
                              <img
                                *ngIf="
                                  relatedVideo2?.widenAssetCompound
                                    ?.widenDocument
                                "
                                [src]="
                                  getThumbnailImage(
                                    relatedVideo2?.widenAssetCompound
                                      ?.widenDocument
                                  )
                                "
                                alt="Importance Of Financial Planning"
                              />
                              <span class="icon icon-fti_play"></span>
                              <span class="gridCaption green-bg">
                                {{ relatedVideo2?.title }}
                              </span>
                              <span class="timeStamp">
                                {{ relatedVideo2?.videoTimeStamp }}
                              </span>
                            </a>

                            <a
                              *ngIf="!relatedVideo1?.links?.site?.type"
                              [href]="relatedVideo1?.links?.site?.href"
                            >
                              <img
                                *ngIf="
                                  relatedVideo2?.widenAssetCompound
                                    ?.widenDocument
                                "
                                [src]="
                                  getThumbnailImage(
                                    relatedVideo2?.widenAssetCompound
                                      ?.widenDocument
                                  )
                                "
                                alt="Importance Of Financial Planning"
                              />
                              <span class="icon icon-fti_play"></span>
                              <span class="gridCaption green-bg">
                                {{ relatedVideo2?.title }}
                              </span>
                              <span class="timeStamp">
                                {{ relatedVideo2?.videoTimeStamp }}
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
