<div class="fundCaveat" *ngIf="contentType === 'IND-carousel'">
  <ft-ind-carousel [component]="component" [page]="page"></ft-ind-carousel>
</div>

<div class="fundCaveat" *ngIf="contentType === 'IND-link-list'">
  <ft-quick-link-list
    [component]="component"
    [page]="page"
  ></ft-quick-link-list>
</div>

<div class="contactUsDisplay" *ngIf="contentType === 'IND-contact-us'">
  <ft-contactus-display-grid
    [component]="component"
    [page]="page"
  ></ft-contactus-display-grid>
</div>

<div class="contactUsDisplay" *ngIf="contentType === 'IND-Address-Book'">
  <ft-address-book [component]="component" [page]="page"></ft-address-book>
</div>

<div class="contactUsDisplay" *ngIf="contentType === 'IND-new-investor-form'">
  <ft-new-investor-login
    [component]="component"
    [page]="page"
  ></ft-new-investor-login>
</div>

<div
  class="contactUsDisplay"
  *ngIf="contentType === 'IND-existing-investor-form'"
>
  <ft-existing-investor-login
    [component]="component"
    [page]="page"
  ></ft-existing-investor-login>
</div>

<div class="contactUsDisplay" *ngIf="contentType === 'IND-document-picker'">
  <ft-document-picker
    [component]="component"
    [page]="page"
  ></ft-document-picker>
</div>

<div class="contactUsDisplay" *ngIf="contentType === 'IND-display-list'">
  <ft-latest-commentaries [page]="page"></ft-latest-commentaries>
</div>

<div class="contactUsDisplay" *ngIf="contentType === 'IND-feedback-form'">
  <ft-feedback [component]="component" [page]="page"></ft-feedback>
</div>

<div class="contactUsDisplay" *ngIf="contentType === 'IND-nps-form'">
  <ft-nps [component]="component" [page]="page"></ft-nps>
</div>

<div class="contactUsDisplay" *ngIf="contentType === 'IND-interested-form'">
  <ft-interested></ft-interested>
</div>

<div class="blogList" *ngIf="contentType === 'IND-rich-text'">
  <ft-rich-text [component]="component" [page]="page"></ft-rich-text>
</div>
<div class="blogList" *ngIf="contentType === 'IND-accordion'">
  <ft-accordion [component]="component" [page]="page"></ft-accordion>
</div>
<div *ngIf="contentType === 'IND-carousel-RFB'">
  <ft-ind-carousel
    [component]="component"
    [page]="page"
    [contentType]="contentType"
  ></ft-ind-carousel>
</div>
