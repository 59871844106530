import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TwoColLeftWideCustomService } from './two-col-left-wide-custom.service';

@Component({
  selector: 'ft-two-col-left-wide-custom',
  templateUrl: './two-col-left-wide-custom.component.html',
  styleUrls: ['./two-col-left-wide-custom.component.scss'],
})
export class TwoColLeftWideCustomComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  hasLeft: boolean;
  constructor(private twoColLeftWideCustom: TwoColLeftWideCustomService) {
    this.subscription = twoColLeftWideCustom
      .getHasLeftColumnContent$()
      .subscribe((hasLeftColContent) => {
        this.hasLeft = hasLeftColContent;
      });
  }

  ngOnInit(): void {
    this.twoColLeftWideCustom.hasLeftColumnContent(false);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
