<div class="media-articles">
  <div class="container">
    <div class="col-lg-12 content-container">
      <div id="w1466595134104">
        <h1 class="media-header">Media</h1>
      </div>
      <div id="w1464272447080">
        <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
          <ng-container
            *ngIf="page?.isPreview()"
            [brManageContentButton]="document"
            documentTemplateQuery="new-ftindia-document"
            folderTemplateQuery="new-ftindia-folder"
            parameter="document"
            [relative]="false"
            root=""
          ></ng-container>

          <!-- Tabs -->
          <div class="fti-tabs">
            <div class="webView">
              <ul class="nav nav-tabs" *ngIf="tabs">
                <li
                  *ngFor="let tab of tabs; let i = index"
                  [class.active]="tab.type === activeTab"
                  (click)="onTabClick($event)"
                  [id]="tab.type"
                >
                  <a [href]="'#' + tab.type">{{ tab.text }}</a>
                </li>
                <li class="dropdownContainer">
                  <select
                    name="year"
                    id="year"
                    [(ngModel)]="selectedYear"
                    (change)="filterData($event, currentMediaType)"
                  >
                    <option
                      *ngFor="let year of yearsFilterDropdown; let i = index"
                      [value]="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </li>
              </ul>
              <select
                class="mobileView yearFilter"
                name="year"
                id="year"
                [(ngModel)]="selectedYear"
                (change)="filterData($event, currentMediaType)"
              >
                <option
                  *ngFor="let year of yearsFilterDropdown; let i = index"
                  [value]="year"
                >
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <!-- Tab Content -->
          <div class="tab-content">
            <div class="tab-pane nested active">
              <div class="row">
                <div class="col-xs-12 col-lg-7">
                  <!-- Article & Interviews Content -->
                  <div
                    class="articlesInterviews fti-mediagrid"
                    [hidden]="!(activeTab === 'PRESS-ARTICLE')"
                  >
                    <div class="articleContainer">
                      <ng-container
                        *ngFor="let row of filteredData; let i = index"
                      >
                        <div
                          class="col-xs-12 col-md-4 col-lg-6 media-col"
                          *ngIf="i < limitResults"
                        >
                          <div
                            [ngClass]="{ 'has-edit-button': page?.isPreview() }"
                          >
                            <ng-container
                              *ngIf="page?.isPreview()"
                              [brManageContentButton]="
                                page?.getContent(row?.ref)
                              "
                            ></ng-container>
                            <div class="mediaItem">
                              <a [href]="'download' + row.link" target="_blank">
                                <img
                                  src="assets/images/Press_Article_Default.png"
                                  alt="article"
                                />
                                <span class="dateStamp">{{
                                  row.longDate
                                }}</span>
                                <span class="gridCaption blue-bg">{{
                                  truncateText(row.title)
                                }}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div
                      class="text-center load-more-div"
                      *ngIf="limitResults < filteredData?.length"
                    >
                      <button
                        (click)="loadMoreResults($event)"
                        class="load-more-videos btn btn-default panel-orange-btn form-filter-btn"
                      >
                        Load More
                      </button>
                    </div>
                  </div>

                  <!-- Releases Content -->
                  <div
                    class="fti-dataTableWrapper"
                    [hidden]="!(activeTab === 'PRESS-RELEASE')"
                  >
                    <div class="bootstrap-table">
                      <table class="tablesorter table tablesorter-default">
                        <thead>
                          <tr class="tablesorter-headerRow">
                            <th
                              class="sortable"
                              (click)="sortTable($event, currentSort)"
                            >
                              Date
                            </th>
                            <th>Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class=""
                            *ngFor="let row of filteredData; let i = index"
                          >
                            <td>{{ row.shortDate }}</td>
                            <td>
                              <a
                                [href]="'download' + row.link"
                                target="_blank"
                                >{{ row.title }}</a
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- Media Contacts -->
                <div class="col-xs-12 col-lg-5" *ngIf="mediaContacts">
                  <div class="fti-panel panel-group dropCol-tablet">
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">{{ mediaContacts.heading }}</h4>
                      </div>
                      <div>
                        <div
                          class="panel-body pt0 pb0"
                          [innerHTML]="mediaContacts.markup"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
