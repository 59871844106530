import { Component, Input } from '@angular/core';

@Component({
  selector: 'ft-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent {
  @Input() toastMessage: string;
}
