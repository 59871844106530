import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private data = new BehaviorSubject<any>(null);
  currentData = this.data.asObservable();

  private city = new BehaviorSubject<any>(null);
  cityData = this.city.asObservable();
  labels: any;

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {}

  changeData(message: string) {
    this.data.next(message);
  }

  setCities(cities: string) {
    this.city.next(cities);
  }

  setLabels(models) {
    this.labels = models;
  }

  getLabels() {
    return this.labels;
  }

  isValidMobile(mobile) {
    if (mobile.length === 10 || mobile.length === 11 || mobile.length === 12) {
      return true;
    } else {
      return false;
    }
  }

  isValidEmail(email: string) {
    return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
      email
    );
  }
}
