<div class="container position-relative">
  <div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
    <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root=""
    >
    </ng-container>

    <div class="row">
      <div
        class="col-sm-12 col-xs-12 col-md-9"
        *ngFor="let item of content?.contentBlocks"
      >
        <div [innerHTML]="item?.content?.content?.value"></div>
      </div>
    </div>
  </div>
</div>
