import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Constant } from '../../../ft-constants/constant';
import { Logger } from '@utils/logger';
import { VocabularyCategories } from '@types';
import { FtsearchService } from '@components/shared/search/ftsearch.service';
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { BlogListCategory } from 'src/app/ft-constants/blog-list-category';
import { TranslateService } from '@components/shared/translate/translate.service';
const logger = Logger.getLogger('BlogListComponent');
const ALL = 'All';

@Component({
  selector: 'ft-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss'],
})
export class BlogListComponent implements OnInit {
  @Input() page;
  @Input() categories;
  @Input() originalBlogs;

  selectedCategory = 'For the Love of Money';
  start: number;
  end: number;
  blogsCategory = [];
  showButton = false;
  totalRecords = 0;
  articleArray = [];
  subscription: Subscription;
  pages = {
    results: [],
    count: 0,
    viewAll: false,
    navigationUrl: '',
  };
  filterData = [];
  totalResults: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  isVisibleDate: string;
  constructor(
    private cdRef: ChangeDetectorRef,
    private searchService: FtsearchService,
    private translateService: TranslateService
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    /* tslint:disable:no-string-literal */
    this.isVisibleDate = this.translateService.instant(
      'ftiArticles.hide-publish-date'
    );
    this.blogsCategory = this.categories?.map((data) => {
      return data['key'];
    });
    /* tslint:enable:no-string-literal */
    this.start = 0;
    this.end = 6;
    if (this.selectedCategory === 'For the Love of Money') {
      this.getFTIBlogData([this.selectedCategory], 0, 6);
    } else {
      this.getFTIBlogData(this.blogsCategory, this.start, this.end);
    }
    const hasCategoryAll: boolean = !!this.categories?.find(
      (category: VocabularyCategories) => {
        return category.key === ALL;
      }
    );
    if (!hasCategoryAll) {
      this.categories?.unshift({ key: ALL, label: ALL });
    }
  }

  getContentBlockData(contentBlockData, charToBeExtracted) {
    if (contentBlockData.length > charToBeExtracted) {
      contentBlockData = contentBlockData.substring(0, charToBeExtracted);
    }
    // Removing logging to avoid logging flood
    logger.debug('newContentData:', contentBlockData);
    return contentBlockData;
  }

  selectCategory(selectedKey) {
    this.selectedCategory = selectedKey;
    if (this.selectedCategory === ALL) {
      this.getFTIBlogData(this.blogsCategory, 0, 6);
    } else if (this.selectedCategory === BlogListCategory.investingInFDMoney) {
      this.getFTIBlogData([this.selectedCategory], 0, 6);
    } else if (this.selectedCategory === BlogListCategory.aSipFinancial) {
      this.getFTIBlogData([this.selectedCategory], 0, 6);
    } else if (
      this.selectedCategory === BlogListCategory.financialPlanningOfSuccess
    ) {
      this.getFTIBlogData([this.selectedCategory], 0, 6);
    } else if (this.selectedCategory === BlogListCategory.investingInEquity) {
      this.getFTIBlogData([this.selectedCategory], 0, 6);
    } else if (
      this.selectedCategory === BlogListCategory.planningYourRetirement
    ) {
      this.getFTIBlogData([this.selectedCategory], 0, 6);
    } else if (this.selectedCategory === BlogListCategory.forTheLoveOfMoney) {
      this.getFTIBlogData([this.selectedCategory], 0, 6);
    }

    const arr = [];
    if (selectedKey !== ALL) {
      this.originalBlogs.forEach((val) => {
        if (val.data.blogCategory === selectedKey) {
          arr.push(val);
        }
      });
    }
  }

  getFTIBlogData(category, start, end) {
    if (start === 0) {
      this.pages.results = [];
      this.articleArray = [];
      this.filterData = [];
    }
    this.filterData = category;
    const filteredValue = [
      { fieldName: 'documentType.exact', fieldValue: ['INDArticleDetails'] },
      { fieldName: 'articleType.keyword', fieldValue: ['Blog'] },
      { fieldName: 'blogCategory.keyword', fieldValue: category },
    ];
    this.end = end;
    /* tslint:disable:no-string-literal */
    this.searchService
      .getFTIBlogResults(JSON.stringify(filteredValue), start, this.end)
      ?.pipe(takeUntil(this.componentDestroyed$))
      ?.subscribe((data: any) => {
        this.pages.results = data['results']?.response?.hits['hits'] || [];
        this.totalResults = data['results']?.response?.hits?.total?.value;
        this.pages.count = this.pages.results.length;
        this.pages.results?.forEach((article) => {
          if (article?._source?.widenAssetJson) {
            const json = JSON.parse(article?._source?.widenAssetJson);
            article._source.image = json?.url;
          }
          if (article?._source?.documentPath) {
            article._source.navigationUrl = article?._source?.documentPath?.split(
              'site-pages'
            )[1];
          }
          this.articleArray.push({
            data: article,
            url: article._source.navigationUrl,
            image: article._source.image,
            date: moment(article?._source?.publishDate).format(
              Constant.dateFormat
            ),
          });
        });
        if (this.pages.results.length < 6) {
          this.showButton = false;
        } else {
          if (this.totalResults <= 6) {
            this.showButton = false;
          } else {
            this.showButton = true;
          }
        }
        this.cdRef.detectChanges();
      });
    /* tslint:enable:no-string-literal */
  }

  loadMore(category, start, end) {
    this.getFTIBlogData(this.filterData, start, end);
  }
}
